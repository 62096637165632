import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  AppBar,
  Toolbar,
  Switch,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
import RedoRoundedIcon from "@material-ui/icons/RedoRounded";
import { BottomCard } from "../../../components";
import {
  layoutComponentsList,
  getPropertiesItems,
  repositoryOperation,
  getComponentCode,
  getComponentMeta,
} from "../../../components/commonFunction";
import { componentsConfig } from "../components/jsonDatas";
import { v4 as uuidv4 } from "uuid";
import isEqual from "react-fast-compare";
import {
  makeid,
  findItemNested,
  cloneComponent,
} from "../../../components/commonFunction";
import { ScreenLayout } from "./screenLayout";
import CodePanel from "./codePanel";
// import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from "@material-ui/core/CircularProgress";
import { templates, DynamicComponentsList } from "./jsonDatas";
import { AuthContext, DialogContext, AlertContext } from "../../../contexts";
import { eleAtrribute } from "./jsonDatas";
import { readDocument, createDocument, updateDocument } from "../../../apis";
import { alertProps } from "../../../utils";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    // backgroundImage:
    //   "linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)",
    // backgroundSize: "20px 20px",
    backgroundColor: theme.palette.lightGray["200"],
    flexGrow: 1,
    padding: "30px 30px 100px 30px",
    display: "flex",
    flexWrap: "wrap",
    minWidth: "2200px",
    maxWidth: "2200px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  screenArea: {
    // width: "320px",
    // height: "520px",
    // margin: "1px auto auto auto",
    padding: "20px",
    paddingBottom: "90px",
  },
  webView: {
    // maxWidth: "1920px",
    maxWidth: "100vw",
    minWidth: "100vw",
    // height: "1080px"
    height: "110vh",
  },
  mobileView: {
    maxWidth: "375px",
    minWidth: "375px",
    minHeight: "812px",
  },
}));

export function BuilderArea(props) {
  const auth = React.useContext(AuthContext);
  const [zoom, setSoom] = useState(0.6);
  const [layoutType, setLayoutType] = useState(1);
  const [selectedScreen, setSelectedScreen] = useState(
    auth.user?.screens.length > 0 ? auth?.user?.screens[0] : {}
  );
  const dialog = React.useContext(DialogContext);

  // This is for the switch
  const [editMode, setEditMode] = useState(false);
  const [isLockedBy, setIsLockedBy] = useState(null);
  const alert = React.useContext(AlertContext);

  const classes = useStyles();
  const isHTMLBlock = (evt, screen_id) => {
    const htmlBlockTags = [
      "div",
      "ol",
      "a",
      "li",
      "aside",
      "footer",
      "section",
      "article",
    ];
    let type = evt.target?.closest(".fielddrag")?.getAttribute("data-name");
    //getting the target element id to check the component type is html
    let typeId = evt.target?.closest(".fielddrag")?.getAttribute("data-id");
    //getting the target element object
    let componentData;
    auth.user.AllScreenList.forEach((screen) => {
      if (screen.id === screen_id) {
        screen.render.child.forEach(function iter(component, index, array) {
          if (component.id === typeId) {
            componentData = component;
          } else {
            if (component?.child?.length > 0) {
              component.child.forEach(iter);
            }
          }
        });
      }
    });
    const isBlock = htmlBlockTags.includes(type);
    return {
      htmlComponent: componentData ?? {},
      isHtmlTagBlock: isBlock,
    };
  };

  const checkDropIsNotItsChild = (dragId, dropId) => {
    let dragComponent;
    auth.user.AllScreenList.forEach((screen) => {
      if (screen.id === auth?.user?.selectedScreen) {
        screen.render.child.forEach(function iter(comp, index, array) {
          if (comp.id === dragId) {
            dragComponent = comp;
          } else {
            if (comp?.child?.length > 0) {
              comp.child.forEach(iter);
            }
          }
        });
      }
    });

    if (!dragComponent?.child) {
      return false;
    }
    if (dragId === dropId) {
      return true;
    }

    let dropComponent;

    dragComponent.child.forEach(function iter(comp, index, array) {
      if (comp.id === dropId) {
        dropComponent = comp;
      } else {
        if (comp?.child?.length > 0) {
          comp.child.forEach(iter);
        }
      }
    });

    if (dropComponent) {
      return true;
    } else {
      return false;
    }
  };

  const onDragEnter = (evt, screen_id) => {
    if (
      auth.user?.screens?.[0]?.readMode === true ||
      (auth.user?.screens?.[0]?.readMode === false &&
        auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy)
    ) {
      return null;
    }
    evt.preventDefault();
    let element = evt.currentTarget;

    if (
      evt.dataTransfer.types[0] === "movetocomponents" ||
      evt.dataTransfer.types[0] === "components" ||
      evt.dataTransfer.types[0] === "componentid" ||
      evt.dataTransfer.types[0] === "template" ||
      evt.dataTransfer.types[0] === "projecttemplate"
    ) {
      evt.dataTransfer.dropEffect = "move";
      // Add the focus backgroundColor class
      let pushTarget = evt.target.closest(".fielddrag")
        ? evt.target.closest(".fielddrag").getAttribute("data-id")
          ? evt.target.closest(".fielddrag").getAttribute("data-id")
          : "new_row"
        : "new_row";
      if (pushTarget === "new_row") {
        element.classList.add("dragged-over-builder");
      } else {
        let type = evt.target.closest(".fielddrag").getAttribute("data-name");
        const isHTMLBlockData = isHTMLBlock(evt, screen_id);
        const componentList = auth?.user?.UIcomponents;
        const closetComponentName = evt.target
          .closest(".fielddrag")
          .getAttribute("data-name");
        let materialBlockComp = componentList.filter(
          (comp) => comp.componentName === closetComponentName
        )[0]?.isLayout;

        if (
          layoutComponentsList(type) ||
          isHTMLBlockData.isHtmlTagBlock ||
          materialBlockComp
        ) {
          let result = checkDropIsNotItsChild(
            localStorage.drag_item_id,
            evt.target.closest(".fielddrag").getAttribute("data-id")
          );
          if (!result) {
            evt.target.closest(".fielddrag").classList.add("mystyle");
          }
          // return false;
        }
      }
    }
  };

  const onDragLeave = (evt) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    let pushTarget = evt.target.closest(".fielddrag")
      ? evt.target.closest(".fielddrag").getAttribute("data-id")
        ? evt.target.closest(".fielddrag").getAttribute("data-id")
        : "new_row"
      : "new_row";

    // Remove the focus backgroundColor class
    if (pushTarget === "new_row") {
      // evt.target.closest(".fielddrag").classList.add("mystyle")
      element.classList.remove("dragged-over-builder");
    } else {
      evt.target.closest(".fielddrag").classList.remove("mystyle");
    }
  };

  const onDragEnd = (evt) => {
    evt.currentTarget.classList.remove("dragged-over-builder");
    if (document.getElementById(evt?.target?.id)?.classList) {
      document.getElementById(evt.target.id).classList.remove("hide");
    }
  };

  const onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
    evt.dataTransfer.effectAllowed = "copyMove";
  };

  const onDrop = (evt, screen_id, componentName) => {
    if (auth.user?.screens?.[0]?.readMode === true) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    if (
      auth.user?.screens?.[0]?.readMode === false &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }

    let drag_item_id = evt.dataTransfer.getData("componentId");
    let move_item_id = evt.dataTransfer.getData("moveToComponents");

    let template_id = evt.dataTransfer.getData("template");
    let project_template_id = evt.dataTransfer.getData("projectTemplate");

    let pushTarget = evt.target.closest(".fielddrag")
      ? evt.target.closest(".fielddrag").getAttribute("data-id")
        ? evt.target.closest(".fielddrag").getAttribute("data-id")
        : "new_row"
      : "new_row";

    let componentObject;

    if (drag_item_id) {
      let componentConfigData = auth?.user?.UIcomponents.filter(
        (item) => item.componentId === drag_item_id
      )[0];
      let componentId = makeid();
      componentObject = {
        type: "component",
        component: componentConfigData.componentName,
        component_type: DynamicComponentsList.some(
          (component) => component.name === drag_item_id
        )
          ? "dynamic"
          : "qdm",
        isLayout: componentConfigData?.isLayout ?? false,
        name: componentConfigData.componentName,
        id: componentId,
        attr: null,
        componentId: componentConfigData.componentId,
        frameWork: componentConfigData.frameWork,
        properties:
          { id: componentId, ...componentConfigData?.defaultProps } ?? {},
        valuePassingKey: componentConfigData?.valuePassingKey ?? "children"
      };
    }

    // ///setting default properties
    // let defaultProperties = {};
    // Object.keys(componentConfigData.properties).forEach((item) => {
    //   if (
    //     componentConfigData.properties[item] ||
    //     componentConfigData.properties[item] === false
    //   ) {
    //     defaultProperties[item] = componentConfigData.properties[item];
    //   }
    // });
    // if (!defaultProperties || !componentConfigData) {
    //   alert("some error with component config..please contact admin");
    //   return;
    // }

    ///setting default properties

    if (drag_item_id === "IdmPanel") {
      let permissionData = auth.user.permissionData;
      auth.user?.AllScreenList &&
        auth.user.AllScreenList.map((screen) => {
          if (!screen.ignore && screen.isPrivate) {
            permissionData = repositoryOperation.addScreen(
              permissionData,
              screen
            );
          }
        });
      if (auth.user?.isIdmEnabled) {
        alert("Idm is already enabled for the component");
        return evt.currentTarget.classList.remove("dragged-over");
      }
      componentObject["properties"] = {
        ...componentObject["properties"],
        MetaDataId: localStorage.metaDataId,
        DbName: localStorage?.projectName,
        keyCloakEndPoint: "orgUserRegistration",
      };
      auth.user.isIdmEnabled = true;
      auth.user.permissionData = permissionData;
    }
    // if((!drag_item_id || drag_item_id?.length === 0 ) && (!move_item_id || move_item_id?.length === 0)){
    //   return false
    // }
    // add new template
    const isHTMLBlockData = isHTMLBlock(evt, screen_id);

    if (evt.dataTransfer.types[0] === "template") {
      let template_name = template_id;
      let getJson = templates.filter((val) => val.name === template_name);
      if (pushTarget === "new_row") {
        addNewComponent(getJson[0].json, screen_id);
        evt.currentTarget.classList.remove("dragged-over");
        evt.preventDefault();
        return false;
      } else {
        let type = evt.target.closest(".fielddrag").getAttribute("data-name");
        if (
          (layoutComponentsList(type) || isHTMLBlockData.isHtmlTagBlock) &&
          getJson[0].type === "component"
        ) {
          updateNewChildComponent(getJson[0].json, pushTarget, screen_id);
        } else {
          alert("Template drop new screen layout only");
        }
        evt.target.closest(".fielddrag").classList.remove("mystyle");
        // return false;
      }
      evt.currentTarget.classList.remove("dragged-over");
      evt.preventDefault();
    }
    //handling project template dropping
    if (evt.dataTransfer.types[0] === "projecttemplate") {
      let template_name = project_template_id;
      let componentData;
      auth.user.projectTemplates.filter((templateGroup) => {
        return templateGroup.templates.some((template) => {
          if (template.name === template_name) {
            componentData = template;
            return true;
          }
        });
      });
      const clonedComponent = cloneComponent(componentData.json);
      let getJson = {
        ...componentData,
        json: clonedComponent,
      };
      if (pushTarget === "new_row") {
        addNewComponent(getJson.json, screen_id);
        evt.currentTarget.classList.remove("dragged-over-builder");
        evt.currentTarget.classList.remove("mystyle");
        evt.preventDefault();
        return false;
      } else {
        evt.target.closest(".fielddrag").classList.remove("mystyle");
        let type = evt.target.closest(".fielddrag").getAttribute("data-name");
        const componentList = auth?.user?.UIcomponents;
        let materialBlockComp = componentList.filter(
          (comp) => comp.componentName === type
        )[0]?.isLayout;
        if (
          (layoutComponentsList(type) ||
            materialBlockComp ||
            isHTMLBlockData.isHtmlTagBlock) &&
          getJson.type === "component"
        ) {
          updateNewChildComponent(getJson.json, pushTarget, screen_id);
        } else {
          alert("Template drop new screen layout only");
        }
        evt.target.closest(".fielddrag").classList.remove("mystyle");
        // return false;
      }
      evt.currentTarget.classList.remove("dragged-over");
      evt.preventDefault();
    }
    // move to component
    if (evt.dataTransfer.types[0] === "movetocomponents") {
      if (move_item_id && move_item_id?.length > 0) {
        let result = checkDropIsNotItsChild(move_item_id, pushTarget);
        if (result) return false;
        moveChildComponent(move_item_id, pushTarget, screen_id);
        if (pushTarget !== "new_row") {
          evt.target.closest(".fielddrag").classList.remove("mystyle");
          evt.target.classList.remove("vivifySmall");
          evt.target.classList.remove("pulsate");
          document.getElementById(move_item_id).classList.remove("hide");
        } else {
          evt.currentTarget.classList.remove("dragged-over-builder");
          evt.currentTarget.classList.remove("mystyle");
        }
        evt.preventDefault();
        evt.currentTarget.classList.remove("dragged-over");
        return false;
      }
    }

    // if(!drag_item_id || drag_item_id?.length <= 0){
    //   return false
    // }

    if (
      evt.dataTransfer.types[0] === "components" ||
      evt.dataTransfer.types[0] === "componentid"
    ) {
      if (pushTarget === "new_row") {
        addNewComponent(componentObject, screen_id);
      } else {
        let type = evt.target.closest(".fielddrag").getAttribute("data-name");
        const componentList = auth?.user?.UIcomponents;
        let materialBlockComp = componentList.filter(
          (comp) => comp.componentName === type
        )[0]?.isLayout;

        console.log(
          layoutComponentsList(type) ||
            materialBlockComp ||
            isHTMLBlockData.isHtmlTagBlock,
          componentObject,
          "componentObject"
        );

        if (
          layoutComponentsList(type) ||
          materialBlockComp ||
          isHTMLBlockData.isHtmlTagBlock
        ) {
          updateNewChildComponent(componentObject, pushTarget, screen_id);
          evt.target.closest(".fielddrag").classList.remove("mystyle");
        }
        //checking the target element (html tag) is a block element to place the children
        // else if (
        //   isHTMLBlockData.isHtmlTagBlock &&
        //   isHTMLBlockData.htmlComponent?.component_type === "html"
        // ) {
        //   updateNewChildComponent(componentObject, pushTarget, screen_id);
        //   // Remove the focus backgroundColor class
        //   if (pushTarget === "new_row") {
        //     // evt.target.closest(".fielddrag").classList.add("mystyle")
        //   } else {
        //     evt.target.closest(".fielddrag").classList.remove("mystyle");
        //   }
        // }
        else {
          return alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: "It's not a layout component",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        }
      }
    }
    evt.currentTarget.classList.remove("dragged-over-builder");
    evt.currentTarget.classList.remove("mystyle");
    evt.preventDefault();
  };

  const zoomInOut = (e, type) => {
    if (type === "zoomIn") {
      let n = zoom + 0.1;
      let number = Math.round(n * 10) / 10;
      if (number === 1.2) return false;
      setSoom(number);
    } else {
      let n = zoom - 0.1;
      let number = Math.round(n * 10) / 10;
      if (number === 0.1) return false;
      setSoom(number);
    }
  };

  const changeLayout = (type) => {
    setLayoutType(type);
  };

  const getComponentId = async (index, component, screenId) => {
    let data2 = getPropertiesItems(component, screenId, eleAtrribute);
    let codePanel = auth.user?.codePanel;
    if (auth?.user?.codePanel?.status) {
      auth.setAuth({
        ...auth.user,
        codePanel: {
          ...auth.user.codePanel,
          loading: true,
        },
      });
      let data = await getComponentCode(
        component.id,
        screenId,
        getComponentMeta(auth, screenId, component.id)
      );
      codePanel = {
        ...codePanel,
        metaData: data?.metaData,
        sourceCode: data?.sourceCode,
        loading: false,
      };
    }
    auth.user.componentAttr = data2;
    auth.setAuth({
      ...auth.user,
      selectedScreen: screenId,
      codePanel: codePanel,
    });
    props.onClose("right", true);
  };

  const moveChildComponent = (move_item_id, pushTarget, screen_id) => {
    // Main parent child update function
    if (pushTarget === "new_row") {
      auth.user.AllScreenList.filter((screen) => {
        if (screen.id === screen_id) {
          let object = findItemNested(
            screen.render.child,
            move_item_id,
            "child"
          );
          deletearr(screen.render.child, move_item_id);
          addNewComponent(object, screen_id);
          return true;
        } else {
          return false;
        }
      });
      return false;
    }

    //   Nested child update function
    if (move_item_id !== pushTarget) {
      auth.user.AllScreenList.filter((screen) => {
        if (screen.id === screen_id) {
          let object = findItemNested(
            screen.render.child,
            move_item_id,
            "child"
          );
          moveChildJson(
            screen.render.child,
            pushTarget,
            move_item_id,
            object,
            screen.render.child
          );
          return true;
        } else {
          return false;
        }
      });
      // this.setState({ ...this.state, isEdited: true});
      auth.setAuth({
        ...auth.user,
        screenEdited: screen_id,
      });
    }
  };
  const deletearr = (arr, key) => {
    const listKey = "id",
      nextLoop = "child";
    arr.map((val, i) => {
      if (val[listKey] === key) {
        arr.splice(i, 1);
      }
      if (nextLoop && val[nextLoop]) {
        deletearr(val[nextLoop], key, listKey, nextLoop);
      }
      return false;
    });
    return arr;
  };

  //   Add New Component function
  const addNewComponent = (object, screen_id) => {
    auth.user.AllScreenList.filter((screen) => {
      if (screen.id === screen_id) {
        return screen.render.child.push(object);
      } else {
        return false;
      }
    });
    auth.setAuth({
      ...auth.user,
      AllScreenList: auth.user.AllScreenList,
      screenEdited: screen_id,
    });
    // this.setState({ ...this.state, isEdited: true });
  };

  // Move or inter change the component JSON  function_2
  const moveChildJson = (screen, child_id, move_item_id, object, screenAll) => {
    if (screen) {
      screen.filter((child, index) => {
        if (child.id === child_id) {
          if (child.child) {
            deletearr(screenAll, move_item_id);
            object.id = move_item_id;
            child.child.push(object);
          } else {
            deletearr(screenAll, move_item_id);
            object.id = move_item_id;
            child["child"] = [object];
          }
          return true;
        } else {
          moveChildJson(child.child, child_id, move_item_id, object, screenAll);
          return true;
        }
      });
      // return true
    } else {
      return false;
    }
  };

  //   Add new component in the child layout component's function
  const updateNewChildComponent = (object, child_id, screen_id) => {
    auth.user.AllScreenList.filter((screen) => {
      if (screen.id === screen_id) {
        updateChildJson(screen.render.child, child_id, object);
        return true;
      } else {
        return false;
      }
    });
    // this.setState({ ...this.state, isEdited: true });
    auth.setAuth({
      ...auth.user,
      screenEdited: screen_id,
    });
  };

  // Looping to find child object
  const updateChildJson = (screen, child_id, object) => {
    if (screen) {
      screen.filter((child, index) => {
        if (child.id === child_id) {
          if (child.child) {
            child.child.push(object);
            if (auth.user.componentAttr.id === child_id) {
            }
          } else {
            child["child"] = [object];
          }

          return true;
        } else {
          updateChildJson(child.child, child_id, object);
          return true;
        }
      });
      // return true
    } else {
      return false;
    }
  };

  const saveScreenToDb = async (editMode) => {
    let currentScreen = auth.user?.screens[0];
    let screenSchema = {
      ...currentScreen,
      readMode: editMode,
      isLockedBy: editMode === false ? auth?.user?.user?.name : null,
    };
    let screenQuery = [
      {
        entity: "screens",
        body: screenSchema,
        filter: {
          id: screenSchema?.id,
          version: screenSchema?.version,
        },
      },
    ];
    let upsert1 = await updateDocument(screenQuery);
    await Promise.all([upsert1])
      .then(function (values) {
        //update the AllScreenList & AllScreenListRef
        let screen = values[0][0]?.properties?.doc;
        let allScreenList = JSON.parse(
          JSON.stringify(auth.user?.AllScreenList)
        );
        allScreenList.forEach((eachScreen, index, array) => {
          if (screen.id === eachScreen?.id) {
            array[index] = screen;
          }
        });
        auth.setAuth({
          ...auth.user,
          AllScreenList: [...allScreenList],
          AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
          screens: allScreenList.filter((val) => val.id === currentScreen.id),
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.success,
          msg: "Screen updated successfully",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "Error while saving screen",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      });
  };

  const handleSaveScreen = async () => {
    // if (auth.user?.screens?.[0]?.readMode === true) {
    //   return alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: alertProps.severity.warning,
    //     msg: "The screen is in read mode",
    //     vertical: alertProps.vertical.top,
    //     horizontal: alertProps.horizontal.right,
    //     tone: true,
    //   });
    // }
    if (
      auth.user?.screens?.[0]?.readMode === false &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    let currentScreen = auth.user?.screens[0];
    let notANewScreen = auth.user?.AllScreenListRef.some(
      (screen) => screen.id === currentScreen.id
    );
    if (notANewScreen) {
      //Checking for changes

      // let [screenRef] = auth.user?.AllScreenListRef.filter(
      //   (screen) => screen.id === currentScreen.id
      // );

      // let isAnythingChanged = isEqual(currentScreen, screenRef);
      // if (isAnythingChanged) {
      //   console.log("Already Saved!");

      //   return alert.setSnack({
      //     ...alert,
      //     open: true,
      //     severity: alertProps.severity.info,
      //     msg: "Screen is already up-to-date",
      //     vertical: alertProps.vertical.top,
      //     horizontal: alertProps.horizontal.right,
      //     tone: true,
      //   });
      // }

      //continue the edit
      const handleOnOkModal = () => {
        saveScreenToDb(false);
        dialog.setDialog({
          ...dialog,
          open: false,
          tone: false,
        });
      };

      //don't continue the edit
      const handClose = () => {
        saveScreenToDb(true);
        dialog.setDialog({
          ...dialog,
          open: false,
          tone: false,
          title: "Delete Alert",
          body: "Do you need to delete this screen?",
          positiveBtn: "Yes",
          negativeBtn: "No",
          onOk: () => {},
          onCancel: () => {},
        });
      };
      dialog.setDialog({
        ...dialog,
        open: true,
        tone: true,
        title: "Continuing Edit",
        body: "Are you going to continuing the screen edit?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: handleOnOkModal,
        onCancel: handClose,
      });
    } else {
      let screenSchema = {
        ...currentScreen,
        version: auth.user.version,
        metadataid: localStorage.metaDataId,
      };

      let screenQuery = [
        {
          entity: "screens",
          body: screenSchema,
        },
      ];
      let upsert1 = await createDocument(screenQuery);
      await Promise.all([upsert1])
        .then(function (values) {
          //update the AllScreenList & AllScreenListRef
          let screen = values[0][0]?.properties?.doc;

          let allScreenList = JSON.parse(
            JSON.stringify(auth.user?.AllScreenList)
          );
          allScreenList.forEach((eachScreen, index, array) => {
            if (screen.id === eachScreen?.id) {
              array[index] = screen;
            }
          });
          auth.setAuth({
            ...auth.user,
            AllScreenList: [...allScreenList],
            AllScreenListRef: [...allScreenList],
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.success,
            msg: "Screen updated successfully",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.error,
            msg: "Error while saving screen",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        });
    }
  };

  const handleSwitchChange = async () => {
    alert.setSnack({
      ...alert,
      open: true,
      severity: alertProps.severity.info,
      msg: `Switching mode...`,
      vertical: alertProps.vertical.top,
      horizontal: alertProps.horizontal.right,
      tone: false,
    });

    let mode = !editMode;
    //check the newly created screen is saved before updating
    let currentScreen = auth.user?.screens[0];
    let allScreenListRef = JSON.parse(
      JSON.stringify(auth.user?.AllScreenListRef)
    );
    // let isCurrentScreenSaved = allScreenListRef.filter(
    //   (screen) => screen.id === currentScreen.id
    // );
    // if (isCurrentScreenSaved.length === 0) {
    //   return alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: alertProps.severity.warning,
    //     msg: "Save the newly created screen",
    //     vertical: alertProps.vertical.top,
    //     horizontal: alertProps.horizontal.right,
    //     tone: true,
    //   });
    // }

    //edit mode
    if (mode === true) {
      let screenQuery = [
        {
          entity: "screens",
          filter: {
            id: auth.user?.selectedScreen,
            version: auth.user?.version ?? 1.0,
          },
        },
      ];
      let result = await readDocument(screenQuery);
      if (result?.screens?.readMode === false) {
        //someone is already editing it
        setIsLockedBy(result?.screens?.isLockedBy);
        console.log(
          `${result?.screens?.isLockedBy} currently editing this screen`
        );
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.warning,
          msg: `${result?.screens?.isLockedBy} currently editing this screen`,
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
        setEditMode(false);
      } else {
        //Set edit mode
        let screenSchema = {
          ...auth.user?.screens[0],
          readMode: false,
          isLockedBy: auth?.user?.user?.name,
        };
        let screenQuery = [
          {
            entity: "screens",
            body: screenSchema,
            filter: {
              id: screenSchema?.id,
              metadataid: localStorage.metaDataId,
              version: screenSchema?.version,
            },
          },
        ];
        await updateDocument(screenQuery)
          .then(() => {
            let allScreenList = JSON.parse(
              JSON.stringify(auth.user?.AllScreenList)
            );
            allScreenList.forEach((eachScreen, index, array) => {
              if (currentScreen.id === eachScreen?.id) {
                array[index] = screenSchema;
              }
            });
            auth.setAuth({
              ...auth.user,
              screens: allScreenList.filter(
                (val) => val.id === currentScreen.id
              ),
              AllScreenList: [...allScreenList],
              AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
            });
            setEditMode(true);
            setIsLockedBy("You");
            alert.setSnack({
              ...alert,
              open: true,
              severity: alertProps.severity.success,
              msg: `Edit Mode enabled`,
              vertical: alertProps.vertical.top,
              horizontal: alertProps.horizontal.right,
              tone: true,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      let screenRef = allScreenListRef.find(
        (screen) => screen.id === currentScreen.id
      );
      let isItEqual = isEqual(screenRef, currentScreen);
      if (!isItEqual) {
        return alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.warning,
          msg: "Please save the changes",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      }
      //read mode
      let screenSchema = {
        ...auth.user?.screens[0],
        readMode: true,
        isLockedBy: "",
      };
      let screenQuery = [
        {
          entity: "screens",
          body: screenSchema,
          filter: {
            id: screenSchema?.id,
            version: screenSchema?.version,
          },
        },
      ];
      await updateDocument(screenQuery)
        .then(() => {
          let allScreenList = JSON.parse(
            JSON.stringify(auth.user?.AllScreenList)
          );
          allScreenList.forEach((eachScreen, index, array) => {
            if (currentScreen.id === eachScreen?.id) {
              array[index] = screenSchema;
            }
          });
          auth.setAuth({
            ...auth.user,
            screens: allScreenList.filter((val) => val.id === currentScreen.id),
            AllScreenList: [...allScreenList],
            AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
          });
          setEditMode(false);
          setIsLockedBy(null);
          alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.success,
            msg: `Read Mode enabled`,
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const undoRedo = (type) => {
    if (auth.user?.screens?.[0]?.readMode === true) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    if (
      auth.user?.screens?.[0]?.readMode === false &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    let undoData = null;
    let currentScreen = null;
    if (type === "redo") {
      undoData = JSON.parse(
        JSON.stringify(auth.user.screensHistory[auth.user.selectedScreen])
      );
      currentScreen =
        undoData[auth.user.screensActiveIndex[auth.user.selectedScreen] + 1];
    } else {
      undoData = JSON.parse(
        JSON.stringify(auth.user.screensHistory[auth.user.selectedScreen])
      );
      currentScreen =
        undoData[auth.user.screensActiveIndex[auth.user.selectedScreen] - 1];
    }
    // let data2 = null;
    // if (Object.keys(auth.user.componentAttr).length > 0) {
    //   data2 = getPropertiesItems(
    //     auth.user.componentAttr,
    //     auth.user.selectedScreen,
    //     eleAtrribute
    //   );
    // }

    let allScreenListCopy = JSON.parse(JSON.stringify(auth.user.AllScreenList));

    allScreenListCopy.forEach((screen, index, array) => {
      if (screen.id === auth.user.selectedScreen) {
        array[index] = {
          ...currentScreen,
          readMode: false,
          isLockedBy: auth.user?.user?.name,
        };
      }
    });

    auth.setAuth({
      ...auth.user,
      AllScreenList: allScreenListCopy,
      screensActiveIndex: {
        ...auth.user.screensActiveIndex,
        [auth.user.selectedScreen]:
          type === "undo"
            ? auth.user.screensActiveIndex[auth.user.selectedScreen] - 1
            : auth.user.screensActiveIndex[auth.user.selectedScreen] + 1,
      },
      screens: [
        {
          ...currentScreen,
          readMode: false,
          isLockedBy: auth.user?.user?.name,
        },
      ],
      // componentAttr: data2 ? data2 : {},
      selectedScreen: currentScreen.id,
    });
    props.onClose("right", false);
  };

  const handleKeyboardSave = (e) => {
    let key = e.keyCode;
    if (key === 83 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      console.log("Ctrl + S Pressed !");
      handleSaveScreen();
    } else if (
      key === 90 &&
      (e.ctrlKey || e.metaKey) &&
      auth.user.undoRedoData.length > 1 &&
      auth.user.activeIndex !== 0
    ) {
      e.preventDefault();
      console.log("Ctrl + Z Pressed !");
      undoRedo("undo");
    } else if (
      key === 89 &&
      (e.ctrlKey || e.metaKey) &&
      auth.user.activeIndex < auth.user.undoRedoData.length - 1
    ) {
      e.preventDefault();
      console.log("Ctrl + Y Pressed !");
      undoRedo("redo");
    }
  };

  useEffect(() => {
    if (auth?.user?.screens.length > 0) {
      setSelectedScreen(auth?.user?.screens[0]);
    }
    let screenReadMode = auth.user?.screens[0]?.readMode;
    if (screenReadMode === false) {
      if (auth.user?.user?.name === auth.user?.screens[0]?.isLockedBy) {
        setEditMode(true);
        setIsLockedBy("You");
      } else {
        setEditMode(false);
        setIsLockedBy(auth.user?.screens[0]?.isLockedBy);
      }
    } else {
      setEditMode(false);
      setIsLockedBy(null);
    }
  }, [auth.user]);

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyboardSave);
    return () => {
      window.removeEventListener("keydown", handleKeyboardSave);
    };
  }, [auth]);

  // const onChange = (e, newValue) => {
  //   let val = newValue.title;
  //   props.changeProjectName(val);
  // }
  console.log(auth.user.activeIndex);
  return (
    <div>
      {auth?.user?.screens?.length === 1 && (
        <AppBar
          position="static"
          color={"inherit"}
          elevation={0}
          style={{
            position: "fixed",
            width: props?.leftMenu ? "calc(100% - 355px)" : "calc(100% - 85px)",
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
              minHeight: "48px",
              borderBottom: "1px solid #ebebeb",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontWeight: 500,
                  marginRight: "86px",
                  fontSize: "14px",
                }}
              >
                {selectedScreen?.name ?? ""}
              </Typography>
              <Typography component="div">
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography style={{ fontSize: "12px" }}>
                      Read Mode
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      size="small"
                      checked={editMode}
                      onChange={handleSwitchChange}
                      name="checkedC"
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: "12px" }}>
                      Edit Mode
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              {isLockedBy && (
                <div
                  style={{
                    display: "flex",
                    marginLeft: "44px",
                    alignItems: "center",
                  }}
                >
                  <EditIcon style={{ fontSize: "14px" }} />
                  <Typography
                    style={{
                      marginLeft: "6px",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    {isLockedBy} currently editing this screen
                  </Typography>
                </div>
              )}
            </div>
            {auth?.user &&
              auth.user?.screens[0]?.readMode === false &&
              auth.user?.user?.name == auth.user?.screens[0]?.isLockedBy &&
              Object.keys(auth.user.screensHistory).length > 0 &&
              Object.keys(auth.user.screensActiveIndex).length > 0 && (
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <>
                    <IconButton
                      onClick={() => undoRedo("undo")}
                      disabled={
                        auth.user.screensHistory[auth.user.selectedScreen]
                          ?.length > 1 &&
                        auth.user.screensActiveIndex[
                          auth.user.selectedScreen
                        ] !== 0
                          ? false
                          : true
                      }
                      disableFocusRipple={
                        auth.user.screensHistory[auth.user.selectedScreen]
                          ?.length > 1 &&
                        auth.user.screensActiveIndex[
                          auth.user.selectedScreen
                        ] !== 0
                          ? false
                          : true
                      }
                      disableRipple={
                        auth.user.screensHistory[auth.user.selectedScreen]
                          ?.length > 1 &&
                        auth.user.screensActiveIndex[
                          auth.user.selectedScreen
                        ] !== 0
                          ? false
                          : true
                      }
                      style={{ marginRight: "12px" }}
                      size="small"
                    >
                      <UndoRoundedIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => undoRedo("redo")}
                      disabled={
                        auth.user.screensActiveIndex[
                          auth.user.selectedScreen
                        ] ===
                        auth.user.screensHistory[auth.user.selectedScreen]
                          ?.length -
                          1
                          ? true
                          : false
                      }
                      disableFocusRipple={
                        auth.user.screensActiveIndex[
                          auth.user.selectedScreen
                        ] ===
                        auth.user.screensHistory[auth.user.selectedScreen]
                          ?.length -
                          1
                          ? true
                          : false
                      }
                      disableRipple={
                        auth.user.screensActiveIndex[
                          auth.user.selectedScreen
                        ] ===
                        auth.user.screensHistory[auth.user.selectedScreen]
                          ?.length -
                          1
                          ? true
                          : false
                      }
                      className={classes.margin}
                      size="small"
                    >
                      <RedoRoundedIcon />
                    </IconButton>
                  </>
                  <Button
                    disabled={auth.user.loading}
                    onClick={handleSaveScreen}
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    style={{ marginLeft: "24px" }}
                    disableElevation
                  >
                    Save Screen
                  </Button>
                </div>
              )}
          </Toolbar>
        </AppBar>
      )}
      <div className={classes.root}>
        {auth.user.loading && (
          // <Skeleton>
          //    <ScreenLayout
          //       previewScreen={props.previewScreen}
          //       screen={{}}
          //    />
          // </Skeleton>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "100vh",
              zoom: 0.6,
            }}
          >
            <CircularProgress
              disableShrink
              style={{ margin: "auto", width: "60px", height: "60px" }}
            />
          </div>
        )}
        {!auth.user.loading &&
          auth?.user?.screens?.length > 0 &&
          auth?.user?.screens.map((screen, index) => {
            return (
              <ScreenLayout
                deleteOldScreen={props.deleteOldScreen}
                selectedAllScreen={
                  auth?.user?.screens?.length > 1 ? true : false
                }
                key={screen?.id}
                previewScreen={props.previewScreen}
                screen={screen}
                index={index}
                zoom={zoom}
                layoutType={layoutType}
                draggable={auth?.user?.screens?.length > 1 ? false : true}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                onDrop={onDrop}
                rightMenu={props.rightMenu}
                getComponentId={getComponentId}
                onClose={props.onClose}
              />
            );
          })}
        {/* error or no data */}
        {!auth.user.loading &&
          auth?.user?.screens &&
          auth?.user?.screens?.length === 0 && (
            <div className={classes.screenArea}>
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <Typography>{"Create new screen..."}</Typography>
              </div>
            </div>
          )}

        <BottomCard
          zoomInOut={zoomInOut}
          zoom={zoom}
          layoutType={layoutType}
          changeLayout={changeLayout}
          onSwitchPreview={props.onSwitchPreview}
          previewScreen={props.previewScreen}
        />
        {auth?.user?.codePanel?.status && <CodePanel />}
      </div>
    </div>
  );
}
