import React from "react";
import { Chart } from "react-google-charts";
import {
  Typography,
  Button,
  IconButton,
  Collapse,
  ListItem,
  Box,
} from "@material-ui/core";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { DialogContext, AlertContext } from "../../../../contexts";
import { getRandomColor } from "../../../../components/commonFunction";
import { alertProps } from "../../../../utils";
import {
  NumberInput,
  TextInput,
  SelectInput,
  ColorInput,
} from "../components.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  iconButton: {
    padding: "6px",
  },
}));

//chart component-https://react-google-charts.com/

export function DynamicChart(props) {
  const { properties, ...remainingproperties } = props;
  const [chartProperties, setchartProperties] = React.useState(properties);

  //This is for changing chart height & width immediately when changing parent w&h
  React.useEffect(() => {
    setTimeout(() => {
      setchartProperties({
        ...properties,
      });
    }, 500);
  }, [properties]);

  return (
    <div {...remainingproperties}>
      {(properties?.chartData?.data?.length === 0 ||
        Object.keys(properties).length === 0) && (
        <Typography style={{ textAlign: "center", fontSize: "18px" }}>
          Select chart
        </Typography>
      )}

      {chartProperties &&
        chartProperties?.chartData?.data?.length > 0 &&
        chartProperties.chartType !== "Select" && (
          <Chart
            key={remainingproperties?.id}
            chartType={`${
              chartProperties?.chartData?.options?.isColumn
                ? "Column"
                : chartProperties.chartType
            }Chart`}
            loader={<div>Loading Chart</div>}
            data={chartProperties?.chartData?.data}
            options={chartProperties?.chartData?.options}
            width={"100%"}
            height={"100%"}
          />
        )}
    </div>
  );
}

//chart component property renderer
export function ChartPropertiesRenderer(props) {
  //this are from property panel
  const {
    data,
    languageOptions,
    setMessageCatalogDialog,
    allLanguages,
    setMessageCatalog,
    messageCatalog,
    handleChange,
  } = props;

  const dialog = React.useContext(DialogContext);
  const alert = React.useContext(AlertContext);

  const classes = useStyles();

  const [chartType, setChartType] = React.useState(data?.properties?.chartType);
  const [chartData, setChartData] = React.useState(data?.properties?.chartData);
  const [labelType, setLabelType] = React.useState("Text");
  const [datasetCount, setDatasetCount] = React.useState(1);
  const [open, setOpen] = React.useState({});
  const [mouseOver, setMouseOver] = React.useState(false);

  //Chart type handler
  const handleChartType = (label, value) => {
    setChartType(value);
    handleChange("properties", "chartType", value);
    const datasetCountData = chartData?.data[0]?.length - 1;
    chartData["options"]["isColumn"] = false;
    if (datasetCountData) {
      setDatasetCount(datasetCountData);
    }
    handleChange("properties", "chartData", {
      ...chartData,
    });
  };

  //collapse handler
  const handleClick = (id) => {
    setOpen({
      [id]: open[id] ? !open[id] : true,
    });
  };
  const handleOptionsClick = (id) => {
    setOpen({
      ...open,
      [id]: open[id] ? !open[id] : true,
    });
  };

  //One dataset is like one column of data in a table
  //We can increase or decrease the dataset count
  const handleDatasetCount = (label, val) => {
    if (val < 1) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "Minimum 1 dataset is required",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    } else if (val > 10) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "Mximum 10 dataset is allowed",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    } else if (chartType === "Pie") {
      alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "Only 1 dataset is allowed in pie chart",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    } else {
      if (val > datasetCount) {
        setDatasetCount(val);
      } else {
        const datasetTitleToDelete = chartData?.data[0][val + 1];
        if (!datasetTitleToDelete) {
          return setDatasetCount(val);
        }
        const handleOnOkModal = () => {
          chartData?.data &&
            chartData.data.forEach((row) => {
              row.splice(val, 1);
            });
          setChartData({
            ...chartData,
          });
          handleChange("properties", "chartData", { ...chartData });
          setDatasetCount(val);
          handClose();
        };
        const handClose = () => {
          dialog.setDialog({
            ...dialog,
            open: false,
            tone: false,
            title: "Delete Alert",
            body: "Do you need to delete this row?",
            positiveBtn: "Yes",
            negativeBtn: "No",
            onOk: () => {},
            onCancel: () => {},
          });
        };
        dialog.setDialog({
          ...dialog,
          open: true,
          tone: true,
          title: "Delete Alert",
          body: `Are you sure?, you want to delete all the datas of ${datasetTitleToDelete}?`,
          positiveBtn: "Yes",
          negativeBtn: "No",
          onOk: handleOnOkModal,
          onCancel: handClose,
        });
      }
    }
  };

  //this is for handling text related content of the chart properties
  const handleAutocompleteChange = (key, v) => {
    if (key === "Chart Title") {
      chartData["options"]["title"] = v;
    } else if (key === "Label Title") {
      if (chartData?.data?.length > 0) {
        chartData["data"][0][0] = v;
      } else {
        chartData.data.push([v]);
      }
    } else if (key === "Label Type") {
      if (v === "Text") {
        chartData["data"][1] = ["label", 5, 12];
      } else {
        chartData["data"][1] = [0, 5, 12];
      }
      setLabelType(v);
    } else if (key.includes("Dataset")) {
      const datasetIndex = key.slice(8, 9);
      chartData["data"][0][datasetIndex] = v;
    } else if (key === "Bar Chart Type") {
      if (v === "Column") {
        chartData["options"]["isColumn"] = true;
        chartData["options"]["isStacked"] = false;
      } else if (v === "Stacked") {
        chartData["options"]["isStacked"] = true;
        chartData["options"]["isColumn"] = false;
      } else {
        chartData["options"]["isStacked"] = false;
        chartData["options"]["isColumn"] = false;
      }
    } else if (key === "v-Axis Title") {
      chartData["options"]["vAxis"]["title"] = v;
    } else if (key === "h-Axis Title") {
      chartData["options"]["hAxis"]["title"] = v;
    } else {
      const labelIndex = key.slice(6);
      chartData["data"][labelIndex][0] = v;
    }
    setChartData({
      ...chartData,
    });
    handleChange("properties", "chartData", { ...chartData }, "languageLabel");
  };

  //chart each data handler
  const handleDataChange = (key, v, rowIndex, dataIndex) => {
    const datasetTitle = chartData["data"][0][dataIndex];
    if (!datasetTitle) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `Please add Dataset-${dataIndex} Title before adding data`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    chartData["data"][rowIndex][dataIndex] = v;
    setChartData({
      ...chartData,
    });
    handleChange("properties", "chartData", { ...chartData });
  };

  //chart color handler
  const handleColorChange = (key, index, val) => {
    const colorData = {
      color: val,
    };
    chartData["options"][key][index] = colorData;
    setChartData({
      ...chartData,
    });
    handleChange("properties", "chartData", { ...chartData });
  };

  // This will add a row, for e.g like a row in a table (label & datsets(based on the count))
  const handleAddRow = () => {
    const newRow =
      labelType === "Text" ? ["label"] : [chartData?.data?.length - 1];
    Array.apply(null, { length: datasetCount }).map((data, index) => {
      return newRow.push(5 + index);
    });
    chartData.data.push(newRow);
    if (chartData?.options?.colors) {
      chartData.options.colors.push({
        color: getRandomColor(),
      });
    } else {
      const colors = [{ color: getRandomColor() }];
      chartData.options = { colors };
    }
    setChartData({
      ...chartData,
    });
    handleChange("properties", "chartData", { ...chartData });
  };

  //these are for to show delete icon only on mouse over for deleting a row
  const handleMouseOver = (id) => {
    if (!mouseOver[id]) {
      setMouseOver({
        [id]: true,
      });
    }
  };
  const handleMouseOut = (id) => {
    if (mouseOver[id]) {
      setMouseOver({
        [id]: false,
      });
    }
  };

  //deleting a row of chart (label & datsets(based on the count))
  const handleRowDelete = (index) => {
    const handleOnOkModal = () => {
      chartData.data.splice(index, 1);
      chartData.options.colors.splice(index, 1);
      setChartData({
        ...chartData,
      });
      handleChange("properties", "chartData", { ...chartData });
      handClose();
    };
    const handClose = () => {
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
        title: "Delete Alert",
        body: "Do you need to delete this row?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => {},
        onCancel: () => {},
      });
    };
    dialog.setDialog({
      ...dialog,
      open: true,
      tone: true,
      title: "Delete Alert",
      body: "Do you need to delete this row?",
      positiveBtn: "Yes",
      negativeBtn: "No",
      onOk: handleOnOkModal,
      onCancel: handClose,
    });
  };

  React.useEffect(() => {
    if (data?.properties?.chartData?.data.length > 0) {
      const datasetCountData = data?.properties?.chartData?.data[0]?.length - 1;
      if (datasetCountData) {
        setDatasetCount(datasetCountData);
      }
    }
  }, [data]);

  return (
    <>
      {/* Chart type select*/}
      <SelectInput
        value={chartType ?? "Select"}
        label="Chart Type"
        options={chartOptions}
        handleAutocompleteChange={handleChartType}
      />

      {chartData &&
        chartOptions.filter((eachType) => eachType.title === chartType).length >
          0 && (
          <Box style={{ display: "grid", gridGap: "6px" }}>
            {/* Chart-label title, label type(only for line chart),  Number of dataset, Dataset title creation*/}
            <Typography
              variant="body1"
              style={{ fontSize: "14px", margin: "2px 0px" }}
            >
              {chartType} Chart
            </Typography>
            <TextInput
              label="Chart Title"
              value={chartData?.options?.title}
              {...{
                handleAutocompleteChange,
                languageOptions,
                setMessageCatalogDialog,
                allLanguages,
                setMessageCatalog,
                messageCatalog,
              }}
            />
            <Box style={{ display: "grid", gridGap: "6px", marginTop: "12px" }}>
              <Box
                display="grid"
                gridTemplateColumns={`repeat(${
                  chartType === "Line" && chartData?.data?.length < 3 ? 2 : 1
                }, minmax(0, 1fr))`}
                gridGap="8px"
              >
                <TextInput
                  label="Label Title"
                  value={
                    chartData?.data?.length > 0 ? chartData?.data[0][0] : ""
                  }
                  {...{
                    handleAutocompleteChange,
                    languageOptions,
                    setMessageCatalogDialog,
                    allLanguages,
                    setMessageCatalog,
                    messageCatalog,
                  }}
                />
                {chartType === "Line" && chartData?.data?.length < 3 && (
                  <SelectInput
                    value={labelType}
                    label="Label Type"
                    options={[{ title: "Text" }, { title: "Number" }]}
                    handleAutocompleteChange={handleAutocompleteChange}
                  />
                )}
              </Box>
              {chartData?.data?.length > 0 && chartData?.data[0][0] && (
                <>
                  {chartType !== "Pie" && (
                    <Box
                      display="grid"
                      alignItems="center"
                      justifyContent="center"
                      gridTemplateColumns="50% 50%"
                      margin="0px auto"
                    >
                      <Typography style={{ fontSize: "14px" }}>
                        Number of dataset
                      </Typography>
                      <NumberInput
                        label="Dataset Count"
                        value={datasetCount}
                        onChange={(label, val) => {
                          handleDatasetCount(label, val);
                        }}
                      />
                    </Box>
                  )}
                  <Box
                    display="grid"
                    style={{
                      gridTemplateColumns: `repeat(${
                        datasetCount > 1 && chartType !== "Pie" ? 2 : 1
                      }, minmax(0, 1fr))`,
                      gridGap: `${datasetCount > 1 ? "8px" : "0px"}`,
                    }}
                  >
                    {Array.apply("null", {
                      length: chartType === "Pie" ? 1 : datasetCount,
                    }).map((data, index) => {
                      return (
                        <TextInput
                          label={`Dataset-${index + 1} Title`}
                          value={
                            chartData?.data.length > 0
                              ? chartData?.data[0][index + 1]
                              : ""
                          }
                          {...{
                            handleAutocompleteChange,
                            languageOptions,
                            setMessageCatalogDialog,
                            allLanguages,
                            setMessageCatalog,
                            messageCatalog,
                          }}
                        />
                      );
                    })}
                  </Box>
                </>
              )}
            </Box>

            {chartData?.data?.length > 0 &&
              chartData?.data[0][0] &&
              chartData?.data[0][1] && (
                <Box>
                  {/* Chart datas*/}
                  <Box display="grid" gridGap="6px">
                    <Typography style={{ fontSize: "14px", marginTop: "6px" }}>
                      Chart Data
                    </Typography>
                    {chartData?.data?.length > 1 &&
                      chartData?.data.map((eachData, index) => {
                        if (index === 0) {
                          return null;
                        } else {
                          return (
                            <>
                              <ListItem
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "default",
                                  marginBottom: "4px",
                                  padding: "0px 2px",
                                }}
                                button
                                onMouseEnter={() =>
                                  handleMouseOver(`label-${index}`)
                                }
                                onMouseLeave={() =>
                                  handleMouseOut(`label-${index}`)
                                }
                                onClick={() => handleClick(`label-${index}`)}
                              >
                                {open[`label-${index}`] ?? false ? (
                                  <ArrowRightRoundedIcon color={"action"} />
                                ) : (
                                  <ArrowDropDownRoundedIcon color={"action"} />
                                )}
                                <Typography
                                  style={{
                                    width: "inherit",
                                    cursor: "default",
                                    padding: "8px 0px",
                                  }}
                                  variant="body2"
                                >
                                  {eachData[0]}
                                </Typography>
                                {mouseOver[`label-${index}`] && (
                                  <IconButton
                                    onClick={() => handleRowDelete(index)}
                                    className={classes.iconButton}
                                    color="action"
                                    aria-label="delete"
                                  >
                                    <DeleteOutlinedIcon
                                      style={{ fontSize: 14 }}
                                    />
                                  </IconButton>
                                )}
                              </ListItem>
                              <Collapse
                                in={open[`label-${index}`] ? true : false}
                                timeout="auto"
                                unmountOnExit
                              >
                                {!isNaN(eachData[0]) ||
                                labelType === "Number" ? (
                                  <NumberInput
                                    label={`label-${index}`}
                                    value={eachData[0]}
                                    onChange={handleAutocompleteChange}
                                  />
                                ) : (
                                  <Box display="grid" gridGap="6px">
                                    <TextInput
                                      label={`label-${index}`}
                                      value={eachData[0]}
                                      {...{
                                        handleAutocompleteChange,
                                        languageOptions,
                                        setMessageCatalogDialog,
                                        allLanguages,
                                        setMessageCatalog,
                                        messageCatalog,
                                      }}
                                    />
                                  </Box>
                                )}
                                <Box
                                  display="grid"
                                  style={{
                                    gridTemplateColumns: `repeat(${
                                      datasetCount > 1 ? 2 : 1
                                    }, minmax(0, 1fr))`,
                                    gridGap: `${
                                      datasetCount > 1 ? "8px" : "0px"
                                    }`,
                                  }}
                                >
                                  {Array.apply("null", {
                                    length:
                                      chartType === "Pie" ? 1 : datasetCount,
                                  }).map((value, dataIndex) => {
                                    return (
                                      <NumberInput
                                        label={`Data-${dataIndex + 1}`}
                                        value={eachData[dataIndex + 1]}
                                        onChange={(key, val) =>
                                          handleDataChange(
                                            key,
                                            val,
                                            index,
                                            dataIndex + 1
                                          )
                                        }
                                      />
                                    );
                                  })}
                                </Box>
                              </Collapse>
                            </>
                          );
                        }
                      })}
                    {/* Chart data row add*/}
                    <Box
                      style={{
                        display: "grid",
                        placeItems: "center",
                        marginBottom: "6px",
                      }}
                    >
                      <Button
                        onClick={handleAddRow}
                        variant={"outlined"}
                        color={"primary"}
                        size="small"
                        style={{ fontSize: "10px" }}
                      >
                        Add Row
                      </Button>
                    </Box>
                  </Box>

                  {/* Chart options*/}
                  {chartData?.data?.length > 1 && (
                    <Box display="grid" gridGap="6px">
                      <ListItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "default",
                          padding: "0px 2px",
                        }}
                        button
                        onClick={() => handleOptionsClick("options")}
                      >
                        {open["options"] ?? false ? (
                          <ArrowRightRoundedIcon color={"action"} />
                        ) : (
                          <ArrowDropDownRoundedIcon color={"action"} />
                        )}
                        <Typography
                          style={{
                            width: "inherit",
                            cursor: "default",
                            padding: "8px 0px",
                          }}
                          variant="body2"
                        >
                          Options
                        </Typography>
                      </ListItem>
                      <Collapse
                        in={open["options"] ? true : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box
                          display="grid"
                          gridGap="6px"
                          style={{ margin: "0px 6px" }}
                        >
                          {/* chart type only for bar chart*/}
                          <Box>
                            {chartType === "Bar" && (
                              <SelectInput
                                value={
                                  chartData?.options?.isColumn === true
                                    ? "Column"
                                    : chartData?.options?.isStacked === true
                                    ? "Stacked"
                                    : "Row"
                                }
                                label="Bar Chart Type"
                                options={[
                                  { title: "Row" },
                                  { title: "Column" },
                                  { title: "Stacked" },
                                ]}
                                handleAutocompleteChange={
                                  handleAutocompleteChange
                                }
                              />
                            )}
                          </Box>

                          {/* chart colors option*/}
                          <Box>
                            <ListItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "default",
                                marginBottom: "4px",
                                padding: "0px 2px",
                              }}
                              onClick={() => handleOptionsClick("colors")}
                              button
                            >
                              {open["colors"] ?? false ? (
                                <ArrowRightRoundedIcon color={"action"} />
                              ) : (
                                <ArrowDropDownRoundedIcon color={"action"} />
                              )}
                              <Typography
                                style={{
                                  width: "inherit",
                                  cursor: "default",
                                  padding: "8px 0px",
                                }}
                                variant="body2"
                              >
                                Colors
                              </Typography>
                            </ListItem>
                            <Collapse
                              in={open["colors"] ? true : false}
                              timeout="auto"
                              unmountOnExit
                            >
                              {chartType === "Pie" &&
                                chartData?.data
                                  ?.slice(1)
                                  .map((color, index) => (
                                    <ColorInput
                                      label={`Data-${index + 1} Color`}
                                      index={index}
                                      value={
                                        chartData?.options?.colors?.length > 0
                                          ? chartData?.options?.colors[index]
                                              ?.color
                                          : getRandomColor()
                                      }
                                      handleColorChange={handleColorChange}
                                    />
                                  ))}
                              {chartType !== "Pie" &&
                                Array.apply("null", {
                                  length:
                                    chartType === "Pie"
                                      ? datasetCount - 1
                                      : datasetCount,
                                }).map((color, index) => (
                                  <ColorInput
                                    label={`Dataset-${index + 1} Color`}
                                    index={index}
                                    value={
                                      chartData?.options?.colors?.length > 0
                                        ? chartData?.options?.colors[index]
                                            ?.color
                                        : getRandomColor()
                                    }
                                    handleColorChange={handleColorChange}
                                  />
                                ))}
                            </Collapse>
                          </Box>
                        </Box>

                        {/* chart h-Axis Title & h-Axis Title*/}
                        {chartType !== "Pie" && (
                          <Box
                            display="grid"
                            style={{
                              gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
                              gridGap: "8px",
                              margin: "0px 6px",
                            }}
                          >
                            <TextInput
                              label={"h-Axis Title"}
                              value={chartData?.options?.hAxis?.title ?? ""}
                              {...{
                                handleAutocompleteChange,
                                languageOptions,
                                setMessageCatalogDialog,
                                allLanguages,
                                setMessageCatalog,
                                messageCatalog,
                              }}
                            />
                            <TextInput
                              label={"v-Axis Title"}
                              value={chartData?.options?.vAxis?.title ?? ""}
                              {...{
                                handleAutocompleteChange,
                                languageOptions,
                                setMessageCatalogDialog,
                                allLanguages,
                                setMessageCatalog,
                                messageCatalog,
                              }}
                            />
                          </Box>
                        )}
                      </Collapse>
                    </Box>
                  )}
                </Box>
              )}
          </Box>
        )}
    </>
  );
}

//charts we are providing
export const chartOptions = [
  { title: "Pie" },
  { title: "Bar" },
  { title: "Line" },
];
