import config from "../config";
import { networkCall } from "../networkcall";

export const readDocument = async (query, options) => {
  //query structure
  //  let query = [
  //    {
  //      entity: "uibuilder",
  //      filter: {
  //        metadataId: metaDataId,
  //        version:47
  //      },
  //    },
  //    {
  //      entity: "screens",
  //      filter: {
  //        id: id,
  //      },
  //    },
  //  ];

  //options
  // options = {
  //   limit: 3,
  //   sort: "",
  // };

  let querySchema = Array.isArray(query) ? query : [query];

  let entityNames = querySchema.reduce((entity, eachEntity) => {
    return [...entity, eachEntity.entity];
  }, []);

  let filters = querySchema.reduce((filter, eachEntity) => {
    let entityName = eachEntity.entity;
    let filterValue = eachEntity.filter;
    let result = Object.entries(filterValue).reduce(
      (allFilters, currentFilter) => {
        let eachResult = entityName
          .concat(".", currentFilter[0])
          .concat(
            "==",
            typeof currentFilter[1] === "number" ||
              typeof currentFilter[1] === "boolean"
              ? currentFilter[1]
              : `'${currentFilter[1]}'`
          );
        return [...allFilters, eachResult];
      },
      []
    );
    return [...filter, result.join("&&")];
  }, []);

  let sorting = querySchema.reduce((sort, eachEntity) => {
    let result = `TO_NUMBER(${eachEntity.entity}._key) DESC`;
    return [...sort, result];
  }, []);

  let returnFields = querySchema.reduce((return_fields, eachEntity) => {
    let result = "{" + eachEntity.entity + "}";
    return [...return_fields, result];
  }, []);

  let payload = {
    db_name: config.db_name,
    entity: entityNames.join(","),
    filter: filters.join("&&"),
    limit: {
      count: options?.limit ?? 1,
    },
    sort: options?.sort ?? sorting.join(","),
    ismime_read: "true",
    return_fields: `[${returnFields.join(",")}]`,
  };

  let response = await networkCall(
    config.api_url + "/read_documents",
    "POST",
    payload
  );

  if (response?.data?.Code !== 201) {
    console.log(
      response?.data?.error ?? "Something went wrong when reading documents",
      query
    );
    return {};
  }

  let result = response?.data?.result[0] ?? [];
  if (result.length === 0) return {};
  let data = result.reduce((acc, curr) => {
    let result = {
      ...acc,
      [Object.keys(curr)[0]]: Object.values(curr)[0],
    };
    return result;
  }, {});
  return data ?? {};
};

export const readDocuments = async (query, options) => {
  //query structure
  //  let query = [
  //    {
  //      entity: "uibuilder",
  //      filter: {
  //        metadataId: metaDataId,
  //        version:47
  //      },
  //    }
  //  ];

  //options
  // options = {
  //   limit: 3,
  //   sort: "",
  // };

  let querySchema = Array.isArray(query) ? query : [query];

  let entityNames = querySchema.reduce((entity, eachEntity) => {
    return [...entity, eachEntity.entity];
  }, []);

  let filters = querySchema.reduce((filter, eachEntity) => {
    let entityName = eachEntity.entity;
    let filterValue = eachEntity.filter;
    let result = Object.entries(filterValue).reduce(
      (allFilters, currentFilter) => {
        let eachResult = entityName
          .concat(".", currentFilter[0])
          .concat(
            "==",
            typeof currentFilter[1] === "number" ||
              typeof currentFilter[1] === "boolean"
              ? currentFilter[1]
              : `'${currentFilter[1]}'`
          );
        return [...allFilters, eachResult];
      },
      []
    );
    return [...filter, result.join("&&")];
  }, []);

  let sorting = querySchema.reduce((sort, eachEntity) => {
    let result = `TO_NUMBER(${eachEntity.entity}._key) DESC`;
    return [...sort, result];
  }, []);

  let payload = {
    db_name: config.db_name,
    entity: entityNames.join(","),
    filter: filters.join("&&"),
    limit: {
      count: options?.limit ?? 1,
    },
    sort: options?.sort ?? sorting.join(","),
    return_fields: query[0].entity,
  };

  let response = await networkCall(
    config.api_url + "/read_documents",
    "POST",
    payload
  );

  if (response?.data?.Code !== 201) {
    console.log(
      response?.data?.error ?? "Something went wrong when reading documents"
    );
  }

  let result = response?.data?.result ?? [];
  return result ?? [];
};

export const createDocument = (payload) => {
  // let payload = [
  //   {
  //     entity: "entityName",
  //     body: data,
  //   },
  //   {
  //     entity: "entityName",
  //     body: data,
  //   },
  // ];
  return new Promise(async (resolve, reject) => {
    // "Producing Code" (May take some time)
    let dataList = payload.reduce((datalist, eachPayload) => {
      let payloadStructure = {
        db_name: config.db_name,
        entity: `${eachPayload.entity}`,
        doc: eachPayload.body,
      };
      return [...datalist, payloadStructure];
    }, []);
    let data = JSON.stringify(dataList);
    let response = await networkCall(
      config.api_url + "/upsert_document",
      "POST",
      data,
      {
        "Content-Type": "application/json",
      }
    );

    if (response?.data?.Code !== 201) {
      console.log(
        response?.data?.error ??
          response?.data?.Errormsg ??
          "Something went wrong when creating documents"
      );
      resolve(
        response?.data?.error ??
          response?.data?.Errormsg ??
          "Something went wrong when creating documents"
      );
    } else {
      let result = response?.data?.Result ?? response?.data?.result;
      console.log(result);
      resolve(result);
    }
  });
};

export const updateDocument = (payload) => {
  // let payload = [
  //   {
  //     entity: "entityName",
  //     body: data,
  //     filter: {
  //       key: "val",
  //     },
  //   },
  //   {
  //     entity: "entityName",
  //     body: data,
  //     filter: {
  //       key: "val",
  //     },
  //   },
  // ];

  return new Promise(async (resolve, reject) => {
    let dataList = payload.reduce((datalist, eachPayload) => {
      let payloadStructure = {
        db_name: config.db_name,
        entity: `${eachPayload.entity}`,
        doc: eachPayload.body,
        filter: eachPayload.filter,
      };
      return [...datalist, payloadStructure];
    }, []);

    let data = JSON.stringify(dataList);

    let response = await networkCall(
      config.api_url + "/upsert_document",
      "POST",
      data,
      {
        "Content-Type": "application/json",
      }
    );
    if (response?.data?.Code !== 201) {
      console.log(
        response?.data?.error ??
          response?.data?.Errormsg ??
          "Something went wrong when updating documents"
      );
      resolve(
        response?.data?.error ??
          response?.data?.Errormsg ??
          "Something went wrong when updating documents"
      );
    } else {
      let result = response?.data?.Result ?? response?.data?.result;
      console.log(result);
      resolve(result);
    }
  });
};

export const deleteDocument = async (payload) => {
  //  let payload = [
  //    {
  //      entity: "screens",
  //      filter: {
  //        id: id,
  //      },
  //    }
  //  ];

  let payloadSchema = Array.isArray(payload) ? payload : [payload];
  let entityNames = payloadSchema.reduce((entity, eachEntity) => {
    return [...entity, eachEntity.entity];
  }, []);

  let filters = payloadSchema.reduce((filter, eachEntity) => {
    let entityName = eachEntity.entity;
    let filterValue = eachEntity.filter;
    let result = Object.entries(filterValue).reduce(
      (allFilters, currentFilter) => {
        let eachResult = entityName
          .concat(".", currentFilter[0])
          .concat(
            "==",
            typeof currentFilter[1] === "number" ||
              typeof currentFilter[1] === "boolean"
              ? currentFilter[1]
              : `'${currentFilter[1]}'`
          );
        return [...allFilters, eachResult];
      },
      []
    );
    return [...filter, result.join("&&")];
  }, []);

  let response = await networkCall(config.api_url + "/soft_delete", "POST", {
    db_name: config.db_name,
    entity: entityNames.join(","),
    filter: filters.join("&&"),
  });

  let result = response?.data?.result ?? response?.data?.error;

  return result;
};
