

import React from "react";
import { DenseAppBar } from "../components";

const withHeader = (Component) => (props) => {

  return (
    <div>
      {/* Top Navigation bar */}
      <DenseAppBar title={"QDM"} />

      {/* Content */}
      <div>
        <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withHeader;
