import { FormRendererParent } from "form-configurator";
import React from "react";

export function CustomForm(props) {
  const { properties, ...remainingproperties } = props;
  return (
    <div {...remainingproperties}>
      {!properties?.id && (
        <p style={{ textAlign: "center", fontSize: "18px" }}>Enter form id</p>
      )}
      {properties?.id && <FormRendererParent id={properties.id} />}
    </div>
  );
}
