import { v4 as uuidv4 } from "uuid";

export const getAllElements = (data, elementsList) => {
    data.map(item => {
        if (item?.properties?.aliasName) {
            elementsList.push({
                label: item?.properties?.aliasName,
                ...item
            })
        }
        if (item?.child && item?.child?.length) {
            getAllElements(item?.child, elementsList);
        }
    })
}

export const getElementData = (data, elementId, setElementData) => {
    data.map(item => {
        if (item.id === elementId) {
            setElementData.push(item);
            return item
        }
        if (item?.child && item?.child?.length) {
            getElementData(item?.child, elementId, setElementData);
        }
    })
}

export const getEventObj = (event, element) => {
    return {
        name: event?.label,
        fromId: element.id,
        CName: element.label,
        value: {
            type: element.name === "TapSelect" ? 1 : 2,
        },
        EntityDetails: {},
        action: [],
        component: element?.component, ///set componentType
        eventId: uuidv4(),
    };
}