import { makeStyles, Paper, AppBar, Toolbar, Typography, Button, Grid, Menu, MenuItem } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column'
    },
    appBar: {
        padding: "10px 0px",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        backgroundColor: '#6977C5',
        "& .MuiToolbar-dense": {
            minHeight: 'initial'
        }
    },
    contentRoot: {
        padding: 20
    },
    mainMenu: {

    },
    actionCard: {
        border: '1px solid #6977C5',
        padding: "4px 6px",
        borderRadius: 4,
        marginRight: 8
    },
    btn: {
        border: '1px solid #6977C5',
        color: "#6977C5"
    }
}))

export const ActionController = (props) => {

    const classes = useStyles();
    const selectedCard = props.selectedCard?.event;
    debugger
    const [mainMenuAnchorEl, setmainMenuAnchorEl] = React.useState(null);
    const mainMenuStatus = Boolean(mainMenuAnchorEl)


    const handleNavigation = () => {
        props.setRightMenu(true, "componentMapping")
        setmainMenuAnchorEl(null)
    }

    return <Paper className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
            <Toolbar variant="dense">
                <Typography variant="caption" color="inherit" component="div">
                    Add Action for Element
                </Typography>
            </Toolbar>
        </AppBar>
        <Grid container className={classes.contentRoot}>
            {/* {JSON.stringify({
                selectedCard: selectedCard,
                status: selectedCard?.isBack || selectedCard?.isRefresh
            })} */}
            {
                (selectedCard?.isBack || selectedCard?.isRefresh) ? <div className={classes.actionCard}>
                    <div>
                        <Typography variant="caption">step {0} :</Typography>
                    </div>
                    <div>
                        {selectedCard?.isBack && "Go back"}
                        {selectedCard?.isRefresh && "Refresh page"}
                    </div>
                </div> : null
            }
            {
                props?.selectedArrow?.map((item, index) => {
                    let targetScreen = props?.allScreenList.filter(screen => item.arrowTarget === screen.pathId)?.[0]?.name;
                    return <div className={classes.actionCard}>
                        <div>
                            <Typography variant="caption">step {index} :</Typography>
                        </div>
                        <div>
                            Navigate to {targetScreen}
                        </div>
                    </div>
                })
            }
            <Button
                className={classes.btn}
                variant="outlined"
                color="primary"
                size="small"
                onClick={(evt) => setmainMenuAnchorEl(evt.currentTarget)}
                aria-controls={mainMenuStatus ? 'main-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={mainMenuStatus ? 'true' : undefined}
            >
                Add Action
            </Button>
            <Menu
                id="main-menu"
                aria-labelledby="main-menu"
                anchorEl={mainMenuAnchorEl}
                open={mainMenuStatus}
                onClose={() => setmainMenuAnchorEl(null)}
                className={classes.mainMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleNavigation}>Navigation</MenuItem>
                <MenuItem onClick={null}>Api's</MenuItem>
                <MenuItem onClick={null}>SessionStorage</MenuItem>
                <MenuItem onClick={null}>Display Logics</MenuItem>
            </Menu>
        </Grid>
    </Paper>
}