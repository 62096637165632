import React from 'react';
import { Grid, makeStyles, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ActionsList } from '../../jsonDatas';
import { getAllElements } from '../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10
    },
    formElement: {
        marginBottom: 15,
        width: '100% !important'
    }
}))

export const SelectElementsForm = (props) => {

    const classes = useStyles();
    const [element, setElement] = React.useState(null);
    const [elementsList, setElementsList] = React.useState([])
    const [event, setEvent] = React.useState(null);
    const [screen, setScreen] = React.useState(null);

    React.useEffect(() => {
        const selectedAction = props?.selectedAction;
        if (selectedAction && Object.keys(selectedAction).length) {
            setElement({ label: selectedAction?.CName })
            setEvent({ label: selectedAction?.name })
            let selectedScreen = props?.auth?.user?.AllScreenList[selectedAction?.screenIndex];
            setScreen({ name: selectedScreen?.name })
            // screenIndex
        } else {
            setEvent(null)
            setElement(null)
            setScreen(null)
        }
    }, [props?.selectedAction])


    const createEvents = () => {
        if (element && event && screen) {
            props.getFormResult({
                screen: screen,
                element: element,
                event: event
            })
        } else {
            alert('please select proper element')
        }
    }

    const updateEvents = () => {
        ///checking for any changes available
        if (element?.id || event?.id || screen?.id) {
            props.getFormResult({
                screen: screen.id ? screen : null,
                element: element.id ? element : null,
                event: event.id ? event : null
            })
        } else {
            ///changes / unedited error hadling
            alert('please select proper element')
        }
    }

    const handleScreeSelect = (value) => {
        let elementsList = [];
        getAllElements(value?.render?.child, elementsList);
        setElementsList(elementsList)
        setScreen(value);
    }

    return <Grid container className={classes.root}>
        <Grid item xs={12}>
            <Autocomplete
                value={screen}
                size={"small"}
                className={classes.formElement}
                options={props?.auth?.user?.AllScreenList ?? []}
                onChange={(e, newValue) => handleScreeSelect(newValue)}
                filterSelectedOptions
                disableClearable
                getOptionLabel={(options) => options.name}
                style={{ width: "35%" }}
                renderInput={(params) => (
                    <TextField {...params} label="Select Screens" variant="outlined" />
                )}
                fullWidth
            />
            <Autocomplete
                value={element}
                size={"small"}
                className={classes.formElement}
                options={elementsList}
                onChange={(e, newValue) => setElement(newValue)}
                filterSelectedOptions
                disableClearable
                getOptionLabel={(options) => options.label}
                style={{ width: "35%" }}
                renderInput={(params) => (
                    <TextField {...params} label="Select Elements" variant="outlined" />
                )}
                fullWidth
            />
            <Autocomplete
                value={event}
                size={"small"}
                className={classes.formElement}
                options={ActionsList}
                onChange={(e, newValue) => setEvent(newValue)}
                filterSelectedOptions
                disableClearable
                getOptionLabel={(options) => options.label}
                style={{ width: "35%" }}
                renderInput={(params) => (
                    <TextField {...params} label="Select Event Type" variant="outlined" />
                )}
                fullWidth
            />
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={props?.selectedAction ? updateEvents : createEvents}
                >
                    {props?.selectedAction ? 'Update' : 'Create'}
                </Button>
            </Grid>
        </Grid>
    </Grid>
}

