import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Collapse,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Switch,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { variantSelector, typeSelector } from "./propertyJson";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "./styles";
import Config from "../../../../config";
import { networkCall } from "../../../../networkcall";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { ImageUpload, Radio } from "../../../../components";
import {
  deleteComponent,
  repositoryOperation,
  getProjectComponents,
} from "../../../../components/commonFunction";
import { AuthContext, DialogContext, AlertContext } from "../../../../contexts";
import * as All from "qdm-component-library";
import * as AllPackages from "functional-component-library";
import Axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { ReactTransliterate } from "react-transliterate";
import {
  alertProps,
  getProjectPages,
  getChildProjectScreen,
} from "../../../../utils";
import "react-transliterate/dist/index.css";
import { v4 as uuidv4 } from "uuid";
import {
  chartOptions,
  ChartPropertiesRenderer,
  MockUIPropertiesRenderer,
  CustomCodePropertiesRenderer,
} from "../dynamicComponents";
import Validations from "./validations";
import {
  convertIntToWord,
  snakeToPascal,
} from "../../../../components/commonFunction";

import {
  languageCodes,
  propertiesToAvoidInDC,
  propertiesToAvoidInQDMC,
  validationsRequiredComponents,
  iconsList,
} from "./constants";
import config from "../../../../config";

export function PropertiesPanel(props) {
  const validationOptions = [
    "isRequired",
    "minLength",
    "maxLength",
    "helperText",
    "regex",
    "min",
    "max",
  ];

  const auth = useContext(AuthContext);
  const dialog = useContext(DialogContext);
  const alert = useContext(AlertContext);

  const classes = useStyles();
  const [data, setData] = useState(auth.user.componentAttr);
  const [collapse, setCollapse] = useState(null);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [allLanguages, setAllLanguages] = useState();
  const [messageCatalogDialog, setMessageCatalogDialog] = useState(false);
  const [messageCatalog, setMessageCatalog] = useState({});
  const [componentFromDb, setComponentFromDb] = useState();
  const [checked, setChecked] = useState({});
  const [validationValue, setValidationValue] = useState([]);
  const [qpasQueries, setqpasQueries] = useState([]);
  const [msgCatalogKey, setMsgCatalogKey] = useState([]);

  // function for if validation needed
  const handleCheck = (event) => {
    if (auth.user?.screens?.[0]?.readMode === true) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    setChecked({
      [auth.user?.componentAttr?.id]: event.target.checked,
    });
  };

  // const iconProps = All["jsProps"];
  // from components get funtion
  const getLanguageList = async () => {
    let list = await networkCall(Config.api_url + "/read_documents", "POST", {
      db_name: `${config.db_name}`,
      entity: `message_catalog`,
      filter: `message_catalog.metadataid == '${localStorage.getItem(
        "metaDataId"
      )}' && message_catalog.activestatus==true`,
      return_fields: `message_catalog`,
    });
    let resultData = list.data.result;
    if (resultData) {
      let result = resultData.reduce((lngs, lng) => {
        let lngObj = {
          Title: lng?.title,
          id: lng?.id,
          ...lng?.language,
        };
        return [...lngs, lngObj];
      }, []);
      setLanguageOptions(result);
    }
  };
  const getAllLanguages = async () => {
    let list = await networkCall(Config.api_url + "/read_documents", "POST", {
      db_name: `${config.db_name}`,
      entity: "projectvstools",
      filter: `projectvstools.metadataid=='${localStorage.getItem(
        "metaDataId"
      )}'`,
      return_fields:
        "merge(keep(projectvstools,'projectid','€€€','clientid'),{projectlang:document(document(projectvstools.projectid).projectlang)})",
    });
    let AllList =
      list?.data?.result?.[list?.data?.result?.length - 1]?.projectlang;
    if (AllList) {
      let result = AllList.reduce((lngs, lng) => {
        let data = {
          language: lng.description,
          code: lng?.code,
        };
        return [...lngs, data];
      }, []);
      console.log(result);
      setAllLanguages(result);
    }
  };

  const handleCollapse = (index) => {
    var res = index === collapse ? null : index;
    setCollapse(res);
  };

  const filter = createFilterOptions();
  const filterOptions = createFilterOptions({
    stringify: (option) => option.split("_").join(" "),
  });

  const selectpropertiesInput = (val, componentName, componentId) => {
    let crnt;
    crnt = propertiesProducer(val, componentName, componentId);
    return crnt;
  };

  const propertiesProducer = (val, componentName, componentId) => {
    let componentPropsFromLibrary;
    let options = [];

    //to get componentProps from qdm-component-library
    const propsName = `${componentName}Props`;
    componentPropsFromLibrary = All[propsName];
    //to get componentProps from functional-component-library
    if (!componentPropsFromLibrary)
      componentPropsFromLibrary = AllPackages[propsName];
    //getting value of a key (prop of a component)

    ///accessing material properties directly from collection
    if (
      componentFromDb.frameWork.startsWith("material") ||
      componentFromDb.frameWork === "custom-material-ui-component"
    ) {
      componentPropsFromLibrary = componentFromDb?.props ?? {};
    }
    const propValue = componentPropsFromLibrary[val] ?? {};

    const typeOfPropValue = typeof propValue;
    const properties = {
      name: val,
      key: val,
      size: 12,
    };

    // this is for using cutsom componet
    if (val === "fontIcon") {
      properties.componentType = "customSelect";
      propValue?.length > 0 &&
        propValue.map((value, i) => options.push({ title: value }));
      properties.options = options;
    } else if (val === "options") {
      properties.componentType = "freeMultiSelect";
      propValue.length &&
        propValue.map((value, i) => options.push({ title: value }));
      properties.options = options;
    }
    //Here we deciding componentType based on the typeof value of a key(prop of a component)
    else if (typeOfPropValue === "string") {
      if (CSS.supports("color", propValue)) {
        properties.componentType = "color";
      } else if (
        val === "label" ||
        val === "name" ||
        val === "title" ||
        val === "placeHolder"
      ) {
        properties.componentType = "languageSelect";
      } else if (val === "weekStartDate" || val === "weekEndDate") {
        properties.componentType = "date";
      } else if (val === "src") {
        properties.componentType = "imageListAndUpload";
      } else if (val === "type") {
        properties.componentType = "type";
      } else {
        properties.componentType = "text";
      }
    } else if (Array.isArray(propValue)) {
      //It is an array so we mapping into the values to get options for select
      if (val === "type") {
        properties.componentType = "type";
        propValue.map((value) => {
          return options.push({ title: value });
        });
        properties.options = options;
      } else {
        properties.componentType = "select";
        propValue.map((value) => {
          return options.push({ title: value });
        });
        properties.options = options;
      }
    } else if (typeOfPropValue === "boolean") {
      properties.componentType = "boolean";
    } else if (typeOfPropValue === "object") {
      properties.componentType = "object";
    } else if (typeOfPropValue === "number" && isFinite(propValue)) {
      properties.componentType = "number";
    }
    //This is the final properties of a single key of a component
    return properties;
  };

  const dynamicComponentsPropertiesProducer = (key, val) => {
    const properties = {
      name: key,
      key: key,
      size: 12,
    };
    const typeOfPropValue = typeof val;
    if (
      key === "label" ||
      key === "name" ||
      key === "title" ||
      key === "placeHolder" ||
      key === "placeholder"
    ) {
      properties.componentType = "languageSelect";
    } else if (key === "src") {
      properties.componentType = "imageListAndUpload";
    } else if (key === "chartType") {
      properties.componentType = "chartType";
      properties.options = chartOptions;
    } else if (key === "chartData") {
      properties.componentType = "chartData";
    } else if (typeOfPropValue === "number" && isFinite(val)) {
      properties.componentType = "number";
    }
    return properties;
  };

  const handleDeleteComponent = (id) => {
    if (auth.user?.screens?.[0]?.readMode === true) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    if (
      auth.user?.screens?.[0]?.readMode === false &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    const handleOnOkModal = () => {
      deleteComponent(id, auth);
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
      });
      props.onClose("right", false);
    };
    const handClose = () => {
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
        title: "Delete Alert",
        body: "Do you need to delete this screen?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => {},
        onCancel: () => {},
      });
    };
    dialog.setDialog({
      ...dialog,
      open: true,
      tone: true,
      title: "Delete Alert",
      body: "Do you need to delete is layer?",
      positiveBtn: "Yes",
      negativeBtn: "No",
      onOk: handleOnOkModal,
      onCancel: handClose,
    });
  };
  //for px and percentage
  const combineTypeValue = (e, data, id, key) => {
    if (e === `${key}Value` || e === `${key}Type`) {
      if (
        data[id][`${key}Value`] !== null &&
        data[id][`${key}Value`] !== undefined
      ) {
        if (!data[id][`${key}Type`]) {
          data[id][`${key}Type`] = "px";
        }
        if (data[id][`${key}Value`] === "auto") {
          data[id][`${key}`] = "auto";
        } else {
          data[id][`${key}`] = `${data[id][`${key}Value`]}${
            data[id][`${key}Type`]
          }`;
        }
      }
    }
  };
  ///handling property change
  const handleChange = (
    id,
    e,
    v,
    type,
    key,
    parentIndex,
    parentArray,
    nestedObject,
    objectParentKey
  ) => {
    console.log(
      id,
      e,
      v,
      type,
      key,
      parentIndex,
      parentArray,
      nestedObject,
      objectParentKey,
      auth.user.componentAttr
    );

    //Checking the screen is in edit mode
    if (auth.user?.screens?.[0]?.readMode === true) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    if (
      auth.user?.screens?.[0]?.readMode === false &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }

    //Updating the value to the property
    if (nestedObject) {
      if (!data[id][objectParentKey]) {
        data[id][objectParentKey] = {};
      }
      data[id][objectParentKey] = { ...data[id][objectParentKey], [e]: v };
    } else if (parentArray) {
      data[id][parentArray][parentIndex][e] = v;
    } else {
      data[id][e] = v;
    }

    //Badge Component Zero content handling
    if (data?.component === "Badge") {
      if (
        data?.properties?.invisible === false &&
        data?.properties?.badgeContent === 0 &&
        data?.properties?.showZero === false
      ) {
        const { invisible, ...remaining } = data["properties"];
        data["properties"] = remaining;
      }
    }

    if (e === "pages") {
      let screenId = auth.user?.screens[0].id;
      auth.user.AllScreenList.forEach((screen, index, array) => {
        debugger;
        if (v.filter((val) => val.pageId === screen.id).length > 0) {
          array[index] = { ...screen, masterLayoutId: screenId };
        } else {
          array[index] = { ...screen, masterLayoutId: "" };
        }
      });
    }

    if (e === "childPageSelect") {
      let screenId = auth.user?.screens[0].id;
      auth.user.AllScreenList.forEach((screen, index, array) => {
        if (v.filter((val) => val.screenId === screen.id).length > 0) {
          array[index] = { ...screen, masterLayoutId: screenId };
        } else {
          array[index] = { ...screen, masterLayoutId: "" };
        }
      });
    }
    if (key) {
      combineTypeValue(e, data, id, key);
    }
    if (type) {
      data[type] = e;
    }

    if (id === "properties" && e === "type" && data?.validations) {
      if (
        validationsRequiredComponents.some((comp) => comp === data?.component)
      ) {
        data["validations"] = {};
      }
    }

    let screenId = auth.user.AllScreenList.findIndex(
      (s) => s.id === auth.user.selectedScreen
    );

    let allScreenList = auth.user.AllScreenList;

    let data1 = allScreenList[screenId].render.child;

    getChildId(data.id, data1, data, type, e);
    auth.setAuth({
      ...auth.user,
      componentAttr: data,
      isEdited: false,
    });
  };

  ///rendering prop types fields
  const getFromComponents = (id, val, index, componentName) => {
    if (!val) return null;
    switch (val.componentType) {
      case "color":
      case "backgroundcolor":
      case "bordercolor":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <FormControl
              variant="outlined"
              margin={"dense"}
              className={classes.inputField}
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password">
                {val.name}
              </InputLabel>
              <OutlinedInput
                label={val.name}
                id="outlined-adornment-password"
                value={data[id][val.key] ?? ""}
                onChange={(e) => handleChange(id, val.key, e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <input
                      type="color"
                      value={data[id][val.key] ?? ""}
                      onChange={(e) =>
                        handleChange(id, val.key, e.target.value)
                      }
                    />
                  </InputAdornment>
                }
                labelWidth={val?.labelWidth ? val?.labelWidth : 0}
              />
            </FormControl>
          </Grid>
        );
      case "number":
      case "text":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <TextField
              value={data[id][val.key] ?? ""}
              onChange={(e) => handleChange(id, val.key, e.target.value)}
              type={val?.componentType}
              fullWidth
              key={val}
              label={val.name}
              className={classes.inputField}
              margin="dense"
              variant="outlined"
            />
          </Grid>
        );
      case "select":
        debugger;
        if (variantSelector[data?.component] || val?.options) {
          return (
            <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
              <Autocomplete
                id="combo-box-demo"
                options={
                  val?.options ?? variantSelector[data?.component]["options"]
                }
                defaultValue={{ title: data[id][val?.key] ?? "" }}
                onChange={(e, v) => handleChange(id, val.key, v?.title ?? v)}
                getOptionLabel={(option) => option?.title ?? ""}
                getOptionSelected={(option) => option.title}
                style={{ width: "100%" }}
                className={classes.inputField}
                renderInput={(params) => (
                  <TextField
                    margin={"dense"}
                    {...params}
                    label={val.name}
                    variant="outlined"
                  />
                )}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
              />
            </Grid>
          );
        } else {
          return <></>;
        }
      case "type":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              id="combo-box-demo"
              options={val?.options ?? typeSelector(componentName)}
              defaultValue={{ title: data[id][val?.key] ?? "" }}
              onChange={(e, v) => handleChange(id, val.key, v?.title)}
              getOptionLabel={(option) => option?.title ?? ""}
              getOptionSelected={(option) => option.title}
              style={{ width: "100%" }}
              className={classes.inputField}
              renderInput={(params) => (
                <TextField
                  margin={"dense"}
                  {...params}
                  label={val.name}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        );
      case "boolean":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <FormControl
              variant="outlined"
              fullWidth
              margin={"dense"}
              className={classes.inputField}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                {val?.key}
              </InputLabel>
              <Select
                labelId="demo-customized-select-label"
                value={data[id][val?.key]}
                onChange={(e) => handleChange(id, val.key, e.target.value)}
                fullWidth
                labelWidth={val?.labelWidth ? val?.labelWidth : 0}
              >
                <MenuItem value={true}>true</MenuItem>
                <MenuItem value={false}>false</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        );
      case "date":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <TextField
              value={data[id][val.key] ?? ""}
              onChange={(e) => handleChange(id, val.key, e.target.value)}
              type={val?.componentType}
              id="date"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              key={val}
              label={val.name}
              className={classes.inputField}
              margin="dense"
              variant="outlined"
            />
          </Grid>
        );
      case "customSelect":
        if (variantSelector[data?.component] || val?.options) {
          return (
            <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
              <Autocomplete
                // freeSolo
                id="free-solo-with-text-demo"
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={
                  val?.options ?? variantSelector[data?.component]["options"]
                }
                defaultValue={{ title: data[id][val?.key] ?? "" }}
                onChange={(e, v) => handleChange(id, val.key, v?.title ?? v)}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.title;
                }}
                getOptionSelected={(option) => option.title}
                style={{ width: "100%" }}
                className={classes.inputField}
                renderInput={(params) => (
                  <TextField
                    margin={"dense"}
                    {...params}
                    label={val.name}
                    variant="outlined"
                  />
                )}
                renderOption={(option) => (
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      color={"textSecondary"}
                      style={{ marginRight: 6 }}
                    >
                      <i
                        class={`fa fa-${option?.title}`}
                        aria-hidden="true"
                      ></i>
                    </Typography>
                    <Typography
                      style={{ fontSize: "14px", textTransform: "capitalize" }}
                      variant={"body2"}
                      color={"textPrimary"}
                    >
                      {option.title}
                    </Typography>
                  </Grid>
                )}
              />
            </Grid>
          );
        } else {
          return <></>;
        }
      case "freeMultiSelect":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              defaultValue={data[id][val?.key] ?? []}
              freeSolo
              onChange={(e, newValue) => optionOnChange(id, val.key, newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"outlined"}
                  label={val.name}
                  margin={"dense"}
                  placeholder="Add Options"
                />
              )}
            />
          </Grid>
        );
      case "languageSelect":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              value={{ Title: data[id][val?.key] ?? "" }}
              // multiple
              defaultValue={{ Title: data[id][val?.key] ?? "" }}
              // filterSelectedOptions
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              id="combo-box-demo"
              options={languageOptions}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.Title;
              }}
              renderOption={(option) => {
                if (option?.id === "message catalog") {
                  return (
                    <Typography
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      {option.Title}
                    </Typography>
                  );
                } else {
                  return (
                    <React.Fragment>
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: "16px",
                          }}
                          variant={"body2"}
                          color={"textPrimary"}
                        >
                          {option.Title}
                        </Typography>
                        {Object.keys(option).map((key) => {
                          if (key === "Title" || key === "id") {
                            return false;
                          } else {
                            return (
                              <Typography
                                variant={"subtitle2"}
                                color={"textSecondary"}
                              >
                                {key + " : " + option[key]}
                              </Typography>
                            );
                          }
                        })}
                      </Grid>
                    </React.Fragment>
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  size={"small"}
                  margin={"dense"}
                  {...params}
                  label={val.name}
                  variant="outlined"
                />
              )}
              onChange={(e, v) => {
                if (v?.id === "message catalog" && allLanguages.length > 0) {
                  const result =
                    allLanguages.length > 0 &&
                    allLanguages.reduce((obj, ln) => {
                      const data = {
                        ...obj,
                        Title: v?.value,
                        [ln.language]:
                          ln?.language === "English" ? v?.value : "",
                      };
                      return data;
                    }, {});
                  setMessageCatalog({ ...result });
                  setMessageCatalogDialog(true);
                } else {
                  handleChange(id, val.key, v?.Title ?? v, "languageLabel");
                }
              }}
              filterOptions={(options, params) => {
                let updatedParams = { ...params };
                if (params.inputValue === "") {
                  let inputValue = data[id][val?.key] ?? "";
                  updatedParams = {
                    ...updatedParams,
                    inputValue,
                  };
                }
                const filtered = filter(options, updatedParams);
                // Suggest the creation of a new value
                if (
                  (params.inputValue !== "" || (data[id][val?.key] ?? false)) &&
                  allLanguages?.length > 0 &&
                  !options.some(
                    (option) =>
                      option?.Title.toLowerCase() ===
                      (params.inputValue !== ""
                        ? params.inputValue.toLowerCase()
                        : data[id][val?.key]?.toLowerCase() ?? "")
                  )
                ) {
                  filtered.unshift({
                    id: "message catalog",
                    value:
                      params.inputValue !== ""
                        ? params.inputValue
                        : data[id][val?.key] ?? "",
                    Title: `Add ${
                      params.inputValue !== ""
                        ? params.inputValue
                        : data[id][val?.key] ?? ""
                    } to message catalog`,
                  });
                }
                return filtered;
              }}
            />
          </Grid>
        );
      case "imageListAndUpload":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <ImageUpload
              data={data}
              val={val}
              id={id}
              handleChange={handleChange}
            />
          </Grid>
        );
      case "chartData":
        return (
          <Box width="100%" key={data?.id} style={{ marginLeft: "8px" }}>
            <ChartPropertiesRenderer
              {...{
                data,
                languageOptions,
                setMessageCatalogDialog,
                allLanguages,
                setMessageCatalog,
                messageCatalog,
                handleChange,
              }}
            />
          </Box>
        );
      case "chartType":
        return;
      case "object":
        return null;
      // case "inputparams":
      //   console.log(data[id][val.key]);
      //   return (
      //     <Box width="100%" key={data?.id} style={{ marginLeft: "8px" }}>
      //       <textarea
      //         placeholder={val.key}
      //         value={data[id][val.key]}
      //         onChange={(e) => handleChange(id, val.key, e.target.value)}
      //         cols="43"
      //       />
      //     </Box>
      //   );
      default:
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <TextField
              value={data[id][val.key] ?? ""}
              onChange={(e) => handleChange(id, val.key, e.target.value)}
              type={val?.componentType}
              fullWidth
              key={val}
              label={val.name}
              className={classes.inputField}
              margin="dense"
              variant="outlined"
            />
          </Grid>
        );
    }
  };

  const optionOnChange = (id, key, newValue) => {
    debugger;
    const stringToBoject = (string) => {
      if (string?.length > 0) {
        let object = { value: string, label: string };
        return object;
      } else {
        return string;
      }
    };
    let string = newValue[newValue?.length - 1] ?? "";
    if (stringToBoject(string)) {
      newValue[newValue?.length - 1] = stringToBoject(string);
      debugger;
      handleChange(id, key, newValue);
    }
  };

  const getChildId = (id, data1, data, type, key) => {
    data1.forEach((item) => {
      if (item.id === id) {
        item.properties = {
          ...data.properties,
        };
        item.inlineStyles = {
          ...data.inlineStyles,
        };
        if (type === "languageLabel") {
          item["isTranslate"] = true;
          if (item?.translateKeys) {
            item.translateKeys = [...item.translateKeys, key];
          } else {
            item.translateKeys = [key];
          }
        } else if (type === "isIdmEnabled") {
          item["isIdmEnabled"] = true;
        }
      } else {
        if (item.child) {
          getChildId(id, item?.child, data, type, key);
        }
      }
    });
  };

  const handleClose = () => {
    setMessageCatalog({});
    setMessageCatalogDialog(false);
  };

  const handleMessageCatalogSubmit = async (event) => {
    const attribute = {
      ...messageCatalog,
      id: uuidv4(),
    };
    let messageDetails = [...languageOptions, attribute];
    console.log(attribute);
    let { Title, id, ...remaining } = attribute;

    let messageCatalogSchema = {
      clientid: auth.user.clientId,
      projectid: auth.user.projectId,
      id: attribute?.id,
      metadataid: localStorage.getItem("metaDataId"),
      language: remaining,
      title: Title,
    };
    let dataList = {
      db_name: config.db_name,
      entity: "message_catalog",
      is_metadata: false,
      doc: messageCatalogSchema,
    };
    console.log(dataList);
    let data = JSON.stringify([dataList]);
    var configfile = {
      method: "post",
      url: `${Config.api_url}/upsert_document`,
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    await Axios;
    Axios(configfile)
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.success,
          msg: "Message catalog created successfully",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.center,
          tone: true,
        });
        setLanguageOptions(messageDetails);
      })
      .catch((err) => {});
    handleChange(
      "properties",
      msgCatalogKey,
      messageCatalog?.Title,
      "languageLabel"
    );
    handleClose();
  };

  const dynamicPropertiesComponent = {
    MockUI: MockUIPropertiesRenderer,
    CustomCode: CustomCodePropertiesRenderer,
  };

  function DcPropertiesProducer() {
    const DynamicComponent =
      dynamicPropertiesComponent[auth.user?.componentAttr?.name];
    return (
      <DynamicComponent
        data={data}
        onClose={props.onClose}
        handleChange={handleChange}
      />
    );
  }

  const getValue = (
    id,
    val,
    index,
    componentName,
    propertiesOptions,
    parentIndex,
    parentArray,
    nestedObject,
    objectParentKey
  ) => {
    if (nestedObject) {
      return data?.[id]?.[objectParentKey]?.[val.key] ?? "";
    } else if (parentArray) {
      return data?.[id]?.[parentArray][parentIndex]?.[val.key];
    } else {
      return data?.[id]?.[val.key] ?? "";
    }
  };

  const dynamicPropertyProducer = (
    id,
    val,
    index,
    componentName,
    propertiesOptions,
    parentIndex,
    parentArray,
    nestedObject,
    objectParentKey
  ) => {
    console.log(propertiesOptions.componentToRender);
    switch (propertiesOptions.componentToRender) {
      case "text":
      case "number":
        return (
          <Grid item lg={12} key={index} style={{ padding: "4px" }}>
            <TextField
              type={propertiesOptions.componentToRender}
              defaultValue={propertiesOptions?.defaultValue}
              value={getValue(
                id,
                val,
                index,
                componentName,
                propertiesOptions,
                parentIndex,
                parentArray,
                nestedObject,
                objectParentKey
              )}
              onChange={(e) => {
                let value =
                  propertiesOptions.componentToRender === "number"
                    ? parseInt(e.target.value)
                    : e.target.value;
                let pattern;
                if (componentName === "Box") {
                  pattern = /^[a-z]{1,}$/;
                  let result = pattern.test(value ?? "");
                  if (!result && value)
                    return alert.setSnack({
                      ...alert,
                      open: true,
                      severity: alertProps.severity.warning,
                      msg: "Not a valid input",
                      vertical: alertProps.vertical.top,
                      horizontal: alertProps.horizontal.right,
                      tone: true,
                    });
                }
                handleChange(
                  id,
                  val.key,
                  value,
                  undefined,
                  undefined,
                  parentIndex,
                  parentArray,
                  nestedObject,
                  objectParentKey
                );
              }}
              fullWidth
              key={val}
              label={val.name}
              className={classes.inputField}
              margin="dense"
              inputProps={
                propertiesOptions.componentToRender === "number" && { min: 0 }
              }
              variant="outlined"
            />
          </Grid>
        );
      case "messageCatalog":
        console.log(data);
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              value={{ Title: data[id][val?.key] ?? "" }}
              // multiple
              defaultValue={{ Title: data[id][val?.key] ?? "" }}
              // filterSelectedOptions
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              id="combo-box-demo"
              options={languageOptions}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.Title;
              }}
              renderOption={(option) => {
                if (option?.id === "message catalog") {
                  return (
                    <Typography
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      {option.Title}
                    </Typography>
                  );
                } else {
                  return (
                    <React.Fragment>
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: "16px",
                          }}
                          variant={"body2"}
                          color={"textPrimary"}
                        >
                          {option.Title}
                        </Typography>
                        {Object.keys(option).map((key) => {
                          if (key === "Title" || key === "id") {
                            return false;
                          } else {
                            return (
                              <Typography
                                variant={"subtitle2"}
                                color={"textSecondary"}
                              >
                                {key + " : " + option[key]}
                              </Typography>
                            );
                          }
                        })}
                      </Grid>
                    </React.Fragment>
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  size={"small"}
                  margin={"dense"}
                  {...params}
                  label={val.name}
                  variant="outlined"
                />
              )}
              onChange={(e, v) => {
                if (v?.id === "message catalog" && allLanguages.length > 0) {
                  const result =
                    allLanguages.length > 0 &&
                    allLanguages.reduce((obj, ln) => {
                      const data = {
                        ...obj,
                        Title: v?.value,
                        [ln.language]:
                          ln?.language === "English" ? v?.value : "",
                      };
                      return data;
                    }, {});
                  setMsgCatalogKey(val.name);
                  setMessageCatalog({ ...result });
                  setMessageCatalogDialog(true);
                } else {
                  handleChange(id, val.key, v?.Title ?? v, "languageLabel");
                }
              }}
              filterOptions={(options, params) => {
                let updatedParams = { ...params };
                if (params.inputValue === "") {
                  let inputValue = data[id][val?.key] ?? "";
                  updatedParams = {
                    ...updatedParams,
                    inputValue,
                  };
                }
                const filtered = filter(options, updatedParams);
                console.log(params, filtered, options, data, allLanguages);
                // Suggest the creation of a new value
                if (
                  (params.inputValue !== "" || (data[id][val?.key] ?? false)) &&
                  allLanguages?.length > 0 &&
                  !options.some(
                    (option) =>
                      option?.Title.toLowerCase() ===
                      (params.inputValue !== ""
                        ? params.inputValue.toLowerCase()
                        : data[id][val?.key]?.toLowerCase() ?? "")
                  )
                ) {
                  filtered.push({
                    id: "message catalog",
                    value:
                      params.inputValue !== ""
                        ? params.inputValue
                        : data[id][val?.key] ?? "",
                    Title: `Add ${
                      params.inputValue !== ""
                        ? params.inputValue
                        : data[id][val?.key] ?? ""
                    } to message catalog`,
                  });
                }
                return filtered;
              }}
            />
          </Grid>
        );
      case "dropdown":
        return (
          <Grid item xs={12} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              id="combo-box-demo"
              options={propertiesOptions?.options}
              defaultValue={
                parentArray
                  ? data?.[id]?.[parentArray][parentIndex]?.[val.key]
                  : data[id][val?.key]
              }
              onChange={(e, v) =>
                handleChange(
                  id,
                  val.key,
                  v,
                  undefined,
                  undefined,
                  parentIndex,
                  parentArray
                )
              }
              getOptionLabel={(option) => option}
              getOptionSelected={(option) => option}
              style={{ width: "100%" }}
              className={classes.inputField}
              renderInput={(params) => (
                <TextField
                  margin={"dense"}
                  {...params}
                  label={val.name}
                  variant="outlined"
                />
              )}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
            />
          </Grid>
        );
      case "select":
        return (
          <Grid item xs={12} key={index} style={{ padding: "4px" }}>
            <FormControl
              variant="outlined"
              fullWidth
              margin={"dense"}
              className={classes.inputField}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                {val?.key}
              </InputLabel>
              <Select
                labelId="demo-customized-select-label"
                value={getValue(
                  id,
                  val,
                  index,
                  componentName,
                  propertiesOptions,
                  parentIndex,
                  parentArray,
                  nestedObject,
                  objectParentKey
                )}
                onChange={(e) =>
                  handleChange(
                    id,
                    val.key,
                    e.target.value,
                    undefined,
                    undefined,
                    parentIndex,
                    parentArray,
                    nestedObject,
                    objectParentKey
                  )
                }
                fullWidth
                labelWidth={val?.labelWidth ? val?.labelWidth : 0}
              >
                {propertiesOptions.options.map((item) => {
                  return <MenuItem value={item}>{`${item}`}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
        );
      case "icons":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              id="icon-select"
              freeSolo
              filterOptions={filterOptions}
              options={iconsList}
              defaultValue={data[id][val?.key]?.["iconName"] ?? null}
              onChange={(e, v) => {
                if (v) {
                  let value = {
                    type: "icon",
                    iconName: snakeToPascal(v),
                    style: {},
                  };
                  handleChange(id, val.key, value);
                }
              }}
              getOptionLabel={(option) => snakeToPascal(option)}
              style={{ width: "100%" }}
              className={classes.inputField}
              renderInput={(params) => (
                <TextField
                  margin={"dense"}
                  {...params}
                  label={val.name}
                  variant="outlined"
                />
              )}
              // renderOption={(option) => {
              //   let icoName = option.split("_").join(" ");
              //   return (
              //     <Grid
              //       item
              //       style={{
              //         display: "flex",
              //         justifyContent: "center",
              //         alignItems: "flex-start",
              //       }}
              //     >
              //       <Typography
              //         style={{ fontSize: "14px", textTransform: "capitalize" }}
              //         variant={"body2"}
              //         color={"textPrimary"}
              //       >
              //         {icoName}
              //       </Typography>
              //     </Grid>
              //   );
              // }}
            />
          </Grid>
        );
      case "imageUpload":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <ImageUpload
              data={data}
              val={val}
              id={id}
              parentIndex={parentIndex}
              parentArray={parentArray}
              handleChange={handleChange}
            />
          </Grid>
        );
      case "autocomplete":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              // freeSolo
              id="free-solo-with-text-demo"
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={propertiesOptions?.options ?? []}
              defaultValue={{ title: data[id][val?.key] ?? "" }}
              onChange={(e, v) => handleChange(id, val.key, v?.title ?? v)}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              getOptionSelected={(option) => option.title}
              style={{ width: "100%" }}
              className={classes.inputField}
              renderInput={(params) => (
                <TextField
                  margin={"dense"}
                  {...params}
                  label={val.name}
                  variant="outlined"
                />
              )}
              renderOption={(option) => (
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    color={"textSecondary"}
                    style={{ marginRight: 6 }}
                  >
                    <i class={`fa fa-${option?.title}`} aria-hidden="true"></i>
                  </Typography>
                  <Typography
                    style={{ fontSize: "14px", textTransform: "capitalize" }}
                    variant={"body2"}
                    color={"textPrimary"}
                  >
                    {option.title}
                  </Typography>
                </Grid>
              )}
            />
          </Grid>
        );
      case "object":
        if (
          Object.keys(componentFromDb?.props?.[val?.key]?.value ?? {}).length >
          0
        ) {
          return (
            <>
              <Typography
                style={{
                  color: "#757575",
                  textTransform: "capitalize",
                  marginLeft: "6px",
                  fontSize: "14px",
                }}
                variant="subtitle1"
              >
                {val?.key}
              </Typography>
              <Grid style={{ margin: "auto" }} md={11}>
                {auth.user?.componentAttr &&
                  auth.user?.componentAttr?.frameWork.startsWith("material") &&
                  Object.keys(componentFromDb?.props[val?.key].value ?? {})
                    .length > 0 &&
                  Object.keys(
                    componentFromDb?.props?.[val?.key]?.value ?? {}
                  ).map((key, index) => {
                    let propertiesOptions =
                      componentFromDb?.props?.[val?.key]?.value[key];
                    let crnt = selectpropertiesInput(
                      key,
                      auth.user?.componentAttr?.component,
                      auth.user?.componentAttr?.componentId
                    );
                    let objectParentKey = val.key;
                    return dynamicPropertyProducer(
                      "properties",
                      crnt,
                      index,
                      auth.user?.componentAttr?.component,
                      propertiesOptions,
                      null,
                      null,
                      true,
                      objectParentKey
                    );
                  })}
              </Grid>
            </>
          );
        } else {
          return null;
        }
      case "component":
        let components = getProjectComponents(auth);
        return (
          <Grid item xs={12} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              id="combo-box-demo"
              options={components}
              defaultValue={
                parentArray
                  ? data?.[id]?.[parentArray][parentIndex]?.[val.key]
                  : data[id][val?.key]
              }
              // handleChange(id, val.key, v)
              onChange={(e, v) =>
                handleChange(
                  id,
                  val.key,
                  v,
                  undefined,
                  undefined,
                  parentIndex,
                  parentArray
                )
              }
              getOptionLabel={(option) => option?.componentName}
              getOptionSelected={(option) => option?.componentName}
              style={{ width: "100%" }}
              className={classes.inputField}
              renderInput={(params) => (
                <TextField
                  margin={"dense"}
                  {...params}
                  label={val.key}
                  variant="outlined"
                />
              )}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
            />
          </Grid>
        );
      case "pageSelect":
        let pages = getProjectPages(auth);
        //to get filtered Options
        pages = (pages ?? []).filter((page) => {
          if (!data[id][val?.key]?.some((v) => v?.pageId === page?.pageId)) {
            return true;
          } else {
            return false;
          }
        });

        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              value={data[id][val?.key] ?? []}
              options={pages}
              onChange={(event, newValue) => {
                handleChange(id, val.key, newValue);
              }}
              className={classes.inputField}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Page Select"
                  margin="dense"
                  style={{ fontSize: "14px" }}
                  placeholder="Select Pages"
                />
              )}
            />
            {/* Selection for the page component */}
            {data[id]?.[val.key]?.length > 0 &&
              data[id][val.key]?.map((vals, parentIndex) => {
                return (
                  <>
                    <Typography
                      style={{
                        color: "#757575",
                        textTransform: "capitalize",
                        marginLeft: "6px",
                      }}
                      variant="subtitle1"
                    >
                      {vals?.name}
                    </Typography>
                    {Object.keys(propertiesOptions?.value?.[0] ?? {}).length >
                      0 &&
                      Object.keys(propertiesOptions?.value?.[0]).map(
                        (key, index) => {
                          let propertiesOption =
                            propertiesOptions?.value[0][key];
                          let crnt = selectpropertiesInput(
                            key,
                            auth.user?.componentAttr?.component,
                            auth.user?.componentAttr?.componentId
                          );
                          let parentArray = val.key;
                          return dynamicPropertyProducer(
                            "properties",
                            crnt,
                            index,
                            auth.user?.componentAttr?.component,
                            propertiesOption,
                            parentIndex,
                            parentArray
                          );
                        }
                      )}
                  </>
                );
              })}
          </Grid>
        );
      case "arrayOfObject":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            {/* Selection for the page component */}
            {propertiesOptions?.value.length > 0 &&
              propertiesOptions?.value.map((payload, parentIndex) => {
                return (
                  <>
                    {Object.keys(payload ?? {}).length > 0 &&
                      Object.keys(payload).map((key, index) => {
                        let propertiesOption = propertiesOptions?.value[0][key];
                        let crnt = selectpropertiesInput(
                          key,
                          auth.user?.componentAttr?.component,
                          auth.user?.componentAttr?.componentId
                        );
                        let parentArray = val.key;
                        return dynamicPropertyProducer(
                          "properties",
                          crnt,
                          index,
                          auth.user?.componentAttr?.component,
                          propertiesOption,
                          parentIndex,
                          parentArray
                        );
                      })}
                  </>
                );
              })}
          </Grid>
        );
      case "tabSelect":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Typography
              style={{
                color: "#757575",
                textTransform: "capitalize",
                marginLeft: "6px",
              }}
              variant="subtitle1"
            >
              {val?.key}
            </Typography>
            <TextField
              type="number"
              defaultValue={propertiesOptions?.defaultValue}
              value={
                data[id][val?.key]?.length > 0
                  ? data[id][val?.key]?.length
                  : null
              }
              onChange={(e) => {
                let Finalresult = [];
                if (e.target.value <= 15) {
                  if (parseInt(e.target.value) > 0) {
                    let result = Array.from(
                      Array(parseInt(e.target.value)).keys()
                    );
                    Finalresult = result.map((val, index) => {
                      return {
                        header: `Tab${index + 1}`,
                        fullWidth: true,
                        body: {},
                      };
                    });
                  }
                  handleChange(
                    id,
                    val.key,
                    Finalresult,
                    undefined,
                    undefined,
                    parentIndex,
                    parentArray
                  );
                }
              }}
              fullWidth
              key={val}
              min="1"
              label="Number of Tabs"
              className={classes.inputField}
              margin="dense"
              variant="outlined"
            />
            {/* Selection for the page component */}
            {data[id]?.[val.key]?.length > 0 &&
              data[id][val.key]?.map((vals, parentIndex) => {
                return (
                  <>
                    <Typography
                      style={{
                        color: "#757575",
                        textTransform: "capitalize",
                        marginLeft: "6px",
                      }}
                      variant="subtitle1"
                    >
                      {vals?.header}
                    </Typography>
                    {Object.keys(propertiesOptions?.value[0] ?? {}).length >
                      0 &&
                      Object.keys(propertiesOptions?.value[0]).map(
                        (key, index) => {
                          let propertiesOption =
                            propertiesOptions?.value[0][key];
                          let crnt = selectpropertiesInput(
                            key,
                            auth.user?.componentAttr?.component,
                            auth.user?.componentAttr?.componentId
                          );
                          let parentArray = val.key;

                          debugger;
                          return dynamicPropertyProducer(
                            "properties",
                            crnt,
                            index,
                            auth.user?.componentAttr?.component,
                            propertiesOption,
                            parentIndex,
                            parentArray
                          );
                        }
                      )}
                  </>
                );
              })}
          </Grid>
        );
      case "state-select":
        return (
          <Autocomplete
            id="combo-box-demo"
            value={data[id][val?.key] ?? null}
            options={qpasQueries}
            getOptionLabel={(option) => option?.label}
            onChange={(event, newValue) => {
              handleChange(id, val.key, newValue);
            }}
            style={{ width: "100%" }}
            className={classes.inputField}
            renderInput={(params) => (
              <TextField
                margin={"dense"}
                {...params}
                label={"select src"}
                placeholder={"select src start"}
                variant="outlined"
              />
            )}
          />
        );
      case "freeMultiSelect":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              defaultValue={data[id][val?.key] ?? []}
              freeSolo
              onChange={(e, newValue) => {
                optionOnChange(id, val.key, newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option?.label}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"outlined"}
                  label={val.name}
                  margin={"dense"}
                  placeholder="Add Options"
                />
              )}
            />
          </Grid>
        );
      case "multiSelect":
        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              defaultValue={data[id][val?.key] ?? []}
              freeSolo
              onChange={(e, newValue) => {
                handleChange(id, val?.key, newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"outlined"}
                  label={val.name}
                  margin={"dense"}
                  placeholder="Add Options"
                />
              )}
            />
          </Grid>
        );

      case "childPageSelect":
        let Childpages = getChildProjectScreen(auth);
        //to get filtered Options
        Childpages = (Childpages ?? []).filter((page) => {
          if (
            !data[id][val?.key]?.some((v) => v?.screenId === page?.screenId)
          ) {
            return true;
          } else {
            return false;
          }
        });

        return (
          <Grid item xs={val.size} key={index} style={{ padding: "4px" }}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={Childpages}
              value={data?.[id]?.[parentArray][parentIndex]?.[val.key] ?? []}
              getOptionLabel={(option) => option?.screenName}
              onChange={(event, newValue) =>
                handleChange(
                  id,
                  val.key,
                  newValue,
                  undefined,
                  undefined,
                  parentIndex,
                  parentArray,
                  nestedObject,
                  objectParentKey
                )
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.screenName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"outlined"}
                  label={val.name}
                  margin={"dense"}
                  placeholder="Add Options"
                />
              )}
            />
          </Grid>
        );
      default:
        break;
    }
  };

  const addToRepo = (isIdmEnabled) => {
    if (isIdmEnabled) {
      alert("delete from repo future not enabled");
      return;
    } else {
      let data = auth.user.componentAttr;

      let screenId = auth.user.AllScreenList.findIndex(
        (s) => s.id === auth.user.selectedScreen
      );

      let allScreenList = auth.user.AllScreenList;

      let data1 = allScreenList[screenId].render.child;

      repositoryOperation.addComponent(
        auth.user.permissionData,
        allScreenList[screenId]?.pathId,
        auth.user.componentAttr
      );

      getChildId(auth.user.componentAttr.id, data1, data, "isIdmEnabled");
    }
  };

  useEffect(() => {
    setData(auth?.user?.componentAttr);
    if (Object.keys(auth.user?.componentAttr?.validations).length > 0) {
      setChecked({
        [auth.user?.componentAttr.id]: true,
      });
      let validationKeys = Object.keys(
        auth.user?.componentAttr?.validations
      ).map((val) => {
        if (val === "patternType" || val === "pattern") {
          return "regex";
        } else if (val === "title") {
          return "helperText";
        } else if (val === "required") {
          return "isRequired";
        }
        return val;
      });
      validationKeys = [...new Set(validationKeys)];
      setValidationValue(validationKeys);
    } else {
      setChecked({
        [auth.user?.componentAttr]: true,
      });
      setValidationValue([]);
    }

    getLanguageList();

    let UIComponent = auth.user?.UIcomponents.filter(
      (comp) => comp.componentName === auth.user?.componentAttr?.component
    );
    let result = UIComponent.length > 0 ? UIComponent[0] : {};
    setComponentFromDb({ ...result });
  }, [auth?.user?.componentAttr, auth?.user?.UIcomponents]);

  const getAllQpasQueries = () => {
    let qpasQueries = [];
    auth.user.qpasQueries.length &&
      auth.user.qpasQueries.forEach((item) => {
        qpasQueries.push({
          id: item?._id,
          label: item?.apiname,
        });
      });
    return qpasQueries;
  };

  useEffect(() => {
    getAllLanguages();
    setqpasQueries(getAllQpasQueries());
  }, []);

  return (
    <div className={classes.root}>
      {auth.user?.componentAttr && (
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.lists}>
            <Grid>
              <Typography className="text" variant="subtitle2">
                {auth.user?.componentAttr?.name ?? ""}
              </Typography>
            </Grid>
            <Grid>
              <DeleteIcon
                className={classes.delete}
                onClick={() => handleDeleteComponent(data.id)}
                color={"action"}
                fontSize={"small"}
                style={{ fontSize: "16px", marginRight: 12 }}
              />
            </Grid>
          </Grid>

          <div style={{ padding: "10px" }}>
            <Grid container>
              {/* aliasName*/}
              <Grid item xs={12} style={{ padding: "0px 4px 0px 4px" }}>
                <TextField
                  value={data?.properties?.aliasName ?? ""}
                  onChange={(e) =>
                    handleChange("properties", "aliasName", e.target.value)
                  }
                  type="text"
                  fullWidth
                  label="Alias Name"
                  className={classes.inputField}
                  margin="dense"
                  variant="outlined"
                ></TextField>
              </Grid>
              {/* id*/}
              <Grid item xs={12} style={{ padding: "4px" }}>
                <TextField
                  value={data?.properties?.id ?? ""}
                  onChange={(e) =>
                    handleChange("properties", "id", e.target.value)
                  }
                  type="text"
                  fullWidth
                  label="Id"
                  className={classes.inputField}
                  margin="dense"
                  variant="outlined"
                ></TextField>
              </Grid>
              {/* Comment*/}
              <Grid item xs={12} style={{ padding: "0px 4px 0px 4px" }}>
                <TextField
                  value={data?.properties?.comment ?? ""}
                  onChange={(e) =>
                    handleChange("properties", "comment", e.target.value)
                  }
                  type="text"
                  fullWidth
                  label="Comment"
                  className={classes.inputField}
                  margin="dense"
                  variant="outlined"
                ></TextField>
              </Grid>
            </Grid>
            {Object.keys(dynamicPropertiesComponent).some(
              (comp) => comp === auth.user?.componentAttr?.name
            ) ? (
              DcPropertiesProducer()
            ) : (
              <>
                {/* QDM Components properties renderer start*/}
                <Grid container>
                  {auth.user?.componentAttr &&
                    auth.user?.componentAttr?.component_type === "qdm" &&
                    auth.user?.componentAttr?.frameWork === "qdm" &&
                    Object.keys(data?.properties ?? {}).map((val, index) => {
                      if (propertiesToAvoidInQDMC.some((prop) => prop === val))
                        return null;
                      let crnt = selectpropertiesInput(
                        val,
                        auth.user?.componentAttr?.component,
                        auth.user?.componentAttr?.componentId
                      );
                      return getFromComponents(
                        "properties",
                        crnt,
                        index,
                        auth.user?.componentAttr?.component
                      );
                    })}
                </Grid>
                {/* QDM Components properties renderer end*/}

                {/* Material UI Components properties renderer start*/}
                <Grid container>
                  {auth.user?.componentAttr &&
                    (auth.user?.componentAttr?.frameWork.startsWith(
                      "material"
                    ) ||
                      auth.user?.componentAttr?.frameWork ===
                        "custom-material-ui-component") &&
                    Object.keys(componentFromDb?.props ?? {}).length > 0 &&
                    Object.keys(componentFromDb?.props ?? {}).map(
                      (key, index) => {
                        let propertiesOptions = componentFromDb?.props[key];
                        let crnt = selectpropertiesInput(
                          key,
                          auth.user?.componentAttr?.component,
                          auth.user?.componentAttr?.componentId
                        );
                        return dynamicPropertyProducer(
                          "properties",
                          crnt,
                          index,
                          auth.user?.componentAttr?.component,
                          propertiesOptions
                        );
                      }
                    )}
                </Grid>
                {/* Material UI Components properties renderer end*/}

                {/* Validation Property */}
                <FormControlLabel
                  labelPlacement="start"
                  label={
                    <Typography style={{ fontSize: "14px" }}>
                      Validation required
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={
                        checked[`${auth.user?.componentAttr?.id}`] ?? false
                      }
                      onChange={handleCheck}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      size="small"
                    />
                  }
                ></FormControlLabel>
                {/* Selection for the validation component */}
                {checked[auth.user?.componentAttr?.id] && (
                  <Autocomplete
                    value={validationValue}
                    multiple
                    id="size-small-outlined-multi"
                    size="small"
                    options={validationOptions}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setValidationValue(newValue);
                    }}
                    className={classes.inputField}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Validations"
                        margin="dense"
                        style={{ fontSize: "14px" }}
                        placeholder="Select validation"
                      />
                    )}
                  />
                )}
                {validationValue.length > 0 && (
                  <Validations
                    validationValue={validationValue}
                    handleCollapse={handleCollapse}
                    collapse={collapse}
                    key={auth.user?.componentAttr?.id}
                  />
                )}
              </>
            )}
          </div>
        </Grid>
      )}
      <Dialog
        open={messageCatalogDialog}
        onClose={handleClose}
        classes={{
          paper: classes.dialog,
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Message Catalog</DialogTitle>
        <TextField
          autoFocus
          variant="outlined"
          margin="dense"
          id="name"
          rquired
          value={messageCatalog?.Title}
          onChange={(event) =>
            setMessageCatalog({ ...messageCatalog, Title: event.target.value })
          }
          className={classes.TitleInput}
          label="Title"
          type="text"
        />
        <DialogContent className={classes.dialogContent}>
          {allLanguages?.length > 0 &&
            allLanguages.map((language) => {
              return (
                <ReactTransliterate
                  onChangeText={(text) => {
                    setMessageCatalog({
                      ...messageCatalog,
                      [language.language]: text,
                    });
                  }}
                  lang={languageCodes[language.language]}
                  renderComponent={(props) => {
                    const inputRef = props.ref;
                    delete props["ref"];
                    return (
                      <TextField
                        {...props}
                        margin="dense"
                        fullWidth
                        rquired
                        autoComplete="off"
                        id="name"
                        label={language.language}
                        type="text"
                        inputRef={inputRef}
                      />
                    );
                  }}
                  maxOptions={4}
                  enabled={language.language !== "English" ? true : false}
                  value={messageCatalog[language.language]}
                  offsetY={8}
                  offsetX={16}
                />
              );
            })}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
          {Object.keys(messageCatalog).filter(
            (val) => messageCatalog[val] !== "" || null || undefined
          )?.length === Object.keys(messageCatalog)?.length && (
            <Button
              size="small"
              type="submit"
              onClick={handleMessageCatalogSubmit}
              variant="contained"
              style={{ marginLeft: "10px" }}
              color="primary"
              disableElevation
            >
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
