/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 17/05/2021
 * @modify 17/05/2021 
 * @desc Exporting all the components from /src/components/common 
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
const useStyles = ((theme) => ({
    selectBox: {
        width: "100%",
        height: "35px",
        "& .MuiFormLabel-root.Mui-focused": {
            transform: "translate(14px, -6px) scale(0.75) !important"
        },
        "& .MuiFormLabel-root.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75) !important"
        },
        "& .MuiInputLabel-outlined": {
            transform: "translate(14px, 9px) scale(1) !important"
        },
        "& .MuiAutocomplete-inputRoot": {
            height: "35px",
            "& input": {
                padding: "0.5px 4px !important"
            }
        }
    }
}));

class SelectBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        let { Optionlabel = 'label', isMulti, classes, onchange, classname, list, value, title, top_title } = this.props;
        return (
            <React.Fragment>
                {top_title && <Typography variant="caption">{top_title}</Typography>}
                <Autocomplete
                    multiple={isMulti ? true : false}
                    options={list}
                    className={`${classes.selectBox} ${value ? 'Mui-focused' : ''} ${classname ? classname : ''}`}
                    onChange={onchange}
                    getOptionLabel={(option) => option[Optionlabel]}
                    style={{ width: "100%", height: "35px" }}
                    value={value}
                    renderInput={(params) => <TextField style={{ height: "35px" }} {...params} label={top_title ? '' : title} variant="outlined" />}
                />
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(SelectBox);