const getIconList = (data) => {
  let list = [];
  data.map((name, i) => {
    return list.push({ title: data[i] });
  });
  return list;
};

const iconList = [
  "address-book",
  "address-book-o",
  "address-card",
  "address-card-o",
  "adjust",
  "american-sign-language-interpreting",
  "anchor",
  "archive",
  "area-chart",
  "arrows",
  "arrows-h",
  "arrows-v",
  "asl-interpreting",
  "assistive-listening-systems",
  "asterisk",
  "at",
  "audio-description",
  "automobile",
  "balance-scale",
  "ban",
  "bank",
  "bar-chart",
  "bar-chart-o",
  "barcode",
  "bars",
  "bath",
  "bathtub",
  "battery",
  "battery-0",
  "battery-1",
  "battery-2",
  "battery-3",
  "battery-4",
  "battery-empty",
  "battery-full",
  "battery-half",
  "battery-quarter",
  "battery-three-quarters",
  "bed",
  "beer",
  "bell",
  "bell-o",
  "bell-slash",
  "bell-slash-o",
  "bicycle",
  "binoculars",
  "birthday-cake",
  "blind",
  "bluetooth",
  "bluetooth-b",
  "bolt",
  "bomb",
  "book",
  "bookmark",
  "bookmark-o",
  "braille",
  "briefcase",
  "bug",
  "building",
  "building-o",
  "bullhorn",
  "bullseye",
  "bus",
  "cab",
  "calculator",
  "calendar",
  "calendar-check-o",
  "calendar-minus-o",
  "calendar-o",
  "calendar-plus-o",
  "calendar-times-o",
  "camera",
  "camera-retro",
  "car",
  "caret-square-o-down",
  "caret-square-o-left",
  "caret-square-o-right",
  "caret-square-o-up",
  "cart-arrow-down",
  "cart-plus",
  "cc",
  "certificate",
  "check",
  "check-circle",
  "check-circle-o",
  "check-square",
  "check-square-o",
  "child",
  "circle",
  "circle-o",
  "circle-o-notch",
  "circle-thin",
  "clock-o",
  "clone",
  "close",
  "cloud",
  "cloud-download",
  "cloud-upload",
  "code",
  "code-fork",
  "coffee",
  "cog",
  "cogs",
  "comment",
  "comment-o",
  "commenting",
  "commenting-o",
  "comments",
  "comments-o",
  "compass",
  "copyright",
  "creative-commons",
  "credit-card",
  "credit-card-alt",
  "crop",
  "crosshairs",
  "cube",
  "cubes",
  "cutlery",
  "dashboard",
  "database",
  "deaf",
  "deafness",
  "desktop",
  "diamond",
  "dot-circle-o",
  "download",
  "drivers-license",
  "drivers-license-o",
  "edit",
  "ellipsis-h",
  "ellipsis-v",
  "envelope",
  "envelope-o",
  "envelope-open",
  "envelope-open-o",
  "envelope-square",
  "eraser",
  "exchange",
  "exclamation",
  "exclamation-circle",
  "exclamation-triangle",
  "external-link",
  "external-link-square",
  "eye",
  "eye-slash",
  "eyedropper",
  "fax",
  "feed",
  "female",
  "fighter-jet",
  "file-archive-o",
  "file-audio-o",
  "file-code-o",
  "file-excel-o",
  "file-image-o",
  "file-movie-o",
  "file-pdf-o",
  "file-photo-o",
  "file-picture-o",
  "file-powerpoint-o",
  "file-sound-o",
  "file-video-o",
  "file-word-o",
  "file-zip-o",
  "film",
  "filter",
  "fire",
  "fire-extinguisher",
  "flag",
  "flag-checkered",
  "flag-o",
  "flash",
  "flask",
  "folder",
  "folder-o",
  "folder-open",
  "folder-open-o",
  "frown-o",
  "futbol-o",
  "gamepad",
  "gavel",
  "gear",
  "gears",
  "gift",
  "glass",
  "globe",
  "graduation-cap",
  "group",
  "hand-grab-o",
  "hand-lizard-o",
  "hand-paper-o",
  "hand-peace-o",
  "hand-pointer-o",
  "hand-rock-o",
  "hand-scissors-o",
  "hand-spock-o",
  "hand-stop-o",
  "handshake-o",
  "hard-of-hearing",
  "hashtag",
  "hdd-o",
  "headphones",
  "heart",
  "heart-o",
  "heartbeat",
  "history",
  "home",
  "hotel",
  "hourglass",
  "hourglass-1",
  "hourglass-2",
  "hourglass-3",
  "hourglass-end",
  "hourglass-half",
  "hourglass-o",
  "hourglass-start",
  "i-cursor",
  "id-badge",
  "id-card",
  "id-card-o",
  "image",
  "inbox",
  "industry",
  "info",
  "info-circle",
  "institution",
  "key",
  "keyboard-o",
  "language",
  "laptop",
  "leaf",
  "legal",
  "lemon-o",
  "level-down",
  "level-up",
  "life-bouy",
  "life-buoy",
  "life-ring",
  "life-saver",
  "lightbulb-o",
  "line-chart",
  "location-arrow",
  "lock",
  "low-vision",
  "magic",
  "magnet",
  "mail-forward",
  "mail-reply",
  "mail-reply-all",
  "male",
  "map",
  "map-marker",
  "map-o",
  "map-pin",
  "map-signs",
  "meh-o",
  "microchip",
  "microphone",
  "microphone-slash",
  "minus",
  "minus-circle",
  "minus-square",
  "minus-square-o",
  "mobile",
  "mobile-phone",
  "money",
  "moon-o",
  "mortar-board",
  "motorcycle",
  "mouse-pointer",
  "music",
  "navicon",
  "newspaper-o",
  "object-group",
  "object-ungroup",
  "paint-brush",
  "paper-plane",
  "paper-plane-o",
  "paw",
  "pencil",
  "pencil-square",
  "pencil-square-o",
  "percent",
  "phone",
  "phone-square",
  "photo",
  "picture-o",
  "pie-chart",
  "plane",
  "plug",
  "plus",
  "plus-circle",
  "plus-square",
  "plus-square-o",
  "podcast",
  "power-off",
  "print",
  "puzzle-piece",
  "qrcode",
  "question",
  "question-circle",
  "question-circle-o",
  "quote-left",
  "quote-right",
  "random",
  "recycle",
  "refresh",
  "registered",
  "remove",
  "reorder",
  "reply",
  "reply-all",
  "retweet",
  "road",
  "rocket",
  "rss",
  "rss-square",
  "s15",
  "search",
  "search-minus",
  "search-plus",
  "send",
  "send-o",
  "server",
  "share",
  "share-alt",
  "share-alt-square",
  "share-square",
  "share-square-o",
  "shield",
  "ship",
  "shopping-bag",
  "shopping-basket",
  "shopping-cart",
  "shower",
  "sign-in",
  "sign-language",
  "sign-out",
  "signal",
  "signing",
  "sitemap",
  "sliders",
  "smile-o",
  "snowflake-o",
  "soccer-ball-o",
  "sort",
  "sort-alpha-asc",
  "sort-alpha-desc",
  "sort-amount-asc",
  "sort-amount-desc",
  "sort-asc",
  "sort-desc",
  "sort-down",
  "sort-numeric-asc",
  "sort-numeric-desc",
  "sort-up",
  "space-shuttle",
  "spinner",
  "spoon",
  "square",
  "square-o",
  "star",
  "star-half",
  "star-half-empty",
  "star-half-full",
  "star-half-o",
  "star-o",
  "sticky-note",
  "sticky-note-o",
  "street-view",
  "suitcase",
  "sun-o",
  "support",
  "tablet",
  "tachometer",
  "tag",
  "tags",
  "tasks",
  "taxi",
  "television",
  "terminal",
  "thermometer",
  "thermometer-0",
  "thermometer-1",
  "thermometer-2",
  "thermometer-3",
  "thermometer-4",
  "thermometer-empty",
  "thermometer-full",
  "thermometer-half",
  "thermometer-quarter",
  "thermometer-three-quarters",
  "thumb-tack",
  "thumbs-down",
  "thumbs-o-down",
  "thumbs-o-up",
  "thumbs-up",
  "ticket",
  "times",
  "times-circle",
  "times-circle-o",
  "times-rectangle",
  "times-rectangle-o",
  "tint",
  "toggle-down",
  "toggle-left",
  "toggle-off",
  "toggle-on",
  "toggle-right",
  "toggle-up",
  "trademark",
  "trash",
  "trash-o",
  "tree",
  "trophy",
  "truck",
  "tty",
  "tv",
  "umbrella",
  "universal-access",
  "university",
  "unlock",
  "unlock-alt",
  "unsorted",
  "upload",
  "user",
  "user-circle",
  "user-circle-o",
  "user-o",
  "user-plus",
  "user-secret",
  "user-times",
  "users",
  "vcard",
  "vcard-o",
  "video-camera",
  "volume-control-phone",
  "volume-down",
  "volume-off",
  "volume-up",
  "warning",
  "wheelchair",
  "wheelchair-alt",
  "wifi",
  "window-close",
  "window-close-o",
  "window-maximize",
  "window-minimize",
  "window-restore",
  "wrench",
  "american-sign-language-interpreting",
  "asl-interpreting",
  "assistive-listening-systems",
  "audio-description",
  "blind",
  "braille",
  "cc",
  "deaf",
  "deafness",
  "hard-of-hearing",
  "low-vision",
  "question-circle-o",
  "sign-language",
  "signing",
  "tty",
  "universal-access",
  "volume-control-phone",
  "wheelchair",
  "wheelchair-alt",
  "hand-grab-o",
  "hand-lizard-o",
  "hand-o-down",
  "hand-o-left",
  "hand-o-right",
  "hand-o-up",
  "hand-paper-o",
  "hand-peace-o",
  "hand-pointer-o",
  "hand-rock-o",
  "hand-scissors-o",
  "hand-spock-o",
  "hand-stop-o",
  "thumbs-down",
  "thumbs-o-down",
  "thumbs-o-up",
  "thumbs-up",
  "ambulance",
  "automobile",
  "bicycle",
  "bus",
  "cab",
  "car",
  "fighter-jet",
  "motorcycle",
  "plane",
  "rocket",
  "ship",
  "space-shuttle",
  "subway",
  "taxi",
  "train",
  "truck",
  "wheelchair",
  "wheelchair-alt",
  "genderless",
  "intersex",
  "mars",
  "mars-double",
  "mars-stroke",
  "mars-stroke-h",
  "mars-stroke-v",
  "mercury",
  "neuter",
  "transgender",
  "transgender-alt",
  "venus",
  "venus-double",
  "venus-mars",
  "file",
  "file-archive-o",
  "file-audio-o",
  "file-code-o",
  "file-excel-o",
  "file-image-o",
  "file-movie-o",
  "file-o",
  "file-pdf-o",
  "file-photo-o",
  "file-picture-o",
  "file-powerpoint-o",
  "file-sound-o",
  "file-text",
  "file-text-o",
  "file-video-o",
  "file-word-o",
  "file-zip-o",
  "circle-o-notch",
  "cog",
  "gear",
  "refresh",
  "spinner",
  "check-square",
  "check-square-o",
  "circle",
  "circle-o",
  "dot-circle-o",
  "minus-square",
  "minus-square-o",
  "plus-square",
  "plus-square-o",
  "square",
  "square-o",
  "cc-amex",
  "cc-diners-club",
  "cc-discover",
  "cc-jcb",
  "cc-mastercard",
  "cc-paypal",
  "cc-stripe",
  "cc-visa",
  "credit-card",
  "credit-card-alt",
  "google-wallet",
  "paypal",
  "area-chart",
  "bar-chart",
  "bar-chart-o",
  "line-chart",
  "pie-chart",
  "bitcoin",
  "btc",
  "cny",
  "dollar",
  "eur",
  "euro",
  "gbp",
  "gg",
  "gg-circle",
  "ils",
  "inr",
  "jpy",
  "krw",
  "money",
  "rmb",
  "rouble",
  "rub",
  "ruble",
  "rupee",
  "shekel",
  "sheqel",
  "try",
  "turkish-lira",
  "usd",
  "viacoin",
  "won",
  "yen",
  "align-center",
  "align-justify",
  "align-left",
  "align-right",
  "bold",
  "chain",
  "chain-broken",
  "clipboard",
  "columns",
  "copy",
  "cut",
  "dedent",
  "eraser",
  "file",
  "file-o",
  "file-text",
  "file-text-o",
  "files-o",
  "floppy-o",
  "font",
  "header",
  "indent",
  "italic",
  "link",
  "list",
  "list-alt",
  "list-ol",
  "list-ul",
  "outdent",
  "paperclip",
  "paragraph",
  "paste",
  "repeat",
  "rotate-left",
  "rotate-right",
  "save",
  "scissors",
  "strikethrough",
  "subscript",
  "superscript",
  "table",
  "text-height",
  "text-width",
  "th",
  "th-large",
  "th-list",
  "underline",
  "undo",
  "unlink",
  "angle-double-down",
  "angle-double-left",
  "angle-double-right",
  "angle-double-up",
  "angle-down",
  "angle-left",
  "angle-right",
  "angle-up",
  "arrow-circle-down",
  "arrow-circle-left",
  "arrow-circle-o-down",
  "arrow-circle-o-left",
  "arrow-circle-o-right",
  "arrow-circle-o-up",
  "arrow-circle-right",
  "arrow-circle-up",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "arrows",
  "arrows-alt",
  "arrows-h",
  "arrows-v",
  "caret-down",
  "caret-left",
  "caret-right",
  "caret-square-o-down",
  "caret-square-o-left",
  "caret-square-o-right",
  "caret-square-o-up",
  "caret-up",
  "chevron-circle-down",
  "chevron-circle-left",
  "chevron-circle-right",
  "chevron-circle-up",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "exchange",
  "hand-o-down",
  "hand-o-left",
  "hand-o-right",
  "hand-o-up",
  "long-arrow-down",
  "long-arrow-left",
  "long-arrow-right",
  "long-arrow-up",
  "toggle-down",
  "toggle-left",
  "toggle-right",
  "toggle-up",
  "arrows-alt",
  "backward",
  "compress",
  "eject",
  "expand",
  "fast-backward",
  "fast-forward",
  "forward",
  "pause",
  "pause-circle",
  "pause-circle-o",
  "play",
  "play-circle",
  "play-circle-o",
  "random",
  "step-backward",
  "step-forward",
  "stop",
  "stop-circle",
  "stop-circle-o",
  "youtube-play",
  // "500px",
  "adn",
  "amazon",
  "android",
  "angellist",
  "apple",
  "bandcamp",
  "behance",
  "behance-square",
  "bitbucket",
  "bitbucket-square",
  "bitcoin",
  "black-tie",
  "bluetooth",
  "bluetooth-b",
  "btc",
  "buysellads",
  "cc-amex",
  "cc-diners-club",
  "cc-discover",
  "cc-jcb",
  "cc-mastercard",
  "cc-paypal",
  "cc-stripe",
  "cc-visa",
  "chrome",
  "codepen",
  "codiepie",
  "connectdevelop",
  "contao",
  "css3",
  "dashcube",
  "delicious",
  "deviantart",
  "digg",
  "dribbble",
  "dropbox",
  "drupal",
  "edge",
  "eercast",
  "empire",
  "envira",
  "etsy",
  "expeditedssl",
  "fa",
  "facebook",
  "facebook-f",
  "facebook-official",
  "facebook-square",
  "firefox",
  "first-order",
  "flickr",
  "font-awesome",
  "fonticons",
  "fort-awesome",
  "forumbee",
  "foursquare",
  "free-code-camp",
  "ge",
  "get-pocket",
  "gg",
  "gg-circle",
  "git",
  "git-square",
  "github",
  "github-alt",
  "github-square",
  "gitlab",
  "gittip",
  "glide",
  "glide-g",
  "google",
  "google-plus",
  "google-plus-circle",
  "google-plus-official",
  "google-plus-square",
  "google-wallet",
  "gratipay",
  "grav",
  "hacker-news",
  "houzz",
  "html5",
  "imdb",
  "instagram",
  "internet-explorer",
  "ioxhost",
  "joomla",
  "jsfiddle",
  "lastfm",
  "lastfm-square",
  "leanpub",
  "linkedin",
  "linkedin-square",
  "linode",
  "linux",
  "maxcdn",
  "meanpath",
  "medium",
  "meetup",
  "mixcloud",
  "modx",
  "odnoklassniki",
  "odnoklassniki-square",
  "opencart",
  "openid",
  "opera",
  "optin-monster",
  "pagelines",
  "paypal",
  "pied-piper",
  "pied-piper-alt",
  "pied-piper-pp",
  "pinterest",
  "pinterest-p",
  "pinterest-square",
  "product-hunt",
  "qq",
  "quora",
  "ra",
  "ravelry",
  "rebel",
  "reddit",
  "reddit-alien",
  "reddit-square",
  "renren",
  "resistance",
  "safari",
  "scribd",
  "sellsy",
  "share-alt",
  "share-alt-square",
  "shirtsinbulk",
  "simplybuilt",
  "skyatlas",
  "skype",
  "slack",
  "slideshare",
  "snapchat",
  "snapchat-ghost",
  "snapchat-square",
  "soundcloud",
  "spotify",
  "stack-exchange",
  "stack-overflow",
  "steam",
  "steam-square",
  "stumbleupon",
  "stumbleupon-circle",
  "superpowers",
  "telegram",
  "tencent-weibo",
  "themeisle",
  "trello",
  "tripadvisor",
  "tumblr",
  "tumblr-square",
  "twitch",
  "twitter",
  "twitter-square",
  "usb",
  "viacoin",
  "viadeo",
  "viadeo-square",
  "vimeo",
  "vimeo-square",
  "vine",
  "vk",
  "wechat",
  "weibo",
  "weixin",
  "whatsapp",
  "wikipedia-w",
  "windows",
  "wordpress",
  "wpbeginner",
  "wpexplorer",
  "wpforms",
  "xing",
  "xing-square",
  "y-combinator",
  "y-combinator-square",
  "yahoo",
  "yc",
  "yc-square",
  "yelp",
  "yoast",
  "youtube",
  "youtube-play",
  "youtube-square",
  "ambulance",
  "h-square",
  "heart",
  "heart-o",
  "heartbeat",
  "hospital-o",
  "medkit",
  "plus-square",
  "stethoscope",
  "user-md",
  "wheelchair",
  "wheelchair-alt",
];

/// select variant property based on component
export const variantSelector = {
  Button: {
    options: [
      { title: "text", year: "text" },
      { title: "outlined", year: "outlined" },
      { title: "contained", year: "contained" },
    ],
  },
  TextInput: {
    options: [
      { title: "standard", year: "standard" },
      { title: "outlined", year: "outlined" },
      { title: "none", year: "none" },
    ],
  },
  BorderLinearProgress: {
    options: [
      { title: "determinate", year: 1 },
      { title: "indeterminate", year: 2 },
      { title: "static", year: 3 },
    ],
  },
  Alert: {
    options: [
      { title: "filled", year: 1 },
      { title: "outline", year: 2 },
      { title: "standard", year: 3 },
    ],
  },
  Divider: {
    options: [
      { title: "fullWidth", year: 1 },
      { title: "inset", year: 2 },
      { title: "middle", year: 3 },
    ],
  },
  Paper: {
    options: [
      { title: "elevation", year: 1 },
      { title: "outlined", year: 2 },
    ],
  },
  Avatar: {
    options: [
      { title: "circle", year: 1 },
      { title: "rounded", year: 2 },
      { title: "square", year: 3 },
    ],
  },
  Select: {
    options: [
      { title: "outlined", year: 1 },
      { title: "standard", year: 1 },
      { title: "none", year: "none" },
    ],
  },
  ButtonGroup: {
    options: [
      { title: "contained", year: 1 },
      { title: "outlined", year: 2 },
    ],
  },
  LocationSearch: {
    options: [{ title: "standard" }, { title: "outlined" }],
  },
  AutoSuggestion: {
    options: [
      { title: "standard" },
      { title: "outlined" },
      { title: "none", year: "none" },
    ],
  },
  OtpInput: {
    options: [{ title: "standard" }, { title: "outlined" }],
  },
  Tab: {
    options: [
      { title: "standard" },
      { title: "contained" },
      { title: "bordered" },
      { title: "withoutBordered" },
    ],
  },
};

/// select type property based on component
export const typeSelector = (componentName) => {
  switch (componentName) {
    case "RangePicker":
      return [{ title: "range", year: 1 }];
    case "DatePicker":
      return [{ title: "range", year: 1 }];
    case "TextInput":
      return [
        { title: "text", year: 1 },
        { title: "number", year: 2 },
        { title: "password", year: 3 },
        { title: "email", year: 4 },
      ];
    case "DateTimepicker":
      return [{ title: "date", year: 1 }];
    case "OtpInput":
      return [{ title: "text" }, { title: "number" }, { title: "password" }];
    case "TapSelect":
      return [{ title: "single" }, { title: "multiple" }];
    default:
      return [
        { title: "button", year: 1 },
        { title: "submit", year: 2 },
        { title: "reset", year: 3 },
      ];
  }
};

/// select color property based on component
export const colorSelector = {
  others: [
    { title: "primary", year: 1 },
    { title: "secondary", year: 2 },
    { title: "default", year: 3 },
  ],
  TopHeader: [
    { title: "primary", year: 1 },
    { title: "secondary", year: 2 },
    { title: "inherit", year: 3 },
    { title: "transparent", year: 3 },
  ],
  Link: [
    { title: "inherit", year: 1 },
    { title: "primary", year: 2 },
    { title: "secondary", year: 3 },
    { title: "textPrimary", year: 4 },
    { title: "textSecondary", year: 5 },
    { title: "error", year: 6 },
  ],
};

/// all properties key
export const propertiesInput = {
  name: {
    name: "Name",
    key: "name",
    size: 12,
    // componentType: "text",
    componentType: "languageSelect",
  },
  children: {
    name: "children",
    key: "children",
    size: 12,
    componentType: "text",
  },
  xsUp: {
    name: "xsUp",
    key: "xsUp",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  xsDown: {
    name: "xsDown",
    key: "xsDown",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  smUp: {
    name: "smUp",
    key: "smUp",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  smDown: {
    name: "smDown",
    key: "smDown",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  mdUp: {
    name: "mdUp",
    key: "mdUp",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  mdDown: {
    name: "mdDown",
    key: "mdDown",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  lgUp: {
    name: "lgUp",
    key: "lgUp",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  lgDown: {
    name: "lgDown",
    key: "lgDown",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  xlUp: {
    name: "xlUp",
    key: "xlUp",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  xlDown: {
    name: "xlDown",
    key: "xlDown",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  position: {
    name: "position",
    key: "position",
    size: 12,
    componentType: "select",
    options: [
      { title: "absolute", year: 1 },
      { title: "fixed", year: 2 },
      { title: "relative", year: 3 },
      { title: "static", year: 4 },
      { title: "sticky", year: 5 },
    ],
  },
  value: {
    name: "value",
    key: "value",
    size: 12,
    componentType: "text",
  },
  letter: {
    name: "Letter",
    key: "letter",
    size: 12,
    componentType: "text",
  },
  activetabs: {
    name: "activetabs",
    key: "activetabs",
    size: 12,
    componentType: "number",
  },
  src: {
    name: "src",
    key: "src",
    size: 12,
    componentType: "imageListAndUpload",
  },
  alt: {
    name: "Alternate Text",
    key: "alt",
    size: 12,
    componentType: "text",
  },
  id: {
    name: "id",
    key: "id",
    size: 12,
    componentType: "text",
  },
  title: {
    name: "Enter title",
    key: "title",
    size: 12,
    // componentType: "text",
    componentType: "languageSelect",
  },
  subheader: {
    name: "Enter Sub title",
    key: "subheader",
    size: 12,
    componentType: "text",
  },
  subtitle: {
    name: "Enter Sub title",
    key: "subtitle",
    size: 12,
    componentType: "text",
  },
  profile: {
    name: "Enter Profile",
    key: "profile",
    size: 12,
    componentType: "text",
  },
  description: {
    name: "Enter Description",
    key: "description",
    size: 12,
    componentType: "text",
  },
  action: {
    name: "Enter action",
    key: "action",
    size: 12,
    componentType: "text",
  },
  image: {
    name: "Pick Image",
    key: "image",
    size: 12,
    componentType: "text",
  },
  subHeader: {
    name: "Enter card sub heading",
    key: "subHeader",
    size: 12,
    componentType: "text",
  },
  avatar: {
    name: "avatar",
    key: "avatar",
    size: 12,
    componentType: "text",
  },
  allowClear: {
    name: "Allow clear",
    key: "allowClear",
    size: 12,
    componentType: "boolean",
    labelWidth: 74,
  },
  label: {
    name: "Label",
    key: "label",
    size: 12,
    // componentType: "text",
    componentType: "languageSelect",
  },
  maxLength: {
    name: "maxLength",
    key: "maxLength",
    size: 12,
    componentType: "text",
  },
  labelWidth: {
    name: "Label Width",
    key: "labelWidth",
    size: 12,
    componentType: "text",
  },
  noWrap: {
    name: "White Space",
    key: "noWrap",
    size: 12,
    componentType: "boolean",
    labelWidth: 35,
  },
  fluid: {
    name: "Container Fluid",
    key: "fluid",
    size: 12,
    componentType: "boolean",
    labelWidth: 30,
  },
  color: {
    name: "Color",
    key: "color",
    size: 12,
    componentType: "select",
    labelWidth: 30,
  },
  size: {
    name: "size",
    key: "size",
    size: 12,
    componentType: "select",
    options: [
      { title: "small", year: 1994 },
      { title: "medium", year: 1972 },
      { title: "large", year: 1972 },
    ],
  },
  ariaHidden: {
    name: "ariaHidden",
    key: "ariaHidden",
    size: 12,
    componentType: "select",
    options: [
      { title: "true", year: 1 },
      { title: "false", year: 2 },
    ],
  },
  underline: {
    name: "Text Decoration",
    key: "underLine",
    size: 12,
    componentType: "select",
    options: [
      { title: "none", year: 1 },
      { title: "hover", year: 2 },
      { title: "always", year: 3 },
    ],
  },
  variant: {
    name: "variant",
    key: "variant",
    size: 12,
    componentType: "select",
  },
  xs: {
    name: "xs",
    key: "xs",
    size: 2,
    componentType: "text",
  },
  md: {
    name: "md",
    key: "md",
    size: 2,
    componentType: "text",
  },
  sm: {
    name: "sm",
    key: "sm",
    size: 2,
    componentType: "text",
  },
  lg: {
    name: "lg",
    key: "lg",
    size: 2,
    componentType: "text",
  },
  justify: {
    name: "Justify Content",
    key: "justify",
    size: 12,
    componentType: "select",
    allowClear: true,
    options: [
      { title: "flex-start", year: 1 },
      { title: "center", year: 2 },
      { title: "flex-end", year: 3 },
      { title: "space-between", year: 4 },
      { title: "space-around", year: 5 },
      { title: "space-evenly", year: 6 },
    ],
  },
  justifyContent: {
    name: "Justify Content",
    key: "justifyContent",
    size: 12,
    componentType: "select",
    allowClear: true,
    options: [
      { title: "flex-start", year: 1 },
      { title: "center", year: 2 },
      { title: "flex-end", year: 3 },
      { title: "space-between", year: 4 },
      { title: "space-around", year: 5 },
      { title: "space-evenly", year: 6 },
      { title: "normal", year: 7 },
    ],
  },
  align: {
    name: "Align",
    key: "align",
    size: 12,
    componentType: "select",
    allowClear: true,
    options: [
      { title: "center", year: 1 },
      { title: "justify", year: 2 },
      { title: "inherit", year: 3 },
      { title: "right", year: 4 },
    ],
  },
  alignItems: {
    name: "Align Items",
    key: "alignItems",
    size: 12,
    componentType: "select",
    allowClear: true,
    options: [
      { title: "flex-start", year: 1 },
      { title: "center", year: 2 },
      { title: "flex-end", year: 3 },
      { title: "stretch", year: 4 },
      { title: "baseline", year: 5 },
      { title: "normal", year: 6 },
    ],
  },
  direction: {
    name: "direction",
    key: "direction",
    size: 12,
    componentType: "select",
    options: [
      { title: "row", year: 1 },
      { title: "column", year: 2 },
      { title: "row-reverse", year: 3 },
      { title: "column-reverse", year: 4 },
    ],
  },
  severity: {
    name: "severity",
    key: "severity",
    size: 12,
    componentType: "select",
    allowClear: true,
    options: [
      { title: "error", year: 1 },
      { title: "info", year: 2 },
      { title: "success", year: 3 },
      { title: "warning", year: 4 },
    ],
  },
  spacing: {
    name: "Spacing",
    key: "spacing",
    size: 12,
    componentType: "number",
  },
  type: {
    name: "Type",
    key: "type",
    size: 12,
    componentType: "type",
  },
  orientation: {
    name: "Orientation",
    key: "orientation",
    size: 12,
    componentType: "select",
    options: [
      { title: "vertical", year: 1 },
      { title: "horizontal", year: 2 },
    ],
  },
  autoFocus: {
    name: "Auto focus",
    key: "autoFocus",
    size: 12,
    componentType: "boolean",
    labelWidth: 70,
  },
  disabled: {
    name: "Disabled",
    key: "disabled",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  open: {
    name: "open",
    key: "open",
    size: 12,
    componentType: "boolean",
    labelWidth: 60,
  },
  defaultChecked: {
    name: "Enable Default Checked",
    key: "defaultChecked",
    size: 12,
    componentType: "boolean",
    labelWidth: 80,
  },
  indeterminate: {
    name: "Enable indeterminate",
    key: "indeterminate",
    size: 12,
    componentType: "boolean",
    labelWidth: 80,
  },
  helperTextType: {
    name: "Helper Text",
    key: "helperTextType",
    size: 12,
    componentType: "select",
    options: [
      { title: "error", year: "1" },
      { title: "warning", year: "2" },
      { title: "success", year: "3" },
    ],
  },
  placeHolder: {
    name: "placeHolder",
    key: "placeHolder",
    size: 12,
    // componentType: "text",
    componentType: "languageSelect",
  },
  apiKey: {
    name: "apiKey",
    key: "apiKey",
    size: 12,
    componentType: "text",
  },
  elevation: {
    name: "elevation",
    key: "elevation",
    size: 12,
    componentType: "select",
    options: [
      { title: "0" },
      { title: "1" },
      { title: "2" },
      { title: "3" },
      { title: "4" },
    ],
  },
  url: {
    name: "url",
    key: "url",
    size: 12,
    componentType: "text",
  },
  backgroundPosition: {
    name: "backgroundPosition",
    key: "backgroundPosition",
    size: 6,
    componentType: "select",
    options: [
      { title: "0% 0%" },
      { title: "bottom" },
      { title: "center" },
      { title: "inherit" },
      { title: "left" },
      { title: "revert" },
      { title: "right" },
      { title: "top" },
      { title: "unset" },
    ],
  },
  backgroundPositionX: {
    name: "backgroundPositionX",
    key: "backgroundPositionX",
    size: 6,
    componentType: "select",
    options: [
      { title: "center" },
      { title: "inherit" },
      { title: "initial" },
      { title: "left" },
      { title: "revert" },
      { title: "right" },
      { title: "unset" },
    ],
  },
  backgroundPositionY: {
    name: "backgroundPositionY",
    key: "backgroundPositionY",
    size: 6,
    componentType: "select",
    options: [
      { title: "bottom" },
      { title: "center" },
      { title: "inherit" },
      { title: "initial" },
      { title: "revert" },
      { title: "top" },
      { title: "unset" },
    ],
  },
  backgroundRepeat: {
    name: "backgroundRepeat",
    key: "backgroundRepeat",
    size: 6,
    componentType: "select",
    options: [
      { title: "repeat" },
      { title: "repeat-x" },
      { title: "repeat-y" },
      { title: "no-repeat" },
      { title: "space" },
      { title: "round" },
      { title: "initial" },
      { title: "inherit" },
      { title: "unset" },
      { title: "revert" },
    ],
  },
  backgroundRepeatX: {
    name: "backgroundRepeatX",
    key: "backgroundRepeatX",
    size: 6,
    componentType: "select",
    options: [
      { title: "repeat" },
      { title: "no-repeat" },
      { title: "initial" },
      { title: "inherit" },
      { title: "unset" },
      { title: "revert" },
    ],
  },
  backgroundRepeatY: {
    name: "backgroundRepeatY",
    key: "backgroundRepeatY",
    size: 6,
    componentType: "select",
    options: [
      { title: "repeat" },
      { title: "no-repeat" },
      { title: "initial" },
      { title: "inherit" },
      { title: "unset" },
      { title: "revert" },
    ],
  },
  backgroundAttachment: {
    name: "backgroundAttachment",
    key: "backgroundAttachment",
    size: 6,
    componentType: "select",
    options: [
      { title: "fixed" },
      { title: "initial" },
      { title: "inherit" },
      { title: "scroll" },
      { title: "unset" },
      { title: "revert" },
    ],
  },
  backgroundSize: {
    name: "backgroundSize",
    key: "backgroundSize",
    size: 6,
    componentType: "select",
    options: [
      { title: "auto" },
      { title: "contain" },
      { title: "cover" },
      { title: "inherit" },
      { title: "initial" },
      { title: "revert" },
      { title: "unset" },
    ],
  },
  backgroundOrigin: {
    name: "backgroundOrigin",
    key: "backgroundOrigin",
    size: 6,
    componentType: "select",
    options: [
      { title: "border-box" },
      { title: "padding-box" },
      { title: "content-box" },
      { title: "inherit" },
      { title: "initial" },
      { title: "unset" },
    ],
  },
  numberOfInputs: {
    name: "numberOfInputs",
    key: "numberOfInputs",
    size: 12,
    componentType: "number",
  },
  focusTextColor: {
    name: "focusTextColor",
    key: "focusTextColor",
    size: 6,
    componentType: "color",
  },
  focusBorderColor: {
    name: "focusBorderColor",
    key: "focusBorderColor",
    size: 6,
    componentType: "color",
  },
  weekStartDate: {
    name: "weekStartDate",
    key: "weekStartDate",
    size: 12,
    componentType: "date",
  },
  weekEndDate: {
    name: "weekEndDate",
    key: "weekEndDate",
    size: 12,
    componentType: "date",
  },
  selectedBorderColor: {
    name: "selectedBorderColor",
    key: "selectedBorderColor",
    size: 6,
    componentType: "color",
  },
  selectedBackgroundColor: {
    name: "selectedBackgroundColor",
    key: "selectedBackgroundColor",
    size: 6,
    componentType: "color",
  },
  selectedTextColor: {
    name: "selectedTextColor",
    key: "selectedTextColor",
    size: 6,
    componentType: "color",
  },
  fontIcon: {
    name: "fontIcon",
    key: "fontIcon",
    size: 12,
    componentType: "customSelect",
    // options: [
    //   { title: 'arrow-left'},
    //   { title: 'arrow-right'},
    //   { title: 'address-book' },
    //   { title: 'address-book-o' },
    //   { title: 'address-card' },
    //   { title: 'address-card-o' },
    //   { title: 'adjust' },
    // ]
    options: getIconList(iconList),
  },
  options: {
    name: "options",
    key: "options",
    size: 12,
    componentType: "freeMultiSelect",
  },
  // url: {
  //   name: "url",
  //   key: "url",
  //   size: 12,
  //   componentType: "text",
  // },
  icon: {
    name: "icon",
    key: "icon",
    size: 12,
    componentType: "customSelect",
    options: getIconList(iconList),
  },
  iconPosition: {
    name: "iconPosition",
    key: "iconPosition",
    size: 12,
    componentType: "select",
    options: [{ title: "left" }, { title: "right" }],
  },
  iconWidth: {
    name: "iconWidth",
    key: "iconWidth",
    size: 12,
    componentType: "number",
  },
  iconHeight: {
    name: "iconHeight",
    key: "iconHeight",
    size: 12,
    componentType: "number",
  },
  placeholder: {
    name: "placeholder",
    key: "placeholder",
    size: 12,
    componentType: "text",
  },
};

/// all styles key --unused
export const styleInputs = {
  ////Text Style properties
  color: {
    name: "Color",
    key: "color",
    size: 4,
    componentType: "color",
    labelWidth: 30,
  },
  fontSize: {
    name: "Font Size",
    key: "fontSize",
    size: 4,
    componentType: "text",
  },
  fontWeight: {
    name: "Weight",
    key: "fontWeight",
    size: 4,
    componentType: "select",
    options: [
      { title: "400", year: 0 },
      { title: "500", year: 1 },
      { title: "600", year: 2 },
      { title: "800", year: 3 },
    ],
  },
  textAlign: {
    name: "Text Align",
    key: "textAlign",
    size: 6,
    componentType: "select",
    options: [
      { title: "right", year: 0 },
      { title: "left", year: 1 },
      { title: "center", year: 2 },
      { title: "start", year: 3 },
      { title: "end", year: 3 },
      { title: "justify", year: 3 },
    ],
  },
  noWrap: {
    name: "White Space",
    key: "noWrap",
    size: 6,
    componentType: "select",
    options: [
      { title: "true", year: 0 },
      { title: "false", year: 1 },
    ],
  },
  ////Element properties
  backgroundColor: {
    name: "Background Color",
    key: "backgroundColor",
    size: 12,
    componentType: "backgroundcolor",
    labelWidth: 140,
  },
  borderStyle: {
    name: "Border style",
    key: "borderStyle",
    size: 6,
    componentType: "select",
    allowClear: true,
    options: [
      { title: "solid", year: 1 },
      { title: "dashed", year: 2 },
      { title: "dotted", year: 3 },
    ],
  },
  borderWidth: {
    name: "Border width",
    key: "borderWidth",
    size: 6,
    componentType: "text",
  },
  borderColor: {
    name: "Border color",
    key: "borderColor",
    size: 6,
    componentType: "bordercolor",
  },
  borderRadius: {
    name: "Border radius",
    key: "borderRadius",
    size: 6,
    componentType: "text",
  },
  ///Element Size
  width: {
    name: "Width",
    key: "width",
    size: 6,
    componentType: "text",
  },
  height: {
    name: "Height",
    key: "height",
    size: 6,
    componentType: "text",
  },
  placeholder: {
    name: "placeholder",
    key: "placeholder",
    size: 6,
    componentType: "text",
  },
  ///flex properties
  flexDirection: {
    name: "Flex Direction",
    key: "flexDirection",
    size: 12,
    componentType: "select",
    options: [
      { title: "row", year: 1 },
      { title: "column", year: 2 },
      { title: "row-reverse", year: 3 },
      { title: "column-reverse", year: 4 },
    ],
  },
  justifyContent: {
    name: "Justify Content",
    key: "justifyContent",
    size: 6,
    componentType: "select",
    allowClear: true,
    options: [
      { title: "flex-start", year: 1 },
      { title: "center", year: 2 },
      { title: "flex-end", year: 3 },
      { title: "space-between", year: 4 },
      { title: "space-around", year: 5 },
      { title: "space-evenly", year: 6 },
      { title: "normal", year: 7 },
    ],
  },
  alignItems: {
    name: "Align Items",
    key: "alignItems",
    size: 6,
    componentType: "select",
    allowClear: true,
    options: [
      { title: "flex-start", year: 1 },
      { title: "center", year: 2 },
      { title: "flex-end", year: 3 },
      { title: "stretch", year: 4 },
      { title: "baseline", year: 5 },
      { title: "normal", year: 6 },
    ],
  },
};
