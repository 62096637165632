import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "../../../../contexts";
import { PropertiesPanel, IdmPanel, StylesPanel } from "../../components";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    marginTop: "48px",
  },
  tapcss: {
    minWidth: "fit-content",
    padding: "0px 10px",
    fontSize: 13,
    textTransform: "none",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: "#fff",
  },
  scroller: {
    backgroundSize: "20px 20px",
    backgroundColor: "#ebebeb6e",
    "& .MuiTabs-scroller": {
      overflowX: "auto !important",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "white",
    },
    "& .MuiTabs-indicator": {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      height: 4,
    },
  },
  close: {
    position: "absolute",
    right: 0,
    zIndex: 9,
  },
  panelHead: {
    display: "flex",
    justifyContent: "space-between",
    backgroundSize: "20px 20px",
    backgroundColor: "#ebebeb6e",
    // backgroundImage: 'linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)',
    "& .text": {
      backgroundColor: "#fff",
      display: "inline-block",
      padding: 10,
      borderTopRightRadius: 6,
    },
  },
  closeIconMargin: {
    margin: theme.spacing(1),
  },
}));

let currData = null;

export function UiBuilderPanel(props) {
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <IconButton
        color="primary"
        component="span"
        className={classes.close}
        onClick={() => props.onClose("right")}
      >
        <CloseIcon />
      </IconButton>
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="simple tabs example"
        indicatorColor="primary"
        // className={`${classes.scroller} scroll-bar`}
        textColor="primary"
      >
        <Tab label="Properties" {...a11yProps(0)} className={classes.tapcss} />
        <Tab label="Styles" {...a11yProps(1)} className={classes.tapcss} />
        <Tab
          label="Idm Settings"
          {...a11yProps(2)}
          className={classes.tapcss}
        />
      </Tabs>
      <div style={{ overflow: "auto", height: "65vh" }}>
        <TabPanel value={value} index={0}>
          <PropertiesPanel
            key={auth.user?.componentAttr?.id}
            onClose={props.onClose}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StylesPanel onClose={props.onClose} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <IdmPanel close={props.onClose} />
        </TabPanel>
      </div>
    </div>
  );
}
