import React, { useEffect } from "react";
import {
  makeStyles, Typography, IconButton, Divider, Button
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "../../../contexts";
import { v4 as uuidv4 } from "uuid";
import { nestedHandler } from '../../../components/commonFunction';
import { DataSource, commonDataSources } from '../components'

const useStyles = makeStyles((theme) => ({
  panelHead: {
    backgroundColor: "#fff",
    marginTop: "2%",
    "& .text": {
      backgroundColor: "#fff",
      display: "inline-block",
      padding: 10,
      borderTopRightRadius: 6,
      fontSize: "1.4rem",
    },
  },
  close: {
    padding: 8,
    position: "absolute",
    right: 0,
  },
  dataPassingParent: {
    padding: "15px",
    padding: 15
  },
  DataSource: {
    padding: 20
  },
  addButtonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  }
}));

///source screen
let currentScreenIndex = null;
///target screen
let targetScreenIndex = null;

export function UiFlowDataMapping(props) {
  const auth = React.useContext(AuthContext);
  const { arrowData, screenData } = props.dataParsingDetails;
  const classes = useStyles();
  const indicesRef = React.useRef(null);
  const [state, setState] = React.useState([]);
  const [dataSourceOptions, setDataSourceOptions] = React.useState({
    listernerEvents: []
  })
  const [selfEvents,setSelfEvents] = React.useState([])
  const addNewDataSource = () => {
    let data = state ?? [];
    data.push({
      name: '',
      value: {
        dataSource: '',
        value: ' '
      }
    })
    setState([...data])
  }

  const handleChange = (v, index) => {
    let data = state;
    data[index]["name"] = v.keyName;
    data[index]["value"] = v;

    setState([...data])
    if (state[index].name && state[index].value.dataSource && state[index].value.value) {
      let allScreenList = auth.user.AllScreenList[screenData.index - 1];
      allScreenList.arrowLink.map((arraow, j) => {
        if (arraow.id === arrowData.id) {
          arraow["passingData"] = [...data]
        }
      })

      allScreenList = auth.user.AllScreenList[targetScreenIndex];
      allScreenList = setHistory(allScreenList, screenData);
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList
      })
    }
  }

  const setHistory = (allScreenList) => {
    let allSetKeys = [];
    if (!allScreenList['history']) {
      allScreenList["history"] = {}
    }
    if (!allScreenList['history'][screenData.name]) {
      allScreenList["history"][screenData.name] = []
    }
    state.map(item => {
      allSetKeys.push({
        name: item.name,
        newVariable: item.value?.newVariable
      })
    })
    allScreenList["history"][screenData.name] = allSetKeys
    return allScreenList;
  }

  React.useEffect(() => {
    currentScreenIndex = screenData.index - 1;
    targetScreenIndex = auth.user.AllScreenList.findIndex(screen => screen.path === arrowData.pathId)

    let activeScreen = auth.user.AllScreenList[currentScreenIndex];
    let listernerEvents = [],
      qpasQueriesResponse = {
        screenLevel: [],
        eventLevel: []
      }
      ;

    /// reading all screen events
    activeScreen?.events &&
      activeScreen.events.filter((item) => {
        if (item.component === "Dropdown") {
          listernerEvents.push(
            { fromId: item.fromId, CName: item.CName },
            {
              fromId: item.fromId,
              CName: `${item.CName}.label`,
              defaultKey: "label",
            },
            {
              fromId: item.fromId,
              CName: `${item.CName}.value`,
              defaultKey: "value",
            }
          );
        } else if (item.name === "onChange") {
          listernerEvents.push({ fromId: item.fromId, CName: item.CName });
        }
      });

    /// reading all qpasqueries list
    auth?.user?.qpasQueries?.length &&
      auth.user.qpasQueries.forEach((item) => {
        if (item?.responseschema) {
          let payload = item?.responseschema;
          let qpasQueries = {
            label: item.apiname,
            id: item._id,
            payload: nestedHandler(
              payload?.items
                ? payload.items?.[0]?.properties?.doc ?? payload.items
                : payload
            ),
          };
          qpasQueriesResponse.screenLevel.push(qpasQueries);
        }
      });

    findChildParentIndices(activeScreen?.render, arrowData.id);
    //set all datasourceoptions
    setDataSourceOptions({
      ...dataSourceOptions,
      listernerEvents: listernerEvents,
      qpasQueriesResponse: qpasQueriesResponse,
      sessionStorage: auth.user?.storageManagement?.sessionStorage,
      localStorage: auth.user?.storageManagement?.localStorage,
      cookies: auth.user?.storageManagement?.cookies
    })

    setState(arrowData.passingData)

  }, [])

  const findChildParentIndices = (data, componentId) => {
    if (data.mappedIndices) {
      indicesRef.current = {
        mappedIndices: data.mappedIndices,
        mappedEntity: data.mappedEntity,
      };
    }
    if (data?.id === componentId) {
      if (indicesRef.current) {
        let selfEvents = mappRequiredIndices(indicesRef.current);
        setSelfEvents(selfEvents)
      }
      return;
    } else if (data?.child) {
      data.child.map((item) => {
        findChildParentIndices(item, componentId);
      });
    }
  };

  const mappRequiredIndices = (selectedComponent) => {
    let qpasQueries = auth.user.qpasQueries;
    let qpasEntities = [];
    qpasQueries.forEach((item) => {
      let payload = [];
      try {
        payload = item.responseschema;
      } catch {
        return null;
      }
      qpasEntities.push({
        label: item.apiname,
        id: item._id,
        column: nestedHandler(payload?.items ? payload.items : payload),
      });
    });
    var selectedValue = qpasEntities.filter(
      (item) => item.label === selectedComponent.mappedEntity
    )[0];

    let indices = selectedComponent?.mappedIndices?.split(".");
    let currentKey = indices[0];
    if (currentKey) {
      let currentColumn = selectedValue?.column?.filter(
        (currentColumn, i) =>
          i === parseInt(currentKey) || currentColumn.name === currentKey
      );
      currentColumn = currentColumn[0];
      var pushArray = [];
      let a = currentColumn?.column?.map((val, i) => {
        var datajson = {
          fromId: "self",
          CName: `data.${val.name}`,
        };
        pushArray.push(datajson);
      });
      return pushArray
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.panelHead}>
        <Typography className="text" variant="subtitle2" component="h6">
          Data Passing
        </Typography>
        <IconButton
          color="primary"
          component="span"
          className={classes.close}
          onClick={() => props.onClose("right")}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ height: 2 }} />
      {/* <div className={classes.dataPassingParent}>
        <Autocomplete
          multiple
          limitTags={4}
          onChange={selectDataPassingValue}
          id="Data-passing"
          options={state.list}
          value={state.dataPassingValue}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Select Data passing" placeholder="Select..." />
          )}
        />
      </div>
      <div className={classes.dataPassingParent}>
        {
          state.dataPassingValue.map(val => <React.Fragment>
            {val.column && <Autocomplete
              multiple
              limitTags={4}
              onChange={(e, value) => selectChildValue(value, val)}
              id="Data-passing"
              options={val?.schema === "qpas" ? val.column[0].column : val.column}
              value={val.dataPassingValue}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={"Select Field from " + val.entity} placeholder="Select..." />
              )}
            />}
          </React.Fragment>)
        }
      </div> */}
      <div className={classes.DataSource}>
        {
          state?.map((item, index) => {
            return <>
              <DataSource
                label={"selected key"}
                keyName={item?.name}
                isKeyEditable={true}
                value={item?.value}
                index={index}
                onChange={(value, indices) => handleChange(value, index)}
                listernerEvents={dataSourceOptions?.listernerEvents}
                qpasQueriesResponse={{
                  screenLevel: dataSourceOptions?.qpasQueriesResponse?.screenLevel ?? [],
                  eventLevel: []
                }}
                dataSources={commonDataSources}
                isEndDecoration={false}
                selfEvents={selfEvents ?? []}
                historyData={[]}
                sessionList={dataSourceOptions?.sessionStorage ?? []}
                localList={dataSourceOptions?.localStorage ?? []}
                cookiesList={dataSourceOptions?.cookies ?? []}
              />
            </>
          })
        }
        <div className={classes.addButtonDiv}>
          <Button
            variant="contained"
            color="primary"
            onClick={addNewDataSource}>
            Add Payload
          </Button>
        </div>
      </div>
    </div>
  );
}
