import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import {
  Toolbar,
  Button,
  IconButton,
  Switch,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import { Typography, Tooltip } from "@material-ui/core";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
// import OpenInBrowserRoundedIcon from '@material-ui/icons/OpenInBrowserRounded';
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import routes from "./../router/routes";
import { useHistory } from "react-router-dom";
import { AuthContext, NotificationStatus } from "../contexts";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
import RedoRoundedIcon from "@material-ui/icons/RedoRounded";
import { getPropertiesItems } from "./commonFunction";
import { eleAtrribute } from "../screens/uiBuilder/components/jsonDatas";
import { useLocation } from "react-router-dom";
// import AppNotify from "../App.notification_autosave";
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid #ebebeb",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
    paddingLeft: "14px",
  },
  layoutLeftIcon: {
    width: "18px",
    height: "14px",
    border: "2px solid",
    borderRadius: "2px",
    borderLeftWidth: "6px",
  },
  layoutRightIcon: {
    width: "18px",
    height: "14px",
    border: "2px solid",
    borderRadius: "2px",
    borderRightWidth: "6px",
  },
  sizeRiplle: {
    padding: "3px",
    borderRadius: 4,
    border: "1px solid " + theme.palette.text.disabled,
    color: theme.palette.text.disabled,
    display: "flex",
    cursor: "pointer",
  },
  sizeRiplleActive: {
    padding: "5px",
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    cursor: "pointer",
  },
  active: {
    backgroundColor: "#3f51b542",
  },
  menu: {
    width: "56px",
    display: "flex",
    borderRight: "1px solid " + theme.palette.text.disabled,
    "& svg": {
      cursor: "pointer",
    },
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: "#fff !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  selecteTab: {
    padding: "3px 12px",
    border: "0px",
    borderRadius: "50px !important",
    minWidth: "144px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tabButton: {
    border: "1px solid #ebebeb",
    borderRadius: "25px",
    padding: "4px",
  },
  titleName: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
  },
  margin: {
    marginRight: theme.spacing(1),
  },
}));

// const projectList = [
//   { title: 'Tanya Care', id: 1 },
//   { title: 'Demo Template', year: 2 },
// ]

export function MainAppBar(props) {
  // const [uiType, setUiType] = React.useState(props.uiType);
  const auth = React.useContext(AuthContext);
  const autoSave = React.useContext(NotificationStatus);
  const history = useHistory();
  const classes = useStyles();
  let location = useLocation();
  console.log(location);
  const onChangeSwitch = async (e, val) => {
    // if (props.onChangeSwitch) {
    //   props.onChangeSwitch(val);
    // } else {
    debugger;

    function getScreen() {
      let result = auth.user.AllScreenList.find(
        (screen) => screen.type === "Screen"
      );
      return result ? [result] : [];
    }

    function getScreenId() {
      let result = auth.user.AllScreenList.find(
        (screen) => screen.type === "Screen"
      );
      return result ? result.id : null;
    }

    function getCurrentScreen() {
      const screen = auth.user.AllScreenList.filter(
        (screen) => screen.id === auth.user.selectedScreen
      );
      let result =
        auth.user.AllScreenList.length === 0
          ? []
          : screen.length === 1
          ? screen
          : getScreen();
      return result;
    }

    function getCurrentScreenId() {
      const screen = auth.user.AllScreenList.filter(
        (screen) => screen.id === auth.user.selectedScreen
      );
      let result =
        auth.user.AllScreenList.length === 0
          ? null
          : screen.length === 1
          ? screen[0]?.id
          : getScreenId();
      return result;
    }

    if (val === "builder") {
      // setUiType("builder");
      localStorage.setItem("currentScreen", "builder");
      history.push(routes.uibuilder);
      console.log(auth.user.AllScreenList, "ui builder");
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        screens: getCurrentScreen(),
        selectedScreen: getCurrentScreenId(),
      });
    } else {
      // setUiType("flow");
      localStorage.setItem("currentScreen", "uiflow");
      if (auth.user?.isSaved === false) {
        await auth.save();
        auth.setAuth({
          ...auth.user,
          isSaved: true,
        });
      }
      history.push(routes.uiflow);
    }
    // }
  };

  // const handleKeyboardSave = (e) => {
  //   let key = e.keyCode;
  //   if (key === 83 && (e.ctrlKey || e.metaKey)) {
  //     e.preventDefault();
  //     console.log("Ctrl + S Pressed !");
  //     props.saveForm();
  //   } else if (
  //     key === 90 &&
  //     (e.ctrlKey || e.metaKey) &&
  //     auth.user.undoRedoData.length > 1 &&
  //     auth.user.activeIndex !== 0
  //   ) {
  //     e.preventDefault();
  //     console.log("Ctrl + Z Pressed !");
  //     undoRedo("undo");
  //   } else if (
  //     key === 89 &&
  //     (e.ctrlKey || e.metaKey) &&
  //     auth.user.activeIndex < auth.user.undoRedoData.length - 1
  //   ) {
  //     e.preventDefault();
  //     console.log("Ctrl + Y Pressed !");
  //     undoRedo("redo");
  //   }
  // };

  // React.useEffect(() => {
  //   window.addEventListener("keydown", handleKeyboardSave);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyboardSave);
  //   };
  // }, [auth]);

  // const onChange = (e, newValue) => {
  //   let val = newValue.title;
  //   props.changeProjectName(val);
  // }

  // const undoRedo = (type) => {
  //   let undoData = null;
  //   let currentScreen = null;
  //   let index = 0;
  //   if (type === "redo") {
  //     undoData = JSON.parse(JSON.stringify(auth.user.undoRedoData))[
  //       auth.user.activeIndex + 1
  //     ];
  //     console.log(undoData);
  //     currentScreen = undoData.filter(
  //       (val) => val.id === auth.user.selectedScreen
  //     );
  //     index = auth.user.activeIndex + 1;
  //   } else {
  //     undoData = JSON.parse(JSON.stringify(auth.user.undoRedoData))[
  //       auth.user.activeIndex - 1
  //     ];
  //     currentScreen = undoData.filter(
  //       (val) => val.id === auth.user.selectedScreen
  //     );
  //     index = auth.user.activeIndex - 1;
  //   }
  //   let data2 = null;
  //   if (Object.keys(auth.user.componentAttr).length > 0) {
  //     data2 = getPropertiesItems(
  //       auth.user.componentAttr,
  //       auth.user.selectedScreen,
  //       eleAtrribute
  //     );
  //   }
  //   auth.setAuth({
  //     ...auth.user,
  //     AllScreenList: undoData,
  //     undoRedo: true,
  //     activeIndex: index,
  //     screens: undoData.length === 1 ? undoData : currentScreen,
  //     componentAttr: data2 ? data2 : {},
  //     selectedScreen:
  //       undoData.length === 1 ? undoData[0]?.id : currentScreen[0]?.id,
  //   });
  //   props.onClose("right", false);
  // };

  const handleChangeArrow = (type, val) => {
    auth.setAuth({
      ...auth.user,
      [type]: val,
    });
  };
  return (
    // <AppNotify>
    <div className={classes.root}>
      <AppBar position="static" color={"inherit"} elevation={0}>
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit" className={classes.menu}>
            <MenuRoundedIcon
              onClick={() => props.onClose("left", !props.leftMenu)}
            />
          </Typography>
          <div className={classes.grow}>
            <Typography color={"textSecondary"}>{props.title}</Typography>
            {/* <Autocomplete
                  className={classes.titleName}
                  disableClearable
                  // value={projectList.filter(val => val.title === props.title)?.[0]??""}
                  value={{title:props?.title?? ""}}
                  style={{width: "240px"}}
                  onChange={(e, newValue)=>onChange(e, newValue)}
                  size={"small"}
                  // options={projectList}
                  options={[{title:props?.title?? ""}]}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => 
                        <TextField variant="outlined" {...params} margin={"dense"} />
                      }
                /> */}
          </div>
          <div className={classes.grow}>
            <ToggleButtonGroup
              size={"small"}
              value={props.uiType}
              exclusive
              onChange={(e, val) => onChangeSwitch(e, val)}
              aria-label="text alignment"
              className={classes.tabButton}
            >
              <ToggleButton
                value={"builder"}
                aria-label="left"
                size={"small"}
                color={"secondary"}
                classes={{
                  root: classes.selecteTab,
                  selected: classes.selectedTab,
                }}
              >
                <Typography component={"p"} variant={"caption"}>
                  UI Designer
                </Typography>
              </ToggleButton>
              <ToggleButton
                value={"flow"}
                aria-label="centered"
                size={"small"}
                classes={{
                  root: classes.selecteTab,
                  selected: classes.selectedTab,
                }}
              >
                <Typography component={"p"} variant={"caption"}>
                  UI Flow
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div style={{ display: "flex" }}>
            {autoSave?.notify?.saveStatus && (
              <Typography
                className={"typing"}
                color={"primary"}
                variant={"body2"}
                style={{ margin: "auto", marginRight: 10, minWidth: 92 }}
              >
                {autoSave.notify.saveStatus}
              </Typography>
            )}
            {/* {JSON.stringify(props)} */}
            {(props?.screens?.length > 1 || props.uiType === "flow") && (
              <>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={auth.user.componentLink}
                        onChange={() =>
                          handleChangeArrow(
                            "componentLink",
                            !auth.user.componentLink
                          )
                        }
                      />
                    }
                    label={<FormHelperText>Component Link</FormHelperText>}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        size="small"
                        checked={auth.user.screenLink}
                        onChange={() =>
                          handleChangeArrow("screenLink", !auth.user.screenLink)
                        }
                      />
                    }
                    label={<FormHelperText>Screen Link</FormHelperText>}
                  />
                </FormGroup>
              </>
            )}

            {props.uiType === "builder" && (
              <>
                {/* <Tooltip title={"Undo"} placement="left-start">
                  <IconButton
                    aria-label="delete"
                    onClick={() => undoRedo("undo")}
                    disabled={
                      auth.user.undoRedoData.length > 1 &&
                      auth.user.activeIndex !== 0
                        ? false
                        : true
                    }
                    disableFocusRipple={
                      auth.user.undoRedoData.length > 1 &&
                      auth.user.activeIndex !== 0
                        ? false
                        : true
                    }
                    disableRipple={
                      auth.user.undoRedoData.length > 1 &&
                      auth.user.activeIndex !== 0
                        ? false
                        : true
                    }
                    className={classes.margin}
                    size="small"
                  >
                    <UndoRoundedIcon />
                  </IconButton>
                </Tooltip> */}
                {/* <Typography>{auth.user.activeIndex}</Typography> */}
                {/* <Tooltip title={"Redo"} placement="right-start">
                  <IconButton
                    aria-label="delete"
                    onClick={() => undoRedo("redo")}
                    disabled={
                      auth.user.activeIndex ===
                      auth.user.undoRedoData.length - 1
                        ? true
                        : false
                    }
                    disableFocusRipple={
                      auth.user.activeIndex ===
                      auth.user.undoRedoData.length - 1
                        ? true
                        : false
                    }
                    disableRipple={
                      auth.user.activeIndex ===
                      auth.user.undoRedoData.length - 1
                        ? true
                        : false
                    }
                    className={classes.margin}
                    size="small"
                  >
                    <RedoRoundedIcon />
                  </IconButton>
                </Tooltip> */}
              </>
            )}
            <Button
              disabled={auth.user.loading}
              onClick={(e) => props.build()}
              variant={"outlined"}
              color={"primary"}
              size={"small"}
              style={{ marginRight: "20px" }}
            >
              Build
            </Button>
            <Button
              disabled={auth.user.loading}
              onClick={(e) => props.saveForm()}
              variant={"outlined"}
              color={"primary"}
              size={"small"}
            >
              Save All
            </Button>
            {/* {props.rightMenu && 
              <div style={{margin: "auto"}}>
                <Tooltip title="Close right menu" placement="top-start">
                <div onClick={()=>props.onClose("right", !props.rightMenu)} className={props.rightMenu ? classes.sizeRiplleActive : classes.sizeRiplle}>
                    <OpenInBrowserRoundedIcon fontSize={"small"} />
                </div>
                </Tooltip>
              </div>
            } */}
          </div>
        </Toolbar>
      </AppBar>
    </div>
    // </AppNotify>
  );
}

MainAppBar.defaultProps = {
  saveForm: () => {},
  uiType: "builder",
  title: "HyperEngage App",
  onClose: () => {},
  changeProjectName: () => {},
  build: () => {},
};
