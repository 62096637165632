/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Collapse,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Switch,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import useStyles from "./styles";
import { TextInput } from "../components";
import { AuthContext } from "../../../../contexts";
import { validationProperties } from "./constants";

export default function Validations(props) {
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const [data, setData] = React.useState(auth.user.componentAttr);
  const { collapse, handleCollapse } = props;

  const regexPatterns = {
    name: `^[a-zA-Z\\s]{3,}$`,
    string: `^[a-zA-Z\\s]{1,}$`,
    stringWithNumber: `^[a-zA-Z0-9\\s]*$`,
    mobileNumber: `^[6-9]\\d{9}$`,
    emailId: `^[a-z0-9\\.]+@[a-z]+\\.[a-z]{2,3}$`,
    number: `^[1-9]*$`,
    postalCode: `^[0-9]{6}$`,
  };

  const handleChange = (key, val) => {
    let data = auth.user.componentAttr;
    data["validations"][key] = val;

    if (key === "patternType") {
      if (val !== "custom") {
        data["validations"]["pattern"] = regexPatterns[val];
      } else {
        data["validations"]["pattern"] = "";
      }
    }

    const selectedScreenId = auth.user.selectedScreen;
    auth.user.AllScreenList.forEach((screen) => {
      if (screen.id === selectedScreenId) {
        screen.render.child.forEach(function iter(component, index, array) {
          if (component.id === data?.id) {
            component["validations"] = {
              ...data.validations,
            };
          } else {
            if (component?.child?.length > 0) {
              component.child.forEach(iter);
            }
          }
        });
      }
    });
    auth.setAuth({
      ...auth.user,
      componentAttr: data,
      isEdited: true,
    });
  };

  const propertiesRenderer = (property, index) => {
    switch (property) {
      case "isRequired":
        return (
          <Grid item xs={12}>
            <Grid
              container
              style={{ padding: "0px 2px" }}
              component="label"
              justify={"space-between"}
            >
              <Typography variant="body2">Is Required</Typography>
              <Switch
                checked={data?.validations?.required ?? false}
                size="small"
                onChange={(e) => {
                  handleChange("required", e.target.checked);
                }}
                color="primary"
                name="required"
              />
            </Grid>
          </Grid>
        );
      case "minLength":
      case "maxLength":
        return (
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                handleChange(property, e.target.value);
              }}
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              label={property === "minLength" ? "Min Length" : "Max Length"}
              className={classes.inputField}
              margin="dense"
              variant="outlined"
              value={data["validations"][property] ?? ""}
            />
          </Grid>
        );
      case "helperText":
        return (
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                handleChange("title", e.target.value);
              }}
              fullWidth
              label={"Helper Text"}
              className={classes.inputField}
              margin="dense"
              variant="outlined"
              value={data?.validations?.title ?? ""}
            />
          </Grid>
        );
      case "regex":
        return (
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Select or Add Regex
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormControl style={{ width: "100%", margin: "10px 1px" }}>
                  <Select
                    className={classes.selectText}
                    inputProps={{ "aria-label": "Without label" }}
                    value={data?.validations?.patternType ?? ""}
                    onChange={(e) => {
                      handleChange("patternType", e.target.value);
                    }}
                  >
                    <MenuItem value={"name"}>Name</MenuItem>
                    <MenuItem value={"string"}>String</MenuItem>
                    <MenuItem value={"stringWithNumber"}>
                      String With Number
                    </MenuItem>
                    <MenuItem value={"number"}>Number</MenuItem>
                    <MenuItem value={"mobileNumber"}>Mobile Number</MenuItem>
                    <MenuItem value={"emailId"}>Email Id</MenuItem>
                    <MenuItem value={"postalCode"}>Postal Code</MenuItem>
                    <MenuItem value={"custom"}>Custom</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  onChange={(e) => {
                    let pattern;
                    if (data?.validations?.patternType === "custom") {
                      pattern = e.target.value;
                      handleChange("pattern", `${pattern}`);
                    } else {
                      return;
                    }
                  }}
                  fullWidth
                  label={"Regex"}
                  className={classes.inputField}
                  margin="dense"
                  variant="outlined"
                  readOnly={
                    data?.validations?.patternType === "custom" ? false : true
                  }
                  value={data?.validations?.pattern ?? ""}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      case "min":
      case "max":
        if (data?.properties?.type === "time") {
          return (
            <Grid item xs={12}>
              <TextField
                onChange={(e) => {
                  handleChange(property, e.target.value);
                }}
                type="time"
                fullWidth
                label={property === "min" ? "Min Value" : "Max Value"}
                className={classes.inputField}
                margin="dense"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={data["validations"][property] ?? ""}
              />
            </Grid>
          );
        }else if (data?.properties?.type === "datetime-local"){
          return (
            <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                handleChange(property, e.target.value);
              }}
              type="datetime-local"
              fullWidth
              label={property === "min" ? "Min Value" : "Max Value"}
              className={classes.inputField}
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={data["validations"][property] ?? ""}
            />
          </Grid>
          )
        } else if (data?.properties?.type === "date") {
          return (
            <Grid item xs={12}>
              <TextField
                onChange={(e) => {
                  handleChange(property, e.target.value);
                }}
                type="date"
                fullWidth
                label={property === "min" ? "Min Value" : "Max Value"}
                className={classes.inputField}
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={data["validations"][property] ?? ""}
              />
            </Grid>
          );
        } else {
          return (
            <Grid item xs={12}>
              <TextField
                onChange={(e) => {
                  handleChange(property, e.target.value);
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                label={property === "min" ? "Min Value" : "Max Value"}
                className={classes.inputField}
                margin="dense"
                variant="outlined"
                value={data["validations"][property] ?? null}
              />
            </Grid>
          );
        }

      default:
        break;
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        className={
          collapse === 7 ? classes.listHeaderActive : classes.listHeader
        }
      >
        <ListItem
          button
          onClick={() => {
            handleCollapse(7);
          }}
        >
          <ListItemText primary="Validations" />
          {collapse === 7 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Grid>
      <Collapse
        in={collapse === 7 ?? false}
        style={{ padding: collapse === 7 ? "8px 6px" : "0px" }}
      >
        <Grid
          container
          spacing={1}
          padding="12px 0px"
          width="100%"
          direction="column"
        >
          {
            props?.validationValue?.map(
              (property, index) => {
                return propertiesRenderer(property);
              }
            )}
          {/* {data &&
            !data?.properties?.type &&
            validationProperties[data?.component].map((property, index) => {
              return propertiesRenderer(property);
            })} */}
        </Grid>
      </Collapse>
    </>
  );
}
