import React from "react";
import {
  fade,
  makeStyles,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Avatar,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
  Menu,
  MenuItem,
  InputBase,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import DeviceHubRoundedIcon from "@material-ui/icons/DeviceHubRounded";
import { withAlertAndDialog } from "../../../HOCs";
import { AlertContext } from "../../../contexts";
import { alertProps } from "../../../utils";
// common function
import cloneDeep from "lodash/cloneDeep";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import Layout from "../../../assets/img/icons8-layout.svg";
import { readDocument } from "../../../apis";
import isEqual from "react-fast-compare";

const options = [
  "Delete",
  "Edit",
  // 'Tree View'
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: "relative !important",
    marginBottom: "14px !important",
    borderRadius: "4px !important",
    borderBottom: "1.3px solid rgba(0, 0, 0, 0.12) !important",
    border: "1.3px solid rgba(0, 0, 0, 0.12) !important",
    backgroundColor: fade("#fff", 0.15),
    "&:hover": {
      backgroundColor: fade("#fff", 0.25),
    },
    width: "100% !important",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: "0px 6px !important",
    height: "100% !important",
    position: "absolute !important",
    pointerEvents: "none !important",
    display: "flex!important",
    alignItems: "center !important",
    justifyContent: "center!important",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    height: "0.9em",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)}px) !important`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  addButton: {
    border: "1px solid " + theme.palette.text.hint,
    borderRadius: "4px",
  },
  switchButton: {
    margin: 0,
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 0px",
  },
  MenuItem: {
    color: "black",
    cursor: "pointer",
    borderRadius: "4px",
    textTransform: "capitalize",
    padding: "4px 2px 4px 4px",
  },
  selected: {
    /* Increase the specificity */
    // color: "#3f51b5",
    backgroundColor: "#F6F6F8 !important",
  },
  dragIcon: {
    fontSize: "14px",
    margin: "0px 4px",
  },
  previewImage: {
    width: "26px",
    marginRight: "6px",
    fontSize: "12px",
    height: "32px",
  },
}));

const initialState = {
  position: null,
  data: null,
};
function ScreenList(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(initialState);
  const [selectedAllScreen, setSelectedAllScreen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const open = Boolean(anchorEl.position);
  const alert = React.useContext(AlertContext);

  const handleClick = (event, id) => {
    setAnchorEl({
      ...anchorEl,
      position: event.currentTarget,
      data: id,
    });
  };

  const handleClose = () => {
    setAnchorEl(initialState);
  };

  const moreOptions = (e, type) => {
    switch (type) {
      case "Delete":
        if (props.auth.user?.screens?.[0]?.readMode === true) {
          return props.alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: "The screen is in read mode",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        }
        if (
          props.auth.user?.screens[0]?.readMode === false &&
          props.auth.user?.user?.name !==
            props.auth.user?.screens[0]?.isLockedBy
        ) {
          return props.alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: `${props.auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        }
        props.deleteOldScreen(anchorEl.data);
        handleClose();
        break;
      case "Edit":
        if (props.auth.user?.screens?.[0]?.readMode === true) {
          return props.alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: "The screen is in read mode",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        }
        if (
          props.auth.user?.screens[0]?.readMode === false &&
          props.auth.user?.user?.name !==
            props.auth.user?.screens[0]?.isLockedBy
        ) {
          return props.alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: `${props.auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        }
        props.openModal(anchorEl.position, "edit", anchorEl.data);
        handleClose();
        break;
      case "Tree View":
        props.treeView(2);
        handleClose();
        break;
      default:
        handleClose();
        break;
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const allScreenListCopy = cloneDeep(props.auth.user.AllScreenList);
    let drag_item_id = result.draggableId;
    let getIndexDragItem = result.source.index;
    let getIndexDropItem = result.destination.index;
    if (allScreenListCopy[getIndexDragItem + 1]?.type === "decisionHandler") {
      let cutOut = allScreenListCopy.splice(getIndexDragItem, 2);
      allScreenListCopy.splice(getIndexDropItem, 0, ...cutOut);
    } else {
      let cutOut = allScreenListCopy.splice(getIndexDragItem, 1)[0];
      allScreenListCopy.splice(getIndexDropItem, 0, cutOut);
    }
    props.auth.setAuth({
      ...props.auth.user,
      AllScreenList: allScreenListCopy,
    });
  };

  const onClickScreenButton = async (selected) => {
    let selecte_id = props.auth.user.AllScreenList[0]?.id;
    props.onClose("right", false);
    if (selected === "checkBox") {
      let screen = props.auth.user.AllScreenList.filter(
        (val) => val.id === selecte_id
      );
      setSelectedAllScreen(!selectedAllScreen);
      props.auth.setAuth({
        ...props.auth.user,
        screens: !selectedAllScreen ? props.auth.user.AllScreenList : screen,
        selectedScreen: !selectedAllScreen ? null : selecte_id,
      });
    } else {
      let currentScreen = props.auth.user.screens[0];
      console.log(selected, currentScreen);
      if (selected.id === currentScreen.id) return null;

      let allScreenListRef = JSON.parse(
        JSON.stringify(props.auth.user?.AllScreenListRef)
      );
      console.log(allScreenListRef);

      let isCurrentScreenSaved = allScreenListRef.filter(
        (screen) => screen.id === selected.id
      );

      console.log(isCurrentScreenSaved, "isCurrentScreenSaved");

      if (isCurrentScreenSaved.length === 0) {
        return alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.warning,
          msg: "Save the newly created screen",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      }

      // if (
      //   currentScreen?.readMode === false &&
      //   props.auth.user?.user?.name === props.auth.user?.screens[0]?.isLockedBy
      // ) {
      //   return alert.setSnack({
      //     ...alert,
      //     open: true,
      //     severity: alertProps.severity.warning,
      //     msg: "Switch the current screen to read mode before switching screen",
      //     vertical: alertProps.vertical.top,
      //     horizontal: alertProps.horizontal.right,
      //     tone: true,
      //   });
      // }

      let [screen] = props.auth.user.AllScreenList.filter(
        (val) => val.id === selected.id
      );
      let screenQuery = [
        {
          entity: "screens",
          filter: {
            id: selected.id,
            metadataid: localStorage.metaDataId,
            version: props.auth.user?.version ?? 1.0,
          },
        },
      ];
      let result = await readDocument(screenQuery);

      let isScreenUpdated = isEqual(result?.screens, screen);

      let allScreenList = JSON.parse(
        JSON.stringify(props.auth.user?.AllScreenList)
      );

      console.log(isScreenUpdated, result, screen);

      if (
        !isScreenUpdated &&
        Object.keys(result).length > 0 &&
        result?.screens?.readMode === false &&
        props.auth.user?.user?.name !== result?.screens?.isLockedBy
      ) {
        allScreenList.forEach((eachScreen, index, array) => {
          if (result.screens.id === eachScreen.id) {
            array[index] = { ...result.screens };
          }
        });
        console.log(allScreenList);
      }

      setSelectedAllScreen(false);
      props.auth.setAuth({
        ...props.auth.user,
        screens: allScreenList.filter((val) => val.id === selected.id),
        AllScreenList: allScreenList,
        AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
        selectedScreen: selected.id,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        style={{ padding: "16px 16px 0px" }}
      >
        <Grid>
          <Typography>Screen's</Typography>
        </Grid>
        {/* Add new screen */}
        <IconButton
          disabled={props.auth.user.loading}
          className={classes.addButton}
          aria-describedby={props.id}
          onClick={(e) => {
            console.log(props);
            props.selectSteppterScreenType(e);
          }}
          size={"small"}
          aria-label="show 4 new mails"
          color={"default"}
        >
          <Tooltip title="Add new screen" placement="right-start">
            <AddIcon fontSize={"small"} />
          </Tooltip>
        </IconButton>

        <FormControlLabel
          disabled={props.auth.user.loading}
          className={classes.switchButton}
          control={
            <Switch
              checked={
                props.auth?.user?.screens?.length > 1 ? true : selectedAllScreen
              }
              onChange={() => onClickScreenButton("checkBox")}
              color="primary"
              name="checkedB"
              size={"small"}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          labelPlacement={"start"}
          label="Show All Screens"
          classes={{ label: "smallIcon" }}
        />
      </Grid>
      {/* Add new screen END */}

      {/* Search screen list */}
      <Grid style={{ padding: "0px 14px" }}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon fontSize={"small"} color={"action"} />
          </div>
          <InputBase
            disabled={props.auth.user.loading}
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      </Grid>
      {/* Search screen list END */}

      {/* Screen List's */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid
          container
          item
          style={{
            justifyContent: "center",
            maxHeight: "calc(100vh - 225px)",
            overflow: "auto",
          }}
          id={"screenList"}
        >
          <Droppable droppableId="droppable" type="screens">
            {(dropProvided, dropSnapshot) => (
              <div
                {...dropProvided.draggableProps}
                key="droppable"
                ref={dropProvided.innerRef}
                style={{
                  background: `${
                    dropSnapshot.isDraggingOver ? "#F9FAFB" : "white"
                  }`,
                  borderRadius: `${
                    dropSnapshot.isDraggingOver ? "5px" : "0px"
                  }`,
                  padding: `${
                    dropSnapshot.isDraggingOver
                      ? "10px 4px"
                      : "0px 0px 60px 0px"
                  }`,
                }}
              >
                {props.auth.user.AllScreenList?.filter(
                  (screen) => screen.type !== "decisionHandler"
                ).map((list, index) => {
                  return (
                    <>
                      {list.name
                        .toLowerCase()
                        .includes(search.toLowerCase()) && (
                        <Draggable
                          key={list.id}
                          draggableId={list.id}
                          index={index}
                        >
                          {(dragProvided, dragSnapshot) => (
                            <div
                              {...dragProvided.draggableProps}
                              ref={dragProvided.innerRef}
                              key={list.id}
                              index={index}
                            >
                              <ListItem
                                title={list?.isLayout ? "Layout" : "Screen"}
                                key={list.id}
                                // draggable={true}
                                id={list.id}
                                style={{
                                  width: "240px",
                                  margin: "5px 0px",
                                  display: `${
                                    list.type === "decisionHandler"
                                      ? "none"
                                      : "flex"
                                  }`,
                                }}
                                dense
                                selected={
                                  props.auth.user.selectedScreen === list.id
                                    ? true
                                    : false
                                }
                                classes={{
                                  root: classes.MenuItem,
                                  selected: classes.selected,
                                }}
                              >
                                <span {...dragProvided.dragHandleProps}>
                                  <DragIndicatorIcon
                                    className={classes.dragIcon}
                                  />
                                </span>
                                <Avatar
                                  alt={list.name}
                                  onClick={() => onClickScreenButton(list)}
                                  className={classes.previewImage}
                                  variant="rounded"
                                  src={
                                    list?.image ?? "/static/images/avatar/1.jpg"
                                  }
                                />

                                <ListItemText
                                  primary={list.name}
                                  style={{ wordBreak: "break-all" }}
                                  onClick={() => onClickScreenButton(list)}
                                />
                                {list?.isLayoutScreen && (
                                  <IconButton
                                    size={"small"}
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                  >
                                    <img
                                      src={Layout}
                                      alt="layout"
                                      width="14px"
                                      height="auto"
                                    />
                                  </IconButton>
                                )}
                                {props.auth.user.selectedScreen === list.id && (
                                  <>
                                    <IconButton
                                      size={"small"}
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={(e) => props.treeView(2)}
                                    >
                                      <DeviceHubRoundedIcon
                                        style={{ fontSize: "14px" }}
                                        color={"action"}
                                        fontSize={"small"}
                                      />
                                    </IconButton>
                                    <IconButton
                                      size={"small"}
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={(e) =>
                                        props.onSwitchPreview(e, list.id)
                                      }
                                    >
                                      <PlayCircleOutlineRoundedIcon
                                        color={
                                          props.previewScreen
                                            ? "primary"
                                            : "action"
                                        }
                                        fontSize={"small"}
                                      />
                                    </IconButton>
                                    <IconButton
                                      size={"small"}
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={(e) => handleClick(e, list.id)}
                                    >
                                      <MoreVertIcon
                                        fontSize={"small"}
                                        color={"action"}
                                      />
                                    </IconButton>
                                  </>
                                )}
                              </ListItem>
                            </div>
                          )}
                        </Draggable>
                      )}
                    </>
                  );
                })}
                {dropProvided.placeholder}
              </div>
            )}
          </Droppable>
        </Grid>
      </DragDropContext>
      {/* Screen List's END */}

      {/* More option menu list based on( Screen ) */}
      <Menu
        id="long-menu"
        anchorEl={anchorEl.position}
        keepMounted
        open={open}
        onClose={() => handleClose()}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((option) => (
          <MenuItem
            dense
            key={option}
            selected={option === "Pyxis"}
            onClick={(e) => moreOptions(e, option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      {/* More option menu list END */}
    </div>
  );
}

ScreenList.defaultProps = {
  onSwitchPreview: () => {},
  previewScreen: false,
  treeView: false,
  setState: () => {},
  deleteOldScreen: () => {},
  openModal: () => {},
  menusList: [],
  selected: 1,
  selectedAllScreen: false,
  onClickScreenButton: () => {},
};

export default withAlertAndDialog(ScreenList);
