import React from "react";
import { debounce } from "lodash";
import { AuthContext } from "./contexts";
import Config from "./config";
import { localStorageKeys } from "./utils";
// import { AllScreenHtml } from "./screens/uiBuilder/components/jsonDatas.js";
import {
  readUIBuilderDoc,
  readProjectVsTools,
  readProjectTemplates,
  readCustomComponents,
  getAllQpasQueries,
  getAllUIComponents,
  readFlowGroups,
  readStorageManagement,
  readDocuments,
  createDocument,
  deleteDocument,
  updateDocument,
} from "./apis";
import {
  getScreensAndComponents,
  repositoryOperation,
} from "./components/commonFunction";
import isEqual from "react-fast-compare";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: true,
      isRefreshed: false,
      AllScreenList: [],
      AllScreenListRef: [],
      screens: [],
      selectedScreen: null,
      isEdited: false,
      undoRedoData: [],
      activeIndex: 0,
      undoRedo: false,
      componentLink: true,
      screenLink: true,
      componentId: null,
      componentDecisionId: null,
      componentAttr: {},
      projectTemplates: [],
      customComponents: [],
      templateDialog: {},
      flowGroup: [],
      activeGroup: null,
      qpasQueries: [],
      isIdmEnabled: false,
      UIcomponents: [],
      storageManagement: {
        sessionStorage: [],
        localStorageKeys: [],
        cookies: [],
      },
      version: 0,
      permissionData: {},
      codePanel: {
        status: false,
        type: "meta",
        sourceCode: "",
        metaData: {},
        loading: false,
      },
      screensHistory: {},
      projectTemplatesCreated: false,
      customComponentsCreated: false,
      flowGroupCreated: false,
      storageManagementCreated: false,
      screensActiveIndex: {},
    };
    this.update = debounce(this._update.bind(this), 1000);
    this.setAuthState = debounce(this._setAuthState.bind(this), 1000);
  }

  componentDidMount() {
    this.refreshAPI();
    fetch("/meta.json")
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem(localStorageKeys.version, data.version);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }

  refreshAPI = async () => {
    let urlParams = new URLSearchParams(window.location.search);
    let metadataIdFromURL = urlParams?.get("metadata_id");
    let metaDataId = localStorage.getItem(localStorageKeys.metaDataId);
    //to get token from url
    let tokenFromUrl = urlParams.get("token");
    let token = localStorage.getItem(localStorageKeys.auth_token);
    localStorage.setItem("currentScreen", "builder");
    let metadataid = metaDataId || metadataIdFromURL;

    let parsedToken;
    let userInfo = {};
    if (tokenFromUrl) {
      parsedToken = tokenFromUrl?.split(".")[1];
      console.log(parsedToken, "parsedToken");
      userInfo = JSON.parse(window.atob(parsedToken));
    } else if (token) {
      console.log(token, "token");
      userInfo = JSON.parse(window.atob(token));
    }

    if (metadataid) {
      // Read UI Builder data
      const uibuilderData = await readUIBuilderDoc(metadataid);

      //If no document is created for this UIBuilder create one
      let AllScreenList = [],
        customComponents = [],
        projectTemplates = [],
        flowGroup = [],
        storageManagement = {
          sessionStorage: [],
          localStorageKeys: [],
          cookies: [],
        };
      if (Object.keys(uibuilderData).length === 0) {
        //get client & project details from projectvstools
        const projectVSToolsData = await readProjectVsTools(metadataid);

        let uibuilderSchema = {
          metadataid: projectVSToolsData?.metadataid,
          projectName: projectVSToolsData.metadataname.split("_")[0],
          version: 1.0,
          projectId: projectVSToolsData.projectid,
          clientId: projectVSToolsData.clientid,
          toolId: projectVSToolsData.toolid,
        };

        //set project name
        localStorage.setItem(
          localStorageKeys.projectName,
          projectVSToolsData.metadataname.split("_")[0]
        );

        await createDocument([
          {
            entity: "uibuilder",
            body: uibuilderSchema,
          },
        ]);
      } else {
        //set project name
        localStorage.setItem(
          localStorageKeys.projectName,
          uibuilderData.projectName
        );
        //get  screens data
        let screenQuery = [
          {
            entity: "screens",
            filter: {
              metadataid: localStorage.metaDataId,
              version: uibuilderData?.version ?? 1,
              activestatus: true,
            },
          },
        ];
        const allScreens = await readDocuments(screenQuery, { limit: 100 });
        AllScreenList = [...allScreens];
        //get project templates
        const projectTemplatesResult = await readProjectTemplates(
          metadataid,
          uibuilderData?.version ?? 0
        );
        if (Object.keys(projectTemplatesResult).length > 0) {
          projectTemplates = projectTemplatesResult?.templateGroups;
          this.state.projectTemplatesCreated = true;
        }
        //get customcomponents
        const customComponentsResult = await readCustomComponents(
          metadataid,
          uibuilderData?.version ?? 0
        );
        if (Object.keys(customComponentsResult).length > 0) {
          customComponents = customComponentsResult?.customComponents ?? [];
          this.state.customComponentsCreated = true;
        }
        //get flow groups
        const flowGroupResult = await readFlowGroups(
          metadataid,
          uibuilderData?.version ?? 0
        );
        if (Object.keys(flowGroupResult).length > 0) {
          flowGroup = flowGroupResult?.flowGroups;
          this.state.flowGroupCreated = true;
        }
        //get storageManagement data
        const storagemanagementResult = await readStorageManagement(
          metadataid,
          uibuilderData?.version ?? 0
        );
        if (Object.keys(storagemanagementResult).length > 0) {
          storageManagement = storagemanagementResult?.storageManagement;
          this.state.storageManagementCreated = true;
        }
      }

      let qpasQueries = await getAllQpasQueries(
        "35d4aeca-cb7a-459f-9478-afc4b4a8f0a9"
      );

      let UIcomponents = await getAllUIComponents();
      console.log(
        AllScreenList.sort(function (a, b) {
          return a?.index ?? 0 - b?.index ?? 0;
        })
      );

      function getInitialScreen() {
        let result = AllScreenList.find((screen) => screen.type === "Screen");
        console.log(result);
        return result ? [result] : [];
      }

      //update the state
      this.setState({
        user: {
          ...userInfo,
          name: userInfo?.given_name,
        },
        isRefreshed: true,
        screensMetaData: uibuilderData?.screens ?? [],
        loading: false,
        qpasQueries: qpasQueries,
        UIcomponents: UIcomponents.sort((a, b) =>
          a.componentName.localeCompare(b.componentName)
        ),
        version: uibuilderData?.version ?? 0,
        undoRedoData: [JSON.parse(JSON.stringify(AllScreenList))],
        activeIndex: 0,
        AllScreenList: AllScreenList.sort(function (a, b) {
          return a.index - b.index;
        }),
        AllScreenListRef: JSON.parse(
          JSON.stringify(
            AllScreenList.sort(function (a, b) {
              return a.index - b.index;
            })
          )
        ),
        screens: getInitialScreen(),
        selectedScreen:
          getInitialScreen().length > 0 ? getInitialScreen()[0].id : null,
        projectTemplates: projectTemplates,
        projectTemplatesRef: JSON.parse(JSON.stringify(projectTemplates)),
        customComponents: customComponents,
        customComponentsRef: JSON.parse(JSON.stringify(customComponents)),
        flowGroup: flowGroup ?? [],
        flowGroupRef: JSON.parse(JSON.stringify(flowGroup)),
        storageManagement: storageManagement,
        storageManagementRef: JSON.parse(JSON.stringify(storageManagement)),
        projectId: uibuilderData?.projectId ?? null,
        clientId: uibuilderData?.clientId ?? null,
        toolId: uibuilderData?.toolId ?? null,
        screensHistory: JSON.parse(JSON.stringify(AllScreenList)).reduce(
          (allScreens, currScreen) => {
            return {
              ...allScreens,
              [currScreen.id]: [{ ...currScreen }],
            };
          },
          {}
        ),
        screensActiveIndex: JSON.parse(JSON.stringify(AllScreenList)).reduce(
          (allScreens, currScreen) => {
            return {
              ...allScreens,
              [currScreen.id]: 0,
            };
          },
          {}
        ),
      });
    } else {
      window.location.replace(Config.qdm_admin_url);
    }
  };

  // auto save metaJSON Data's
  _update = async (uiflow) => {
    let metaDataId = localStorage.metaDataId;
    //find what have changed
    let changes = [
      {
        type: "screens",
        ops: [
          {
            type: "updated",
            payload: [],
          },
          {
            type: "deleted",
            payload: [],
          },
        ],
      },
      {
        type: "projectTemplates",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
      {
        type: "customComponents",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
      {
        type: "flowGroup",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
      {
        type: "storageManagement",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
    ];

    //screen - deleted, updated
    let allScreenListRef = this.state.AllScreenListRef;
    //deleted screens
    allScreenListRef.forEach(({ id: id1 }) => {
      if (!this.state.AllScreenList.some(({ id: id2 }) => id2 === id1)) {
        changes.forEach((eachType) => {
          if (eachType.type === "screens") {
            eachType.ops.forEach((op) => {
              if (op.type === "deleted") {
                op.payload.push(id1);
              }
            });
          }
        });
      }
    });
    this.state.AllScreenList.forEach((screen) => {
      //created & updated screens
      let foundScreen = allScreenListRef.find(
        (eachScreen) => eachScreen.id === screen.id
      );
      let isEqualOrNot = isEqual(foundScreen, screen);
      if (!isEqualOrNot) {
        changes.forEach((eachType) => {
          if (eachType.type === "screens") {
            eachType.ops.forEach((op) => {
              if (op.type === "updated") {
                op.payload.push(screen);
              }
            });
          }
        });
      }
    });
    console.log(changes);

    console.log(changes, "changes");
    //projectTemplates is not equal- create or update it
    let projectTemplatesRef = this.state.projectTemplatesRef;
    let pTStatus = isEqual(
      this.state.projectTemplates ?? [],
      projectTemplatesRef ?? []
    );

    if (!pTStatus) {
      if (this.state.projectTemplatesCreated) {
        changes.forEach((eachType) => {
          if (eachType.type === "projectTemplates") {
            eachType.ops.forEach((op) => {
              if (op.type === "updated") {
                op.payload = true;
              }
            });
          }
        });
      } else {
        changes.forEach((eachType) => {
          if (eachType.type === "projectTemplates") {
            eachType.ops.forEach((op) => {
              if (op.type === "created") {
                op.payload = true;
              }
            });
          }
        });
      }
    }
    //customcomponents is not equal- create or update it
    let customComponentsRef = this.state.customComponentsRef;

    let ccStatus = isEqual(this.state.customComponents, customComponentsRef);

    if (!ccStatus) {
      if (this.state.customComponentsCreated) {
        changes.forEach((eachType) => {
          if (eachType.type === "customComponents") {
            eachType.ops.forEach((op) => {
              if (op.type === "updated") {
                op.payload = true;
              }
            });
          }
        });
      } else {
        changes.forEach((eachType) => {
          if (eachType.type === "customComponents") {
            eachType.ops.forEach((op) => {
              if (op.type === "created") {
                op.payload = true;
              }
            });
          }
        });
      }
    }

    //flowgroup is not equal- create or update it
    let flowGroupRef = this.state.flowGroupRef;

    let fgStatus = isEqual(this.state.flowGroup, flowGroupRef);

    if (!fgStatus) {
      if (this.state.flowGroupCreated) {
        changes.forEach((eachType) => {
          if (eachType.type === "flowGroup") {
            eachType.ops.forEach((op) => {
              if (op.type === "updated") {
                op.payload = true;
              }
            });
          }
        });
      } else {
        changes.forEach((eachType) => {
          if (eachType.type === "flowGroup") {
            eachType.ops.forEach((op) => {
              if (op.type === "created") {
                op.payload = true;
              }
            });
          }
        });
      }
    }
    //storageManagement is not equal- create or update it
    let storageManagementRef = this.state.storageManagementRef;

    let smStatus = isEqual(this.state.storageManagement, storageManagementRef);

    if (!smStatus) {
      if (this.state.storageManagementCreated) {
        changes.forEach((eachType) => {
          if (eachType.type === "storageManagement") {
            eachType.ops.forEach((op) => {
              if (op.type === "updated") {
                op.payload = true;
              }
            });
          }
        });
      } else {
        changes.forEach((eachType) => {
          if (eachType.type === "storageManagement") {
            eachType.ops.forEach((op) => {
              if (op.type === "created") {
                op.payload = true;
              }
            });
          }
        });
      }
    }

    //let's do the network call
    let allPromises = [];
    changes.forEach((eachType) => {
      switch (eachType.type) {
        case "screens":
          eachType.ops.forEach(async (op) => {
            if (op.type === "created" && op.payload.length > 0) {
              let screenCreatePayload = op.payload.reduce(
                (payload, eachPayload) => {
                  let result = {
                    entity: "screens",
                    body: eachPayload,
                  };
                  return [...payload, result];
                },
                []
              );
              let screensCreateRequest = createDocument(screenCreatePayload);
              allPromises.push(screensCreateRequest);
            } else if (op.type === "updated" && op.payload.length > 0) {
              let screenUpdatePayload = op.payload.reduce(
                (payload, eachPayload) => {
                  let result = {
                    entity: "screens",
                    body: eachPayload,
                    filter: {
                      id: eachPayload.id,
                      metadataid: localStorage.metaDataId,
                      version: this.state.version ?? 1.0,
                    },
                  };
                  return [...payload, result];
                },
                []
              );
              let screensUpdateRequest = updateDocument(screenUpdatePayload);
              allPromises.push(screensUpdateRequest);
            } else if (op.type === "deleted" && op.payload.length > 0) {
              op.payload.forEach(async (payload, index) => {
                let result = {
                  entity: "screens",
                  filter: {
                    id: payload,
                  },
                };
                let screensDeleteRequest = deleteDocument([result]);
                allPromises.push(screensDeleteRequest);
              });
            }
          });
          break;
        case "projectTemplates":
          eachType.ops.forEach(async (op) => {
            if (op.type === "created" && op.payload) {
              let projectTemplatesSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                templateGroups: this.state.projectTemplates,
                version: this.state.version,
              };

              let pTCreateRequest = createDocument([
                {
                  entity: "project_templates",
                  body: projectTemplatesSchema,
                },
              ]);
              this.setState({
                projectTemplatesCreated: true,
              });
              allPromises.push(pTCreateRequest);
            } else if (op.type === "updated" && op.payload) {
              let projectTemplatesSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                templateGroups: this.state.projectTemplates,
                version: this.state.version,
              };
              let pTUpdateRequest = updateDocument([
                {
                  entity: "project_templates",
                  body: projectTemplatesSchema,
                  filter: {
                    metadataId: metaDataId,
                    version: this.state.version,
                  },
                },
              ]);
              allPromises.push(pTUpdateRequest);
            }
          });
          break;
        case "customComponents":
          eachType.ops.forEach(async (op) => {
            if (op.type === "created" && op.payload) {
              let customComponentsSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                customComponents: this.state.customComponents,
                version: this.state.version,
              };

              let ccCreateRequest = createDocument([
                {
                  entity: "custom_components",
                  body: customComponentsSchema,
                },
              ]);
              this.setState({
                customComponentsCreated: true,
              });
              allPromises.push(ccCreateRequest);
            } else if (op.type === "updated" && op.payload) {
              let customComponentsSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                customComponents: this.state.customComponents,
                version: this.state.version,
              };
              let ccUpdateRequest = updateDocument([
                {
                  entity: "custom_components",
                  body: customComponentsSchema,
                  filter: {
                    metadataId: metaDataId,
                    version: this.state.version,
                  },
                },
              ]);
              allPromises.push(ccUpdateRequest);
            }
          });
          break;
        case "flowGroup":
          eachType.ops.forEach(async (op) => {
            if (op.type === "created" && op.payload) {
              let flowGroupSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                flowGroups: this.state.flowGroup,
                version: this.state.version,
              };

              let fgCreateRequest = createDocument([
                {
                  entity: "flowgroup",
                  body: flowGroupSchema,
                },
              ]);
              this.setState({
                flowGroupCreated: true,
              });
              allPromises.push(fgCreateRequest);
            } else if (op.type === "updated" && op.payload) {
              let flowGroupSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                flowGroups: this.state.flowGroup,
                version: this.state.version,
              };
              let fgUpdateRequest = updateDocument([
                {
                  entity: "flowgroup",
                  body: flowGroupSchema,
                  filter: {
                    metadataId: metaDataId,
                    version: this.state.version,
                  },
                },
              ]);
              allPromises.push(fgUpdateRequest);
            }
          });

          break;
        case "storageManagement":
          eachType.ops.forEach(async (op) => {
            if (op.type === "created" && op.payload) {
              let storageManagementSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                storageManagement: this.state.storageManagement,
                version: this.state.version,
              };

              let smCreateRequest = createDocument([
                {
                  entity: "storagemanagement",
                  body: storageManagementSchema,
                },
              ]);
              this.setState({
                storageManagementCreated: true,
              });
              allPromises.push(smCreateRequest);
            } else if (op.type === "updated" && op.payload) {
              let storageManagementSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                storageManagement: this.state.storageManagement,
                version: this.state.version,
              };
              let smUpdateRequest = updateDocument([
                {
                  entity: "storagemanagement",
                  body: storageManagementSchema,
                  filter: {
                    metadataId: metaDataId,
                    version: this.state.version,
                  },
                },
              ]);
              allPromises.push(smUpdateRequest);
            }
          });
          break;
        default:
          break;
      }
    });

    Promise.all(allPromises)
      .then(async (result) => {
        console.log("result:\n" + JSON.stringify(result));
        //increase version & update refvalues
        this.setState({
          AllScreenListRef: JSON.parse(
            JSON.stringify(this.state.AllScreenList)
          ),
          projectTemplatesRef: JSON.parse(
            JSON.stringify(this.state.projectTemplates)
          ),
          customComponentsRef: JSON.parse(
            JSON.stringify(this.state.customComponents)
          ),
        });
      })
      .catch((err) => {
        console.error("Failed to do the operations");
        console.error(err);
      });
  };

  _setAuthState = (props) => {
    let screensHistory = this.state.screensHistory;
    let screensActiveIndex =
      props?.screensActiveIndex ?? this.state.screensActiveIndex;

    //Add screensHistory & screensActiveIndex for newly created screens

    if (
      !Object.keys(screensHistory).some(
        (screen) => screen === props?.selectedScreen
      )
    ) {
      screensHistory = {
        ...screensHistory,
        [props?.selectedScreen]: props?.screens,
      };
    }
    if (
      !Object.keys(screensActiveIndex).some(
        (screen) => screen === props?.selectedScreen
      )
    ) {
      screensActiveIndex = {
        ...screensActiveIndex,
        [props?.selectedScreen]: 0,
      };
    }

    let activeIndex;
    console.log(props?.screenEdited, screensHistory, screensActiveIndex);
    if (props?.screenEdited) {
      activeIndex = props.screensActiveIndex[props?.screenEdited];
      let screens = Object.keys(props.screensHistory ?? {});
      console.log(screens);
      let currentScreen = JSON.parse(
        JSON.stringify(screensHistory[props?.screenEdited])
      );
      let [currentScreenData] = props.AllScreenList.filter(
        (eachScreen) => eachScreen.id === props?.screenEdited
      );
      currentScreen.push(currentScreenData);
      screensHistory = {
        ...screensHistory,
        [props?.screenEdited]: JSON.parse(JSON.stringify(currentScreen)),
      };
      screensActiveIndex = {
        ...screensActiveIndex,
        [props?.screenEdited]: activeIndex + 1,
      };
    }
    console.log(props, "props", screensActiveIndex, screensHistory);
    this.setState({
      ...props,
      screensHistory: screensHistory,
      screensActiveIndex: screensActiveIndex,
      screenEdited: null,
    });
  };

  render() {
    return (
      <>
        {
          // this.state.isRefreshed ?
          <AuthContext.Provider
            value={{
              user: this.state,
              setAuth: this._setAuthState,
              save: this._update,
            }}
          >
            {this.props.children}
          </AuthContext.Provider>
          // : <>Your Loader Here...</>
        }
      </>
    );
  }
}

export default AppAuth;
