import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useLayoutEffect,
} from "react";
import * as All from "qdm-component-library";
import {
  Typography,
  Button,
  Collapse,
  ListItem,
  Box,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DialogContext, AlertContext, AuthContext } from "../../../../contexts";
import { alertProps } from "../../../../utils";
import { makeid, bytesConvertor } from "../../../../components/commonFunction";
import { networkCall } from "../../../../networkcall";
import Config from "../../../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import isEqual from "react-fast-compare";
import { ComponentsLists } from "../jsonDatas";
import { FileCard, UploadButton, TextInput } from "../components";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";
import { green } from "@material-ui/core/colors";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import espree from "espree";
import JSZip from "jszip";
import cloneDeep from "lodash/cloneDeep";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  inputField: {
    "& label": {
      fontSize: 14,
      lineHeight: "normal",
    },
    "& input": {
      fontSize: 14,
    },
    "& fieldset": {
      border: "1px solid #ebebeb",
    },
  },
}));

export function CustomCode(props) {
  const { properties, ...remainingproperties } = props;
  const component = remainingproperties?.component;
  const auth = React.useContext(AuthContext);

  return (
    <div
      style={{ display: "grid", placeItems: "center" }}
      {...remainingproperties}
    >
      {component?.component !== "CustomCode" ? (
        <div>
          <Typography
            variant="subtitle2"
            style={{ textAlign: "center", color: "#9CA3AF" }}
          >
            Custom Component
          </Typography>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            {component?.component}
          </Typography>
        </div>
      ) : (
        <Typography variant="h5" style={{ textAlign: "center" }}>
          Insert your custom code
        </Typography>
      )}
    </div>
  );
}

export function CustomCodePropertiesRenderer(props) {
  const { data, onClose } = props;
  const auth = React.useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [customComponent, setCustomComponent] = useState(null);
  const [customComponents, setCustomComponents] = useState([]);

  useLayoutEffect(() => {
    setCustomComponent(null);

    const allCustomComponents = cloneDeep(auth?.user?.customComponents);
    setCustomComponents(allCustomComponents);
    allCustomComponents.forEach((cc) => {
      if (cc.component === data.component) {
        if (cc.properties) {
          cc["properties"] = data?.properties ?? cc.properties;
        }
        setCustomComponent({ ...cc });
      }
    });
    setLoading(false);
  }, [data]);

  if (loading) {
    return null;
  }

  return (
    <>
      {customComponent && (
        <CustomCodeProperties
          component={customComponent}
          data={data}
          setCustomComponent={setCustomComponent}
          allComponents={customComponents}
        />
      )}
      {!customComponent && (
        <div
          style={{
            display: "grid",
            width: "100%",
            padding: "12px 10px",
            gridGap: "20px",
          }}
        >
          {customComponents.length > 0 && (
            <>
              <Typography variant="subtitle2" style={{ color: "gray" }}>
                Select From Your Custom Components
              </Typography>
              <ComponentSelection
                allComponents={customComponents}
                setCustomComponent={setCustomComponent}
                data={data}
              />
              <Typography
                variant="subtitle2"
                style={{
                  color: "gray",
                  textAlign: "center",
                  margin: "12px 0px",
                }}
              >
                OR
              </Typography>
            </>
          )}
          <Typography variant="subtitle2" style={{ color: "gray" }}>
            Upload Your Custom Component
          </Typography>
          <CustomCodeFileUpload
            data={data}
            onClose={onClose}
            setCustomComponent={setCustomComponent}
          />
        </div>
      )}
    </>
  );
}

export function ComponentSelection(props) {
  const { allComponents, setCustomComponent, data, component } = props;
  const auth = React.useContext(AuthContext);
  const handleCCChange = (selectedComponent) => {
    if (!selectedComponent) return;
    const selectedScreenId = auth.user.selectedScreen;
    auth.user.AllScreenList.forEach((screen) => {
      if (screen.id === selectedScreenId) {
        screen.render.child.forEach(function iter(component, index, array) {
          if (component.id === data.id) {
            component.component = selectedComponent.component;
          } else {
            if (component?.child?.length > 0) {
              component.child.forEach(iter);
            }
          }
        });
      }
    });
    setCustomComponent({ ...selectedComponent });
    auth.setAuth({
      ...auth.user,
      isEdited: true,
    });
  };

  return (
    <Box>
      <Autocomplete
        options={allComponents}
        size="small"
        onChange={(e, v) => {
          handleCCChange(v);
        }}
        value={{
          component: component?.component ?? "",
        }}
        getOptionLabel={(option) => option.component}
        // className={classes.inputField}
        renderInput={(params) => (
          <TextField {...params} label="Custom Components" variant="outlined" />
        )}
        style={{ width: "100%" }}
      />
    </Box>
  );
}

export function CustomCodeProperties(props) {
  const { component, setCustomComponent, data } = props;
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState({ files: false, dependencies: false });
  const [zipFile, setZipFile] = useState(null);
  const componentNameRef = useRef(null);
  const propertiesRef = useRef({});
  const zipFileRef = useRef(null);
  const dependenciesRef = useRef([]);

  const handleClick = (id) => {
    setOpen({
      [id]: open[id] ? !open[id] : true,
    });
  };

  const handleVersionChange = (pkg, val) => {
    component.dependencies.forEach((dep) => {
      if (dep.package === pkg) {
        dep.version = val;
      }
    });
    setCustomComponent({
      ...component,
    });
    auth.user.customComponents.forEach((cc) => {
      if (cc.component === data.component) {
        cc = {
          ...component,
        };
      }
    });
    auth.setAuth({
      ...auth.user,
      isEdited: true,
    });
  };

  const handleValueChange = (key, val) => {
    component["properties"][key] = val;
    setCustomComponent({
      ...component,
    });
    const selectedScreenId = auth.user.selectedScreen;
    auth.user.AllScreenList.forEach((screen) => {
      if (screen.id === selectedScreenId) {
        screen.render.child.forEach(function iter(cmpnt, index, array) {
          if (cmpnt.id === data.id) {
            cmpnt.properties = component["properties"];
          } else {
            if (cmpnt?.child?.length > 0) {
              cmpnt.child.forEach(iter);
            }
          }
        });
      }
    });
    auth.setAuth({
      ...auth.user,
      isEdited: true,
    });
  };

  const importZip = (evt) => {
    var files = evt.target.files;
    let fileDetails;
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      zipFileRef.current = file;
      fileDetails = {
        fileName: file.name,
        fileSize: bytesConvertor(file.size),
        fileType: "zip",
      };
      setZipFile({
        fileName: file.name,
        fileSize: bytesConvertor(file.size),
        fileType: "zip",
      });
    }
    return fileDetails;
  };

  const uploadFile = async (file) => {
    const Data = new FormData();
    Data.append("files", file);
    const downloadURL = await networkCall(Config.image_url + "11", "POST", Data)
      .then((response) => {
        if (response.data.status === "200") {
          return response.data;
        } else {
        }
      })
      .catch((error) => alert(error));
    return downloadURL;
  };

  const propertyValidator = (value) => {
    if (typeof value === "string") {
      return true;
    } else if (typeof value === "boolean") {
      return true;
    } else if (Number.isInteger(value)) {
      return true;
      // } else if (Array.isArray(value)) {
      //   const result = value.every((val) => typeof val === "string");
      //   return result;
      // }
    } else {
      return false;
    }
  };

  const jsParser = (code) => {
    const options = {
      ecmaVersion: 11,
      // specify which type of script you're parsing ("script" or "module")
      sourceType: "module",
      // specify additional language features
      ecmaFeatures: {
        // enable JSX parsing
        jsx: true,
      },
    };
    const ast = espree.parse(code, options);
    const body = ast?.body;

    body.forEach((node) => {
      if (node.type === "ImportDeclaration") {
        if (!node?.source?.value.startsWith(".")) {
          const value = node?.source?.value;
          const splitted = value.split("/");
          const pkg = splitted.slice(0, 2).join("/");
          if (!dependenciesRef.current.some((eachPkg) => eachPkg === pkg)) {
            dependenciesRef.current.push(pkg);
          }
        }
      } else if (
        (node.type === "ExportDefaultDeclaration" &&
          node?.declaration?.name === componentNameRef.current) ||
        (node.type === "FunctionDeclaration" &&
          node?.id?.name === componentNameRef.current)
      ) {
        body.forEach((node) => {
          if (
            node.type === "ExpressionStatement" &&
            node?.expression?.left?.property?.name === "defaultProps"
          ) {
            const properties = node?.expression?.right?.properties;
            propertiesRef.current = properties.reduce(
              (allProperties, property) => {
                let value;
                if (property?.value?.value !== undefined) {
                  value = property?.value?.value;
                }
                //  else if (
                //   !property?.value?.value &&
                //   Array.isArray(property?.value?.elements)
                // ) {
                //   const result = property.value.elements.reduce(
                //     (values, element) => {
                //       return [element.value, ...values];
                //     },
                //     []
                //   );
                //   value = result;
                // }
                if (value === undefined) return allProperties;
                const result = propertyValidator(value);
                if (!result) return allProperties;
                let updatedProperties = {
                  ...allProperties,
                  [property.key.name]: value,
                };
                return updatedProperties;
              },
              propertiesRef.current
            );
          }
        });
      }
    });
    return false;
  };

  const updateScreen = (schema) => {
    schema["files"] = schema?.files.reverse();
    schema["properties"] = propertiesRef.current;
    auth.user.customComponents.forEach((cc, index, array) => {
      if (cc.component === component.component) {
        array[index] = schema;
      }
    });
    const selectedScreenId = auth.user.selectedScreen;
    auth.user.AllScreenList.forEach((screen) => {
      if (screen.id === selectedScreenId) {
        screen.render.child.forEach(function iter(component, index, array) {
          if (component.name === "CustomCode") {
            component.properties = {
              ...schema["properties"],
              ...component.properties,
            };
          } else {
            if (component?.child?.length > 0) {
              component.child.forEach(iter);
            }
          }
        });
      }
    });
    setCustomComponent({ ...schema });
    alert.setSnack({
      ...alert,
      open: true,
      severity: alertProps.severity.success,
      msg: "Custom component updated successfully",
      vertical: alertProps.vertical.top,
      horizontal: alertProps.horizontal.right,
      tone: true,
    });
    auth.setAuth({
      ...auth.user,
      isEdited: true,
    });
    dependenciesRef.current = [];
  };

  const handleImport = async () => {
    const schema = {
      files: [],
      src: "",
      component: "",
      dependencies: [],
    };
    //files- will have all the files list
    JSZip.loadAsync(zipFileRef.current).then(async function (zip) {
      if (!zip.files) return;
      let haveIndexFile = [];
      zip.forEach((relativePath, file) => {
        const splitted = relativePath.split("/");
        const fileName = splitted[splitted.length - 1];
        if (fileName === "index.js") {
          haveIndexFile.push("true");
        }
      });
      if (haveIndexFile.length === 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "Index.js file is missing",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      if (haveIndexFile.length > 1) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "More than one index.js is found",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      if (haveIndexFile.length === 1) {
        //upload the zip file & add it to QDM schema
        const zipObj = await uploadFile(zipFileRef.current);
        const zipLink = Config.image_url + zipObj.fileid;
        schema["src"] = zipLink;
        const promises = [];
        zip.forEach(async (relativePath, file) => {
          if (file.dir) return null;
          const splitted = relativePath.split("/");
          const [componentName, ...remaining] = splitted;
          schema["component"] = componentName;
          componentNameRef.current = componentName;
          const path = remaining.slice(0, remaining.length - 1).join("/");
          const fileName = splitted[splitted.length - 1];
          const fileNameSplitted = fileName.split(".");
          const fileType = fileNameSplitted[fileNameSplitted.length - 1];
          schema["files"].push({
            path: remaining.length > 1 ? path : null,
            fileName: fileName,
            fileType: fileType,
          });
          if (file.name.endsWith(".js")) {
            promises.push(zip.file(file.name).async("string"));
          }
        });
        Promise.all(promises).then(async function (code) {
          code.forEach((text) => {
            jsParser(text);
          });
          let fetches = [];
          dependenciesRef.current.forEach(async (pkg) => {
            fetches.push(
              fetch(`https://api.npms.io/v2/search?q=${pkg}&size=1`)
                .then((res) => res.json())
                .then((data) => {
                  if (!data.results.length > 0) return null;
                  return {
                    package: pkg,
                    version: data.results[0].package.version ?? "0.0.0",
                  };
                })
            );
          });
          Promise.all(fetches).then((data) => {
            if (!data) return;
            data.forEach((pkg) => {
              schema["dependencies"].push(pkg);
            });
            updateScreen(schema);
          });
        });
      }
    });
  };

  const handleUpdate = (event) => {
    const result = importZip(event);
    const [componentName] = result.fileName.split(".");
    //If not the same custom component
    if (componentName !== component.component)
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `Please use the same file name while uploading`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    //If it is same custom component
    handleImport();
  };

  const valueRenderer = (key, value) => {
    if (typeof value === "string" || Number.isInteger(value)) {
      return (
        <TextField
          label="Value"
          value={value}
          id="outlined-size-small"
          defaultValue="Small"
          variant="outlined"
          size="small"
          onChange={(e) => {
            handleValueChange(key, e.target.value);
          }}
          className={classes.inputField}
        />
      );
    } else if (typeof value === "boolean") {
      return (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={(e) => {
            handleValueChange(key, e.target.value);
          }}
          style={{
            fontSize: "14px",
          }}
        >
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </Select>
      );
    }
  };

  return (
    <div style={{ width: "100%", padding: "6px 10px" }}>
      <Box style={{ margin: "0px 0px 16px 0px" }}>
        <FileCard
          fileName={component?.component}
          fileSize={null}
          fileType="Zip"
          type="file"
          action={true}
          actionText="Update"
          handleAction={handleUpdate}
          uploading={false}
          multiple={false}
          accept=".zip"
        />
      </Box>
      <Box display="grid" gridGap="12px">
        <Box display="grid" gridGap="6px">
          <ListItem
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "default",
              padding: "0px 2px",
            }}
            onClick={() => {
              handleClick("files");
            }}
            button
          >
            {open["files"] ? (
              <ArrowRightRoundedIcon color={"action"} />
            ) : (
              <ArrowDropDownRoundedIcon color={"action"} />
            )}
            <Typography
              style={{
                width: "inherit",
                cursor: "default",
                padding: "8px 0px",
              }}
              variant="body2"
            >
              {`Files - ${component?.files.length}`}
            </Typography>
          </ListItem>
          <Collapse in={open["files"]} timeout="auto" unmountOnExit>
            <Box display="grid" gridGap="12px" style={{ margin: "4px" }}>
              {component?.files.map((file) => {
                return (
                  <div>
                    <Typography
                      style={{
                        margin: "2px 0px 6px 0px",
                        display: "block",
                        color: "gray",
                      }}
                      variant="caption"
                    >
                      /{component?.component}
                      {file?.path ? `/${file.path}` : ""}
                    </Typography>
                    <FileCard
                      {...file}
                      uploading={false}
                      type="file"
                      multiple={false}
                    />
                  </div>
                );
              })}
            </Box>
          </Collapse>
        </Box>
        <Box display="grid" gridGap="12px">
          <ListItem
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "default",
              padding: "0px 2px",
            }}
            onClick={() => {
              handleClick("dependencies");
            }}
            button
          >
            {open["dependencies"] ? (
              <ArrowRightRoundedIcon color={"action"} />
            ) : (
              <ArrowDropDownRoundedIcon color={"action"} />
            )}
            <Typography
              style={{
                width: "inherit",
                cursor: "default",
                padding: "8px 0px",
              }}
              variant="body2"
            >
              {`Dependencies - ${component?.dependencies.length}`}
            </Typography>
          </ListItem>
          <Collapse in={open["dependencies"]} timeout="auto" unmountOnExit>
            <Box display="grid" gridGap="12px" style={{ margin: "4px" }}>
              {component?.dependencies.map((dependency) => (
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(2,1fr)"
                  gridGap="12px"
                >
                  <TextField
                    label="Package"
                    id="outlined-size-small"
                    variant="outlined"
                    size="small"
                    value={dependency?.package}
                    readOnly={true}
                    className={classes.inputField}
                  />
                  <TextField
                    label="Version"
                    value={dependency?.version}
                    id="outlined-size-small"
                    defaultValue="Small"
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      handleVersionChange(dependency?.package, e.target.value);
                    }}
                    className={classes.inputField}
                  />
                </Box>
              ))}
            </Box>
          </Collapse>
        </Box>
        {Object.keys(component?.properties).length > 0 && (
          <Box display="grid" gridGap="12px">
            <ListItem
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "default",
                padding: "0px 2px",
              }}
              onClick={() => {
                handleClick("properties");
              }}
              button
            >
              {open["properties"] ? (
                <ArrowRightRoundedIcon color={"action"} />
              ) : (
                <ArrowDropDownRoundedIcon color={"action"} />
              )}
              <Typography
                style={{
                  width: "inherit",
                  cursor: "default",
                  padding: "8px 0px",
                }}
                variant="body2"
              >
                {`Properties - ${Object.keys(component?.properties).length}`}
              </Typography>
            </ListItem>
            <Collapse in={open["properties"]} timeout="auto" unmountOnExit>
              <Box display="grid" gridGap="26px" style={{ margin: "4px" }}>
                {Object.entries(component?.properties).map((property) => (
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(2,1fr)"
                    gridGap="12px"
                  >
                    <TextField
                      label="Property"
                      id="outlined-size-small"
                      variant="outlined"
                      size="small"
                      value={property[0]}
                      readOnly={true}
                      className={classes.inputField}
                    />
                    {valueRenderer(property[0], property[1])}
                  </Box>
                ))}
              </Box>
            </Collapse>
          </Box>
        )}
      </Box>
    </div>
  );
}

export function CustomCodeFileUpload(props) {
  const { data, setCustomComponent } = props;
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const classes = useStyles();
  const [zipFile, setZipFile] = useState(null);
  const [importing, setImporting] = useState(false);

  const zipFileRef = useRef(null);
  const dependenciesRef = useRef([]);
  const componentNameRef = useRef(null);
  const propertiesRef = useRef({});

  const importZip = (evt) => {
    var files = evt.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      zipFileRef.current = file;
      setZipFile({
        fileName: file.name,
        fileSize: bytesConvertor(file.size),
        fileType: "zip",
      });
    }
  };

  const uploadFile = async (file) => {
    const Data = new FormData();
    Data.append("files", file);
    const downloadURL = await networkCall(Config.image_url + "11", "POST", Data)
      .then((response) => {
        if (response.data.status === "200") {
          return response.data;
        } else {
        }
      })
      .catch((error) => alert(error));
    return downloadURL;
  };

  const propertyValidator = (value) => {
    if (typeof value === "string") {
      return true;
    } else if (typeof value === "boolean") {
      return true;
    } else if (Number.isInteger(value)) {
      return true;
    }
    //  else if (Array.isArray(value)) {
    //   const result = value.every((val) => typeof val === "string");
    //   return result;
    // }
    else {
      return false;
    }
  };

  const jsParser = (code) => {
    const options = {
      ecmaVersion: 11,
      // specify which type of script you're parsing ("script" or "module")
      sourceType: "module",
      // specify additional language features
      ecmaFeatures: {
        // enable JSX parsing
        jsx: true,
      },
    };
    const ast = espree.parse(code, options);
    const body = ast?.body;

    body.forEach((node) => {
      if (node.type === "ImportDeclaration") {
        if (!node?.source?.value.startsWith(".")) {
          const value = node?.source?.value;
          const splitted = value.split("/");
          const pkg = splitted.slice(0, 2).join("/");
          if (!dependenciesRef.current.some((eachPkg) => eachPkg === pkg)) {
            dependenciesRef.current.push(pkg);
          }
        }
      } else if (
        (node.type === "ExportDefaultDeclaration" &&
          node?.declaration?.name === componentNameRef.current) ||
        (node.type === "FunctionDeclaration" &&
          node?.id?.name === componentNameRef.current)
      ) {
        body.forEach((node) => {
          if (
            node.type === "ExpressionStatement" &&
            node?.expression?.left?.property?.name === "defaultProps"
          ) {
            const properties = node?.expression?.right?.properties;
            propertiesRef.current = properties.reduce(
              (allProperties, property) => {
                let value;
                if (property?.value?.value !== undefined) {
                  value = property?.value?.value;
                }
                // else if (
                //   !property?.value?.value &&
                //   Array.isArray(property?.value?.elements)
                // ) {
                //   const result = property.value.elements.reduce(
                //     (values, element) => {
                //       return [element.value, ...values];
                //     },
                //     []
                //   );
                //   value = result;
                // }
                if (value === undefined) return allProperties;
                const result = propertyValidator(value);
                if (!result) return allProperties;
                let updatedProperties = {
                  ...allProperties,
                  [property.key.name]: value,
                };
                return updatedProperties;
              },
              propertiesRef.current
            );
          }
        });
      }
    });
    return false;
  };

  const updateScreen = (schema) => {
    const selectedScreenId = auth.user.selectedScreen;
    schema["files"] = schema?.files.reverse();
    schema["properties"] = propertiesRef.current;
    auth.user.AllScreenList.forEach((screen) => {
      if (screen.id === selectedScreenId) {
        screen.render.child.forEach(function iter(component, index, array) {
          if (component.id === data.id) {
            component.component = schema["component"];
            component.properties = schema["properties"];
          } else {
            if (component?.child?.length > 0) {
              component.child.forEach(iter);
            }
          }
        });
      }
    });
    auth.user.customComponents.push(schema);
    setImporting(false);
    setCustomComponent({ ...schema });
    auth.setAuth({
      ...auth.user,
      isEdited: true,
    });
  };

  const handleImport = async () => {
    setImporting(true);
    const schema = {
      files: [],
      src: "",
      component: "",
      dependencies: [],
    };
    //files- will have all the files list
    JSZip.loadAsync(zipFileRef.current).then(async function (zip) {
      if (!zip.files) return;
      const [path] = Object.keys(zip.files);
      const splitted = path.split("/");
      const componentName = splitted[0];
      componentNameRef.current = componentName;
      const isAlreadyPresent = auth.user.customComponents.some(
        (cc) => cc.component === componentName
      );
      if (isAlreadyPresent) {
        setImporting(false);
        return alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.info,
          msg: `${componentName} is already imported!`,
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      }
      let haveIndexFile = [];
      zip.forEach((relativePath, file) => {
        const splitted = relativePath.split("/");
        const fileName = splitted[splitted.length - 1];
        if (fileName === "index.js") {
          haveIndexFile.push("true");
        }
      });
      if (haveIndexFile.length === 0) {
        setImporting(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "Index.js file is missing",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      if (haveIndexFile.length > 1) {
        setImporting(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "More than one index.js is found",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      if (haveIndexFile.length === 1) {
        //upload the zip file & add it to QDM schema
        const zipObj = await uploadFile(zipFileRef.current);
        const zipLink = Config.image_url + zipObj.fileid;
        schema["src"] = zipLink;
        const promises = [];
        zip.forEach(async (relativePath, file) => {
          if (file.dir) return null;
          const splitted = relativePath.split("/");
          const [componentName, ...remaining] = splitted;
          schema["component"] = componentName;
          componentNameRef.current = componentName;
          const path = remaining.slice(0, remaining.length - 1).join("/");
          const fileName = splitted[splitted.length - 1];
          const fileNameSplitted = fileName.split(".");
          const fileType = fileNameSplitted[fileNameSplitted.length - 1];
          schema["files"].push({
            path: remaining.length > 1 ? path : null,
            fileName: fileName,
            fileType: fileType,
          });
          if (file.name.endsWith(".js")) {
            promises.push(zip.file(file.name).async("string"));
          }
        });
        Promise.all(promises).then(async function (code) {
          code.forEach((text) => {
            jsParser(text);
          });
          let fetches = [];
          dependenciesRef.current.forEach(async (pkg) => {
            fetches.push(
              fetch(`https://api.npms.io/v2/search?q=${pkg}&size=1`)
                .then((res) => res.json())
                .then((data) => {
                  if (!data.results.length > 0) return null;
                  return {
                    package: pkg,
                    version: data.results[0].package.version ?? "0.0.0",
                  };
                })
            );
          });
          Promise.all(fetches).then((data) => {
            if (!data) return;
            data.forEach((pkg) => {
              schema["dependencies"].push(pkg);
            });
            updateScreen(schema);
          });
        });
      }
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "grid",
          width: "100%",
          padding: "24px",
          borderRadius: "8px",
          backgroundColor: "#F9FAFB",
          border: "1px solid #E5E7EB",
        }}
      >
        <Typography
          variant="subtitle2"
          style={{
            margin: "0px 0px 16px 0px",
            textAlign: "center",
            color: "#6B7280",
          }}
        >
          Import Your custom code
        </Typography>
        <div
          style={{
            backgroundColor: "white",
            width: "60px",
            height: "60px",
            borderRadius: "100%",
            padding: "12px",
            display: "grid",
            placeItems: "center",
            margin: "0px auto 32px auto",
          }}
        >
          <FileCopyIcon style={{ fontSize: "30px", color: "#94A3B8" }} />
        </div>
        {!zipFile && (
          <UploadButton
            handleChange={(event) => {
              importZip(event);
            }}
            accept=".zip"
            multiple={false}
            name="Zip File"
          />
        )}

        {zipFile && (
          <>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0px 0px 12px 0px",
              }}
              onClick={() => {
                setZipFile(null);
                setImporting(false);
              }}
            >
              <HighlightOffIcon
                color="action"
                fontSize="small"
                style={{ cursor: "pointer" }}
              />
            </Box>
            <FileCard
              {...zipFile}
              uploading={false}
              type="file"
              action={true}
              handleAction={importZip}
              multiple={false}
              accept={".zip"}
            />
          </>
        )}
      </div>
      {zipFile && (
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <Button
            style={{ margin: "42px", width: "100%" }}
            variant="contained"
            color="primary"
            onClick={handleImport}
            disabled={!importing ? false : true}
            disableElevation
            size="medium"
            startIcon={
              importing && (
                <CircularProgress
                  size={16}
                  color="inherit"
                  className={classes.buttonProgress}
                />
              )
            }
          >
            {importing ? "Importing the files..." : "Import"}
          </Button>
        </div>
      )}
    </div>
  );
}
