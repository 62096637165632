import React, { useEffect, useState, useContext } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import * as Icons from "../../../components";
import ProjectTemplates from "./projectTemplates";
import blue from "@material-ui/core/colors/blue";
import gray from "@material-ui/core/colors/blueGrey";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
// json data
import { docorateName } from "../../../components";
import { AuthContext } from "../../../contexts";
import { WebAppPanel } from "../../../components/tab";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const ComponentsTemplateLists = [
  {
    catagerie: "Login Screen's",
    list: [
      {
        name: "Login 1",
      },
      {
        name: "Login 2",
      },
    ],
  },
  {
    catagerie: "Layout's",
    list: [
      {
        name: "Layout 1",
      },
      {
        name: "Layout 2",
      },
      {
        name: "Layout 3",
      },
      {
        name: "Layout 4",
      },
      {
        name: "Layout 5",
      },
      {
        name: "Confirmation Template",
      },
      {
        name: "Demographic Data",
      },
      {
        name: "Listing Layout",
      },
      {
        name: "Listing Card",
      },
    ],
  },
  {
    catagerie: "Template Component's",
    list: [
      {
        name: "Form 1",
      },
    ],
  },
];

const useStyles = makeStyles((theme) => {
  console.log(theme, "theme");
  return {
    root: {
      flexGrow: 1,
    },
    tabButton: {
      textTransform: "capitalize",
      fontSize: "11px !important",
      minWidth: "100px",
      border: "1px solid #ebebeb ",
      borderRadius: "15px ",
      backgroundColor: "#F6F6F8 ",
    },
    selectedTab: {
      // backgroundColor: "red" ,
      // color: "#fff !important",5px 12px",
      border: "0px",
      borderRadius: "50px !important",
      padding: "4px 12px",
      minWidth: "122px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },

    selecteTab: {
      padding: "4px 12px",
      border: "0px",
      borderRadius: "50px !important",
      minWidth: "122px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    catagerie: {
      textTransform: "capitalize",
      padding: "4px 10px",
      marginBottom: "4px",
    },
    dragArea: {
      width: "75px",
      cursor: "grab",
      textAlign: "center",
      wordBreak: "break-words",
      padding: "0px 0px 5px 0px",
      minHeight: "80px",
      borderRadius: "8px",
      display: "grid",
      placeItems: "center",
      backgroundColor: blue[700],
      boxShadow: `0px 0px 10px ${gray[50]}`,
      "&:hover": {
        backgroundColor: blue[50],
        boxShadow: `0px 0px 20px ${gray[200]}`,
        "& .dragAreaText": {
          color: blue[900],
        },
        "& .iconBg": {
          backgroundColor: blue[700],
        },
        "& .dragAreaIcon": {
          color: "white",
        },
      },
      "& .iconBg": {
        borderRadius: "6px 6px 0px 0px",
        backgroundColor: blue[50],
        width: "100%",
        height: "100%",
        padding: "14px 0px",
        display: "grid",
        placeItems: "center",
      },
      "& .dragAreaText": {
        color: "white",
        padding: "0px 1px 0px 1px",
      },
      "& .dragAreaIcon": {
        color: blue[700],
        fontSize: "28px",
      },
    },
    collapseView: {
      // maxHeight: 'calc(100vh - 286px)',
      // overflowX: "hidden",
      // overflowY: "auto",
      color: "gray",
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexWrap: "wrap",
      },
    },
    inputInput: {
      height: "0.9em",
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)}px) !important`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    searchIcon: {
      padding: "0px 6px !important",
      position: "absolute !important",
      pointerEvents: "none !important",
      display: "flex!important",
      alignItems: "center !important",
      justifyContent: "center!important",
      marginTop: "10px !important",
    },
    search: {
      borderRadius: "4px !important",
      borderBottom: "1.3px solid rgba(0, 0, 0, 0.12) !important",
      border: "1.3px solid rgba(0, 0, 0, 0.12) !important",
      marginLeft: "12px",
      marginRight: "20px",

      //backgroundColor: fade("#fff", 0.15),
      "&:hover": {
        // backgroundColor: fade("#fff", 0.25),
      },

      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
  };
});

const componentsListData = [
  {
    componentName: "Button",
    componentId: "material-comp-1",
    frameWork: "material",
    componentType: "input",
    isLayout: false,
    supported: ["web"],
    props: {
      children: {
        componentToRender: "text",
        defaultValue: "Button",
      },
      disabled: {
        componentToRender: "dropdown",
        options: [true, false],
      },
      disableElevation: {
        type: "select",
        options: [true, false],
      },
      disableFocusRipple: {
        type: "select",
        options: [true, false],
      },
      disableRipple: {
        type: "select",
        options: [true, false],
      },
      fullWidth: {
        type: "select",
        options: [true, false],
      },
      endIcon: {
        type: "icons",
      },
      startIcon: {
        type: "icons",
      },
      href: {
        type: "text",
      },
      size: {
        type: "dropdown",
        options: ["large", "medium", "small"],
      },
      variant: {
        type: "dropdown",
        options: ["contained", "outlined", "text"],
      },
    },
  },
  {
    componentName: "Switch",
    componentId: "material-comp-2",
    frameWork: "material",
    isLayout: true,
    supported: ["web", "app"],
    props: {
      checked: {
        type: "select",
        options: [true, false],
      },
      disabled: {
        type: "select",
        options: [true, false],
      },
      disableRipple: {
        type: "select",
        options: [true, false],
      },
      checkedIcon: {
        type: "icons",
      },
      icon: {
        type: "icons",
      },
      color: {
        type: "dropdown",
        options: ["default", "primary", "secondary"],
      },
      required: {
        type: "select",
        options: [true, false],
      },
      size: {
        type: "dropdown",
        options: ["medium", "small"],
      },
      edge: {
        type: "dropdown",
        options: ["end", "start", "false"],
      },
    },
  },
  {
    componentName: "Container",
    componentId: "material-comp-3",
    frameWork: "material",
    componentGroup: "container",
    isLayout: true,
    supported: ["web"],
    props: {
      fluid: {
        setType: "select",
        options: [true, false],
      },
      maxWidth: {
        setType: "select",
        options: ["xm", "sm", "xl", "lg"],
      },
      fixed: {
        setType: "select",
        options: [true, false],
      },
    },
  },
  {
    componentName: "Button",
    componentId: "material-comp-12",
    frameWork: "material",
    componentType: "input",
    isLayout: false,
    supported: ["web"],
    props: {
      children: {
        componentToRender: "text",
        defaultValue: "Button",
      },
      disabled: {
        componentToRender: "dropdown",
        options: [true, false],
      },
      disableElevation: {
        type: "select",
        options: [true, false],
      },
      disableFocusRipple: {
        type: "select",
        options: [true, false],
      },
      disableRipple: {
        type: "select",
        options: [true, false],
      },
      fullWidth: {
        type: "select",
        options: [true, false],
      },
      endIcon: {
        type: "icons",
      },
      startIcon: {
        type: "icons",
      },
      href: {
        type: "text",
      },
      size: {
        type: "dropdown",
        options: ["large", "medium", "small"],
      },
      variant: {
        type: "dropdown",
        options: ["contained", "outlined", "text"],
      },
    },
  },
  {
    componentName: "Button",
    componentId: "material-comp-11",
    frameWork: "material",
    componentType: "input",
    isLayout: false,
    supported: ["web"],
    props: {
      children: {
        componentToRender: "text",
        defaultValue: "Button",
      },
      disabled: {
        componentToRender: "dropdown",
        options: [true, false],
      },
      disableElevation: {
        type: "select",
        options: [true, false],
      },
      disableFocusRipple: {
        type: "select",
        options: [true, false],
      },
      disableRipple: {
        type: "select",
        options: [true, false],
      },
      fullWidth: {
        type: "select",
        options: [true, false],
      },
      endIcon: {
        type: "icons",
      },
      startIcon: {
        type: "icons",
      },
      href: {
        type: "text",
      },
      size: {
        type: "dropdown",
        options: ["large", "medium", "small"],
      },
      variant: {
        type: "dropdown",
        options: ["contained", "outlined", "text"],
      },
    },
  },
];

export function ComponentsList(props) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState({});
  //const autoSave = React.useContext(NotificationStatus);
  //const history = useHistory();
  const [componentList, setComponentList] = useState(
    auth?.user?.UIcomponents ?? []
  );

  // const onChangeSwitch = async (e, val) => {
  //   // if (props.onChangeSwitch) {
  //   //   props.onChangeSwitch(val);
  //   // } else {
  //   if (val === "builder") {
  //     // setUiType("builder");
  //     history.push(routes.uibuilder);
  //     const screen = auth.user.AllScreenList.filter(
  //       (screen) => screen.id === auth.user.selectedScreen
  //     );
  //     auth.setAuth({
  //       ...auth.user,
  //       AllScreenList: auth.user.AllScreenList,
  //       screens: screen.length > 0 ? screen : [auth.user.AllScreenList[0]],
  //     });
  //   } else {
  //     // setUiType("flow");
  //     if (auth.user?.isSaved === false) {
  //       await auth.save();
  //       auth.setAuth({
  //         ...auth.user,
  //         isSaved: true,
  //       });
  //     }
  //     history.push(routes.uiflow);
  //   }
  //   // }
  // };

  const tabChange = (val) => {
    setTab(val);
  };
  const handleClick = (val) => {
    // setOpen(!open);
    setOpen({
      // ...open,
      [val]: open[val] ? !open[val] : true,
    });
  };

  const onDragStart = (evt, type, obj) => {
    let element = evt.currentTarget;
    element.classList.add("vivifySmall");
    element.classList.add("pulsate");
    element.classList.add("dragAreaBg");
    // evt.dataTransfer.setData(type, evt.currentTarget.id);
    // let element = evt.currentTarget;
    // element.classList.add("dragged");
    evt.dataTransfer.setData("components", evt.currentTarget.id);
    evt.dataTransfer.setData("componentId", obj.componentId);
    evt.dataTransfer.effectAllowed = "move";
  };

  const onDragEnd = (evt, type) => {
    let element = evt.currentTarget;
    element.classList.remove("dragAreaBg");
    element.classList.remove("vivifySmall");
    element.classList.remove("pulsate");
  };

  const getComponentsList = (list, open, type) => {
    return (
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        style={{ width: "100%" }}
      >
        {/* Component category list map */}
        {list.map((title, index) => {
          return (
            <div key={index}>
              {/* Component category list */}
              <ListItem
                button
                key={index}
                onClick={() => handleClick(title.catagerie)}
                dense
                className={classes.catagerie}
              >
                {open[title.catagerie] ?? false ? (
                  <ArrowDropDownRoundedIcon
                    fontSize={"default"}
                    color={"action"}
                  />
                ) : (
                  <ArrowRightRoundedIcon
                    color={"action"}
                    fontSize={"default"}
                  />
                )}
                <ListItemText primary={title.catagerie} />
              </ListItem>
              {/* Component category list END */}

              {/* Components List */}
              <Collapse
                in={open[title.catagerie] ?? false}
                timeout="auto"
                unmountOnExit
                className={`${classes.collapseView} scroll-bar`}
              >
                {title.list.map((list, index) => {
                  let Icon =
                    type === "components"
                      ? Icons[list.name]
                      : Icons["Template"];
                  return (
                    <div style={{ margin: "5px auto 16px auto" }}>
                      <div
                        draggable={true}
                        key={index}
                        className={classes.dragArea}
                        onDragStart={(e) => onDragStart(e, type)}
                        onDragEnd={(e) => onDragEnd(e, type)}
                        id={list.name}
                      >
                        <div className="iconBg">
                          {Icon && <Icon className="dragAreaIcon" />}
                        </div>
                        <div>
                          <Typography
                            variant={"subtitle2"}
                            color={"inherit"}
                            className="dragAreaText"
                            style={{ fontSize: "11px", marginTop: "6px" }}
                          >
                            {docorateName(list.name)}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Collapse>
              {/* Components List END */}
            </div>
          );
        })}
        {/* Component category list Map END */}
      </List>
    );
  };

  const getgrouperComponentsList = (
    list,
    open,
    type,
    group,
    openAll,
    setOpenAll
  ) => {
    return (
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        style={{ width: "100%", padding: "0px" }}
      >
        {/* Component category list map */}

        {/* Component category list */}
        <ListItem
          button
          onClick={() => {
            setOpenAll(false);
            handleClick(group);
          }}
          dense
          className={classes.catagerie}
        >
          {(openAll || open[group]) ?? false ? (
            <ArrowDropDownRoundedIcon fontSize={"default"} color={"#00000"} />
          ) : (
            <ArrowRightRoundedIcon color={"#00000"} fontSize={"default"} />
          )}
          <ListItemText primary={group === "undefined" ? "Others" : group} />
        </ListItem>
        {/* Component category list END */}

        {/* Components List */}
        <Collapse
          in={(openAll || open[group]) ?? false}
          timeout="auto"
          unmountOnExit
          className={`${classes.collapseView} scroll-bar`}
        >
          {list.map((component, index) => {
            let Icon = Icons[component.componentName] ?? Icons["Template"];
            return (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  draggable={true}
                  key={index}
                  onDragStart={(e) => onDragStart(e, "components", component)}
                  onDragEnd={(e) => onDragEnd(e, "components")}
                  id={component.componentId}
                  style={{
                    cursor: "grab",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    margin: "10px",
                    border: "1.4px dashed #cbd5e1",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {Icon && <Icon style={{ color: "#64748b" }} />}

                    <Typography
                      variant={"body1"}
                      color={"inherit"}
                      style={{
                        fontSize: "15px",
                        color: "#0f172a",
                        width: "100%",
                        // wordBreak: "break-all",
                        marginLeft: "10px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {docorateName(component?.componentName)}
                    </Typography>
                  </div>
                  <DragIndicatorIcon
                    color={"action"}
                    style={{
                      fontSize: "14px",
                      marginTop: "5px",
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Collapse>
        {/* Components List END */}

        {/* Component category list Map END */}
      </List>
    );
  };

  const [groupedComponents, setGroupedComponents] = useState({});

  useEffect(() => {
    auth?.user?.UIcomponents && setComponentList([...auth?.user?.UIcomponents]);
    if (auth?.user?.UIcomponents) {
      const components = {};
      auth.user.UIcomponents.forEach((component) => {
        if (!components[component?.componentType]) {
          components[component?.componentType] = [];
        }
        components[component?.componentType].push(component);
      });
      setGroupedComponents(components);
    }
  }, [auth?.user?.UIcomponents]);

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      {/* Menu Title */}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        style={{ padding: "16px 16px 0px" }}
      >
        <Grid>
          <Typography
            style={{ paddingLeft: "10px", fontSize: "15px" }}
            variant={"h6"}
          >
            Component's
          </Typography>
        </Grid>
      </Grid>
      {/* Menu Title END */}

      {/* Component Type Tab */}

      <div
        className={classes.grow}
        style={{ textAlign: "center", padding: "10px" }}
      >
        <ToggleButtonGroup
          size={"small"}
          value={props.uiType}
          exclusive
          aria-label="text alignment"
          className={classes.tabButton}
        >
          <ToggleButton
            value={"builder"}
            aria-label="left"
            size={"small"}
            color={"primary"}
            classes={{
              root: tab === 0 ? classes.selecteTab : classes.selectedTab,
            }}
            onClick={() => tabChange(0)}
          >
            <Typography component={"p"} variant={"caption"}>
              Primary
            </Typography>
          </ToggleButton>
          <ToggleButton
            value={"flow"}
            aria-label="centered"
            size={"small"}
            classes={{
              root: tab === 1 ? classes.selecteTab : classes.selectedTab,
            }}
            onClick={() => tabChange(1)}
          >
            <Typography component={"p"} variant={"caption"}>
              Templates
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {/* Component Type Tab END */}

      {/* Component Libray List  */}

      {tab === 0 && (
        <>
          <Grid container item style={{ marginTop: "10px" }}>
            <Grid xs={12}>
              <WebAppPanel
                groupedComponents={groupedComponents}
                getgrouperComponentsList={getgrouperComponentsList}
                open={open}
                handleTabChange={handleTabChange}
                value={value}
              />
            </Grid>
          </Grid>
          {/* <div
            style={{
              padding: "16px 3px",
              borderRadius: "5px",
              overflow: "auto",
              margin: "0px 4px",
              maxHeight: "66vh",
            }}
          > */}
          {/* search components */}
          {/* {componentList.length > 0 &&
              componentList.map((component, index) => {
                let Icon = Icons[component.componentName] ?? Icons["Template"];
                if (true) {
                  return (
                    <div
                      draggable={true}
                      key={index}
                      onDragStart={(e) =>
                        onDragStart(e, "components", component)
                      }
                      onDragEnd={(e) => onDragEnd(e, "components")}
                      id={component.componentId}
                      style={{
                        cursor: "grab",
                        backgroundColor: "white",
                        boxShadow: "0px 0px 6px 3px #e2e8f0",
                        borderRadius: "3px",
                        width: "80px",
                        display: "grid",
                        minHeight: "90px",
                        padding: "6px 3px",
                        placeItems: "center",
                        margin: "0px auto 16px auto",
                        border: "1.4px dashed #cbd5e1",
                      }}
                    >
                      <div style={{ display: "grid", placeItems: "center" }}>
                        {Icon && (
                          <div
                            style={{
                              width: "44px",
                              height: "44px",
                              backgroundColor: "#e2e8f0",
                              display: "grid",
                              placeItems: "center",
                              borderRadius: "100%",
                              marginBottom: "4px",
                            }}
                          >
                            <Icon style={{ color: "#64748b" }} />
                          </div>
                        )}
                        <Typography
                          variant={"body1"}
                          color={"inherit"}
                          style={{
                            fontSize: "12px",
                            color: "#0f172a",
                            width: "100%",
                            // wordBreak: "break-all",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                          }}
                        >
                          {docorateName(component?.componentName)}
                        </Typography>
                      </div>
                      {component?.componentGroup && (
                        <Typography
                          variant={"body1"}
                          style={{
                            fontWeight: "500",
                            fontSize: "11px",
                            color: "#94a3b8",
                            margin: "2px auto",
                          }}
                        >
                          {component?.componentGroup}
                        </Typography>
                      )}
                    </div>
                  );
                }
                return null;
              })} */}

          {/* {Object.keys(groupedComponents).map((group) => {
              const components = groupedComponents[group].filter((comp) =>
                comp.supported.includes("web")
              );
              if (components.length > 0) {
                return getgrouperComponentsList(
                  components,
                  open,
                  "components",
                  group
                );
              }
              return null;
            })} */}
          {/* </div> */}
        </>
      )}
      {tab === 1 && (
        <Grid
          item
          style={{
            marginTop: "12px",
            width: "100%",
            height: "calc(100vh - 196px)",
            overflow: "auto",
          }}
        >
          {/* {getComponentsList(ComponentsTemplateLists, open, "template")} */}
          <ProjectTemplates />
        </Grid>
      )}
      {/* Component Libray List End */}
    </div>
  );
}
