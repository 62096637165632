import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, TextField, Fab, Avatar } from "@material-ui/core";
import Config from "./../config";
import { networkCall } from "../networkcall";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import Skeleton from "@material-ui/lab/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "./../config";
import { alertProps } from "../utils";
import { AlertContext } from "../contexts";

// const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid #ebebeb",
  },
  input: {
    display: "none",
  },
  button: {
    // color: "red",
    margin: 5,
  },
  progressLoading: {
    position: "absolute",
    top: "3px",
    left: "3px",
  },
}));

export function ImageUpload(props) {
  const classes = useStyles();
  const [imageList, setImageList] = React.useState([]);
  const [uploadedFile, setUploadedFile] = React.useState([]);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [imageUpload, setImageUpload] = React.useState("");
  const alert = React.useContext(AlertContext);
  // from components get funtion
  const getLanguageList = async () => {
    let list = await networkCall(Config.api_url + "/read_documents", "POST", {
      db_name: config.db_name,
      entity: "image_upload",
      filter: `image_upload.imageUploadId == '${localStorage.getItem(
        "metaDataId"
      )}'`,
      return_fields: "{image_upload}",
    });
    let AllList =
      list?.data?.result?.[list?.data?.result?.length - 1]?.image_upload
        ?.attributes?.uibuilder ?? [];
    if (AllList) {
      setImageList(AllList);
    }
  };
  console.log("image upload props", props, imageList);

  React.useLayoutEffect(() => {
    getLanguageList();
  }, []);

  const handleUploadClick = async (event) => {
    if (!event.target.files[0]) {
      return false;
    }
    var file = event.target.files[0];

    console.log(file, "file");
    if (!file.type.startsWith("image"))
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "Only images are supported",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });

    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    var data = new FormData();
    data.append("files", event.target.files[0]);

    reader.onloadend = function (e) {
      // let re = [reader.result];
      let array = uploadedFile;
      array.push("loading");
      // setUploadedFile(re);
      setUploadedFile(array);
      setUploadLoading(true);
    };

    let imageId = await networkCall(Config.image_url + "11", "POST", data);

    if (imageId.data.status === "200") {
      let uploadImageId = imageId?.data?.fileid;
      let array = uploadedFile;
      array.push(Config.image_url + uploadImageId);
      setUploadedFile(array.filter((val) => val !== "loading"));
      setUploadLoading(false);
      let imageListArray = imageList;
      imageListArray.push({ name: file?.name, fileid: uploadImageId });
      setImageList(imageListArray);
      upsetImagesToProject(imageListArray);
    } else {
      // let array = uploadedFile;
      // setUploadedFile(array.filter(val => val !== "loading"));
    }

    console.log(url); // Would see a path?
    // setUploadedFile(event.target.files[0]);
  };

  const upsetImagesToProject = async (list) => {
    let dataList = {
      db_name: config.db_name,
      entity: "image_upload",
      is_metadata: false,
      projectname: localStorage.getItem("projectName"),
      doc: {
        metadataId: localStorage.getItem("metaDataId"),
        imageUploadId: localStorage.getItem("metaDataId"),
        projectname: localStorage.getItem("projectName"),
        attributes: {
          uibuilder: list,
        },
      },
    };
    let data = JSON.stringify([dataList]);
    await networkCall(Config.api_url + "/upsert_document", "POST", data, {
      "Content-Type": "application/json",
    });
    console.log("new image upload mapped");
  };

  return (
    <div>
      <Autocomplete
        value={
          props?.parentArray
            ? {
                name:
                  imageList?.filter(
                    (list) =>
                      list?.fileid ===
                      props?.data?.[props?.id]?.[props.parentArray]?.[
                        props.parentIndex
                      ]?.[props?.val?.key]?.replaceAll(Config.image_url, "")
                  )?.[0]?.name ?? "",
              }
            : {
                name:
                  imageList?.filter(
                    (list) =>
                      list?.fileid ===
                      props?.data?.[props?.id]?.[props?.val?.key]?.replaceAll(
                        Config.image_url,
                        ""
                      )
                  )?.[0]?.name ?? "",
              }
        }
        // defaultValue={{
        //   name:
        //     imageList?.filter(
        //       (list) =>
        //         list?.fileid ===
        //         props?.data?.[props?.id]?.[props?.val?.key]?.replaceAll(
        //           Config.image_url,
        //           ""
        //         )
        //     )?.[0]?.name ?? "",
        // }}
        // freeSolo
        disabled={uploadLoading}
        id="combo-box-demo"
        options={imageList}
        getOptionLabel={(option) => option.name}
        renderOption={(option) => (
          <React.Fragment>
            <Grid item>
              <Typography
                style={{ fontSize: "16px", textTransform: "capitalize" }}
                variant={"body2"}
                color={"textPrimary"}
              >
                {option.name}
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            size={"small"}
            margin={"dense"}
            {...params}
            label={uploadLoading ? "Uploading..." : props?.val?.name}
            variant="outlined"
          />
        )}
        onChange={(e, v) =>
          props.handleChange(
            props?.id,
            props?.val?.key,
            v?.fileid ? Config.image_url + v?.fileid : v,
            null,
            null,
            props?.parentIndex,
            props?.parentArray
          )
        }
        // filterOptions={(options, params) => {
        //     const filtered = filter(options, params);

        //         // Suggest the creation of a new value
        //         if (params.inputValue !== "") {
        //             filtered.push({
        //                 id: params,
        //                 name: params.inputValue,
        //             });
        //         }

        //     return filtered;
        //     }
        // }
      />
      <div>
        <React.Fragment>
          <Grid container justify={"flex-end"} alignItems={"center"}>
            {uploadedFile?.map((url) => {
              return (
                <>
                  {url === "loading" && uploadLoading ? (
                    <Skeleton
                      variant={"rect"}
                      width={40}
                      height={40}
                      style={{ margin: "5px" }}
                    />
                  ) : (
                    <Avatar
                      alt="Travis Howard"
                      style={{ backgroundColor: "#0000001c", margin: "5px" }}
                      variant={"square"}
                      src={url}
                    />
                  )}
                </>
              );
            })}
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              // multiple
              type="file"
              onChange={(e) => handleUploadClick(e)}
              disabled={uploadLoading}
            />
            <label
              htmlFor="contained-button-file"
              style={{ position: "relative" }}
            >
              <Fab size={"small"} component="span" className={classes.button}>
                <AddPhotoAlternateIcon fontSize={"small"} color={"primary"} />
              </Fab>
              {uploadLoading && (
                <CircularProgress
                  size={44}
                  className={classes.progressLoading}
                  thickness={1.5}
                />
              )}
            </label>
          </Grid>
        </React.Fragment>
      </div>
    </div>
  );
}
