import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useLayoutEffect,
} from "react";
import { parser } from "posthtml-parser";
import postcss from "postcss";
import postcssJs from "postcss-js";
import * as All from "qdm-component-library";
import { Typography, Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DialogContext, AlertContext, AuthContext } from "../../../../contexts";
import { alertProps } from "../../../../utils";
import {
  makeid,
  makeidWithoutCaps,
} from "../../../../components/commonFunction";
import { networkCall } from "../../../../networkcall";
import Config from "../../../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import isEqual from "react-fast-compare";
import { ComponentsLists } from "../jsonDatas";
import { FileCard, UploadButton } from "../components";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";
import { green } from "@material-ui/core/colors";

import JSZip from "jszip";
import config from "../../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export function MockUI(props) {
  const { properties, ...remainingproperties } = props;
  return (
    <div
      style={{ display: "grid", placeItems: "center" }}
      {...remainingproperties}
    >
      <Typography variant="h5" style={{ textAlign: "center" }}>
        Import MockUI
      </Typography>
    </div>
  );
}

//chart component property renderer
export function MockUIPropertiesRenderer(props) {
  const { data, onClose } = props;
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const classes = useStyles();

  const [uploadingStatus, setUploadingStatus] = useState();
  const [uploaded, setUploaded] = useState();
  const [converting, setConverting] = useReducer((state) => {
    return !state;
  }, false);

  const htmlRef = useRef(null);
  const cssRef = useRef([]);

  const imagesNamesRef = useRef([]);
  const fontNamesRef = useRef([]);
  const cssNamesRef = useRef([]);
  const projectImagesList = useRef([]);
  const projectFontsList = useRef([]);

  const jsonRef = useRef(null);
  const zipRef = useRef();

  const importZip = (evt) => {
    var files = evt.target.files;
    for (var i = 0; i < files.length; i++) {
      handleFile(files[i]);
    }
  };
  function handleFile(f) {
    JSZip.loadAsync(f).then(async function (zip) {
      zipRef.current = zip;
      const htmlFilesLength = Object.keys(zip.files).filter((file) =>
        file.endsWith(".html")
      );
      if (htmlFilesLength.length > 1) {
        return alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "Multiple HTML files are detected. Only one HTML File is supported",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      }
      if (htmlFilesLength.length === 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "Please upload a valid mock file",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      if (zip.files) {
        zip.forEach((relativePath, file) => {
          if (!file.dir && file.name.endsWith(".html")) {
            setUploadingStatus("Reading html file");
            zip
              .file(file.name)
              .async("string")
              .then(async (text) => {
                const converted = parser(text);
                htmlRef.current = converted;
                htmlHandler();
                setUploaded({
                  ["html"]: true,
                });
              });
          }
        });
      }
    });
  }
  const getCSSFileNames = () => {
    const cssFileNames = [];
    const htmlCopy = JSON.parse(JSON.stringify(htmlRef.current));
    htmlCopy.forEach(function iter(htmlTag) {
      if (htmlTag?.tag === "head") {
        htmlTag.content.forEach((headTag) => {
          if (headTag?.tag === "link" && headTag?.attrs.type === "text/css") {
            const cssfilehref = headTag.attrs.href;
            const cssfileHrefArray = cssfilehref.split("/");
            const cssFileName = cssfileHrefArray.filter((str) =>
              str.endsWith(".css")
            );
            cssFileNames.push(cssFileName[0]);
          }
        });
      } else {
        htmlTag?.content?.length > 0 && htmlTag.content.forEach(iter);
      }
    });
    return cssFileNames;
  };

  const imageNameFinder = (imagePath) => {
    const imageNameWithPath = imagePath;
    const splitted = imageNameWithPath.split("/");
    const srcImg = splitted[splitted?.length - 1];
    const srcImage = srcImg.replace(")", "");
    if (
      srcImage.endsWith(".png") ||
      srcImage.endsWith(".jpg") ||
      srcImage.endsWith(".jpeg") ||
      srcImage.endsWith(".webp") ||
      srcImage.endsWith(".svg") ||
      srcImage.endsWith(".gif")
    ) {
      const uniqueOrNot = imagesNamesRef.current.some(
        (img) => img.imageName === srcImage
      );
      if (!uniqueOrNot) {
        imagesNamesRef.current = [
          ...imagesNamesRef.current,
          {
            imageName: srcImage,
            imported: false,
          },
        ];
      }
      return srcImage;
    }
  };

  const fontNameFinder = (fontPath) => {
    const fontString = `${fontPath}`;
    const fontPathWithPath = fontString.split("format");
    const splitted = fontPathWithPath[0].split("/");
    const srcFontName = splitted[splitted?.length - 1].trim();
    const srcFont = srcFontName.replace('")', "").trim();
    const uniqueOrNot = fontNamesRef.current.some(
      (fontName) => fontName === srcFont
    );
    if (!uniqueOrNot) {
      fontNamesRef.current = [
        ...fontNamesRef.current,
        {
          fontName: srcFont,
          imported: false,
        },
      ];
    }
    return fontPathWithPath[1]
      ? srcFont.concat(" ", `format${fontPathWithPath[1]}`)
      : srcFont;
  };

  const htmlAttributesParser = (htmlTagObj) => {
    let inlineStyles = {};
    let properties = {};
    let attributes = {};
    const attributesList = [
      "class",
      "id",
      "name",
      "action",
      "method",
      "required",
      "type",
      "value",
    ];
    Object.keys(htmlTagObj?.attrs ?? "").length > 0 &&
      Object.entries(htmlTagObj?.attrs).forEach((obj) => {
        if (obj[0] === "style") {
          const root = postcss.parse(obj[1]);
          const style = postcssJs.objectify(root);
          inlineStyles = {
            ...style,
          };
        } else if (attributesList.includes(obj[0])) {
          attributes = {
            ...attributes,
            [obj[0]]: obj[1],
          };
        } else if (htmlTagObj?.tag === "img" && obj[0] === "src") {
          const image = imageNameFinder(obj[1]);
          properties = {
            ...properties,
            [obj[0]]: image,
            alt: image,
          };
        } else {
          properties = {
            ...properties,
            [obj[0]]: obj[1],
          };
        }
      });
    let baseStructure = {
      type: "component",
      component: htmlTagObj.tag,
      component_type: "html",
      name: htmlTagObj.tag,
      id: makeid(),
      child: [],
      properties,
      inlineStyles,
      attributes,
    };
    return baseStructure;
  };

  function htmlHandler() {
    //get css classNames
    const cssFileNames = getCSSFileNames();
    const result = cssFileNames.reduce((arr, cssName) => {
      const newCSS = { cssName: cssName, imported: false };
      return [...arr, newCSS];
    }, []);
    cssNamesRef.current = result;
    //find body tag & create a json structure
    htmlRef.current.forEach(function iter(htmlTag) {
      if (htmlTag.tag === "body") {
        const bodyComponent = htmlAttributesParser(htmlTag);
        bodyComponent["component"] = "div";
        bodyComponent["name"] = "div";
        bodyComponent["properties"] = {};
        bodyComponent["attributes"] = {};
        bodyComponent["inlineStyles"] = {};
        jsonRef.current = bodyComponent;
        if (htmlTag?.content?.length > 0) {
          htmlTag.content.reduce(function reducer(obj, bodyElement) {
            let bodyElementComponent;
            let stringContent = false;
            if (
              !bodyElement?.tag &&
              typeof bodyElement === "string" &&
              bodyElement.trim().length > 0 &&
              obj?.properties
            ) {
              obj.properties = {
                ...obj.properties,
                name: bodyElement.trim(),
              };
            }
            if (bodyElement?.tag && bodyElement?.tag !== "script") {
              bodyElementComponent = htmlAttributesParser(bodyElement);
              if (bodyElement?.content?.length > 0) {
                const inlineTags = ["br", "i", "em", "b"];
                const result = bodyElement?.content.filter((eachContent) => {
                  if (
                    typeof eachContent === "object" &&
                    inlineTags.includes(eachContent?.tag)
                  ) {
                    return true;
                  }
                });
                if (result.length > 0) {
                  const children = bodyElement.content.reduce(
                    (json, currentContent) => {
                      let baseStructure = {
                        type: "component",
                        component:
                          typeof currentContent === "object"
                            ? currentContent.tag
                            : "span",
                        component_type: "html",
                        name:
                          typeof currentContent === "object"
                            ? currentContent.tag
                            : "span",
                        id: makeid(),
                        child: [],
                        properties: {},
                        inlineStyles: {},
                        attributes: {},
                      };
                      if (typeof currentContent === "string") {
                        baseStructure["properties"] = {
                          name: currentContent.trim(),
                        };
                      }
                      return [...json, baseStructure];
                    },
                    []
                  );
                  bodyElementComponent.child = children;
                  stringContent = true;
                }
              }

              obj.child.push(bodyElementComponent);
            }
            bodyElement?.content?.length > 0 &&
              !stringContent &&
              bodyElement.content.reduce(reducer, bodyElementComponent);
            return obj;
          }, bodyComponent);
        }
      } else {
        htmlTag?.content?.length > 0 && htmlTag.content.forEach(iter);
      }
    });
  }

  function cssVariableNameReplacer(oldName, newName) {
    cssRef.current.forEach((cssObj, index, arr) => {
      Object.entries(cssObj).forEach((css) => {
        let cssProps = css[1];
        Object.entries(cssProps).forEach((prop) => {
          let key = prop[0];
          let value = prop[1];
          if (typeof value === "string" && value.includes(oldName)) {
            value = value.replace(oldName, newName);
          }
          cssProps = {
            ...cssProps,
            [key]: value,
          };
        });
        arr[index] = {
          [css[0]]: cssProps,
        };
      });
    });
  }

  function cssImageFinder() {
    const cssFile = cssRef.current;
    cssFile.forEach((cssObj, index) => {
      Object.entries(cssObj).forEach((css) => {
        if (css[0] === ":root") {
          //assign unique variable names for font size names & replace
          //the new varibale in all the css objects where it used
          let newRootVariables = {};
          Object.entries(css[1]).forEach((prop) => {
            let newName = prop[0].concat(`-${makeidWithoutCaps()}`);
            cssVariableNameReplacer(prop[0], newName);
            newRootVariables = {
              [newName]: prop[1],
              ...newRootVariables,
            };
          });
          //move position of the root to top
          // const rootObj = cssFile[0][":root"];
          // if (rootObj) return;
          cssFile.splice(index, 1);
          cssFile.splice(0, 0, { ":root": newRootVariables });
        }
        //Detect background image and add it to imagesNamesRef
        if (css[1]) {
          Object.entries(css[1]).map((cssObj) => {
            if (cssObj[0] === "backgroundImage") {
              if (cssObj[1].includes("http")) return null;
              const srcImage = imageNameFinder(cssObj[1]);
              let newCssObj = {
                [css[0]]: {
                  ...css[1],
                  backgroundImage: srcImage,
                },
              };
              cssFile[index] = newCssObj;
            }
          });
        }
        // if the classname is ".screen a" remove that object
        if (css[0] === ".screen a") {
          cssFile.splice(index, 1);
        }
      });
    });
  }

  function cssFontFinder() {
    const cssFile = cssRef.current;
    cssFile.forEach((cssObj, index) => {
      Object.entries(cssObj).forEach((css) => {
        if (css[0] === "@font-face") {
          const fontFiles = Array.isArray(css[1]) ? css[1] : [css[1]];
          fontFiles.forEach((eachCssObj, cssIndex, arr) => {
            // if (eachCssObj?.src?.includes("http")) return null;
            const srcFont = fontNameFinder(eachCssObj?.src);
            arr[cssIndex] = {
              ...eachCssObj,
              src: srcFont,
            };
          });
          let newCssObj = {
            [css[0]]: css[1],
          };
          cssFile[index] = newCssObj;
        }
      });
    });
  }

  const uploadFile = async (file) => {
    const data = new FormData();
    data.append("files", file);
    const downloadURL = await networkCall(Config.image_url + "11", "POST", data)
      .then((response) => {
        if (response.data.status === "200") {
          return response.data;
        } else {
        }
      })
      .catch((error) => alert(error));
    return downloadURL;
  };

  const imageSrcReplacer = (imageName, imageLink) => {
    //replace in html json
    jsonRef.current.child.forEach(function iter(tag) {
      if (tag.component === "img" && tag.properties["src"] === imageName) {
        tag.properties = {
          ...tag.properties,
          src: imageLink,
        };
      }
      tag?.child.length > 0 && tag.child.forEach(iter);
    });
    //replace in cssfile
    const cssFile = cssRef.current;
    cssFile.forEach((cssObj, index) => {
      Object.entries(cssObj).forEach((css) => {
        if (css[1]) {
          Object.entries(css[1]).map((cssObj) => {
            if (cssObj[0] === "backgroundImage" && cssObj[1] === imageName) {
              let newCssObj = {
                [css[0]]: {
                  ...css[1],
                  backgroundImage: `url(${imageLink})`,
                },
              };
              cssFile[index] = newCssObj;
            }
          });
        }
      });
    });
  };
  const fontSrcReplacer = (fontName, fontLink) => {
    //replace in cssfile
    const cssFile = cssRef.current;
    cssFile.forEach((cssObj, index) => {
      Object.entries(cssObj).forEach((css) => {
        if (css[0] === "@font-face") {
          const fontFiles = Array.isArray(css[1]) ? css[1] : [css[1]];
          fontFiles.forEach((eachCssObj, cssIndex, arr) => {
            let src = eachCssObj?.src;
            let newSrc = src.replace(fontName, `url(${fontLink})`);
            arr[cssIndex] = {
              ...eachCssObj,
              src: newSrc,
            };
          });
          let newCssObj = {
            [css[0]]: css[1],
          };
          cssFile[index] = newCssObj;
        }
      });
    });
  };

  const convertCSSObjToCSS = async () => {
    const cssFile = cssRef.current;
    const arrToObj = cssFile.reduce((css, obj) => {
      return { ...css, ...obj };
    }, {});
    const cssConverted = await postcss()
      .process(arrToObj, { parser: postcssJs })
      .then((result) => {
        return result.css;
      });
    return cssConverted;
  };

  const uploadCSS = async (data) => {
    let blob = new Blob([data], { type: "text/css" });
    let fileOfBlob = new File([blob], `style-${makeid()}.css`);
    const cssUrl = await uploadFile(fileOfBlob);
    const cssLink = Config.image_url + cssUrl.fileid;
    return cssLink;
  };

  const upsetFontsToProject = async () => {
    let dataList = {
      db_name: config.db_name,
      entity: "font_upload",
      is_metadata: false,
      projectname: localStorage.getItem("projectName"),
      doc: {
        metadataId: localStorage.getItem("metaDataId"),
        fontUploadId: localStorage.getItem("UidesignerModelerid"),
        projectname: localStorage.getItem("projectName"),
        attributes: {
          uibuilder: projectFontsList.current,
        },
      },
    };
    let data = JSON.stringify([dataList]);
    await networkCall(Config.api_url + "/upsert_document", "POST", data, {
      "Content-Type": "application/json",
    });
  };

  const upsetImagesToProject = async () => {
    let dataList = {
      db_name: config.db_name,
      entity: "image_upload",
      is_metadata: false,
      projectname: localStorage.getItem("projectName"),
      doc: {
        metadataId: localStorage.getItem("metaDataId"),
        imageUploadId: localStorage.getItem("UidesignerModelerid"),
        projectname: localStorage.getItem("projectName"),
        attributes: {
          uibuilder: projectImagesList.current,
        },
      },
    };
    let data = JSON.stringify([dataList]);
    await networkCall(Config.api_url + "/upsert_document", "POST", data, {
      "Content-Type": "application/json",
    });
  };

  const qdmComponentReplacer = () => {
    const finalJSON = [jsonRef.current];
    const cssFile = cssRef.current;

    finalJSON.forEach(function iter(component, index, array) {
      if (component?.attributes?.class?.includes("qdm")) {
        //Get the component name from the className
        const classNameSplitted = component?.attributes?.class.split("-");
        const [comp] = classNameSplitted.slice(1, 2);
        let compName;
        ComponentsLists.map((category) => {
          const hasComponent = category.list.some((component) => {
            if (component.name.toLowerCase() === comp) {
              compName = component.name;
              return true;
            }
          });
          if (hasComponent) {
            if (compName === "List") {
              let listStyle = {
                margin: "0px 10px 10px 0px",
              };
              //cut & get width & height from the css classname
              cssFile.forEach((cssObj, index) => {
                Object.entries(cssObj).forEach((css) => {
                  const [splitted] = component?.attributes?.class.split(" ");
                  const listClassNames = css[0].split(" ");
                  if (listClassNames.some((cn) => cn === `.${splitted}`)) {
                    listStyle["width"] = css[1].width;
                    listStyle["height"] = css[1].height;
                    css[1]["display"] = "flex";
                    css[1]["flexWrap"] = "wrap";
                    css[1]["alignContent"] = "flex-start";
                    css[1]["width"] = css[1].width.includes("calc")
                      ? "100%"
                      : "auto";
                    css[1]["overflow"] = "auto";
                    delete css[1].height;
                  }
                });
              });
              //put that W&H to thew first child css and removed top & left
              component.child.map((childComp) => {
                if (childComp?.attributes?.class.includes("listitem")) {
                  cssFile.forEach((cssObj, index) => {
                    Object.entries(cssObj).forEach((css) => {
                      const [splitted] =
                        childComp?.attributes?.class.split(" ");
                      const listitemClassNames = css[0].split(" ");
                      if (
                        listitemClassNames.some((cn) => cn === `.${splitted}`)
                      ) {
                        delete css[1].height;
                        delete css[1].width;
                        delete css[1].top;
                        delete css[1].left;
                        css[1]["width"] = listStyle?.width;
                        css[1]["height"] = listStyle?.height;
                        css[1]["margin"] = listStyle?.margin;
                      }
                    });
                  });
                }
              });
              return null;
            }
            //create a component schema with the component name from the className
            let compSchema = {
              type: "component",
              component: compName,
              component_type: "qdm",
              name: compName,
              id: makeid(),
              child: compName === "Form" ? component?.child : [],
              properties: {},
              inlineStyles: {},
              attributes: {},
            };

            console.log(component, "component");
            // //get the classname of the component bg
            // const childComp = component.child.find((childComp) =>
            //   childComp?.attributes?.class.includes(comp + "bg")
            // );
            // compSchema["attributes"] = {
            //   ...(childComp?.attributes ?? ""),
            // };
            //Get the default properties from the components library
            const inputComp = component.child.find(
              (childComp) => childComp?.component === "input"
            );
            compSchema["properties"] = {
              ...(inputComp?.properties ?? ""),
            };
            //insert the component schema to the child
            if (component.component === "img" || compName === "Form") {
              compSchema["attributes"] = component.attributes;
              array[index] = compSchema;
            } else {
              component["child"] = [compSchema];
            }
          }
        });
      }

      if (component?.attributes?.class?.includes("spl")) {
        //Handle spl elements
      }
      if (component?.child?.length > 0) {
        component.child.forEach(iter);
      }
    });
  };

  const customComponentReplacer = () => {
    const finalJSON = [jsonRef.current];
    finalJSON.forEach(function iter(component, index, array) {
      if (component?.attributes?.class?.includes("cc-")) {
        //place the customcode component as a child
        component.child = [
          {
            component: "CustomCode",
            component_type: "dynamic",
            id: makeid(),
            name: "CustomCode",
            properties: {},
            type: "component",
          },
        ];
      }
      if (component?.child?.length > 0) {
        component.child.forEach(iter);
      }
    });
  };

  const handleConvert = () => {
    setConverting();
    const finishConversion = async () => {
      qdmComponentReplacer();
      customComponentReplacer();
      const css = await convertCSSObjToCSS();
      const cssUrl = await uploadCSS(css);
      await upsetImagesToProject();
      await upsetFontsToProject();
      const selectedScreenId = auth.user.selectedScreen;
      auth.user.AllScreenList.forEach((screen, index, arr) => {
        if (screen.id === selectedScreenId) {
          // const cssArr = screen[css] ?? [];
          arr[index] = {
            ...screen,
            css: cssUrl,
          };
        }
      });
      auth.user.AllScreenList.forEach((screen) => {
        if (screen.id === selectedScreenId) {
          screen.render.child.forEach(function iter(component, index, array) {
            if (component.id === data.id) {
              array[index] = jsonRef.current;
            } else {
              if (component?.child?.length > 0) {
                component.child.forEach(iter);
              }
            }
          });
        }
      });
      auth.setAuth({
        ...auth.user,
        isEdited: true,
      });
      var head = document.head;
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = cssUrl;
      head.appendChild(link);
      alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.success,
        msg: "Converted successfully",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
      // onClose("right", false);
    };
    if (
      (imagesNamesRef.current.length > 0 && uploaded?.image) ||
      (fontNamesRef.current.length === 0 &&
        imagesNamesRef.current.length === 0 &&
        uploaded?.css)
    ) {
      finishConversion();
    }
  };

  useEffect(() => {
    const zip = zipRef.current;
    uploaded?.html &&
      zip.forEach((relativePath, file) => {
        if (!file.dir && file.name.endsWith(".css")) {
          const cssFileNames = file.name.split("/");
          const cssFileName = cssFileNames[cssFileNames.length - 1];
          if (!cssNamesRef.current.some((css) => css.cssName === cssFileName)) {
            return;
          }
          zip
            .file(file.name)
            .async("string")
            .then(async (text) => {
              setUploadingStatus(`Reading css file ${cssFileName}`);
              const removeComments = text.replace(
                /\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm,
                "$1"
              );
              const root = postcss.parse(removeComments);
              const converted = postcssJs.objectify(root);
              const objToArr = Object.entries(converted).reduce((arr, css) => {
                return [
                  ...arr,
                  {
                    [css[0]]: css[1],
                  },
                ];
              }, []);
              cssRef.current = [...cssRef.current, ...objToArr];
              cssNamesRef.current.forEach((css) => {
                if (css.cssName === cssFileName) {
                  css["imported"] = true;
                }
              });
              if (cssNamesRef.current.every((css) => css.imported === true)) {
                cssImageFinder();
                cssFontFinder();
                setUploaded({
                  ["css"]: true,
                });
              }
            });
        }
      });
  }, [uploaded?.html]);

  //font Upload
  useEffect(() => {
    const zip = zipRef.current;
    uploaded?.css &&
      fontNamesRef.current?.length > 0 &&
      zip.forEach((relativePath, file) => {
        if (
          !file.dir &&
          (file.name.endsWith(".ttf") ||
            file.name.endsWith(".woff") ||
            file.name.endsWith(".woff2") ||
            file.name.endsWith(".otf"))
        ) {
          const fontFileName = file.name.split("/");
          if (
            !fontNamesRef.current.some(
              (font) => font.fontName === fontFileName[fontFileName.length - 1]
            )
          ) {
            return;
          }
          zip
            .file(file.name)
            .async("blob")
            .then(async (blob) => {
              const fileName = file.name.split("/");
              const fontName = fileName[fileName.length - 1];
              setUploadingStatus(`Uploading font ${fontName}`);
              var files = new File([blob], fontName);
              const isAlreadypresent = projectFontsList.current.find(
                (font) => font.name === fontName
              );
              let fontObj, fontLink;
              if (isAlreadypresent) {
                fontObj = isAlreadypresent;
                fontLink = Config.image_url + fontObj.fileid;
              } else {
                fontObj = await uploadFile(files);
                fontLink = Config.image_url + fontObj.fileid;
                projectFontsList.current = [
                  ...projectFontsList.current,
                  {
                    name: fontName,
                    fileid: fontObj.fileid,
                  },
                ];
              }
              fontSrcReplacer(fontName, fontLink);
              fontNamesRef.current.forEach((font) => {
                if (font.fontName === fontName) {
                  font["imported"] = true;
                }
              });
              if (
                fontNamesRef.current.every((font) => font.imported === true)
              ) {
                setUploaded({
                  ["font"]: true,
                });
              }
            });
        }
      });
  }, [uploaded]);

  //image upload
  useEffect(() => {
    const zip = zipRef.current;
    if (
      (fontNamesRef.current?.length > 0 && uploaded?.font) ||
      (uploaded?.css && fontNamesRef.current?.length === 0)
    ) {
      zip.forEach((relativePath, file) => {
        if (
          !file.dir &&
          (file.name.endsWith(".png") ||
            file.name.endsWith(".jpg") ||
            file.name.endsWith(".jpeg") ||
            file.name.endsWith(".webp") ||
            file.name.endsWith(".svg") ||
            file.name.endsWith(".gif"))
        ) {
          const imageFileName = file.name.split("/");
          const imageName = imageFileName[imageFileName.length - 1];
          if (
            !imagesNamesRef.current.some(
              (image) => image.imageName === imageName
            )
          ) {
            return;
          }
          zip
            .file(file.name)
            .async("blob")
            .then(async (blob) => {
              const imageFileName = file.name.split("/");
              const imageName = imageFileName[imageFileName.length - 1];
              setUploadingStatus(`Uploading image ${imageName}`);
              var files = new File([blob], imageName);
              const isAlreadypresent = projectImagesList.current.find(
                (image) => image.name === imageName
              );
              let imagObj, imgLink;
              if (isAlreadypresent) {
                imagObj = isAlreadypresent;
                imgLink = Config.image_url + imagObj.fileid;
              } else {
                imagObj = await uploadFile(files);
                imgLink = Config.image_url + imagObj.fileid;
                projectImagesList.current = [
                  ...projectImagesList.current,
                  {
                    name: imageName,
                    fileid: imagObj.fileid,
                  },
                ];
              }
              imageSrcReplacer(imageName, imgLink);
              imagesNamesRef.current.forEach((image) => {
                if (image.imageName === imageName) {
                  image["imported"] = true;
                }
              });
              if (
                imagesNamesRef.current.every((image) => image.imported === true)
              ) {
                setUploaded({
                  ["image"]: true,
                });
                setUploadingStatus("All files read and uploaded successfully");
              }
            });
        }
      });
    }
  }, [uploaded]);

  const getImagesList = async () => {
    let list = await networkCall(Config.api_url + "/read_documents", "POST", {
      db_name: config.db_name,
      entity: "image_upload",
      filter: `image_upload.imageUploadId == '${localStorage.getItem(
        "UidesignerModelerid"
      )}'`,
      sort: "TO_NUMBER(image_upload._key) DESC ",
      limit: {
        count: 1,
      },
      return_fields: "{image_upload}",
    });
    let AllList =
      list?.data?.result?.[list?.data?.result?.length - 1]?.image_upload
        ?.attributes?.uibuilder ?? [];
    if (AllList) {
      projectImagesList.current = AllList;
    }
  };
  const getFontsList = async () => {
    let list = await networkCall(Config.api_url + "/read_documents", "POST", {
      db_name: config.db_name,
      entity: "font_upload",
      filter: `font_upload.fontUploadId == '${localStorage.getItem(
        "UidesignerModelerid"
      )}'`,
      sort: "TO_NUMBER(font_upload._key) DESC ",
      limit: {
        count: 1,
      },
      return_fields: "{font_upload}",
    });
    let AllList =
      list?.data?.result?.[list?.data?.result?.length - 1]?.font_upload
        ?.attributes?.uibuilder ?? [];
    if (AllList) {
      projectFontsList.current = AllList;
    }
  };

  useLayoutEffect(() => {
    getImagesList();
    getFontsList();
  }, []);

  return (
    <div style={{ width: "100%", padding: "12px 10px" }}>
      <div
        style={{
          display: "grid",
          width: "100%",
          padding: "24px",
          borderRadius: "8px",
          backgroundColor: "#F9FAFB",
          placeItems: "center",
          border: "1px solid #E5E7EB",
        }}
      >
        <Typography
          variant="subtitle2"
          style={{
            margin: "0px 0px 16px 0px",
            textAlign: "center",
            color: "#6B7280",
          }}
        >
          Import Mock Files
        </Typography>
        <div
          style={{
            backgroundColor: "white",
            width: "60px",
            height: "60px",
            borderRadius: "100%",
            padding: "12px",
            display: "grid",
            placeItems: "center",
            margin: "0px 0px 32px 0px",
          }}
        >
          <FileCopyIcon style={{ fontSize: "30px", color: "#94A3B8" }} />
        </div>
        {!uploadingStatus && (
          <UploadButton
            handleChange={(event) => {
              importZip(event);
            }}
            accept=".zip"
            multiple={false}
            name="Zip File"
          />
        )}
        {uploadingStatus && (
          <div
            style={{
              backgroundColor: "white",
              padding: "10px 24px",
              boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
              display: "grid",
              gridTemplateColumns: "10% 90%",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            {(imagesNamesRef.current.length > 0 && uploaded?.image) ||
            (fontNamesRef.current.length === 0 &&
              imagesNamesRef.current.length === 0 &&
              uploaded?.css) ? (
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "100%",
                  backgroundColor: "#F0FDF4",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <CheckIcon style={{ color: "#22C55E", fontSize: 16 }} />
              </div>
            ) : (
              <CircularProgress size={18} />
            )}
            <Typography
              variant="body2"
              style={{
                color: "#6B7280",
                marginLeft: "12px",
              }}
            >
              {uploadingStatus}
            </Typography>
          </div>
        )}
      </div>
      <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
        {((imagesNamesRef.current.length > 0 && uploaded?.image) ||
          (fontNamesRef.current.length === 0 &&
            imagesNamesRef.current.length === 0 &&
            uploaded?.css)) && (
          <Button
            style={{ margin: "42px", width: "100%" }}
            variant="contained"
            color="primary"
            onClick={handleConvert}
            disabled={converting}
            disableElevation
            size="medium"
            startIcon={
              converting && (
                <CircularProgress
                  size={16}
                  color="inherit"
                  className={classes.buttonProgress}
                />
              )
            }
          >
            {!converting ? "Convert" : "Converting..."}
          </Button>
        )}
      </div>
    </div>
  );
}
