// /**
//  * @author Kumaravel Pazhani
//  * @email kumaravel@qdmplatforms.com
//  * @create 12/14/202
//  * @modify 12/14/2020
//  * @desc Exporting all the components from /src/components
//  */
// ​
import React from "react";
import {
  Divider,
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Popover,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { withAlertAndDialog } from "../HOCs";
import { withStyles } from "@material-ui/core/styles";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import { getComponentCode, getComponentMeta } from "./commonFunction";

const getPercentage = {
  0.1: "10%",
  0.2: "20%",
  0.3: "30%",
  0.4: "40%",
  0.5: "50%",
  0.6: "60%",
  0.7: "70%",
  0.8: "80%",
  0.9: "90%",
  1: "100%",
  1.1: "110%",
  1.2: "120%",
};

const layoutMenu = [
  {
    name: "Web View",
    id: 1,
    icon: "",
  },
  {
    name: "Mobile View",
    id: 2,
    icon: "",
  },
];

const useStyles = (theme) => ({
  paperCard: {
    position: "absolute",
    bottom: "17px",
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "21fr 6px 1fr 6px 1fr 6px 1fr 6px 0fr",
    width: "52%",
    left: "24%",
    padding: "8px 20px 8px 20px",
    textAlign: "center",
    zIndex: 2,
  },
  textAlign: {
    display: "grid",
    gridTemplateColumns: "33px 1fr 33px",
    cursor: "pointer",
  },
  textAlignLeft: {
    marginRight: "6px",
  },
  selectedMenu: {
    color: "#3f51b5",
    backgroundColor: "#3f51b514",
  },
  zoomText: {
    color: theme.palette.text.primary + " !important",
  },
});
class BottomCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      layout: 1,
      fullScreen: false,
      codePanel: {},
    };
  }

  componentDidMount() {
    if (this.props.layoutType) {
      this.setState({ layout: this.props.layoutType });
    }
  }

  handleClick = (event) => {
    this.setState({ ...this.state, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ ...this.state, anchorEl: null });
  };

  selectLayoutType = (e, type) => {
    this.setState({
      ...this.state,
      layout: type.id,
      anchorEl: null,
    });
    this.props.changeLayout(type.id);
  };

  getLayoutIcon = (type) => {
    switch (type) {
      case 1:
        return (
          <DesktopWindowsIcon
            fontSize={"small"}
            color={"action"}
            style={{ marginTop: "4px", padding: "2px" }}
          />
        );
      case 2:
        return (
          <PhoneIphoneIcon
            fontSize={"small"}
            color={"action"}
            style={{ marginTop: "4px", padding: "2px" }}
          />
        );
      default:
        return (
          <DesktopWindowsIcon
            fontSize={"small"}
            color={"action"}
            style={{ marginTop: "4px", padding: "2px" }}
          />
        );
    }
  };

  fullScreenMode = (type) => {
    var elem = document.getElementById("fullViewMode");
    var mainLayout = document.getElementById("mainLayer");
    function openFullscreen() {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
    // close fullScreen
    function closeFullscreen() {
      if (document && document?.exitFullscreen) {
        document.exitFullscreen();
      } else if (document && document?.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document && document?.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
    if (type === "exit") {
      closeFullscreen();
      mainLayout.style.height = "calc(100vh - 92px)";
    } else {
      openFullscreen();
      mainLayout.style.height = "calc(100vh - 49px)";
    }
    this.setState({
      ...this.state,
      fullScreen: !this.state.fullScreen,
    });
  };

  setCodePanel = async (codePanel) => {
    debugger;
    let { id, screenId } = this.props.auth.user.componentAttr;
    if (id && screenId) {
      this.props.auth.setAuth({
        ...this.props.auth.user,
        codePanel: {
          ...codePanel,
          loading: true,
        },
      });

      let data = await getComponentCode(
        id,
        screenId,
        getComponentMeta(this.props.auth, screenId, id)
      );
      codePanel = {
        ...codePanel,
        metaData: data?.metaData,
        sourceCode: data?.sourceCode,
        status: !codePanel.status,
        loading: false,
      };
      this.props.auth.setAuth({
        ...this.props.auth.user,
        codePanel,
      });
    } else {
      this.props.alert.setSnack({
        ...this.props.alert,
        open: true,
        severity: "error",
        msg: "please select proper element",
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const { codePanel } = this.props.auth.user;
    let { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Paper className={classes.paperCard}>
            <Grid>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() => this.setCodePanel(codePanel)}
                    checked={codePanel.status}
                    color={"primary"}
                  />
                }
                label="Code Panel"
                labelPlacement="start"
              />
            </Grid>
            <Divider orientation="vertical" />
            <Grid className={classes.textAlignLeft}>
              <Button
                variant={"text"}
                fullWidth
                onClick={(e) => this.handleClick(e)}
              >
                {this.getLayoutIcon(this.state.layout)}
                <Typography
                  style={{ textTransform: "capitalize", paddingLeft: "6px" }}
                >
                  Layout
                </Typography>
                <ArrowDropDownRoundedIcon
                  fontSize={"default"}
                  color={"action"}
                />
              </Button>
            </Grid>
            <Divider orientation="vertical" />
            <Grid className={classes.textAlignLeft}>
              <Button
                variant={"text"}
                fullWidth
                onClick={() => this.props.onSwitchPreview()}
              >
                <Typography
                  color={this.props.previewScreen ? "primary" : "textPrimary"}
                  style={{ textTransform: "capitalize", paddingRight: "8px" }}
                >
                  Preview
                </Typography>
                <PlayCircleOutlineRoundedIcon
                  fontSize={"small"}
                  color={this.props.previewScreen ? "primary" : "action"}
                />
              </Button>
            </Grid>
            <Divider orientation="vertical" />
            <Grid className={classes.textAlign}>
              <IconButton
                disabled={
                  getPercentage[this.props.zoom] === "20%" ? true : false
                }
                style={{ width: "36px" }}
                size={"small"}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => this.props.zoomInOut(e, "zoomOut")}
              >
                <RemoveCircleOutlineRoundedIcon fontSize={"small"} />
              </IconButton>
              <Button
                disabled
                color={"default"}
                className={classes.zoomText}
                disableElevation
                disableFocusRipple
                disableRipple
                component={"span"}
              >
                {getPercentage[this.props.zoom]}
              </Button>
              <IconButton
                disabled={
                  getPercentage[this.props.zoom] === "110%" ? true : false
                }
                style={{ width: "36px" }}
                size={"small"}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => this.props.zoomInOut(e, "zoomIn")}
              >
                <AddCircleOutlineIcon fontSize={"small"} />
              </IconButton>
            </Grid>
            <Divider orientation="vertical" style={{ marginLeft: 5 }} />
            <Grid className={classes.textAlignLeft} style={{ marginLeft: 14 }}>
              <IconButton
                // disabled={getPercentage[this.props.zoom] === "20%" ? true : false}
                style={{ width: "36px", height: "36px" }}
                size={"small"}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) =>
                  this.fullScreenMode(this.state.fullScreen ? "exit" : "full")
                }
              >
                {this.state.fullScreen ? (
                  <FullscreenExitIcon fontSize={"small"} />
                ) : (
                  <FullscreenIcon fontSize={"small"} />
                )}
              </IconButton>
            </Grid>
          </Paper>
        </Grid>

        {/* Layout View menu */}

        <Popover
          // id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {layoutMenu.map((val, index) => {
            return (
              <MenuItem
                key={index}
                className={
                  val.id === this.state.layout ? classes.selectedMenu : ""
                }
                onClick={(e) => this.selectLayoutType(e, val)}
              >
                <Typography style={{ marginRight: "6px" }}>
                  {this.getLayoutIcon(index + 1)}
                </Typography>
                {val.name}
              </MenuItem>
            );
          })}
        </Popover>
        {/* Layout View menu END */}
      </React.Fragment>
    );
  }
}

BottomCard.defaultProps = {
  zoomInOut: () => {},
  zoom: 0.6,
  layoutType: 1,
  changeLayout: () => {},
  onSwitchPreview: () => {},
  previewScreen: false,
};

export default withAlertAndDialog(withStyles(useStyles)(BottomCard));
