import React from 'react';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles((theme, props) => ({
    root: {
        position: 'relative',
        width: '90%',
        height: 150,
        padding: 10,
        marginBottom: 18,
        borderRadius: 8,
        backgroundColor: "#fff",
        boxShadow: "2px 2px 2px -3px rgba(0,0,0,0.75)",
        border: props => props.isHighlight ? `solid 1px ${theme.palette.primary.main}` : '',
        "&:hover,&.active": {
            backgroundColor: "#e2e8f0",
            boxShadow: "2px 2px 8px -2px rgba(0,0,0,0.75)",
        }
    },
    floatRight: {
        position: 'absolute',
        right: 5,
        top: 5,
        padding: 2
    },
    textHolder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
    }
}))

export const DisplayCard = (props) => {
    const classes = useStyles({
        isHighlight: props.isHighlight
    });

    const addNewEvent = <Typography variant="caption">Click here to add New Event</Typography>

    const eventCard = (event) => <Typography variant="caption">
        When {event.component} of {event.CName} is {event.name.replace("on", '')}
    </Typography>

    return <div className={`${classes.root} display-card`}>
        {
            props?.isEdit && <IconButton className={classes.floatRight} onClick={props?.onEdit}>
                <EditOutlinedIcon size="small" style={{ fontSize: 18 }} />
            </IconButton>
        }
        <div className={classes.textHolder}>
            {props?.isRoot ? addNewEvent : ""}
            {props?.isEvent ? eventCard(props.event) : ""}
        </div>
    </div>
}
