import React, { Component } from "react";
import Axios from "axios";
// import ReactDOM from "react-dom";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "@material-ui/core/Modal";

import { Grid, withStyles, Typography, Popover } from "@material-ui/core";
// import * as All from "qdm-component-library";
// import { inputProps } from "qdm-component-library";
import {
  ScreenList,
  ComponentsList,
  BuilderArea,
  // AddScreen,
  PropertiesPanel,
  ComponentTree,
  UiBuilderPanel,
} from "./components";
import { MainAppBar, LoadingStepper } from "../../components";
// common function
import {
  makeid,
  screenArrowLinkAndEventRemover,
  decisionHandlerArrowLinkRemover,
  repositoryOperation,
} from "../../components/commonFunction";
import * as Icons from "@material-ui/icons";
import "./styles/styles.css";
// JSON Data
import {
  // AllScreenList,
  mainMenuList,
  // eleAtrribute,
  templates,
} from "./components/jsonDatas";
// networkCall
// import { networkCall } from "../../networkcall";
import Config from "./../../config";
import { alertProps } from "../../utils";
import {
  readDocument,
  createDocument,
  updateDocument,
  getScreenIndex,
  deleteDocument,
} from "../../apis";

import { withAlertAndDialog, withHeader } from "../../HOCs";
import { withRouter } from "react-router-dom";
import routes from "../../router/routes";
import { AddScreenStepper } from "./components/addScreenStepper";
import AppNotify from "../../App.notification_autosave";

const styles = (theme) => ({
  root: {
    backgroundColor: "#ebebeb",
  },
  gridArea: {
    display: "grid",
    gridGap: "1px",
    height: "calc(100vh - 92px)",
  },
  contentArea: {
    // padding: theme.spacing(2),
  },
  paperLeft: {
    // padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "calc(100vh - 49px)",
    // height: "100vh",
    backgroundColor: "#fff",
  },
  paperRight: {
    padding: theme.spacing(1),
    // margin: theme.spacing(1),
    border: "2px solid transparent",
    borderStyle: "dashed",
    overflow: "auto",
    height: "calc(100vh - 66px)",
  },
  menuButton: {
    padding: "20px 8px",
    fontSize: "10px",
    width: "100%",
    height: "68px",
    wordBreak: "break-word",
    textTransform: "capitalize",
    // backgroundColor: "#eeeeee",
    borderRadius: 0,
  },
  item1: {
    gridArea: "header",
    backgroundColor: "red",
  },
  item2: {
    gridArea: "menu",
    backgroundColor: "#fff",
  },
  item3: {
    gridArea: "mainArea",
    overflow: "auto",
    display: "flex",
  },
  item4: {
    overflow: "auto",
    gridArea: "properties",
    backgroundColor: "#fff",
    zIndex: 4,
  },
  item4Hiden: {
    display: "none",
  },
  item5: {
    gridArea: "menulist",
    backgroundColor: "#fff",
  },
  item5Hiden: {
    display: "none",
  },
  backcrop: {
    padding: "2.5rem",
    backgroundImage:
      "linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)",
    backgroundSize: "20px 20px",
    backgroundColor: "rgba(255, 255, 255, 0.36)",
    height: "100%",
    minWidth: "10rem",
    width: "100%",
    display: "block",
    alignItems: "center",
    overflow: "auto",
    position: "relative",
    flexDirection: "column",
  },
  menusList: {
    width: "100%",
    textAlign: "center",
    padding: "14px 6px",
    whiteSpace: "break-spaces",
    cursor: "pointer",
    color: theme.palette.text.disabled,
    borderLeft: "4px solid transparent",
    "&:hover": {
      backgroundColor: "rgba(0, 122, 255, 0.04)",
    },
  },
  activeMenusList: {
    width: "100%",
    textAlign: "center",
    padding: "14px 6px",
    whiteSpace: "break-spaces",
    cursor: "pointer",
    borderLeft: "4px solid " + theme.palette.primary.main,
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  menuText: {
    fontSize: "10px !important",
    wordBreak: "break-word",
  },
});

class UiBuilder extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      name: "",
      loading: true,
      AllScreenList: [],
      screens: null,
      selectedScreen: 1,
      mainMenu: 0,
      rightMenu: false,
      leftMenu: true,
      selectedAllScreen: false,
      anchorEl: null,
      editScreen: "",
      uiType: "builder",
      componentAttr: null,
      previewScreen: false,
      buildModal: false,
      isEdited: false,
      newScreenType: "",
    };
  }
  async componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  onUnload = (e) => {
    if (this.props.auth.screens[0].readMode === true) {
      return;
    }
    const confirmationMessage =
      "You are in edit mode. Can you please save & leave?";
    e.preventDefault();

    let message = (e.returnValue = confirmationMessage);
    return message;
  };

  // Add New Screen Function
  openModal = (event, val) => {
    if (val === "close") {
      this.setState({
        ...this.state,
        anchorEl: null,
        editScreen: "",
        stepperType: false,
        newScreenType: "",
      });
    } else if (val === "edit") {
      this.setState({ ...this.state, anchorEl: event, editScreen: val });
    } else {
      this.setState({ ...this.state, anchorEl: event.currentTarget });
    }
  };

  submitAddScreen = async (e, newScreenObject, type, scenes) => {
    let currentScreen;
    if (type === "edit") {
      this.props.auth.user.AllScreenList.filter((val) => {
        if (val.id === this.props.auth.user.screens[0]?.id) {
          return (
            (val.name = newScreenObject.name),
            (val.description = newScreenObject.description),
            (val.isHome = newScreenObject.isHome),
            (val.isIdmEnabled = newScreenObject.isIdmEnabled),
            (val.isPrivate = newScreenObject.isPrivate),
            (val.isLayoutScreen = newScreenObject.isLayoutScreen),
            (val.path = newScreenObject.path),
            (val["type"] = newScreenObject.type),
            (val["scenes"] = scenes ? scenes : [])
          );
        }
        return false;
      });
      let permissionData = this.props.auth.user.permissionData;
      // if(newScreenObject?.isPrivate){
      //   permissionData = repositoryOperation.addScreen(permissionData,this.props.auth.user.screens[0]);
      // }
      if (newScreenObject?.isIdmEnabled) {
        permissionData = repositoryOperation.addScreen(
          permissionData,
          this.props.auth.user.screens[0]
        );
      } else {
        permissionData = repositoryOperation.removeScreen(
          permissionData,
          this.props.auth.user.screens[0]
        );
      }
      this.props.auth.setAuth({
        ...this.props.auth.user,
        AllScreenList: this.props.auth.user.AllScreenList,
        AllScreenListRef: JSON.parse(
          JSON.stringify(this.props.auth.user.AllScreenList)
        ),
        permissionData: permissionData,
      });
      this.setState({
        ...this.state,
        anchorEl: null,
        // allScreenList: this.state.allScreenList,
        // isEdited: true,
        stepperType: false,
        newScreenType: "",
        editScreen: "",
      });
      let currentScreen = this.props.auth.user?.screens[0];
      let screenSchema = {
        ...currentScreen,
        readMode: currentScreen?.editMode,
        isLockedBy:
          currentScreen?.editMode === false
            ? this.props.auth?.user?.user?.name
            : null,
      };
      let screenQuery = [
        {
          entity: "screens",
          body: screenSchema,
          filter: {
            id: screenSchema?.id,
            version: screenSchema?.version,
          },
        },
      ];
      return await updateDocument(screenQuery);
    } else {
      let metadataId = localStorage.getItem("metaDataId");

      const indexFromDb = await getScreenIndex(
        metadataId,
        this.props.auth.user.version
      );

      let setTemplate = templates?.filter(
        (val) => val.name === newScreenObject.layoutId
      )?.[0]?.json;
      currentScreen = {
        id: makeid(),
        pathId: makeid(),
        arrowLink: [],
        ...newScreenObject,
        scenes: scenes ? scenes : [],
        readMode: true,
        index: indexFromDb,
        render: {
          component: "div",
          component_type: "qdm",
          child: setTemplate ? [setTemplate] : [],
        },
      };

      this.props.auth.user.AllScreenList.push(currentScreen);
      this.props.auth.user.selectedScreen = currentScreen.id;
      this.props.auth.user.screens = [currentScreen];
      let permissionData = this.props.auth.user.permissionData;
      // if(newScreenObject?.isPrivate){
      //   permissionData = repositoryOperation.addScreen(permissionData,newScreen);
      // }
      if (newScreenObject?.isIdmEnabled) {
        permissionData = repositoryOperation.addScreen(
          permissionData,
          currentScreen
        );
      } else {
        permissionData = repositoryOperation.removeScreen(
          permissionData,
          currentScreen
        );
      }

      this.props.auth.setAuth({
        ...this.props.auth.user,
        AllScreenList: this.props.auth.user.AllScreenList,
        AllScreenListRef: JSON.parse(
          JSON.stringify(this.props.auth.user.AllScreenList)
        ),
        permissionData: permissionData,
        // isEdited: true,
        selectedScreen: currentScreen.id,
        screens: [currentScreen],
      });

      this.setState({
        ...this.state,
        anchorEl: null,
        // allScreenList: this.state.allScreenList,
        // isEdited: true,
        stepperType: false,
        newScreenType: "",
        editScreen: "",
      });
      let screenSchema = {
        ...currentScreen,
        version: this.props.auth.user.version,
        metadataid: localStorage.metaDataId,
      };
      let screenQuery = [
        {
          entity: "screens",
          body: screenSchema,
        },
      ];
      return await createDocument(screenQuery);
    }
  };

  //   delete screen
  deleteOldScreen = (id) => {
    if (this.props.auth.user?.screens?.[0]?.readMode === true) {
      return this.props.alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    if (
      this.props.auth.user?.screens[0]?.readMode === false &&
      this.props.auth.user?.user?.name !==
        this.props.auth.user?.screens[0]?.isLockedBy
    ) {
      return this.props.alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${this.props.auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }

    const handleOnOkModal = () => {
      const pathId = this.props.auth.user.AllScreenList.filter(
        (screen) => screen.id === id
      )[0]?.pathId;

      this.props.auth.user.AllScreenList.forEach(async (screen, index, arr) => {
        console.log(screen);
        // deleting screen related ArrowLinks In Decision Handlers
        if (screen.type === "decisionHandler") {
          screen.arrowLink.forEach((link, index, arrowLinkArray) => {
            if (link.arrowTarget === pathId) {
              const arrowSourceId = link.id;
              const filteredArray = arrowLinkArray.filter(
                (link) => link.id === arrowSourceId
              );
              if (filteredArray.length > 2) {
                arrowLinkArray.splice(index, 1);
              } else {
                const newFilteredArray = arrowLinkArray.filter(
                  (link) => link.id !== arrowSourceId
                );
                screen.arrowLink = newFilteredArray;
                //removing the child component
                screen.render.child.forEach(function iter(val, index, array) {
                  if (val.component === "DecisionBox") {
                    if (val.id === arrowSourceId) {
                      array.splice(index, 1);
                    }
                  } else {
                    if (val?.child?.length > 0) {
                      val.child.forEach(iter);
                    }
                  }
                });
              }
            }
          });
          //deleting components related arrowlinks of the matched screen to delete
          screen.render?.child?.length > 0 &&
            screen.render.child.forEach(function iter(cmpnt) {
              decisionHandlerArrowLinkRemover(screen, cmpnt.id);
              cmpnt?.child?.length > 0 && cmpnt.child.forEach(iter);
            });
        }
        // deleting screen related ArrowLinks In Other Screens
        if (screen.type === "Screen") {
          if (screen?.arrowLink?.length > 0) {
            screen.arrowLink.forEach((link, index, array) => {
              if (link.arrowTarget === pathId) {
                array.splice(index, 1);
              }
            });
          }
          //deleting all the events & actions of the components of the matched screen to delete
          screen.render?.child?.length > 0 &&
            screen.render.child.forEach(function iter(cmpnt) {
              screenArrowLinkAndEventRemover(screen, cmpnt.id);
              cmpnt?.child?.length > 0 && cmpnt.child.forEach(iter);
            });
        }
        //delete the screen and the decision handler if it has
        if (screen.id === id) {
          const decisionBoxOrNot = arr[index + 1];
          if (decisionBoxOrNot?.type === "decisionHandler") {
            arr.splice(index, 2);
          } else {
            arr.splice(index, 1);
          }

          let currentScreen = this.props.auth.user.screens[0];

          let allScreenListRef = JSON.parse(
            JSON.stringify(this.props.auth.user?.AllScreenListRef)
          );

          let isCurrentScreenSaved = allScreenListRef.filter(
            (screen) => screen.id === currentScreen.id
          );

          if (isCurrentScreenSaved.length === 1) {
            //delete from db
            let payload = [
              {
                entity: "screens",
                filter: {
                  id: screen.id,
                  version: screen?.version,
                },
              },
            ];
            await deleteDocument(payload);
          }
        }
      });
      // deleting decision handler if it arrowlink is empty
      this.props.auth.user.AllScreenList.forEach((screen, index, array) => {
        if (
          screen.type === "decisionHandler" &&
          screen.arrowLink.length === 0
        ) {
          array.splice(index, 1);
        }
      });

      let updatedScreens = this.props.auth.user.AllScreenList;
      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: false,
        tone: false,
      });

      console.log(updatedScreens);

      this.props.auth.setAuth({
        ...this.props.auth.user,
        AllScreenListRef: JSON.parse(JSON.stringify(updatedScreens)),
        screens: updatedScreens.length > 0 ? [updatedScreens[0]] : [],
        selectedScreen: updatedScreens.length > 0 ? updatedScreens[0].id : "",
      });
      this.props.alert.setSnack({
        ...this.props.alert,
        open: true,
        severity: alertProps.severity.success,
        msg: "Screen deleted successfully",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
      console.log(this.props.auth);
    };

    const handClose = () => {
      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: false,
        tone: false,
        title: "Delete Alert",
        body: "Do you need to delete this screen?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => {},
        onCancel: () => {},
      });
    };

    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      tone: true,
      title: "Delete Alert",
      body: "Do you need to delete this screen?",
      positiveBtn: "Yes",
      negativeBtn: "No",
      onOk: handleOnOkModal,
      onCancel: handClose,
    });
  };

  // change layout
  getLayoutClass = (rightMenu, leftMenu) => {
    if (rightMenu && leftMenu) {
      return "gridContainerFull";
    } else if (!rightMenu && !leftMenu) {
      return "gridContainer";
    } else if (rightMenu && !leftMenu) {
      return "gridContainerRight";
    } else if (!rightMenu && leftMenu) {
      return "gridContainerleft";
    } else {
      return "gridContainer";
    }
  };

  onClickMenu = (selected) => {
    this.setState({
      ...this.state,
      mainMenu: selected.id,
      leftMenu: true,
    });
  };

  onClose = (type, val) => {
    if (type === "left") {
      this.setState({ ...this.state, leftMenu: val });
    } else if (type === "right") {
      if (document.querySelector(".selectedComponent") && !val) {
        document
          .querySelector(".selectedComponent")
          .classList.remove("selectedComponent");
      }
      this.setState({ ...this.state, rightMenu: val });
    }
  };

  treeView = (val) => {
    this.setState({ ...this.state, mainMenu: val });
  };

  goBack = () => {
    this.setState({ ...this.state, mainMenu: 0 });
  };

  //   Side layout rendering list function
  getMenuListComponents = (val) => {
    switch (val) {
      case 0:
        return (
          <ScreenList
            // loading={this.state.loading}

            selectSteppterScreenType={this.selectSteppterScreenType}
            onSwitchPreview={this.onSwitchPreview}
            previewScreen={this.state.previewScreen}
            treeView={this.treeView}
            deleteOldScreen={this.deleteOldScreen}
            openModal={this.openModal}
            onClose={this.onClose}
          />
        );
      case 1:
        return <ComponentsList />;
      case 2:
        return <ComponentTree onClose={this.onClose} goBack={this.goBack} />;
      default:
        break;
    }
  };

  //   switch to view preview mode
  onSwitchPreview = () => {
    this.setState({ ...this.state, previewScreen: !this.state.previewScreen });
  };

  saveForm = async () => {
    // this.onClose("right", false);
    this.props.auth
      .save()
      .then((response) => {
        console.log(response);
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: alertProps.severity.success,
          msg: "UI saved successfully",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.center,
          tone: true,
        });
      })
      .catch((err) => {
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "Network Error",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.center,
        });
      });
  };

  build = async () => {
    let dataList = {
      db_name: Config.db_name,
      entity: "uibuilder",
      is_metadata: false,
      projectname: localStorage.getItem("projectName"),
      doc: {
        metadataId: localStorage.getItem("metaDataId"),
        UidesignerModelerid: localStorage.getItem("UidesignerModelerid"),
        projectname: localStorage.getItem("projectName"),
        attributes: this.props.auth.user.AllScreenList,
      },
    };

    let data = JSON.stringify([dataList]);

    var config = {
      method: "post",
      url: Config.api_url + "/upsert_document",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    if (!this.state.isEdited) {
      this.setState({
        buildModal: true,
      });
      return false;
    }

    await Axios;
    Axios(config)
      .then((response) => {
        this.setState({
          buildModal: true,
        });
      })
      .catch((err) => {
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "Network Error",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.center,
          tone: true,
        });
      });
  };

  handleClose = (val) => {
    this.setState({
      [val]: false,
    });
  };

  onChangeSwitch = async (val) => {
    if (this.props.auth.user.isEdited) {
      const handSaveAndMove = async () => {
        Promise.resolve(this.saveForm()).then((res) => {
          this.props.dialog.setDialog({
            ...this.props.dialog,
            loading: true,
            negativeBtn: "Saving....",
          });

          setTimeout(() => {
            this.props.dialog.setDialog({
              ...this.props.dialog,
              open: false,
              title: "Save Alert",
              body: "Do you need to save the changes?",
              positiveBtn: "Leave the chages",
              negativeBtn: "Yes",
              onOk: () => {},
              onCancel: () => {},
              loading: false,
            });
            this.props.history.push(routes.uiflow);
          }, 3000);
        });
      };
      const handleOnOkModal = async () => {
        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          title: "Save Alert",
          body: "Do you need to save the changes?",
          positiveBtn: "Leave the chages",
          negativeBtn: "Yes",
          onOk: () => {},
          onCancel: () => {},
        });
        this.props.history.push(routes.uiflow);
      };
      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: true,
        title: "Save Alert",
        body: "Do you need to save the changes?",
        positiveBtn: "Leave the chages",
        negativeBtn: "Save & Move",
        onOk: handleOnOkModal,
        onCancel: handSaveAndMove,
      });
    } else {
      this.props.history.push(routes.uiflow);
      this.setState({ ...this.state, uiType: val });
    }
  };

  openStepper = (e, val) => {
    // this.setState({...this.state, newScreenType: val});
    // this.openModal(e);
    this.setState({
      ...this.state,
      anchorEl: e.currentTarget,
      newScreenType: val,
    });
  };
  selectSteppterScreenType = (e) => {
    this.setState({ ...this.state, stepperType: e.currentTarget });
  };

  render() {
    const openScreenStepper = Boolean(this.state.anchorEl);
    const open = Boolean(this.state.stepperType);
    const id = open ? "simple-popover" : undefined;

    const { classes } = this.props;
    const { rightMenu, leftMenu } = this.state;
    return (
      <div className={classes.root} id={"fullViewMode"}>
        <Modal
          closeAfterTransition
          open={this.state.buildModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
        >
          {
            <LoadingStepper
              handleClose={() => this.handleClose("buildModal")}
            />
          }
        </Modal>
        {/* Top App Bar */}
        {/* <DenseAppBar title={"QDM"} onClose={this.onClose} {...this.state} /> */}
        {/* Title Heaser bar */}
        <AppNotify>
          <MainAppBar
            onChangeSwitch={this.onChangeSwitch}
            build={this.build}
            title={localStorage.projectName}
            onClose={this.onClose}
            saveForm={this.saveForm}
            {...this.state}
            screens={this.props.auth.user.screens}
          />
        </AppNotify>

        {/* Top App Bar end */}

        {/* Main layout area */}
        <div
          id={"mainLayer"}
          className={`${classes.gridArea} ${this.getLayoutClass(
            rightMenu,
            leftMenu
          )}`}
        >
          {/* Side menu list */}
          <div className={classes.item2}>
            <Grid container>
              {mainMenuList?.map((list, index) => {
                let IconType = Icons[list.icon];
                return (
                  <>
                    <div
                      onClick={() => this.onClickMenu(list)}
                      className={
                        this.state.mainMenu === list.id
                          ? classes.activeMenusList
                          : classes.menusList
                      }
                    >
                      <Typography>
                        <IconType fontSize={"small"} />
                      </Typography>
                      <Typography variant={"h6"} className={classes.menuText}>
                        {list.name}
                      </Typography>
                    </div>
                  </>
                );
              })}
            </Grid>
          </div>
          {/* Side menu list END */}

          {/* Menu Component Left menu list  */}
          <div
            className={this.state.leftMenu ? classes.item5 : classes.item5Hiden}
          >
            {this.state.leftMenu &&
              this.getMenuListComponents(this.state.mainMenu)}
          </div>
          {/* Menu Component Left menu list END */}

          {/* UI Builder Area */}
          <div className={`${classes.item3} scroll-bar`}>
            <BuilderArea
              deleteOldScreen={this.deleteOldScreen}
              selectedAllScreen={this.state.selectedAllScreen}
              onSwitchPreview={this.onSwitchPreview}
              previewScreen={this.state.previewScreen}
              onClose={this.onClose}
              rightMenu={this.state.rightMenu}
              leftMenu={this.state.leftMenu}
            />
          </div>
          {/* UI Builder Area END */}

          {/* Right Side Menu ( Properties, Relationship, Entities ) */}
          <div className={rightMenu ? classes.item4 : classes.item4Hiden}>
            {rightMenu && (
              <>
                <UiBuilderPanel onClose={this.onClose} />
              </>
              // <>
              //   <PropertiesPanel onClose={this.onClose} />
              // </>
            )}
          </div>
          {/* Right Side Menu END */}
        </div>
        {/* Main layout area END */}

        {/* Add screen Modal  */}
        {openScreenStepper && (
          <AddScreenStepper
            type={this.state.newScreenType}
            viewType={this.state.editScreen}
            submitAddScreen={this.submitAddScreen}
            modal={this.openModal}
            open={openScreenStepper}
          />
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.stepperType}
          onClose={(e) => this.openModal(e, "close")}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Grid spacing={2}>
            <Typography
              variant={"body1"}
              style={{ padding: "4px 10px", cursor: "pointer" }}
              onClick={(e) => this.openStepper(e, "Screen")}
            >
              {"Screen"}
            </Typography>
            <Typography
              variant={"body1"}
              style={{ padding: "4px 10px", cursor: "pointer" }}
              onClick={(e) => this.openStepper(e, "Component")}
            >
              {"Component"}
            </Typography>
          </Grid>
        </Popover>
        {/* Add screen Modal END */}
      </div>
    );
  }
}

export default withRouter(
  withHeader(withAlertAndDialog(withStyles(styles)(UiBuilder)))
);
