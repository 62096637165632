import React, { useRef, useLayoutEffect } from "react";
import { makeStyles, Typography, Grid, IconButton } from "@material-ui/core";
import * as All from "qdm-component-library";
import * as AllPackages from "functional-component-library";
import * as AllMaterialCore from "@material-ui/core";
import * as AllMaterialIcons from "@material-ui/icons";
import { TextField, Chip, Box, Hidden } from "@material-ui/core";
import * as AllMaterialLab from "@material-ui/lab";
import { Autocomplete } from "@material-ui/lab";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import isEqual from "react-fast-compare";
import "../../../App.css";
import * as AllMaiCus from "custom-material-ui-component";

// import { WithStyledComponents } from './iframe';
import Xarrow from "react-xarrows/lib";
// import * as AllMU from "@material-ui/core";
import {
  layoutComponentsList,
  cloneComponent,
  deleteComponent,
  componentDeepIdRemover,
  pascalToSnake,
} from "../../../components/commonFunction";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { Delete } from "@material-ui/icons";
import { AuthContext, DialogContext, AlertContext } from "../../../contexts";
import { alertProps } from "../../../utils";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MakeTemplateModal } from "./projectTemplates";
import Divider from "@material-ui/core/Divider";
import {
  CustomForm,
  DynamicChart,
  MockUI,
  CustomCode,
} from "./dynamicComponents";
import { networkCall } from "../../../networkcall";
import { customStyle } from "../../../utils";
// import { Button, Tab, Tabpanel } from "qdm-component-library";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "30px",
    display: "flex",
    flexWrap: "wrap",
    minWidth: "2200px",
    maxWidth: "2200px",
  },
  screenArea: {
    // width: "320px",
    // height: "520px",
    // margin: "1px auto auto auto",
    padding: "20px",
    paddingBottom: "90px",
  },
  arrowLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  middleLabel: {
    backgroundColor: "#fff",
    color: "black",
    fontWeight: 500,
    padding: 10,
    marginBottom: 10,
  },
  webView: {
    // maxWidth: "1920px",
    maxWidth: "max-content",
    minWidth: "1620px",
    // height: "1080px"
    // height: "100vh",
    minHeight: (props) => (props.selectedAllScreen ? "fit-content" : "100vh"),
  },
  mobileView: {
    maxWidth: "375px",
    minWidth: "375px",
    minHeight: "812px",
  },
  componentView: {
    // maxWidth: "fit-content",
    maxWidth: "100vw",
    minWidth: "760px",
    minHeight: "812px",
  },
  screenHeader: {
    textAlign: "center",
    position: "relative",
    cursor: "pointer",
    userSelect: "none",
    background: "#cbd5e1",
    display: "flex",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  titleDetails: {
    minWidth: "226px",
    background: "#fff",
    padding: "8px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    boxShadow: "2px -3px 6px 0px rgb(0 0 0 / 4%)",
  },
  screenSetting: {
    padding: "4px 9px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "#000000b3",
    cursor: "pointer",
    borderRadius: "50px",
    "&:hover": {
      background: "rgb(0 0 0 / 8%)",
      color: "#007aff !important",
    },
  },
  screenDelete: {
    padding: "4px 9px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "#000000b3",
    cursor: "pointer",
    borderRadius: "50px",
    "&:hover": {
      background: "#f5005714",
      color: "red !important",
    },
  },
  projectTemplateTitle: {
    paddingLeft: "16px",
    fontSize: "15px",
    textTransform: "capitalize",
    marginTop: "10px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: "6px",
  },
  dragArea: {
    width: "76px",
    border: "1px dashed " + theme.palette.text.hint,
    display: "grid",
    textAlign: "center",
    alignItems: "center",
    wordBreak: "break-all",
    padding: "5px 10px",
    margin: "5px auto",
    minHeight: "108px",
    borderRadius: "4px",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  collapseView: {
    color: "gray",
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      flexWrap: "wrap",
      gap: "5px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialog: {
    minWidth: 400,
    padding: "20px",
  },
  templateName: {
    marginBottom: "30px",
  },
  templateNameInput: {
    fontSize: 14,
    padding: "12px 0px",
  },
}));

const initialState = {
  mouseX: null,
  mouseY: null,
};

function ComponentsRenderer({
  component,
  index,
  returnComponentId,
  screenName,
  draggable,
  pathId,
  screenId,
  screenIndex,
  previewScreen,
  layoutType,
  Parentindex,
  handleContextMenu,
  setSelectedComponent,
}) {
  const styleDeclaration = (compName, style, customStyle) => {
    let Compstyle = customStyle[compName];
    return { ...Compstyle, ...style };
  };
  const auth = React.useContext(AuthContext);
  const [popoverPosition, setPopoverPosition] = React.useState({});
  const DynamicComponents = {
    CustomForm: CustomForm,
    Chart: DynamicChart,
    MockUI: MockUI,
    CustomCode: CustomCode,
  };
  const getComponentId = (event, index, component, indexs) => {
    returnComponentId(
      index,
      component,
      screenId,
      event,
      pathId,
      screenIndex,
      screenName,
      indexs
    );
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    // alert(component.component)
  };

  const onDragStart = (evt) => {
    // evt.preventDefault();
    let id = evt.target.getAttribute("data-id");
    let element = evt.target;
    element.classList.add("vivifySmall");
    element.classList.add("pulsate");
    // element.classList.add("dragged");
    evt.dataTransfer.setData("moveToComponents", id);
    localStorage.setItem("drag_item_id", id);
    evt.dataTransfer.effectAllowed = "move";
    // setTimeout(() => {
    //   document.getElementById(id).className += " hide";
    // }, 0);
    // evt.stopPropagation();
    // evt.nativeEvent.stopImmediatePropagation();
  };

  let comp,
    Layer,
    propertiesList,
    attributesList,
    nestedComments,
    htmlChildren = "";
  if (
    component.frameWork?.startsWith("material") ||
    component.frameWork === "qdm" ||
    component.frameWork === "looper" ||
    component.frameWork === "functional-lib" ||
    component.frameWork === "custom-material-ui-component"
  ) {
    comp = component?.component === "Modal" ? "Row" : component?.component;
    if (component.frameWork === "functional-lib") {
      Layer = AllPackages[comp];
    } else if (component.frameWork?.startsWith("material")) {
      if (component.frameWork === "material/core") {
        Layer = AllMaterialCore[comp] ?? AllMaterialCore["Button"];
      } else if (component.frameWork === "material/lab") {
        Layer = AllMaterialLab[comp] ?? AllMaterialLab["Autocomplete"];
      }
    } else if (component.frameWork === "custom-material-ui-component") {
      Layer = AllMaiCus[comp] ?? <text>not render pls check</text>;
    } else {
      Layer = All[comp];
    }

    if (component?.child && component?.child.length > 0) {
      nestedComments = (component.child || []).map((component, index) => {
        return (
          <ComponentsRenderer
            Parentindex={`${Parentindex}_${index}`}
            layoutType={layoutType}
            key={component?.id}
            index={index}
            component={component}
            type="child"
            returnComponentId={returnComponentId}
            screenName={screenName}
            draggable={draggable}
            pathId={pathId}
            screenId={screenId}
            screenIndex={screenIndex}
            previewScreen={previewScreen}
            handleContextMenu={handleContextMenu}
            setSelectedComponent={setSelectedComponent}
          />
        );
      });
    } else if (typeof component?.properties?.children) {
      nestedComments = component?.properties?.children;
    }
    if (comp === "Col" && layoutType === 2) {
      if (component.properties) {
        // component.properties.lg = component.properties.xs
        propertiesList = {
          ...component.properties,
        };
        propertiesList.lg = propertiesList.xs;
      }
    } else {
      propertiesList = JSON.parse(JSON.stringify(component?.properties));
    }
  }

  function getIcon(iconData) {
    const { iconName, ...remaning } = iconData;
    let Icon = AllMaterialIcons[iconName];
    return <Icon style={remaning?.style ?? {}} />;
  }

  //Material UI Icon rendering
  if (
    Object.keys(component?.properties ?? {}).length > 0 &&
    Object.keys(component?.properties ?? {}).some((key) =>
      key.toLowerCase().includes("icon")
    )
  ) {
    Object.keys(component?.properties ?? {}).forEach((key) => {
      if (
        key.toLowerCase().includes("icon") &&
        component?.properties[key]?.type === "icon"
      ) {
        propertiesList[key] = getIcon(component?.properties[key]);
      }
    });
  }
  //Materia Icon rendering
  if (component?.component === "Icon") {
    if (component?.properties?.children?.iconName) {
      propertiesList["children"] = pascalToSnake(
        component?.properties?.children?.iconName
      );
    }
  }

  if (component.component === "Autocomplete") {
    return (
      <div
        draggable={draggable}
        id={component.id}
        ta-id={component.id}
        data-name={component.name}
        onClick={(e) => {
          setSelectedComponent(component);
          getComponentId(e, index, component, Parentindex);
        }}
        onDragStart={(e) => onDragStart(e)}
        onMouseDown={(e) => {
          if (e.button === 2) {
            handleContextMenu(e, component);
          }
        }}
        style={component.inlineStyles}
      >
        <Autocomplete
          options={[]}
          key={index}
          id={component.id}
          className={`${
            component?.attributes?.class ? component.attributes.class : ""
          }`}
          disabled={component?.properties?.disabled ?? true}
          defaultValue={[]}
          data-id={component.id}
          data-name={component.name}
          style={component.inlineStyles}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionLabel={(option) => option?.title ?? option.label ?? option}
          renderInput={(params) => (
            <TextField
              // onClick={(e) => {
              //   setSelectedComponent(component);
              //   getComponentId(e, index, component, Parentindex);
              // }}
              {...params}
              label={
                component?.properties?.value
                  ? component?.properties?.value
                  : component?.properties?.label
              }
              variant="outlined"
              // {...component?.properties}
              style={component.inlineStyles}
            />
          )}
          {...propertiesList}
        />
      </div>
    );
  }
  if (component.component === "Hidden") {
    return (
      <div
        draggable={draggable}
        id={component.id}
        data-id={component.id}
        data-name={component.name}
        onClick={(e) => {
          setSelectedComponent(component);
          getComponentId(e, index, component, Parentindex);
        }}
        onDragStart={(e) => onDragStart(e)}
        onMouseDown={(e) => {
          if (e.button === 2) {
            handleContextMenu(e, component);
          }
        }}
        style={component.inlineStyles}
        className={`${
          component?.attributes?.class ? component.attributes.class : ""
        } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
      >
        <Hidden
          draggable={draggable}
          onDragStart={(e) => onDragStart(e)}
          onClick={(e) => {
            setSelectedComponent(component);
            getComponentId(e, index, component, Parentindex);
          }}
          onMouseDown={(e) => {
            if (e.button === 2) {
              handleContextMenu(e, component);
            }
          }}
          {...propertiesList}
          key={index}
          className={`${
            component?.attributes?.class ? component.attributes.class : ""
          } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
          data-id={component.id}
          data-name={component.name}
          inLineStyles={component.inlineStyles}
          id={component.id}
        >
          {nestedComments}
        </Hidden>
      </div>
    );
  }

  if (component.frameWork === "html" || component.frameWork === "dynamic") {
    propertiesList = component.properties ?? {};
    attributesList = component.attributes ?? {};
    Layer = component.component;

    if (component?.child && component?.child.length > 0) {
      htmlChildren = (component.child || []).map((component, index) => {
        return (
          <ComponentsRenderer
            Parentindex={`${Parentindex}_${index}`}
            layoutType={layoutType}
            key={component?.id}
            index={index}
            component={component}
            type="child"
            returnComponentId={returnComponentId}
            screenName={screenName}
            draggable={draggable}
            pathId={pathId}
            screenId={screenId}
            screenIndex={screenIndex}
            previewScreen={previewScreen}
            handleContextMenu={handleContextMenu}
            setSelectedComponent={setSelectedComponent}
          />
        );
      });
    } else if (
      component?.properties?.name ||
      typeof component?.properties?.children === "string"
    ) {
      htmlChildren =
        component.properties?.name ?? component?.properties?.children ?? "";
    } else {
      htmlChildren = "";
    }
  }

  function dynamicComponentProducer() {
    const DynamicComponent = DynamicComponents[Layer];
    return (
      <DynamicComponent
        draggable={draggable}
        onDragStart={(e) => onDragStart(e)}
        onClick={(e) => {
          setSelectedComponent(component);
          getComponentId(e, index, component, Parentindex);
        }}
        onMouseDown={(e) => {
          if (e.button === 2) {
            handleContextMenu(e, component);
          }
        }}
        properties={component.properties}
        key={component.id}
        className={previewScreen ? "fielddrag" : "fielddrag fielddragCss"}
        data-id={component.id}
        data-name={component.name}
        style={component.inlineStyles}
        id={component.id}
        component={component}
      />
    );
  }

  const inlineTags = ["br", "i", "em", "b"];

  // if (component.component === "Hidden") {
  //   return (
  //     <div
  //       draggable={draggable}
  //       id={component.id}
  //       ta-id={component.id}
  //       data-name={component.name}
  //       onClick={(e) => {
  //         setSelectedComponent(component);
  //         getComponentId(e, index, component, Parentindex);
  //       }}
  //       onDragStart={(e) => onDragStart(e)}
  //       onMouseDown={(e) => {
  //         if (e.button === 2) {
  //           handleContextMenu(e, component);
  //         }
  //       }}
  //       className={`${
  //         component?.attributes?.class ? component.attributes.class : ""
  //       } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
  //       style={component.inlineStyles}
  //     >
  //       <Hidden {...component?.properties}>
  //         <Box>{nestedComments}</Box>
  //       </Hidden>
  //     </div>
  //   );
  // }

  return (
    <>
      {component.frameWork === "qdm" ||
      component.frameWork === "looper" ||
      component.frameWork === "functional-lib" ? (
        //rendering qdm components
        layoutComponentsList(comp) ? (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.name}
            inLineStyles={component.inlineStyles}
            id={component.id}
          >
            {nestedComments}
          </Layer>
        ) : (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...component?.properties}
            key={index}
            id={component.id}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }`}
            data-id={component.id}
            data-name={component.name}
            inLineStyles={component.inlineStyles}
          />
        )
      ) : component.frameWork.startsWith("material") ||
        component.frameWork === "custom-material-ui-component" ? (
        //rendering qdm components
        component?.isLayout === true ? (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseOver={(e) => {
              if (e.target.getAttribute("data-id") === component?.id) {
                console.log(e.pageX, e.pageY);
                // setPopoverPosition({
                //   x: e.pageX,
                //   y: e.pageY + 170,
                // });
                e.target.classList.add("highlighter");
              }
            }}
            onMouseOut={(e) => {
              if (e.target.getAttribute("data-id") === component?.id) {
                e.target.classList.remove("highlighter");
                // setPopoverPosition({});
              }
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.name}
            style={styleDeclaration(
              component.name,
              component.inlineStyles,
              customStyle
            )}
            id={component.id}
            title={
              component.component === "CardHeader"
                ? component?.properties.title
                : component?.properties?.aliasName ?? component?.component
            }
          >
            {nestedComments}
          </Layer>
        ) : (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            onMouseOver={(e) => {
              if (e.target.getAttribute("data-id") === component?.id) {
                console.log(e.pageX, e.pageY);
                // setPopoverPosition({
                //   x: e.pageX,
                //   y: e.pageY + 170,
                // });
                e.target.classList.add("highlighter");
              }
            }}
            onMouseOut={(e) => {
              if (e.target.getAttribute("data-id") === component?.id) {
                e.target.classList.remove("highlighter");
                // setPopoverPosition({});
              }
            }}
            {...propertiesList}
            key={index}
            id={component.id}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }`}
            data-id={component.id}
            data-name={component.name}
            style={component.inlineStyles}
            title={component?.properties?.aliasName ?? component?.component}
          />
        )
      ) : component.frameWork === "html" ? (
        (component?.child && component?.child.length > 0) ||
        (component?.properties?.name ?? component?.properties?.children) ? (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            {...component.attributes}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"} ${
              component?.attributes?.class &&
              component?.attributes?.class.includes("qdm-list")
                ? "qdmList"
                : ""
            }`}
            data-id={component.id}
            data-name={component.component}
            style={component.inlineStyles}
            id={component.id}
          >
            {htmlChildren}
          </Layer>
        ) : (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            {...component.attributes}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.component}
            style={component.inlineStyles}
            id={component.id}
          />
        )
      ) : component.frameWork !== "html" &&
        component.frameWork !== "qdm" &&
        !component.frameWork?.startsWith("material") &&
        Object.keys(DynamicComponents).includes(component.name) ? (
        dynamicComponentProducer()
      ) : (
        <Typography
          style={{ fontSize: "20px", margin: "20px", textAlign: "center" }}
        >
          Component type is not supported
        </Typography>
      )}
    </>
  );
}

var customizerEnabled = false;

export function ScreenLayout(props) {
  const classes = useStyles(props);
  var customizer = {};
  const auth = React.useContext(AuthContext);
  const dialog = React.useContext(DialogContext);
  const alert = React.useContext(AlertContext);
  const cssRef = useRef(null);
  const { projectTemplates } = auth.user;
  const [arrowLinks, setArrowLinks] = React.useState(
    props?.screen?.arrowLink ?? null
  );
  const [state, setState] = React.useState(initialState);
  const [selectedComponent, setSelectedComponent] = React.useState();
  const [clonedComponent, setClonedComponent] = React.useState();
  const [paste, setPaste] = React.useState(false);
  const [duplicate, setDuplicate] = React.useState(false);
  const [templateChanged, setTemplateChanged] = React.useState(false);
  const [isTemplate, setIsTemplate] = React.useState(false);

  //handling the copy/paste menu
  const handleContextMenu = (event, component) => {
    if (props.uiType === "uiFlow") {
      return;
    }
    event.preventDefault();

    //show copy/paste menu only when a component selected
    if (selectedComponent && selectedComponent.id === component?.id) {
      //checking the selected component a template for modifying the copy/paste menu
      if (selectedComponent?.templateName && auth.user?.projectTemplate) {
        setIsTemplate(true);
        //checking for template changes
        const saveChanges = auth.user?.projectTemplates.filter(
          (templateGroup) => {
            return templateGroup.templates.some((template) => {
              if (template.name === selectedComponent.templateName) {
                const selectedComponentIdRemoved =
                  componentDeepIdRemover(selectedComponent);
                const templateComponentIdRemoved = componentDeepIdRemover(
                  template.json
                );
                const changedOrNot = isEqual(
                  selectedComponentIdRemoved,
                  templateComponentIdRemoved
                );
                return changedOrNot;
              }
            });
          }
        );
        if (saveChanges?.length === 0) {
          setTemplateChanged(true);
        } else {
          setTemplateChanged(false);
        }
        //checking if the template name is present or deleted
        const isTemplateGroupDeleted = projectTemplates.filter(
          (templateGroup) => {
            return templateGroup.templates.some((template) => {
              return template.name === selectedComponent.templateName;
            });
          }
        );
        if (isTemplateGroupDeleted?.length === 0) {
          setIsTemplate(false);
        }
      } else {
        setIsTemplate(false);
      }
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
  };

  const handleCopy = () => {
    //for getting the unique id for the component
    const clonedComponentResult = cloneComponent(selectedComponent);
    // if (clonedComponentResult?.templateName) {
    //   delete clonedComponentResult.templateName;
    // }
    setClonedComponent(clonedComponentResult);
    handleClose();
    // return clonedComponentResult;
  };

  //close menu on outside click
  const handleClose = () => {
    setState(initialState);
  };

  //handling duplicate option in copy/paste menu
  const handleDuplicate = () => {
    if (auth.user?.screens?.[0]?.readMode === true) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    if (
      auth.user?.screens?.[0]?.readMode === false &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    setDuplicate(true);
  };

  const handlePaste = () => {
    if (auth.user?.screens?.[0]?.readMode === true) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    if (
      auth.user?.screens?.[0]?.readMode === false &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    setPaste(true);
  };

  const handleDeleteComponent = (id) => {
    if (auth.user?.screens?.[0]?.readMode === true) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    if (
      auth.user?.screens?.[0]?.readMode === false &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    const handleOnOkModal = () => {
      //this function delete the component & the related actions & events
      deleteComponent(id, auth);
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
      });
      props.onClose("right", false);
      handleClose();
    };
    const handClose = () => {
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
        title: "Delete Alert",
        body: "Do you need to delete this screen?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => {},
        onCancel: () => {},
      });
      handleClose();
    };
    dialog.setDialog({
      ...dialog,
      open: true,
      tone: true,
      title: "Delete Alert",
      body: "Do you need to delete is layer?",
      positiveBtn: "Yes",
      negativeBtn: "No",
      onOk: handleOnOkModal,
      onCancel: handClose,
    });
  };

  const handleMakeTemplate = () => {
    //for getting the unique id for the component
    const clonedComponentResult = cloneComponent(selectedComponent);
    // setting the options to open create template dialog
    const templateDialog = {
      open: true,
      name: "",
      type: "Create",
      componentId: selectedComponent.id,
      template: clonedComponentResult,
    };
    auth.setAuth({
      ...auth.user,
      templateDialog,
    });
    handleClose();
  };

  const handleTemplateSave = () => {
    //saves latest template changes
    auth.user.projectTemplates.forEach((templateGroup) => {
      templateGroup.templates.some((template) => {
        if (template.name === selectedComponent.templateName) {
          template.json = selectedComponent;
        }
      });
    });
    handleClose();
  };

  //keyboard shorcuts for copy/paste menu
  const handleMenuShortcuts = (e) => {
    if (props.uiType === "uiFlow") {
      return;
    }
    let key = e.keyCode;
    if (key === 86 && (e.ctrlKey || e.metaKey)) {
      handlePaste();
    } else if (key === 67 && (e.ctrlKey || e.metaKey)) {
      handleCopy();
    } else if (key === 68 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      handleDuplicate();
    } else if (key === 77 && (e.ctrlKey || e.metaKey)) {
      //checking if the template name is present
      if (selectedComponent?.templateName) {
        return;
      } else {
        handleMakeTemplate();
      }
    } else if (key === 46) {
      handleDeleteComponent(selectedComponent?.id);
    }
  };

  React.useEffect(() => {
    if (selectedComponent) {
      const keyboardAccess = document.querySelector("#keyboardAccess");
      keyboardAccess.addEventListener("keydown", handleMenuShortcuts);
      return () => {
        keyboardAccess.removeEventListener("keydown", handleMenuShortcuts);
      };
    }
  }, [selectedComponent]);

  React.useEffect(() => {
    if (clonedComponent?.id && paste) {
      const htmlBlockTags = [
        "div",
        "ol",
        "a",
        "li",
        "aside",
        "footer",
        "section",
        "article",
      ];
      //checkling the selected component to paste is layout or not
      const isLayoutComponent = layoutComponentsList(selectedComponent.name);
      //only paste inside a layout compoenent not normal component
      if (
        isLayoutComponent ||
        htmlBlockTags.includes(selectedComponent.component) ||
        selectedComponent?.isLayout
      ) {
        const selectedScreenId = auth.user.selectedScreen;
        auth.user.AllScreenList.forEach((screen) => {
          if (screen.id === selectedScreenId) {
            screen.render.child.forEach(function iter(component, index, array) {
              if (component.id === selectedComponent.id) {
                component?.child
                  ? component.child.push(clonedComponent)
                  : (component["child"] = [clonedComponent]);
              } else {
                if (component?.child?.length > 0) {
                  component.child.forEach(iter);
                }
              }
            });
          }
        });
        //replacing id's of the component for the consecutive pasting
        const newClonedComponent2 = cloneComponent(clonedComponent);
        setClonedComponent({ ...newClonedComponent2 });
        setPaste(false);
        auth.setAuth({
          ...auth.user,
          screenEdited: auth.user.selectedScreen,
        });
        handleClose();
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.warning,
          msg: "Not a Layout Component",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
        setPaste(false);
        handleClose();
      }
    }
    if (!clonedComponent && paste) {
      setPaste(false);
    }
  }, [clonedComponent, paste]);

  React.useEffect(() => {
    if (clonedComponent) {
      let data = JSON.stringify(clonedComponent);
      localStorage.setItem("clonedComponent", data);
    }
  }, [clonedComponent]);

  React.useEffect(() => {
    let data = localStorage.getItem("clonedComponent");
    if (data) {
      setClonedComponent(JSON.parse(data));
    }
  }, []);

  React.useEffect(() => {
    if (duplicate) {
      const clonedComponentResult = cloneComponent(selectedComponent);
      // if (clonedComponentResult?.templateName) {
      //   delete clonedComponentResult.templateName;
      // }
      const selectedScreenId = auth.user.selectedScreen;
      if (clonedComponentResult) {
        //this will place the duplicate component adjacently
        auth.user.AllScreenList.forEach((screen) => {
          if (screen.id === selectedScreenId) {
            screen.render.child.forEach(function iter(component, index, array) {
              if (component.id === selectedComponent.id) {
                array.splice(index + 1, 0, clonedComponentResult);
              } else {
                if (component?.child?.length > 0) {
                  component.child.forEach(iter);
                }
              }
            });
          }
        });
      }
      auth.setAuth({
        ...auth.user,
        screenEdited: auth.user.selectedScreen,
      });
      setDuplicate(false);
      handleClose();
    }
  }, [duplicate]);

  React.useEffect(() => {
    let arrows = props?.screen?.arrowLink ?? null;
    if (!auth.user.componentLink && !auth.user.screenLink) {
      setArrowLinks(null);
    } else if (auth.user.screenLink && !auth.user.componentLink) {
      let values = arrows.filter((val) => val.linkType !== "component_link");
      setArrowLinks(values);
    } else if (!auth.user.screenLink && auth.user.componentLink) {
      let values = arrows.filter((val) => val.linkType !== "screen_link");
      setArrowLinks(values);
    } else {
      setArrowLinks(props?.screen?.arrowLink ?? null);
    }
    // eslint-disable-next-line
  }, [auth.user, props.screen]);

  React.useEffect(() => {
    if (!props.rightMenu) {
      setSelectedComponent(null);
    }
  }, [props.rightMenu]);

  React.useLayoutEffect(() => {
    if (props?.screen?.css) {
      let head = document.head;
      let link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = props?.screen?.css;

      if (Object.keys(cssRef?.current ?? {}).length === 0) {
        head.appendChild(link);
        cssRef.current = {
          ...cssRef.current,
          [props?.screen?.id]: true,
        };
      } else {
        if (!cssRef?.current[props?.screen?.id]) {
          head.appendChild(link);
          cssRef.current = {
            ...cssRef.current,
            [props?.screen?.id]: true,
          };
        }
      }
    }
  }, [props?.screen]);

  const getLayoutClassName = (type) => {
    switch (type) {
      case 1:
        return classes.webView;
      case 2:
        return classes.mobileView;
      case 3:
        return classes.tabView;
      default:
        return classes.webView;
    }
  };
  ///Automate initial customizeArrow
  const customizeArrow = (val) => {
    const _gen = (min, max) => {
      return Math.random() * (max - min) + min;
    };
    const pathType = ["smooth", "straight"];
    const anchorType = ["top", "right", "left", "bottom", "auto"];
    if (!customizerEnabled) {
      val.customizer = {
        curveness: _gen(0, 1.4),
        path: pathType[Math.floor(_gen(0, 2))],
        startAnchor: anchorType[Math.floor(_gen(0, 5))],
        endAnchor: anchorType[Math.floor(_gen(0, 5))],
        _cpx1Offset: Math.floor(_gen(-20, 20)),
        _cpy1Offset: Math.floor(_gen(-20, 20)),
        _cpx2Offset: Math.floor(_gen(-20, 20)),
        _cpy2Offset: Math.floor(_gen(-20, 20)),
      };
      customizerEnabled = true;
    }
  };
  return (
    <div
      key={props.index}
      className={`
      ${classes.screenArea} 
      ${
        props.screen?.type === "decisionHandler"
          ? classes.fixedView
          : props.screen?.type === "Component"
          ? classes.componentView
          : getLayoutClassName(props.layoutType)
      } vivifySmall fadeIn`}
      style={{
        zoom: `${props.zoom}`,
        marginBottom: auth.user?.codePanel?.status ? "740px" : "320px",
        marginTop: "110px",
        marginLeft: "32px",
      }}
    >
      {arrowLinks?.length && !props.draggable
        ? arrowLinks?.map((val, screenindexVal) => {
            var labelVal = {};
            val.activePath
              ? (labelVal = {
                  middle: (
                    <label
                      htmlFor="icon-button-file"
                      className={classes.arrowLink}
                    >
                      {val?.middleLabel ? (
                        <div className={classes.middleLabel}>
                          {val?.middleLabel}
                        </div>
                      ) : null}
                      <IconButton
                        component="span"
                        style={{
                          backgroundColor: "#007aff",
                          border:
                            val?.linkType === "component_link"
                              ? "1px solid #F60157"
                              : "1px solid #007aff",
                        }}
                        onClick={() =>
                          props?.rightMenu("dataMapping", val, props?.screen)
                        }
                      >
                        <FlipCameraAndroidIcon
                          style={{
                            color:
                              val?.linkType === "component_link"
                                ? "#F60157"
                                : "#007aff",
                          }}
                        />
                      </IconButton>
                    </label>
                  ),
                })
              : (labelVal = {
                  middle: (
                    <label
                      htmlFor="icon-button-file"
                      className={classes.arrowLink}
                    >
                      {val?.middleLabel ? (
                        <div className={classes.middleLabel}>
                          {val?.middleLabel}
                        </div>
                      ) : null}
                      <IconButton
                        component="span"
                        style={{
                          backgroundColor: "#fff",
                          border:
                            val?.linkType === "component_link"
                              ? "1px solid #F60157"
                              : "1px solid #007aff",
                        }}
                        onClick={() =>
                          props?.rightMenu("dataMapping", val, props?.screen)
                        }
                      >
                        <FlipCameraAndroidIcon
                          style={{
                            color:
                              val?.linkType === "component_link"
                                ? "#F60157"
                                : "#007aff",
                          }}
                        />
                      </IconButton>
                    </label>
                  ),
                });

            return (
              <div style={{ position: "relative", zIndex: 1 }}>
                <Xarrow
                  dashness={
                    val?.linkType === "component_link"
                      ? {
                          animation: 2,
                        }
                      : false
                  }
                  start={`${val?.arrowSource}`}
                  end={`${val?.arrowTarget}`}
                  startAnchor={[val?.startAnchor ?? "auto"]}
                  path={"smooth"}
                  // divContainerProps={{
                  //   style: { position: "relative", zIndex: 1 },
                  // }}
                  lineColor={
                    val?.linkType === "component_link" ? "#F60157" : "#007aff"
                  }
                  tailColor={
                    val?.linkType === "component_link" ? "#F60157" : "#007aff"
                  }
                  headColor={
                    val?.linkType === "component_link" ? "#F60157" : "#007aff"
                  }
                  label={
                    val?.linkType === "component_link"
                      ? { ...labelVal }
                      : { ...labelVal }
                    // : val?.middleLabel
                    //   ? {
                    //     middle: <div>
                    //       <div>{val?.middleLabel}</div>
                    //       <div>{labelVal.middle}</div>
                    //     </div>,
                    //   }
                    //   : { ...labelVal }
                  }
                  headShape={"circle"}
                />
              </div>
            );
          })
        : null}
      {props.screen?.type !== "decisionHandler" && (
        <div className={classes.screenHeader} id={"mianScreenHeader"}>
          <div className={classes.titleDetails}>
            <Typography>{props?.screen?.name}</Typography>
          </div>
          {/* setting */}
          {props.uiType === "uiFlow" && (
            <div
              className={`${classes.screenSetting} fontIcon`}
              onClick={(e) =>
                props.getComponentId(e, "screensParent", props?.screen?.id)
              }
            >
              <SettingsApplicationsIcon />
            </div>
          )}
          <div
            className={`${classes.screenDelete} fontIcon`}
            onClick={() => props.deleteOldScreen(props?.screen?.id)}
          >
            <Delete />
          </div>
        </div>
      )}
      <div
        style={
          props.screen?.type !== "decisionHandler"
            ? {
                backgroundColor: "#fff",
                transition: "background-color 0.5s linear",
                minHeight: props.selectedAllScreen ? "fit-content" : "100vh",
                // height: "678px",
                boxShadow: "0px 0px 16px 0px #0000001a",
                // overflow: "auto",
              }
            : {
                minHeight: props.selectedAllScreen ? "fit-content" : "100vh",
              }
        }
        data-screen={props.screen?.name}
        id={props.screen?.pathId}
        onDragEnter={(e) => props.onDragEnter(e, props?.screen?.id)}
        onDragLeave={(e) => props.onDragLeave(e)}
        onDragEnd={(e) => props.onDragEnd(e)}
        onDragOver={(e) => props.onDragOver(e)}
        onDrop={(e) => props.onDrop(e, props?.screen?.id)}
      >
        <div
          style={{ display: "flex", height: "100%" }}
          id="keyboardAccess"
          onContextMenu={handleContextMenu}
          style={{ cursor: "context-menu" }}
          tabIndex="0"
        >
          <Grid container item>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              // style={{ padding: "30px 16px" }}
            >
              {/* <WithStyledComponents>
                <Modal open={true} >
                  <Avatar />
                </Modal>
              </WithStyledComponents> */}
              {/* <Tab>
                <Tabpanel >
                  dfghjkl
                </Tabpanel>
                <Tabpanel >
                  2
                </Tabpanel>
              </Tab> */}
              <>
                {props?.screen?.render?.child?.length > 0 ? (
                  props?.screen?.render?.child?.map(
                    (component, Parentindex) => {
                      return (
                        <ComponentsRenderer
                          layoutType={props.layoutType}
                          key={component?.id}
                          component={component}
                          index={Parentindex}
                          Parentindex={Parentindex}
                          returnComponentId={props.getComponentId}
                          screenId={props?.screen.id}
                          screenName={props?.screen?.name}
                          screenIndex={props?.index}
                          pathId={props?.screen.pathId}
                          draggable={props.draggable}
                          previewScreen={props?.previewScreen}
                          handleContextMenu={handleContextMenu}
                          setSelectedComponent={setSelectedComponent}
                        />
                      );
                    }
                  )
                ) : (
                  <div style={{ textAlign: "center", marginBottom: "10px" }}>
                    {/* <Typography color={"textSecondary"}>
                      {"Drop Layout Components..."}
                    </Typography> */}
                  </div>
                )}
              </>
            </Grid>
            <Menu
              open={state.mouseY !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                state.mouseY !== null && state.mouseX !== null
                  ? { top: state.mouseY, left: state.mouseX }
                  : undefined
              }
            >
              <MenuItem onClick={handleDuplicate}>
                <Typography variant="body2">Duplicate</Typography>
              </MenuItem>
              <MenuItem onClick={handleCopy}>
                <Typography variant="body2">Copy</Typography>
              </MenuItem>
              <MenuItem
                disabled={clonedComponent?.id ? false : true}
                onClick={handlePaste}
              >
                <Typography variant="body2">Paste</Typography>
              </MenuItem>
              {!isTemplate && (
                <MenuItem onClick={handleMakeTemplate}>
                  <Typography variant="body2"> Make as Template</Typography>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => handleDeleteComponent(selectedComponent?.id)}
              >
                <Typography variant="body2">Delete</Typography>
              </MenuItem>
              {isTemplate && (
                <>
                  <Divider light />
                  <div>
                    <Typography
                      style={{
                        padding: "6px 16px 6px 16px",
                        color: "gray",
                        fontSize: "12px",
                      }}
                    >
                      Template: {selectedComponent?.templateName}
                    </Typography>
                    {templateChanged && (
                      <MenuItem dense="true" onClick={handleTemplateSave}>
                        <Typography variant="body2">Save Changes</Typography>
                      </MenuItem>
                    )}
                  </div>
                </>
              )}
            </Menu>
            {/*create template dialog */}
            <MakeTemplateModal
              auth={auth}
              projectTemplates={projectTemplates}
              classes={classes}
              dialog={dialog}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
}

ScreenLayout.defaultProps = {
  zoom: 0.6,
  previewScreen: true,
  layoutType: 2,
  draggable: false,
  onDragEnter: () => {},
  onDragLeave: () => {},
  onDragEnd: () => {},
  onDragOver: () => {},
  onDrop: () => {},
  getComponentId: () => {},
  deleteOldScreen: () => {},
  index: 0,
  selectedAllScreen: true,
  rightMenu: () => {},
};
