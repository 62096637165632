import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import routes from "./routes";
import PrivateRoute from "./private_router";

import {
  Login,
  // SignUp,
  // Home,
  // Profile,
  UiBuilder,
  UiFlow,
} from "./../screens";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to={routes.uibuilder} />
        </Route>
        <PrivateRoute exact path={routes.uibuilder} component={UiBuilder} />
        <PrivateRoute exact path={routes.uiflow} component={UiFlow} />
        {/* Strategic builder */}
        {/* <PrivateRoute exact path="/builder" component={Builder} /> */}
        {/* <PrivateRoute exact path="/" component={Login} /> */}
        {/* <Route exact path="/" component={Login}/> */}
        {/* <Route exact path={routes.login} component={Login}/> */}
        {/* <Route path={routes.signup} component={SignUp}/> */}
        {/* <PrivateRoute path={routes.home} component={Home} /> */}
        {/* <PrivateRoute path={routes.profile} component={Profile} /> */}
      </Switch>
    </Router>
  );
};

export default RouterApp;
