import React from "react"
import {
    makeStyles,
    Grid,
    Typography,
    Switch,
    TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { AuthContext, DialogContext, AlertContext } from "../../../../contexts";
import { repositoryOperation, getParentNode } from '../../../../components/commonFunction';

const useStyles = makeStyles(() => ({
    root: { padding: '14px' },
    rowSpacing: { marginTop: 10, marginBottom: 10 },
    checkBoxGroup: { marginTop: 20, marginBottom: 10 }
}))

let screenId = null;

export const IdmPanel = () => {
    const [data, setData] = React.useState({});
    const [isPreview, setIsPreview] = React.useState(false)
    const [destOptions, setDestOptions] = React.useState([]);
    const classes = useStyles();

    const auth = React.useContext(AuthContext);
    const dialog = React.useContext(DialogContext);

    React.useEffect(() => {
        screenId = auth.user.AllScreenList.findIndex(
            (s) => s.id === auth.user.selectedScreen
        );
        let data = auth.user.componentAttr;
        let activeScreen = auth.user?.AllScreenList[screenId];

        /// parent of ele having with idm enabled 
        let parentRef = [];
        getParentNode(activeScreen?.render?.child, data?.id, "idm", parentRef)

        /// get destination option
        destinationOptions(activeScreen, parentRef?.length ? parentRef[0] : null)

        setData({
            ...data,
            idm: {
                name: data?.component ?? data?.properties?.label ?? '',
                type: data?.component_type ?? '',
                ...data?.idm
            }
        })

        if (data?.idm?.status) setIsPreview(true)
        else setIsPreview(false)

    }, [auth?.user?.componentAttr?.id])

    const destinationOptions = (activeScreen, parentRef) => {
        var options = []
        options.push({
            label: activeScreen?.name,
            id: activeScreen?.name
        })
        if (parentRef?.idm) {
            options.push({
                label: parentRef?.idm?.name,
                id: parentRef?.id
            })
        }
        setDestOptions(options);
    }

    const getChildId = (id, data1, value, type) => {
        data1.forEach((item) => {
            if (item.id === id) {
                item['idm'] = data.idm;
                value ?
                    item['idm'][type] = value :
                    Object.keys(item['idm']).length === 1 ?
                        delete item['idm'] :
                        delete item['idm'][type]

                setData({ ...item })
            } else {
                if (item.child) {
                    getChildId(id, item?.child, value, type);
                }
            }
        });
    };

    const closeDialog = () => {
        dialog.setDialog({ open: false })
    }

    const update = (value, key, isAddPermission) => {
        let activeScreen = auth.user.AllScreenList[screenId];
        getChildId(
            auth.user.componentAttr.id, activeScreen?.render?.child,
            value, key
        );
        if (isAddPermission) {
            repositoryOperation.addComponent(
                auth.user.permissionData,
                activeScreen?.name?.replaceAll(' ', '_'),
                {
                    ...auth.user.componentAttr,
                    idm : {
                        ...data?.idm,
                        parent : data?.idm?.parent ?? destOptions[0]
                    }
                }
            )
            if(data?.idm?.status) setIsPreview(true)
            else setIsPreview(false)
        }
    }

    const handleChange = (value, key) => {
        let activeScreen = auth.user.AllScreenList[screenId];

        if (key === 'status') {
            if (!value) {
                dialog.setDialog({
                    ...dialog,
                    open: true,
                    tone: false,
                    title: "Remove Component",
                    body: "Do you need to remove component from idm.All permission accces related to component must be changed manually",
                    positiveBtn: "Proceed",
                    negativeBtn: "Cancel",
                    onOk: () => {
                        update(value, key, true);
                        closeDialog()
                    },
                    onCancel: () => {
                        closeDialog()
                    },
                });
            } else {
                update(value, key, true);
            }
        } else if (key === 'name' || key === 'type' || key === 'parent') {
            update(value, key);
        }
    }

    // const addToRepo = (isIdmEnabled) => {
    //     if (isIdmEnabled) {
    //         alert('delete from repo future not enabled')
    //         return
    //     } else {
    //         let data = auth.user.componentAttr;

    //         let screenId = auth.user.AllScreenList.findIndex(
    //             (s) => s.id === auth.user.selectedScreen
    //         );

    //         let allScreenList = auth.user.AllScreenList;

    //         let data1 = allScreenList[screenId].render.child;

    //         repositoryOperation.addComponent(auth.user.permissionData, allScreenList[screenId]?.pathId, auth.user.componentAttr)

    //         getChildId(auth.user.componentAttr.id, data1, isIdmEnabled, "status");
    //     }
    // }

    return <Grid container className={classes.root}>
        {
            (data?.component_type === "html" || data?.component_type === "qdm") && 
            (auth.user.AllScreenList[screenId]?.isIdmEnabled || auth.user.AllScreenList[screenId]?.type === "Component")   ? <Grid item xs={12}>
                <Grid item xs={12}>
                    <TextField
                        label="component name"
                        variant="outlined"
                        fullWidth
                        className={classes.rowSpacing}
                        size={"small"}
                        onChange={(e) => handleChange(e?.target?.value, "name")}
                        value={data?.idm?.name ?? ''}
                        disabled={isPreview}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="component type"
                        variant="outlined"
                        fullWidth
                        className={classes.rowSpacing}
                        size={"small"}
                        onChange={(e) => handleChange(e?.target?.value, "type")}
                        value={data?.idm?.type ?? ''}
                        disabled={isPreview}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="component id"
                        variant="outlined"
                        fullWidth
                        className={classes.rowSpacing}
                        size={"small"}
                        value={data?.id}
                        disabled
                    />
                </Grid>
                <Grid>
                    {
                        auth.user.AllScreenList[screenId]?.type === 'Screen' && <Autocomplete
                            size={"small"}
                            options={destOptions ?? []}
                            className={classes.rowSpacing}
                            id="controllable-states-demo12"
                            onChange={(e, newValue) => handleChange(newValue, "parent")}
                            value={data?.idm?.parent ?? destOptions[0] ?? null}
                            getOptionLabel={(option) =>
                                option.label ? option.label : ""
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`select parent repo`}
                                    variant="outlined"
                                />
                            )}
                            disabled={isPreview || destOptions?.length == 1}
                        />
                    }
                </Grid>
                <Grid
                    container
                    justify={"space-between"}
                    className={`${classes.checkBoxGroup}`}
                    xs={12}
                >
                    <Typography
                        variant="subtitle1"
                        style={{ textTransform: "underline" }}
                    >
                        {`${!data?.idm?.status ? 'Add to' : "Remove from"} Repo`}
                    </Typography>
                    <Switch
                        checked={data?.idm?.status}
                        onClick={(event) => {
                            handleChange(!data?.idm?.status, "status")
                            event.preventDefault()
                        }}
                        color="primary"
                        name="condition-enabler"
                        inputProps={{ "aria-label": "condition-enabler-checkbox" }}
                    />
                </Grid>
            </Grid> : <div style={{
                padding: 10,
                color: '#d44c4c',
                fontWeight: 500}
                }> Idm Repositories access allowed only for Idm Enabled screens </div>
        }
    </Grid>
}

