import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { fade, withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import DeleteIcon from "@material-ui/icons/Delete";
import { withAlertAndDialog } from "../../../HOCs";
import { deleteComponent } from "../../../components/commonFunction";
import { eleAtrribute } from "./jsonDatas";
import {
  getPropertiesItems,
  GetNodeID,
} from "../../../components/commonFunction";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Collapse from "@material-ui/core/Collapse";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import cloneDeep from "lodash/cloneDeep";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  deleteIcon: {
    display: "none",
    marginRight: 8,
    cursor: "pointer",
  },
  selected_inline: {
    // "& .Mui-selected .MuiTreeItem-content .label_inline * svg": {
    "& .Mui-selected > .MuiTreeItem-content .MuiTreeItem-label * svg": {
      display: "block",
      // color: "red",
    },
  },
});

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => {
  return (
    <TreeItem
      {...props}
      className={props.styleclasses.selected_inline}
      label={
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <span {...props.dragProvided.dragHandleProps}>
            <DragIndicatorIcon
              color={"action"}
              style={{
                fontSize: "14px",
              }}
            />
          </span>
          <Grid
            onClick={() =>
              props.onClickComponentId(props.nodeId, props.object, props)
            }
            style={{ padding: "6px" }}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography>{props.label}</Typography>
          </Grid>
          <Tooltip title={`Delete ${props.label}`} placement="right-start">
            <DeleteIcon
              className={props.styleclasses.deleteIcon}
              onClick={() => props.delectComponent(props)}
              color={"action"}
              fontSize={"small"}
              style={{ fontSize: "16px" }}
            />
          </Tooltip>
        </div>
      }
    />
  );
});

function Children({
  children,
  Parentindex,
  delectComponent,
  onClickComponentId,
  index,
  droppableId,
  styleclasses,
}) {
  let nestedChildrens = "";
  if (children.child && children.child.length > 0) {
    nestedChildrens = (
      <Droppable droppableId={droppableId} type={`level-${Parentindex}`}>
        {(dropProvided, dropSnapshot) => (
          <div
            {...dropProvided.droppableProps}
            ref={dropProvided.innerRef}
            style={{
              background: `${
                dropSnapshot.isDraggingOver ? "#F9FAFB" : "white"
              }`,
              margin: "2px 0px",
              padding: `${dropSnapshot.isDraggingOver ? "2px 0px" : "0px"}`,
            }}
          >
            {(children.child || []).map((nestChildren, index) => {
              return (
                <Children
                  key={nestChildren.id}
                  index={index}
                  Parentindex={`${Parentindex}_${index}`}
                  children={nestChildren}
                  type="child"
                  delectComponent={delectComponent}
                  onClickComponentId={onClickComponentId}
                  droppableId={nestChildren.id}
                  styleclasses={styleclasses}
                />
              );
            })}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
  return (
    <Draggable key={Parentindex} draggableId={children.id} index={index}>
      {(dragProvided, dragSnapshot) => (
        <div {...dragProvided.draggableProps} ref={dragProvided.innerRef}>
          <StyledTreeItem
            key={children.id}
            nodeId={`${Parentindex}`}
            label={
              children.properties.aliasName
                ? children.properties.aliasName
                : children.name
            }
            object={children}
            delectComponent={delectComponent}
            onClickComponentId={onClickComponentId}
            dragProvided={dragProvided}
            styleclasses={styleclasses}
          >
            {nestedChildrens}
          </StyledTreeItem>
        </div>
      )}
    </Draggable>
  );
}

const getNodeTreeIds = (node) => {
  let active = ["0"];
  if (typeof node === "string" && node !== 0) {
    active = node?.split("_").map((val, i) =>
      node
        .replaceAll("_", "")
        .slice(0, i + 1)
        .split("")
        .join()
        .replaceAll(",", "_")
    );
  }
  return active;
};

const ComponentTree = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    activeNode: ["0"],
    selectedNode: ["0"],
    activeComponentIndex: null,
  });
  const [componentsData, setComponentsData] = React.useState();

  const goBack = () => {
    props.goBack();
  };

  const delectComponent = (e) => {
    if (props.auth.user?.screens?.[0]?.readMode === true) {
      return;
    }
    if (
      props.auth.user?.screens?.[0]?.readMode === false &&
      props.auth.user?.user?.name !== props.auth.user?.screens[0]?.isLockedBy
    ) {
      return;
    }
    const handleOnOkModal = async () => {
      deleteComponent(e.object.id, props.auth);
      props.dialog.setDialog({
        ...props.dialog,
        open: false,
        tone: false,
      });

      props.auth.setAuth({
        ...props.auth.user,
        AllScreenList: props.auth.user.AllScreenList,
        screenEdited: props.auth.user.selectedScreen,
      });

      props.onClose("right", false);
    };

    const handClose = () => {
      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: false,
        tone: false,
        title: "Delete Alert",
        body: "Do you need to delete this screen?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => {},
        onCancel: () => {},
      });
    };

    props.dialog.setDialog({
      ...props.dialog,
      open: true,
      tone: true,
      title: "Delete Alert",
      body: "Do you need to delete is layer?",
      positiveBtn: "Yes",
      negativeBtn: "No",
      onOk: handleOnOkModal,
      onCancel: handClose,
    });
  };

  const onClickComponentId = (index, component, propVal) => {
    let data2 = getPropertiesItems(
      component,
      props.auth.user.selectedScreen,
      eleAtrribute
    );
    props.auth.setAuth({
      ...props.auth.user,
      componentAttr: data2,
      undoRedo: true,
    });
    props.onClose("right", true);
  };

  const onDragEnd = (result) => {
    let componentsDataCopy = cloneDeep(componentsData);
    if (!result.destination) {
      return;
    }
    if (result.type === "level-1") {
      if (componentsDataCopy.length > 1) {
        const [removed] = componentsDataCopy.splice(result.source.index, 1);
        componentsDataCopy.splice(result.destination.index, 0, removed);
        setComponentsData([...componentsDataCopy]);
        const screens = props.auth.user.screens;
        screens[0].render.child = componentsDataCopy;
        props.auth.setAuth({
          ...props.auth.user,
          screens: screens,
          isEdited: true,
        });
      }
    } else {
      componentsDataCopy.forEach(function iter(eachComponent, index) {
        if (eachComponent.id === result.destination.droppableId) {
          const [removed] = eachComponent.child.splice(result.source.index, 1);
          eachComponent.child.splice(result.destination.index, 0, removed);
        } else {
          eachComponent?.child?.length > 0 && eachComponent.child.forEach(iter);
        }
      });
      setComponentsData([...componentsDataCopy]);
      const screens = props.auth.user.screens;
      screens[0].render.child = componentsDataCopy;
      props.auth.setAuth({
        ...props.auth.user,
        screens: screens,
        isEdited: true,
      });
    }
  };

  //to get node id for show selected component
  React.useEffect(() => {
    if (props.auth.user.componentAttr) {
      const selectedScreenId =
        props.auth.user?.selectedScreen ?? props.auth.user.screens?.[0]?.id;
      const Data =
        props.auth?.user?.screens &&
        props.auth.user.screens.filter(
          (val) => val.id === selectedScreenId
        )?.[0]?.render?.child;
      const treeData = Data?.length > 0 ? Data : null;
      const selectedApp = props.auth.user.componentAttr;

      const NodeID = GetNodeID(treeData, selectedApp.id, "");
      setState({
        activeNode: getNodeTreeIds(NodeID),
        selectedNode: NodeID ?? ["0"],
        activeComponentIndex: NodeID,
      });
    }
  }, [props.auth.user.componentAttr]);
  React.useEffect(() => {
    const selectedScreenId =
      props.auth.user?.selectedScreen ?? props.auth.user.screens?.[0]?.id;
    const Data =
      props.auth?.user?.screens &&
      props.auth.user.screens.filter((val) => val.id === selectedScreenId)?.[0]
        ?.render?.child;
    const treeData = Data?.length > 0 ? Data : null;
    setComponentsData(treeData);
  }, [props.auth]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ padding: "14px 16px 14px 8px" }}
      >
        <IconButton color="primary" size={"small"} onClick={(e) => goBack(e)}>
          <ArrowBackIosRoundedIcon
            style={{ fontSize: "16px" }}
            color={"action"}
          />
        </IconButton>
        <Typography
          style={{ paddingLeft: "10px", fontSize: "15px" }}
          variant={"h6"}
        >
          Feed
        </Typography>
      </Grid>
      <Grid>
        <Typography
          style={{ paddingLeft: "10px", padding: "4px 14px", fontSize: "13px" }}
          color={"textSecondary"}
          variant={"button"}
        >
          COMPONENT TREE
        </Typography>
      </Grid>
      <Grid
        container
        style={{
          padding: "14px 16px",
          overflow: "auto",
          height: "calc(100vh - 166px)",
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          {!props.auth.user.loading && (
            <TreeView
              className={classes.root}
              defaultExpanded={state.activeNode ?? ["0"]}
              expanded={state.activeNode ?? ["0"]}
              selected={state.selectedNode ?? ["0"]}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={""}
            >
              <Droppable droppableId="droppable" type="level-1">
                {(dropProvided, dropSnapshot) => (
                  <div
                    {...dropProvided.draggableProps}
                    ref={dropProvided.innerRef}
                    style={{
                      background: `${
                        dropSnapshot.isDraggingOver ? "#F9FAFB" : "white"
                      }`,
                      margin: "2px 0px",
                      padding: `${
                        dropSnapshot.isDraggingOver ? "2px 0px" : "0px"
                      }`,
                    }}
                  >
                    {componentsData?.length > 0 &&
                      componentsData?.map((val, Parentindex) => {
                        return (
                          <Draggable
                            key={val.id}
                            draggableId={val.id}
                            index={Parentindex}
                          >
                            {(dragProvided, dragSnapshot) => (
                              <div
                                {...dragProvided.draggableProps}
                                ref={dragProvided.innerRef}
                                key={val.id}
                              >
                                <StyledTreeItem
                                  key={val.id}
                                  nodeId={`${Parentindex}`}
                                  object={val}
                                  label={
                                    val.properties.aliasName
                                      ? val.properties.aliasName
                                      : val.name
                                  }
                                  delectComponent={delectComponent}
                                  onClickComponentId={onClickComponentId}
                                  dragProvided={dragProvided}
                                  styleclasses={classes}
                                >
                                  <Droppable
                                    droppableId={val.id}
                                    type="level-2"
                                  >
                                    {(dropProvided, dropSnapshot) => (
                                      <div
                                        {...dropProvided.droppableProps}
                                        ref={dropProvided.innerRef}
                                        style={{
                                          background: `${
                                            dropSnapshot.isDraggingOver
                                              ? "#F9FAFB"
                                              : "white"
                                          }`,
                                          margin: "2px 0px",
                                          padding: `${
                                            dropSnapshot.isDraggingOver
                                              ? "2px 0px"
                                              : "0px"
                                          }`,
                                        }}
                                      >
                                        {val?.child?.length > 0 &&
                                          val?.child?.map((children, index) => {
                                            return (
                                              <Children
                                                key={children.id}
                                                Parentindex={`${Parentindex}_${index}`}
                                                nodeId={`${Parentindex}_${index}`}
                                                children={children}
                                                index={index}
                                                styleclasses={classes}
                                                delectComponent={
                                                  delectComponent
                                                }
                                                onClickComponentId={
                                                  onClickComponentId
                                                }
                                                droppableId={children.id}
                                              />
                                            );
                                          })}
                                        {dropProvided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </StyledTreeItem>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </TreeView>
          )}
        </DragDropContext>
      </Grid>
    </div>
  );
};

export default withAlertAndDialog(ComponentTree);
