import React from 'react';

import {
    Grid,
    Button,
    Modal,
    Paper,
    Collapse,
    makeStyles,
    IconButton
} from '@material-ui/core';
import { DataSource } from '../../components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { AuthContext } from '../../../../contexts'
import { v4 as UUID } from 'uuid';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        border: '2px solid #000',
        boxShadow: 24,
        height: 450
    },
    root: {
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    ListHead: {
        display: 'flex'
    },
    section: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    collapseRoot: {
        padding: 10,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        "& .section": {
            overflow: 'auto'
        },
        "& .action": {
            display: 'flex',
            justifyContent: 'end'
        }
    },
    flexShrink: {
        maxHeight: 20,
        flex: 0
    },
    overflowDynamic: {
        overflow: 'hidden'
    },
    closeIcon: {
        position: "absolute",
        right: "-25px",
        top: "-25px"
    }
}));

const SessionManagement = (props) => {

    const [modal, setModal] = React.useState(false);
    const [collapse, setCollapse] = React.useState(null);
    const [state, setState] = React.useState({
        sessionStorage: [],
        localStorage: [],
        cookies: []
    });
    const auth = React.useContext(AuthContext);
    const classes = useStyles();

    // React.useEffect(() => {
    //     if (collapse === 1 && !state.sessionStorage.length) {
    //         addDataSource("sessionStorage");
    //     }
    //     else if (collapse === 2 && !state.localStorage.length) {
    //         addDataSource("localStorage");
    //     }
    //     else if (collapse === 3 && !state.cookies.length) {
    //         addDataSource("cookies");
    //     }
    // }, [collapse])

    React.useEffect(() => {
        if (props?.storageData) {
            let storageData = props?.storageData;
            setState({
                sessionStorage: storageData?.sessionStorage ?? [],
                localStorage: storageData?.localStorage ?? [],
                cookies: storageData?.cookies ?? []
            })
        }
    }, [props?.modal])

    const addDataSource = (parent) => {
        let crntState = state;
        crntState[parent].push({
            id: props?.eventId
        })
        setState({
            ...crntState
        })
    }

    const handleChange = (v, index, parent) => {
        let crntState = state;
        crntState[parent][index] = v;
        setState({
            ...crntState
        })
    }

    const closeModal = () => {
        setModal(false)
    }

    const updateSessionData = () => {
        props.onChange(state);

    }

    return <>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end', marginTop: 10 }}>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => setModal(!modal)}
            >
                Add To Session
            </Button>
        </Grid>
        {
            <Modal
                open={modal}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper className={classes.paper}>
                    <div className={classes.closeIcon}>
                        <IconButton style={{ backgroundColor: '#fff', padding: 0 }} onClick={() => setModal(false)}>
                            <HighlightOffIcon size="small" />
                        </IconButton>
                    </div>
                    <Grid container className={classes.root}>
                        <Grid
                            item
                            xs={12}
                            className={`${classes.section} ${collapse !== 1 ? `${classes.flexShrink}` : `${classes.overflowDynamic}`}`}
                        >
                            <div onClick={() => setCollapse(collapse === 1 ? null : 1)} className={classes.ListHead}>
                                <div>{collapse === 1 ? <ExpandMoreIcon /> : <ChevronRightIcon />}</div>
                                <div>Session Storage</div>
                            </div>
                            <CustomCollapse in={collapse === 1}>
                                <div className={classes.collapseRoot}>
                                    <Grid container className={"section"}>
                                        <Grid item xs={12}>
                                            {
                                                state?.sessionStorage?.map((item, index) => {
                                                    return <>
                                                        {
                                                            item?.id === props?.eventId && <DataSource
                                                                label={props.label}
                                                                keyName={item?.keyName}
                                                                isKeyEditable={props.isKeyEditable}
                                                                value={item}
                                                                index={index}
                                                                onChange={(v, index) => handleChange(v, index, "sessionStorage")}
                                                                listernerEvents={props?.listernerEvents ?? []}
                                                                qpasQueriesResponse={props?.qpasQueriesResponse ?? []}
                                                                dataSources={props?.dataSources}
                                                                selfEvents={props?.selfEvents}
                                                                historyData={props?.dataSources}
                                                                isEndDecoration={props?.isEndDecoration}
                                                            />
                                                        }
                                                    </>
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                    <div className='action'>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => addDataSource("sessionStorage")}
                                        >
                                            Add Key +
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ marginLeft: 15 }}
                                            onClick={updateSessionData}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </CustomCollapse>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={`${classes.section} ${collapse !== 2 ? `${classes.flexShrink}` : ''}`}
                        >
                            <div onClick={() => setCollapse(collapse === 2 ? null : 2)} className={classes.ListHead}>
                                <div>{collapse === 2 ? <ExpandMoreIcon /> : <ChevronRightIcon />}</div>
                                <div>Local Storage</div>
                            </div>
                            <CustomCollapse in={collapse === 2}>
                                <div className={classes.collapseRoot}>
                                    <Grid container className={"section"}>
                                        <Grid item xs={12}>
                                            {
                                                state?.localStorage?.map((item, index) => {
                                                    return <>
                                                        {
                                                            item?.id === props?.eventId && <DataSource
                                                                label={props.label}
                                                                keyName={item?.keyName}
                                                                isKeyEditable={props.isKeyEditable}
                                                                value={item}
                                                                index={index}
                                                                onChange={(v, index) => handleChange(v, index, "localStorage")}
                                                                listernerEvents={props?.listernerEvents ?? []}
                                                                qpasQueriesResponse={props?.qpasQueriesResponse ?? []}
                                                                dataSources={props?.dataSources}
                                                                selfEvents={props?.selfEvents}
                                                                isEndDecoration={props?.isEndDecoration}
                                                            />
                                                        }
                                                    </>
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                    <div className='action'>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => addDataSource("localStorage")}
                                        >
                                            Add Key +
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ marginLeft: 15 }}
                                            onClick={updateSessionData}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </CustomCollapse>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={`${classes.section} ${collapse !== 3 ? `${classes.flexShrink}` : ''}`}
                        >
                            <div onClick={() => setCollapse(collapse === 3 ? null : 3)} className={classes.ListHead}>
                                <div>{collapse === 3 ? <ExpandMoreIcon /> : <ChevronRightIcon />}</div>
                                <div>Cookies Storage</div>
                            </div>
                            <CustomCollapse in={collapse === 3}>
                                <div className={classes.collapseRoot}>
                                    <Grid container className={"section"}>
                                        <Grid item xs={12}>
                                            {
                                                state?.cookies?.map((item, index) => {
                                                    return <>
                                                        {
                                                            item?.id === props?.eventId && <DataSource
                                                                label={props.label}
                                                                keyName={item?.keyName}
                                                                isKeyEditable={props.isKeyEditable}
                                                                value={item}
                                                                index={index}
                                                                onChange={(v, index) => handleChange(v, index, "cookies")}
                                                                listernerEvents={props?.listernerEvents ?? []}
                                                                qpasQueriesResponse={props?.qpasQueriesResponse ?? []}
                                                                dataSources={props?.dataSources}
                                                                selfEvents={props?.selfEvents}
                                                                isEndDecoration={props?.isEndDecoration}
                                                            />
                                                        }
                                                    </>
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                    <div className='action'>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => addDataSource("cookies")}
                                        >
                                            Add Key +
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ marginLeft: 15 }}
                                            onClick={updateSessionData}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </CustomCollapse>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        }
    </>
}

export const CustomCollapse = (props) => {
    return <>
        {
            props?.in && props?.children
        }
    </>
}

export default SessionManagement;