import { readDocument, readDocuments } from "./crud";
import { metadata_id } from "./constants";
import Config from "../config";
import { networkCall } from "../networkcall";

//Read UI Builder Entity
export async function readUIBuilderDoc(metaDataId) {
  let uibuilderQuery = [
    {
      entity: "uibuilder",
      filter: {
        metadataid: metaDataId,
      },
    },
  ];

  let result = await readDocument(uibuilderQuery, {
    sort: "uibuilder.version desc",
  });
  return result?.uibuilder ?? {};
}

//Read project_templates
export async function readProjectTemplates(metaDataId, version) {
  let projectTemplatesQuery = [
    {
      entity: "project_templates",
      filter: {
        metadataid: metaDataId,
        version: version,
      },
    },
  ];
  let result = await readDocument(projectTemplatesQuery);
  return result?.project_templates ?? {};
}

//Read custom components
export async function readCustomComponents(metaDataId, version) {
  let customComponentsQuery = [
    {
      entity: "custom_components",
      filter: {
        metadataid: metaDataId,
        version: version,
      },
    },
  ];
  let result = await readDocument(customComponentsQuery);
  console.log(result, "result");
  return result?.custom_components ?? {};
}

//Read flow groups
export async function readFlowGroups(metaDataId, version) {
  let flowGroupsQuery = [
    {
      entity: "flowgroup",
      filter: {
        metadataid: metaDataId,
        version: version,
      },
    },
  ];
  let result = await readDocument(flowGroupsQuery);
  return result?.flowgroup ?? {};
}

//Read storage management
export async function readStorageManagement(metaDataId, version) {
  let storagemanagementQuery = [
    {
      entity: "storagemanagement",
      filter: {
        metadataid: metaDataId,
        version: version,
      },
    },
  ];
  let result = await readDocument(storagemanagementQuery);
  return result?.storagemanagement ?? {};
}

//Read projectvstools
export async function readProjectVsTools(metaDataId) {
  let projectVsToolsQuery = [
    {
      entity: "projectvstools",
      filter: {
        metadataid: metaDataId,
      },
    },
  ];

  let result = await readDocument(projectVsToolsQuery);
  return result?.projectvstools ?? {};
}

//get qpas queries
export const getAllQpasQueries = async (metaDataId) => {
  let payload = {
    db_name: Config.db_name,
    entity: "serviceregistry",
    filter: `serviceregistry.metadataid == '${metaDataId}'`,
    return_fields: "serviceregistry",
  };
  let { data } = await networkCall(
    Config.api_url + "/read_documents",
    "POST",
    payload
  );
  return data.result ?? [];
};

// get UI Components
export const getAllUIComponents = async () => {
  let { data } = await networkCall(Config.api_url + "/read_documents", "POST", {
    db_name: Config.db_name,
    entity: "UIcomponents",
    filter: "UIcomponents.active==true",
    return_fields: "UIcomponents",
  });
  return data.result ?? [];
};

export const getScreenIndex = async (metaDataId, version) => {
  let screenQuery = [
    {
      entity: "screens",
      filter: {
        metadataid: metaDataId,
        version: version ?? 1.0,
        activestatus: true,
      },
    },
  ];
  const allScreens = await readDocuments(screenQuery, { limit: 100 });
  console.log(allScreens, "allScreens");
  return allScreens.length + 1;
};
