import React from 'react';
import { OverlayContext } from './contexts';

class AppOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            msg: '',
        }
    }

    close = () => {
        this.setState({
            open: false,
            msg: '',
        })
    }

    set = (props) => {
        this.setState({ ...props })
    }

    render() {
        return (
            <OverlayContext.Provider value={{
                ...this.state, onclose: this.close,
                setSnack: this.set
            }} >
                {this.state.open ? <div id="overlay" onClick={this.close}></div> : ''}
                {this.props.children}
            </OverlayContext.Provider>
        )
    }
}

export default AppOverlay;