import dotenv from "dotenv";
dotenv.config();

let config = {};

config.api_url = process.env.REACT_APP_API_ENDPOINT;
config.api_url_build = process.env.REACT_APP_API_ENDPOINT_BUILD;
config.image_url = process.env.REACT_APP_IMAGE_URL;
config.graphql = process.env.REACT_APP_GQL_ENDPOINT;
config.socket = process.env.REACT_APP_SOCKET;
config.qdm_admin_url = process.env.REACT_APP_QDM_ADMIN_ENDPOINT;
config.db_name = process.env.REACT_APP_DBNAME;
config.node_backend = process.env.REACT_APP_NODE_BACKEND;

export default config;
