import React, { useLayoutEffect, useState } from "react";
import {
  Grid,
  Typography,
  Popover,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  // TopSelect,
  TapSelect,
} from "qdm-component-library";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  lable: {
    margin: "auto 0",
  },
  rowSpace: {
    paddingBottom: "20px",
  },
  rowSpaceBetween: {
    justifyContent: "space-between",
    display: "flex",
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: "#fff !important",
  },
  selecteTab: {
    padding: "0 10px",
  },
}));
const screenTypeOptin = [
  { label: "Screen", value: "Screen" },
  { label: "Component", value: "Component" },
];
export function AddScreen(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    name: "",
    description: "",
    isHome: false,
    isPrivate: false,
    path: "",
    error: {},
  });

  useLayoutEffect(() => {
    const initialFunction = () => {
      if (props.viewType === "edit") {
        setState({
          name: props?.screenData[0]?.name,
          description: props?.screenData[0]?.description,
          isHome: props?.screenData[0]?.isHome,
          isPrivate: props?.screenData[0]?.isPrivate,
          path: props?.screenData[0]?.path,
          type: props?.screenData[0]?.type,
        });
      } else {
        setState({ name: "", description: "" });
      }
    };
    initialFunction();
  }, [props]);

  const onChangeInput = (e, name) => {
    setState({
      ...state,
      [name]:
        e?.target?.value?.length === 0 || e?.target?.value
          ? e?.target?.value
          : e,
    });
  };

  const onChangeSwitch = (e, name, value) => {
    setState({
      ...state,
      [name]: !state[name],
    });
  };

  const isValidFrom = () => {
    if (
      state.name?.length > 3 &&
      state.type?.length > 1 &&
      (state.type === "Screen"
        ? state.path?.length >= 1
        : state.type?.length > 1)
    ) {
      return true;
    } else {
      let errorFiled = {
        name: state.name?.length > 3 ? false : true,
        path: state.path?.length >= 1 ? false : true,
        type: state.type?.length > 1 ? false : true,
      };
      setState({
        ...state,
        error: errorFiled,
      });
      return false;
    }
  };

  const addNewScreen = (e, type) => {
    if (isValidFrom()) {
      setState({
        ...state,
        name: "",
        description: "",
        isHome: false,
        isPrivate: false,
        path: "",
        error: {},
      });
      props.submitAddScreen(e, state, type);
    }
  };

  // const TapCss = {
  //   active: {
  //     background: "#007AFF",
  //     fontSize: "14px"
  //   },
  //   tap: {
  //     background: "#e8e8e8",
  //     fontSize: "14px"
  //   }
  // }

  return (
    <Popover
      elevation={1}
      id={props.id}
      anchorEl={props.anchorEl}
      onClose={(e) => props.modal(e, "close")}
      disableRestoreFocus
      open={props.open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Grid item xs={12}>
        <Typography style={{ padding: "10px 16px 10px" }}>
          {props.viewType === "edit" ? "Update screen" : "Add Screen"}
        </Typography>
      </Grid>
      <Grid
        container
        style={{
          width: "300px",
          padding: "16px 16px 20px",
          borderTop: "1px solid #ebebeb",
        }}
      >
        <Grid item xs={12} className={classes.rowSpace}>
          {/* <TopSelect
                activeStyles={TapCss.active}
                nonactiveStyles={TapCss.tap} 
                label={<p style={{fontSize: "12px"}}>Type *</p>} 
                options={[{label: "Screen", value: "Screen"}, {label: "Component", value: "Component"}]} 
                onChange={(e)=>onChangeInput(e, "type")}
                value={state.type}
            /> */}
          <TapSelect
            selectedBorderColor="#1890ff"
            selectedBackgroundColor="#fff"
            selectedTextColor="#1890ff"
            textSize={"small"}
            label={<p style={{ fontSize: "12px" }}>Screen Type *</p>}
            options={screenTypeOptin}
            onChange={(e) => onChangeInput(e.value, "type")}
            value={
              screenTypeOptin.filter((val) => val.value === state.type)?.[0]
            }
          />
          {state.error?.type && (
            <Typography
              variant={"subtitle2"}
              style={{ color: "red", fontSize: "10px" }}
            >
              Select screen type
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} className={classes.rowSpace}>
          <TextField
            error={state.error?.name ?? false}
            required
            onChange={(e) => onChangeInput(e, "name")}
            fullWidth
            label="Name"
            id="screen_name"
            value={state.name}
            variant="outlined"
            size="small"
          />
        </Grid>
        {state.type === "Screen" && (
          <>
            <Grid item xs={12} className={classes.rowSpace}>
              <TextField
                error={state.error?.path ?? false}
                required
                onChange={(e) => onChangeInput(e, "path")}
                fullWidth
                label="Screen path"
                id="screen_path"
                value={state.path}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
            >
              <Typography className={classes.lable}>
                {"Home Screen?"}
              </Typography>
              <ToggleButtonGroup
                size={"small"}
                value={state.isHome ? "yes" : "no"}
                exclusive
                onChange={(e) => onChangeSwitch(e, "isHome")}
                aria-label="text alignment"
              >
                <ToggleButton
                  value={"yes"}
                  aria-label="left"
                  size={"small"}
                  classes={{
                    root: classes.selecteTab,
                    selected: classes.selectedTab,
                  }}
                >
                  <Typography component={"p"} variant={"caption"}>
                    Yes
                  </Typography>
                </ToggleButton>
                <ToggleButton
                  value={"no"}
                  aria-label="centered"
                  size={"small"}
                  classes={{ root: classes.selecteTab }}
                >
                  <Typography component={"p"} variant={"caption"}>
                    No
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
            >
              <Typography className={classes.lable}>
                {"Private Screen?"}
              </Typography>
              <ToggleButtonGroup
                size={"small"}
                value={state.isPrivate ? "yes" : "no"}
                exclusive
                onChange={(e) => onChangeSwitch(e, "isPrivate")}
                aria-label="text alignment"
              >
                <ToggleButton
                  value={"yes"}
                  aria-label="left"
                  size={"small"}
                  classes={{
                    root: classes.selecteTab,
                    selected: classes.selectedTab,
                  }}
                >
                  <Typography component={"p"} variant={"caption"}>
                    Yes
                  </Typography>
                </ToggleButton>
                <ToggleButton
                  value={"no"}
                  aria-label="centered"
                  size={"small"}
                  classes={{ root: classes.selecteTab }}
                >
                  <Typography component={"p"} variant={"caption"}>
                    No
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </>
        )}
        <Grid item xs={12} className={classes.rowSpace}>
          <TextField
            onChange={(e) => onChangeInput(e, "description")}
            fullWidth
            id="outlined-textarea"
            label="Description"
            value={state.description}
            placeholder="Enter description"
            multiline
            variant="outlined"
            rows={4}
          />
        </Grid>
        <Grid item container justify={"flex-end"}>
          <Button
            onClick={(e) => props.modal(e, "close")}
            size="small"
            color="default"
            style={{ marginRight: "10px", color: "gary" }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => addNewScreen(e, props.viewType)}
            size="small"
            variant="contained"
            color="primary"
          >
            {props.viewType === "edit" ? "Update" : "Add Screen"}
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
}
