import React from "react";

//HOC's
import AppAuth from "./App.auth";
import AppTheme from "./App.theme";
// import AppGQLClient from './App.gqlclient';
import AppAlert from "./App.alert";
import AppOverlay from "./App.overlay";
import AppErrorBoundary from "./App.error_boundry";
import RouterApp from "./router";
import AppDialog from "./App.dialog";
import "./animation.css";

function App() {
  return (
    <AppAuth>
      <AppErrorBoundary>
        <AppTheme>
          {/* <AppGQLClient> */}
          <AppAlert>
            <AppDialog>
              <AppOverlay>
                <RouterApp />
              </AppOverlay>
            </AppDialog>
          </AppAlert>
          {/* </AppGQLClient> */}
        </AppTheme>
      </AppErrorBoundary>
    </AppAuth>
  );
}

export default App;
