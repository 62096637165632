import React from "react";
import { Redirect, Route } from "react-router-dom";
import { localStorageKeys } from "../utils";
import routes from "./routes";
import { useSearchParamsQuery } from "./../hooks";
import Config from "./../config";

const PrivateRoute = ({ children, ...rest }, props) => {
  let query = useSearchParamsQuery();

  const isAuthenticated = () => {
    let metadataIdFromURL = query.get("metadata_id");
    let token = query.get("token");
    let authToken = localStorage.getItem(localStorageKeys.auth_token);
    let metaDataId = localStorage.getItem(localStorageKeys.metaDataId);

    if (!metaDataId && !metadataIdFromURL && !authToken && !token) {
      window.location.replace(Config.qdm_admin_url);
    } else if (metadataIdFromURL && token) {
      let tokenSplitted = token?.split(".")[1];
      localStorage.setItem(localStorageKeys.auth_token, tokenSplitted);
      localStorage.setItem(localStorageKeys.metaDataId, metadataIdFromURL);
      return true;
    } else if (metaDataId && authToken) {
      return true;
    }
  };

  return isAuthenticated() ? (
    <Route {...rest} render={({ location }) => children} />
  ) : (
    <Route
      render={({ location }) => (
        <Redirect
          to={{
            pathname: routes.login,
            state: { from: location },
          }}
        />
      )}
    />
  );
};

export default PrivateRoute;
