import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  makeStyles,
  Chip,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { templates } from "./jsonDatas";
import {
  FindScene,
  uniqeArray,
  filterJSON,
} from "../../../components/commonFunction";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { AuthContext } from "../../../contexts";
import Config from "../../../config";
import config from "../../../config";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  lable: {
    margin: "auto 0",
  },
  rowSpace: {
    paddingBottom: "20px",
  },
  rowSpaceBetween: {
    justifyContent: "space-between",
    display: "flex",
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: "#fff !important",
  },
  selecteTab: {
    padding: "0 10px",
  },
  layoutStyle: {
    cursor: "pointer",
    borderRadius: "4px",
    border: "1px solid lightgray",
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "120px",
    margin: "5px",
  },
  activeLayoutStyle: {
    backgroundColor: "#017afe",
    color: "#fff",
    border: "1px solid #017afe",
  },
  selecteSceneTab: {
    padding: "10px",
    border: "1px solid lightgray",
    borderRadius: "4px",
    "&:hover": {
      // backgroundColor: "#f9f7f7",
      removeBtn: {
        display: "block",
      },
    },
  },
  removeBtn: {
    top: "-2px",
    color: "#f50057",
    padding: "2px",
    right: 0,
    borderRadius: "20px",
    minWidth: "20px",
    backgroundColor: "#ffffff",
    position: "absolute",
    fontSize: "10px",
    textTransform: "capitalize",
    display: "none",
    "&:hover": {
      display: "block",
    },
  },
  imageViewCss: {
    cursor: "pointer",
    width: "100%",
    border: "3px solid #fff",
    borderRadius: "4px",
    height: "100%",
  },
  addScreenBtn: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export function AddScreenStepper(props) {
  console.log(props);

  const auth = React.useContext(AuthContext);
  const classes = useStyles();
  const [state, setState] = useState({
    name: "",
    description: "",
    isHome: false,
    isIdmEnabled: false,
    isPrivate: false,
    path: "/",
    type: "",
    error: {},
    layoutId: null,
    isLayoutScreen: false,
  });

  // Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  // scenes
  const [selectedQpasScenes, setSelectedQpasScenes] = React.useState([]);

  const [scenesDetails, setScenesDetails] = React.useState({
    playFilter: [],
    actFilter: [],
    selectedScemes: [],
  });
  const [playList, setPlayList] = React.useState([]);
  const [actList, setActList] = React.useState([]);

  const [qpassScene, setQpassScene] = React.useState([]);
  const [allQpassScene, setAllQpassScene] = React.useState([]);
  // get qpas scenes list
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var QpasRaw = JSON.stringify({
    db_name: config.db_name,
    entity: "QPAS",
    filter: `QPAS.qpasid == '${localStorage.getItem(
      "UidesignerModelerid"
    )}' sort uibuilder._key DESC limit 1`,
    return_fields: "{QPAS}",
  });
  var QpasRequestOptions = {
    method: "POST",
    headers: myHeaders,
    body: QpasRaw,
    redirect: "follow",
  };
  const fetchDataQpas = async () => {
    await fetch(Config.api_url + "/read_documents", QpasRequestOptions)
      .then((response) => response.json())
      .then((res) => {
        var Data = res.result[res.result.length - 1].QPAS;
        var SceneArray = FindScene(Data.attributes.scene);

        var Pnames = [];
        SceneArray.map((val) => {
          return Pnames.push({ label: val.playName });
        });
        var Anames = [];
        SceneArray.map((val) => {
          return Anames.push({ label: val.actName });
        });
        setPlayList(uniqeArray(Pnames, "label"));
        setActList(uniqeArray(Anames, "label"));
        setAllQpassScene(SceneArray);
        let filter = filterJSON(
          SceneArray,
          auth.user.AllScreenList,
          "",
          [],
          []
        );
        setQpassScene(filter);
      })
      .catch((error) => console.log("error", error));
  };

  useLayoutEffect(() => {
    const initialFunction = () => {
      if (props.viewType === "edit") {
        setState({
          name: auth.user.screens[0]?.name,
          description: auth.user.screens[0]?.description,
          isHome: auth.user.screens[0]?.isHome,
          isIdmEnabled: auth.user.screens[0]?.isIdmEnabled,
          isPrivate: auth.user.screens[0]?.isPrivate,
          path: auth.user.screens[0]?.path,
          type: auth.user.screens[0]?.type ?? "Screen",
        });
        setActiveStep(0);
        setSelectedQpasScenes(auth.user.screens[0]?.scenes);
      } else {
        setActiveStep(0);
        setSelectedQpasScenes([]);
        setState({
          type: props?.type?.length > 0 ? props.type : "",
          path: "/",
        });
      }
    };
    initialFunction();
    // fetchDataQpas();
    // eslint-disable-next-line
  }, [props]);

  useLayoutEffect(() => {
    fetchDataQpas();
    // eslint-disable-next-line
  }, [props.open]);

  const getSteps = () => {
    if (state.type === "Screen" && props.viewType === "edit") {
      return ["QPAS Scenes", "Screen Details"];
    } else if (state.type === "Component") {
      return ["Screen Details"];
    } else {
      return ["QPAS Scenes", "Screen Layout", "Screen Details"];
    }
  };
  const steps = getSteps();

  const isStepOptional = (step) => {
    if (state.type === "Screen" && props.viewType !== "edit") {
      return step === 1 || step === 0;
    } else if (state.type === "Screen" && props.viewType === "edit") {
      return step === 0;
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (e) => {
    const currentStep = activeStep + 1;

    // add screen api trigger
    if (currentStep === steps.length) {
      if (!isValidFrom()) {
        return false;
      }
      return addNewScreen(e, props.viewType);
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  useEffect(() => {
    setQpassScene(qpassScene);
  }, [selectedQpasScenes, qpassScene]);

  const addScreenToScenes = (val) => {
    // let selected = Array.isArray(selectedQpasScenes)?selectedQpasScenes:[];
    // let updatedArray = selected.push(val);
    setSelectedQpasScenes([val]);
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const onChangeInput = (e, name) => {
    if (name === "name" && state.type === "Screen") {
      setState({
        ...state,
        name: e?.target?.value,
        path: "/" + e?.target?.value?.replace(/\s/g, "_").toLocaleLowerCase(),
      });
    } else {
      setState({
        ...state,
        [name]:
          e?.target?.value?.length === 0 || e?.target?.value
            ? e?.target?.value
            : e,
      });
    }
  };

  const onChangeSwitch = (e, name, value) => {
    setState({
      ...state,
      [name]: !state[name],
    });
  };

  const isValidFrom = () => {
    if (
      state.name?.length > 3 &&
      state.type?.length > 1 &&
      (state.type === "Screen"
        ? state.path?.length >= 1
        : state.type?.length > 1)
    ) {
      setState({
        ...state,
        error: {},
      });
      return true;
    } else {
      let errorFiled = {
        name: state.name?.length > 3 ? false : true,
        path: state.path?.length >= 1 ? false : true,
        type: state.type?.length > 1 ? false : true,
      };
      setState({
        ...state,
        error: errorFiled,
      });
      return false;
    }
  };

  const addNewScreen = async (e, type) => {
    // if (isValidFrom()) {
    let result = await props.submitAddScreen(
      e,
      state,
      type,
      selectedQpasScenes
    );
    console.log(result, "result");
    setState({
      name: "",
      description: "",
      isHome: false,
      isPrivate: false,
      path: "/",
      type: "",
      error: {},
      layoutId: null,
      isLayoutScreen: false,
    });
    setActiveStep(0);
    setSelectedQpasScenes([]);
    // }
  };

  const onSelectScene = (e, val, state) => {
    scenesDetails[state] = val;
    // storyName
    let playNames = scenesDetails?.playFilter?.map((val) => val?.label);
    let actNames = scenesDetails?.actFilter?.map((val) => val?.label);
    let searchName = scenesDetails.selectedScemes;

    // let filterApplyed = allQpassScene.filter(val => val.label.includes(sneces) || val.playName.includes(actNames) || val.playName.includes(playNames) );
    let filter = filterJSON(
      allQpassScene,
      auth.user.AllScreenList,
      searchName,
      playNames,
      actNames
    );
    setQpassScene(filter);
    setScenesDetails({ ...scenesDetails });
  };

  const removeScene = (value) => {
    let removed = selectedQpasScenes.filter((val) => val.label !== value.label);
    setSelectedQpasScenes(removed);
  };

  const getStepContent = (step) => {
    step = props.viewType === "edit" ? (step === 1 ? 2 : step) : step;
    if (props.type === "Component") {
      step = 2;
    } else if (state.type === "Component") {
      step = 2;
    }
    switch (step) {
      case 2:
        return (
          <Grid container>
            <Grid item xs={12} className={classes.rowSpace}>
              <Typography variant={"body1"}>
                {"Screen Type: " + state.type}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.rowSpace}>
              <TextField
                error={state.error?.name ?? false}
                required
                onChange={(e) => onChangeInput(e, "name")}
                fullWidth
                label="Name"
                id="screen_name"
                value={state.name}
                variant="outlined"
                size="small"
              />
            </Grid>
            {state.type === "Screen" && (
              <>
                <Grid item xs={12} className={classes.rowSpace}>
                  <TextField
                    error={state.error?.path ?? false}
                    required
                    onChange={(e) => onChangeInput(e, "path")}
                    fullWidth
                    label="Screen path"
                    id="screen_path"
                    value={state.path}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ paddingRight: "14px" }}
                  className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
                >
                  <Typography className={classes.lable}>
                    {"Home Screen?"}
                  </Typography>
                  <ToggleButtonGroup
                    size={"small"}
                    value={state.isHome ? "yes" : "no"}
                    exclusive
                    onChange={(e) => {
                      onChangeSwitch(e, "isHome");
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value={"yes"}
                      aria-label="left"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                        selected: classes.selectedTab,
                      }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        Yes
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value={"no"}
                      aria-label="centered"
                      size={"small"}
                      classes={{ root: classes.selecteTab }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        No
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
                  style={{ paddingRight: "14px" }}
                >
                  <Typography className={classes.lable}>
                    {"Private Screen?"}
                  </Typography>
                  <ToggleButtonGroup
                    size={"small"}
                    value={state.isPrivate ? "yes" : "no"}
                    exclusive
                    onChange={(e) => onChangeSwitch(e, "isPrivate")}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value={"yes"}
                      aria-label="left"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                        selected: classes.selectedTab,
                      }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        Yes
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value={"no"}
                      aria-label="centered"
                      size={"small"}
                      classes={{ root: classes.selecteTab }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        No
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
                >
                  <Typography className={classes.lable}>
                    {"Idm Enabled?"}
                  </Typography>
                  <ToggleButtonGroup
                    size={"small"}
                    value={state.isIdmEnabled ? "yes" : "no"}
                    exclusive
                    onChange={(e) => onChangeSwitch(e, "isIdmEnabled")}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value={"yes"}
                      aria-label="left"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                        selected: classes.selectedTab,
                      }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        Yes
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value={"no"}
                      aria-label="centered"
                      size={"small"}
                      classes={{ root: classes.selecteTab }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        No
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ paddingRight: "14px" }}
                  className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
                >
                  <Typography className={classes.lable}>
                    {"Is Layout?"}
                  </Typography>
                  <ToggleButtonGroup
                    size={"small"}
                    value={state.isLayoutScreen ? "yes" : "no"}
                    exclusive
                    onChange={(e) => {
                      onChangeSwitch(e, "isLayoutScreen");
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value={"yes"}
                      aria-label="left"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                        selected: classes.selectedTab,
                      }}
                      disabled={
                        state.isHome || state?.isPrivate || state?.isIdmEnabled
                      }
                    >
                      <Typography component={"p"} variant={"caption"}>
                        Yes
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value={"no"}
                      aria-label="centered"
                      size={"small"}
                      classes={{ root: classes.selecteTab }}
                      disabled={
                        state.isHome || state?.isPrivate || state?.isIdmEnabled
                      }
                    >
                      <Typography component={"p"} variant={"caption"}>
                        No
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </>
            )}
            <Grid item xs={12} className={classes.rowSpace}>
              <TextField
                onChange={(e) => onChangeInput(e, "description")}
                fullWidth
                id="outlined-textarea"
                label="Description"
                value={state.description}
                placeholder="Enter description"
                multiline
                variant="outlined"
                rows={4}
              />
            </Grid>
            {state?.type === "Component" && (
              <Grid
                item
                xs={12}
                md={4}
                className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
              >
                <Typography className={classes.lable}>
                  {"Idm Enabled?"}
                </Typography>
                <ToggleButtonGroup
                  size={"small"}
                  value={state.isIdmEnabled ? "yes" : "no"}
                  exclusive
                  onChange={(e) => onChangeSwitch(e, "isIdmEnabled")}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value={"yes"}
                    aria-label="left"
                    size={"small"}
                    classes={{
                      root: classes.selecteTab,
                      selected: classes.selectedTab,
                    }}
                  >
                    <Typography component={"p"} variant={"caption"}>
                      Yes
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    value={"no"}
                    aria-label="centered"
                    size={"small"}
                    classes={{ root: classes.selecteTab }}
                  >
                    <Typography component={"p"} variant={"caption"}>
                      No
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            )}
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant={"subtitle2"}
                color={"textSecondary"}
                align={"center"}
                style={{ marginTop: "24px" }}
              >
                {"No Screen Layout"}
              </Typography>
              {/* <div
                className={`${classes.layoutStyle} ${
                  state.layoutId === "Empty" ? classes.activeLayoutStyle : ""
                }`}
                onClick={() => onChangeInput("Empty", "layoutId")}
              >
                {"Empty"}
              </div> */}
            </Grid>
            {/* {templates.map((val, i) => {
              return (
                <Grid item xs={12} md={3}>
                  <Typography variant={"body1"} align={"center"}>
                    {val.name}
                  </Typography>
                  <div
                    className={`${classes.layoutStyle} ${
                      state.layoutId === val.name
                        ? classes.activeLayoutStyle
                        : ""
                    }`}
                    key={i}
                    onClick={() => onChangeInput(val.name, "layoutId")}
                  > */}
            {/* {val.name} */}
            {/* <img
                      alt={val.name}
                      className={classes.imageViewCss}
                      src={val?.preview_url?.length > 0 ? val.preview_url : ""}
                    />
                  </div>
                </Grid>
              );
            })} */}
          </Grid>
        );
      case 0:
        return (
          <div>
            <Grid
              container
              spacing={2}
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "#fff",
              }}
            >
              <Grid item xs={12} md={4}>
                <TextField
                  id="outlined-basic"
                  onChange={(e, newValue) =>
                    onSelectScene(e, e.target.value, "selectedScemes")
                  }
                  fullWidth
                  label="Search Scene Name"
                  margin={"dense"}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  id="combo-box-demo"
                  options={playList}
                  getOptionLabel={(option) => option.label}
                  renderTags={(value, getTagProps) => (
                    <Chip
                      variant={"outlined"}
                      color="primary"
                      size={"small"}
                      style={{ borderRadius: "4px" }}
                      label={"Play Filter " + value.length}
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin={"dense"}
                      label="Play Filter"
                      variant="outlined"
                    />
                  )}
                  onChange={(e, newValue) =>
                    onSelectScene(e, newValue, "playFilter")
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  id="combo-box-demo"
                  options={actList}
                  getOptionLabel={(option) => option.label}
                  renderTags={(value, getTagProps) => (
                    <Chip
                      variant={"outlined"}
                      color="primary"
                      size={"small"}
                      style={{ borderRadius: "4px" }}
                      label={"Act Filter " + value.length}
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin={"dense"}
                      label="Act Filter"
                      variant="outlined"
                    />
                  )}
                  onChange={(e, newValue) =>
                    onSelectScene(e, newValue, "actFilter")
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: "20px" }}>
              {qpassScene && qpassScene?.length > 0 ? (
                qpassScene?.map((val, i) => (
                  <Grid item xs={12} md={12} style={{ position: "relative" }}>
                    <Grid container item className={classes.selecteSceneTab}>
                      <Grid item md={6}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            textTransform: "capitalize",
                          }}
                          variant={"body2"}
                          color={"textPrimary"}
                        >
                          {val.label}
                        </Typography>
                        <Typography
                          variant={"subtitle2"}
                          color={"textSecondary"}
                        >
                          {"Scene #" + val.sceneId} {" | "} {val.storyName}
                          {" | "}
                          {val.playName}
                          {" | "}
                          {val.actName}
                        </Typography>
                      </Grid>
                      <Grid item md={6} className={classes.addScreenBtn}>
                        <Typography style={{ marginRight: "10px" }}>
                          {val?.count > 0 && `${val.count} Screen`}
                        </Typography>
                        {selectedQpasScenes?.length > 0 &&
                        selectedQpasScenes?.filter(
                          (sel) => sel?.label === val?.label
                        )?.[0]?.label === val.label ? (
                          <Button
                            style={{ width: "126px" }}
                            variant={"contained"}
                            onClick={() => removeScene(val)}
                            color={"primary"}
                            size={"small"}
                          >
                            Added Screen
                          </Button>
                        ) : (
                          <Button
                            style={{ width: "126px" }}
                            variant={"outlined"}
                            onClick={() => addScreenToScenes(val)}
                            color={"secondary"}
                            size={"small"}
                          >
                            Add Screen
                          </Button>
                        )}
                      </Grid>
                      {/* <Typography variant={"subtitle1"} color={"secondary"} style={{textAlign: "right"}}> */}
                      <Button
                        className={classes.removeBtn}
                        onClick={() => removeScene(val)}
                        size="small"
                      >
                        <HighlightOffIcon
                          color={"secondary"}
                          fontSize={"small"}
                        />
                      </Button>
                      {/* </Typography> */}
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} md={12} style={{ position: "relative" }}>
                  <Typography
                    variant={"subtitle2"}
                    color={"textSecondary"}
                    align={"center"}
                  >
                    {"No Scene"}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>
        );
      default:
        return "Unknown step";
    }
  };
  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={props.open}
      onClose={(e) => props.modal(e, "close")}
      aria-labelledby="form-dialog-title"
    >
      {/* <DialogTitle id="form-dialog-title"> */}
      <Stepper
        activeStep={activeStep}
        style={{ padding: "24px ​24px 0px 24p" }}
      >
        {steps.length > 0 &&
          steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
      </Stepper>
      {/* </DialogTitle> */}
      <DialogContent
        style={{
          padding: "0px 24px 8px 24px",
          minHeight: "calc(100vh - 240px)",
        }}
      >
        {/* <div> */}
        {getStepContent(activeStep)}
        {/* </div> */}
      </DialogContent>
      <DialogActions style={{ padding: "24px" }}>
        <div>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            Back
          </Button>
          {/* Skip and Proceed */}
          {isStepOptional(activeStep) && (
            <Button
              variant={"outlined"}
              color="primary"
              onClick={handleSkip}
              className={classes.button}
            >
              Skip and Proceed
            </Button>
          )}
          {props.type === "Screen" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              {activeStep === steps.length - 1
                ? props.viewType === "edit"
                  ? "Update"
                  : "Add Screen"
                : "Next"}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              {props.viewType === "edit" ? "Update" : "Add Component"}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}
