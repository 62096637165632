import React from 'react';
import { Grid, Typography, Switch, Button, TextField, Divider, makeStyles } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ConditionForm from './conditionform';
import { AuthContext } from '../../../../contexts';
import { v4 as uuidv4 } from "uuid";
import { TextInput } from 'qdm-component-library';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        ///outer container fixed height copied
        maxHeight: 'calc(100vh - 92px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    body: {
        padding: 20,
        paddingTop: 30,
        flexGrow: 1,
        overflow: 'auto'
    },
    fieldLabel: {
        color: 'rgba(0, 0, 0, 0.38)',
    }
}))

let base_child_index = 0;

const ConditonRenderer = (props) => {
    const classes = useStyles();
    const auth = React.useContext(AuthContext);
    let currentUUID = auth.user.componentDecisionId;

    const [navigationOptions, setNavigationoptions] = React.useState();
    const [entitiesList, setEntitiesList] = React.useState();
    const [entities, setEntities] = React.useState();
    const [state, setState] = React.useState({
        optionsRes: [{
            conditionType: {}
        }]
    });

    /// static action options
    const actionOptions = [
        {
            value: "status code",
            label: "status code"
        },
        {
            value: "onclick",
            label: "onclick"
        }
    ]
    /// static condition options
    const conditionOptions = [
        {
            value: "<",
            label: "lesser than"
        },
        {
            value: ">",
            label: "greater than"
        },
        {
            value: "===",
            label: "equals"
        },
        {
            value: "!==",
            label: "not equals"
        }
    ]
    /// static result option
    const resOptions = [
        {
            value: '200',
            label: '200'
        },
        {
            value: '201',
            label: '201'
        }
    ]

    const handleChange = (event) => {
        let Data = auth.user.AllScreenList[props.index + 1];
        let body = Data.render.child[0].child;
        body[base_child_index].properties["name"] = event.target.value;
        auth.setAuth({
            ...auth.user,
            AllScreenList: auth.user.AllScreenList,
            isEdited: true,
        });
    }
    const handleEntityChange = (value) => {
        if (entities?.title && entities?.title !== value?.title) {
            ///removing all previous condition on entity change
            removeAllCondition();
            setEntities(value)
        } else {
            setEntities(value)
        }
    }
    ///remove all active condition --- decisional based on apply filter
    const removeAllCondition = () => {
        var Data = auth.user.AllScreenList[props.index + 1];
        var arrowLinks = auth.user.AllScreenList[props.index + 1].arrowLink;
        arrowLinks = arrowLinks.filter(item => item.arrowSource !== auth.user.componentDecisionId);
        Data.arrowLink.splice(0);
        Data["arrowLink"].push(...arrowLinks);
        props.updatePropsData()
    }
    ///remove selected target
    const removeTarget = (index) => {
        var arrowLinks = auth.user.AllScreenList[props.index + 1].arrowLink;
        arrowLinks.splice(index, 1);
        auth.setAuth({
            ...auth.user,
            AllScreenList: auth.user.AllScreenList,
            isEdited: true,
        });
    }
    ///handle condition select
    const handleDropdown = (event, newValue, selector, index) => {
        if (selector === "navigationOptions") {
            const { conditionValue, actionValue, resValue, navigationValue } = state.optionsRes[index]["conditionType"];
            if (navigationValue?.value) {
                var parentIndex = state.optionsRes[index].parentIndex;
                var updateJson = auth.user.AllScreenList[props.index + 1].arrowLink;
                updateJson[parentIndex] = {
                    ...updateJson[parentIndex],
                    arrowTarget: newValue.value,
                    pathId: newValue.path,
                    label: newValue.label,
                    middleLabel: actionValue.label + " " + conditionValue.value + " " + resValue.label,
                    conditionType: {
                        ...updateJson[parentIndex].conditionType,
                        navigationValue: newValue
                    }
                }
                auth.setAuth({
                    ...auth.user,
                    AllScreenList: auth.user.AllScreenList,
                    isEdited: true,
                });
            }
            else if (conditionValue && actionValue && resValue) { /// ALLOW if all other actions are enabled
                var getComponent = document?.getElementById(auth.user.componentId);
                let UpdateJson = {};
                var id = getComponent.dataset.id;
                var Data = auth.user.AllScreenList[props.index + 1]?.arrowLink;
                var findindex = Data?.findIndex((x) => x.id === id);
                var startAnchor = index === 0 ? 'bottom' : index === 1 ? 'right' : index === 2 ? 'top' : 'auto'
                if (Data[findindex]?.arrowSource === auth.user.componentId) {
                    UpdateJson = {
                        id: currentUUID,
                        arrowSource: currentUUID,
                        arrowTarget: newValue.value,
                        pathId: newValue.path,
                        label: newValue.label,
                        middleLabel: actionValue.label + " " + conditionValue.value + " " + resValue.label,
                        conditionType: {
                            ...state.optionsRes[index].conditionType,
                            navigationValue: newValue
                        },
                        startAnchor,
                        entity: entities.title,
                        linkType : "screen_link"
                    };
                    auth.user.AllScreenList[props.index + 1].arrowLink[findindex] = UpdateJson;
                } else {
                    UpdateJson = {
                        id: currentUUID,
                        arrowSource: currentUUID,
                        arrowTarget: newValue.value,
                        pathId: newValue.path,
                        label: newValue.label,
                        middleLabel: actionValue.label + " " + conditionValue.value + " " + resValue.label,
                        conditionType: {
                            ...state.optionsRes[index].conditionType,
                            navigationValue: newValue
                        },
                        startAnchor,
                        entity: entities.title
                    };
                    auth.user.AllScreenList[props.index + 1].arrowLink.push(UpdateJson);
                }
                console.log(auth.user.AllScreenList[props.index + 1].arrowLink)
                var screenName = document?.getElementById(newValue.value);
                // targetScreenName = screenName?.dataset.screen;
                auth.setAuth({
                    ...auth.user,
                    AllScreenList: auth.user.AllScreenList,
                    isEdited: true,
                });

                ////validation througher
                state.optionsRes[index]["errorText"] = "fill all fields";
                setState({ ...state });
            } else {
                state.optionsRes[index]["errorText"] = "fill all fields";
                setState({ ...state });
            }
        } else if (selector === "actionOptions") {  ///set action options
            state.optionsRes[index]["conditionType"]["actionValue"] = newValue;
            setState({ ...state });
        } else if (selector === "conditionOptions") { ///set condition options
            state.optionsRes[index]["conditionType"]["conditionValue"] = newValue;
            setState({ ...state });
        } else if (selector === "resOptions") { ///set res options
            state.optionsRes[index]["conditionType"]["resValue"] = newValue;
            setState({ ...state });
        }
    }

    ////fetch screen data's
    React.useEffect(() => {
        const fetchData = () => {
            ////get all valid screens list
            let append = [];
            auth.user.AllScreenList.map((val, index) => {
                if (
                    val.name.toLowerCase().trim().replace(" ", "") !==
                    props.screenName.toLowerCase().trim().replace(" ", "") && !val.ignore
                ) {
                    append.push({
                        value: val.pathId,
                        label: val.name,
                        path: val.path,
                        type: val.type
                    });
                }
                setNavigationoptions(append);
                return null;
            });
            ////get all entities list
            let activeScreen = auth.user.AllScreenList?.filter(({ pathId }) => pathId === props.id)[0];
            let events = activeScreen?.events?.filter(val => val.fromId === auth.user.componentId && val.isApiCall)
            if (events[0]?.apiCalls) {
                let list = events[0].apiCalls.map((item, index) => {
                    if (item?.name) {
                        return { title: item.name, id: index }
                    } else {
                        return { title: item?.EntityDetails?.entity, id: index }
                    }
                })
                console.log(list)
                setEntitiesList(list ?? []);
            }
            // handleEntityChange(data[0])
        };
        fetchData();
    }, [])

    React.useEffect(() => {
        ////set initial entities
        var allDecisions = auth.user.AllScreenList[props.index + 1].arrowLink.filter(item => item.arrowSource === auth.user.componentDecisionId);
        if (allDecisions.length && entitiesList) {
            var curreEntity = entitiesList.filter(item => item.title === allDecisions[0].entity)
            handleEntityChange(curreEntity[0])
        }
    }, [entitiesList])

    // fetch selected decision with parent index as remainder
    React.useEffect(() => {
        let entities = {};
        if (auth.user.componentDecisionId) {
            var allDecisions = auth.user.AllScreenList[props.index + 1].arrowLink;
            allDecisions = allDecisions.filter((item, index) => {
                if (item.id === auth.user.componentDecisionId && item.arrowSource === auth.user.componentDecisionId) {
                    item.parentIndex = index;
                    return true;
                }
                ///parent component
                else if (item.id === auth.user.componentDecisionId && item.arrowSource !== auth.user.componentDecisionId) {
                    state.baseIndex = index;
                }
                return false
            })
            ///set decisions or empty based on
            if (allDecisions.length) {
                state.optionsRes = allDecisions;
                setState({ ...state })
            }
            else {
                state.optionsRes = [{
                    conditionType: {}
                }]
                setState({ ...state })
            }
        }
        let Data = auth.user.AllScreenList[props.index + 1];
        let body = Data.render.child[0]?.child ?? [];
        base_child_index = body.findIndex(item => item.id === auth.user.componentDecisionId);
    }, [props])

    return (
        <div className={classes.root}>
            <div className={classes.body}>
                {
                    state.optionsRes.length ?
                        <>
                            <Typography variant="subtitle1" className={classes.fieldLabel}>
                                Name
                            </Typography>
                            <div>
                                <TextField
                                    id="outlined-helperText"
                                    // label="Name"
                                    value={auth.user.AllScreenList[props.index + 1]?.render?.child[0]?.child[base_child_index]?.properties?.name ?? ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <Typography variant="subtitle1" className={classes.fieldLabel} style={{ marginTop: 20 }}>
                                Choose entity
                            </Typography>
                            <div>
                                <Autocomplete
                                    className={classes.marginTop}
                                    onChange={(event, newValue) =>
                                        handleEntityChange(newValue)
                                    }
                                    id="controllable-states-demo2"
                                    options={entitiesList}
                                    getOptionLabel={(option) => (option.title ? option.title : "")}
                                    value={entities ?? null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="outlined-helperText"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                            {
                                entities?.title && <>
                                    <Typography variant="subtitle1" className={classes.fieldLabel}>
                                        conditions
                                    </Typography>
                                    {
                                        state.optionsRes.map((item, index) => <>
                                            {
                                                (item.entity === entities.title || !item?.conditionType?.navigationValue) && <>
                                                    <ConditionForm
                                                        {...props}
                                                        options={{
                                                            actionOptions: actionOptions,
                                                            conditionOptions: conditionOptions,
                                                            resOptions: resOptions,
                                                            navigationOptions: navigationOptions
                                                        }}
                                                        value={item.conditionType}
                                                        errorText={item?.errorText}
                                                        handleDropdown={handleDropdown}
                                                        index={index}
                                                    />
                                                    <Button
                                                        color="secondary"
                                                        disabled={
                                                            state.optionsRes[index]?.id ? false : true
                                                        }
                                                        style={{ textTransform: "none" }}
                                                        onClick={() => removeTarget(item.parentIndex)}
                                                    >
                                                        Remove Target
                                                    </Button>
                                                </>
                                            }
                                        </>
                                        )
                                    }
                                    <div>
                                        {entities?.title && !state.optionsRes.length == 0 && <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => {
                                                var conditionsLength = state.optionsRes?.length - 1;
                                                if(conditionsLength > -1 && Object.keys(state.optionsRes[conditionsLength]).length > 2){
                                                    state.optionsRes.push({
                                                        conditionType: {}
                                                    });
                                                    setState({ ...state });
                                                }
                                            }}
                                        >
                                            + Add Condition
                                        </Button>}
                                    </div>
                                </>
                            }
                        </> : null
                }
            </div>
            {/* Apply filters */}
            {
                state.optionsRes.length && <div style={{ textAlign: 'center', padding: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.applyDecisionPanel}
                        fullWidth
                    >
                        Apply Filter
                    </Button>
                </div>
            }
            {/* Apply filters END*/}
        </div>
    )
}


export default ConditonRenderer;