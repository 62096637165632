import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
// import { PropertiesPanel, IdmPanel } from "../../components";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    "& .MuiTabs-root": {
      minHeight: "35px",
    },
  },
  tapcss: {
    minWidth: "fit-content",
    padding: "0px 10px",
    fontSize: 13,
    textTransform: "none",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: "#fff",

    "&.MuiTab-root": {
      minHeight: "20px",
    },
  },
  scroller: {
    backgroundSize: "3px 3px",
    backgroundColor: "white",
    "& .MuiTabs-scroller": {
      overflowX: "auto !important",
      height: "28px",
      backgroundColor: "white",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "white",
    },
    "& .MuiTabs-indicator": {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      height: 2,
    },
  },
  close: {
    position: "absolute",
    right: 0,
    zIndex: 9,
  },
  panelHead: {
    display: "flex",
    justifyContent: "space-between",
    backgroundSize: "10px 10px",
    backgroundColor: "#ebebeb6e",
    // backgroundImage: 'linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)',
    "& .text": {
      backgroundColor: "#fff",
      display: "inline-block",
      padding: 10,
      borderTopRightRadius: 6,
    },
  },
  closeIconMargin: {
    margin: theme.spacing(1),
  },
  inputInput: {
    height: "0.9em",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)}px) !important`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  searchIcon: {
    padding: "0px 6px !important",
    position: "absolute !important",
    marginTop: "6px",
    pointerEvents: "none !important",
    display: "flex!important",
    alignItems: "center !important",
    justifyContent: "center!important",
  },
  search: {
    borderRadius: "4px !important",
    borderBottom: "1.3px solid rgba(0, 0, 0, 0.12) !important",
    border: "1.3px solid rgba(0, 0, 0, 0.12) !important",
    marginLeft: "12px",
    marginRight: "20px",
    marginBottom: "10px",
    marginTop: "10px",

    //backgroundColor: fade("#fff", 0.15),
    "&:hover": {
      // backgroundColor: fade("#fff", 0.25),
    },

    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
}));

export function WebAppPanel(props) {
  const classes = useStyles();
  const { groupedComponents, value, handleTabChange } = props;
  const [search, setSearch] = React.useState("");
  const [openAll, setOpenAll] = React.useState(false);
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length > 0) {
      setOpenAll(true);
    } else {
      setOpenAll(false);
    }
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="simple tabs example"
        indicatorColor="primary"
        className={`${classes.scroller} scroll-bar`}
        textColor="primary"
      >
        <Tab label="Web" {...a11yProps(0)} className={classes.tapcss} />
        <Tab label="App" {...a11yProps(2)} className={classes.tapcss} />
        <Tab label="Web/App" {...a11yProps(3)} className={classes.tapcss} />
      </Tabs>

      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon fontSize={"small"} color={"action"} />
        </div>
        <InputBase
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          classes={{
            //   root: classes.inputRoot,
            input: classes.inputInput,
          }}
        />
      </div>
      <div
        style={{
          padding: "16px 3px",
          borderRadius: "5px",
          overflow: "auto",
          margin: "0px 4px",
          maxHeight: "51vh",
          paddingTop: "0px",
        }}
      >
        <TabPanel value={value} index={0}>
          {Object.keys(groupedComponents).map((group) => {
            const components = groupedComponents[group].filter(
              (comp) =>
                comp.supported.includes("web") &&
                comp.componentName.toLowerCase().includes(search.toLowerCase())
            );
            if (components.length > 0) {
              return props.getgrouperComponentsList(
                components,
                props.open,
                "components",
                group,
                openAll,
                setOpenAll
              );
            }
            return null;
          })}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {Object.keys(groupedComponents).map((group) => {
            const components = groupedComponents[group].filter((comp) =>
              comp.supported.includes("app")
            );
            if (components.length > 0) {
              return props.getgrouperComponentsList(
                components,
                props.open,
                "components",
                group,
                openAll,
                setOpenAll
              );
            }
            return null;
          })}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {Object.keys(groupedComponents).map((group) => {
            const components = groupedComponents[group].filter((comp) =>
              comp.supported.includes("web", "app")
            );
            if (components.length > 0) {
              return props.getgrouperComponentsList(
                components,
                props.open,
                "components",
                group,
                openAll,
                setOpenAll
              );
            }
            return null;
          })}
        </TabPanel>
      </div>
    </div>
  );
}
