import React, { useEffect, useRef } from "react";
//These are input components made for chart properties
import {
  Grid,
  Typography,
  TextField,
  makeStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  inputField: {
    "& label": {
      fontSize: 14,
      lineHeight: "normal",
    },
    "& input": {
      fontSize: 14,
    },
    "& fieldset": {
      border: "1px solid #ebebeb",
    },
  },
  iconButton: {
    padding: "6px",
  },
  smallButton: {
    "& .MuiButton-label": {
      fontSize: "12px",
    },
  },
  uploadButton: {
    backgroundColor: "white",
    border: "none",
    padding: "6px 24px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    color: "#4B5563",
    cursor: "pointer",
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  },
}));

export const NumberInput = (props) => {
  const classes = useStyles();
  const { label, value, onChange, max } = props;
  return (
    <TextField
      value={parseInt(value)}
      onChange={(e) => {
        onChange(label, parseInt(e.target.value));
      }}
      type="number"
      label={label}
      min="1"
      max={max}
      className={classes.inputField}
      margin="dense"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
    />
  );
};
NumberInput.defaultProps = {
  label: "Count",
  onChange: () => {},
  value: 0,
  max: 10,
};

export const TextInput = (props) => {
  const classes = useStyles();
  const filter = createFilterOptions();

  const {
    label,
    value,
    languageOptions,
    handleAutocompleteChange,
    setMessageCatalogDialog,
    allLanguages,
    setMessageCatalog,
    messageCatalog,
  } = props;
  return (
    <Autocomplete
      value={{ Title: value ?? "" }}
      freeSolo
      id="combo-box-demo"
      options={languageOptions}
      getOptionLabel={(option) => option.Title}
      renderOption={(option) => {
        if (option?.id === "message catalog") {
          return (
            <Typography
              style={{
                fontSize: "16px",
              }}
            >
              {option.Title}
            </Typography>
          );
        } else {
          return (
            <React.Fragment>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "16px",
                  }}
                  variant={"body2"}
                  color={"textPrimary"}
                >
                  {option.Title}
                </Typography>
                {Object.keys(option).map((key) => {
                  if (key === "Title" || key === "id") {
                    return false;
                  } else {
                    return (
                      <Typography variant={"subtitle2"} color={"textSecondary"}>
                        {key + " : " + option[key]}
                      </Typography>
                    );
                  }
                })}
              </Grid>
            </React.Fragment>
          );
        }
      }}
      renderInput={(params) => (
        <TextField
          size={"small"}
          margin={"dense"}
          className={classes.inputField}
          {...params}
          label={label}
          variant="outlined"
        />
      )}
      onChange={(e, v) => {
        if (v?.id === "message catalog") {
          setMessageCatalogDialog(true);
          allLanguages.length > 0 &&
            allLanguages.map((ln) => {
              return setMessageCatalog({
                Title: v?.value,
                [ln.language]: ln?.language === "English" ? v?.value : "",
                ...messageCatalog,
              });
            });
          handleAutocompleteChange(label, v?.value);
        } else {
          handleAutocompleteChange(label, v?.Title);
        }
      }}
      filterOptions={(options, params) => {
        let updatedParams = { ...params };
        if (params.inputValue === "") {
          let inputValue = value ? value : "";
          updatedParams = {
            ...updatedParams,
            inputValue,
          };
        }
        const filtered = filter(options, updatedParams);
        // Suggest the creation of a new value
        if (
          (params.inputValue !== "" || value) &&
          !options.some(
            (option) =>
              option?.Title.toLowerCase() ===
              (params.inputValue !== ""
                ? params.inputValue.toLowerCase()
                : value
                ? value.toLowerCase()
                : "")
          )
        ) {
          filtered.push({
            id: "message catalog",
            value:
              params.inputValue !== "" ? params.inputValue : value ? value : "",
            Title: `Add ${
              params.inputValue !== "" ? params.inputValue : value ? value : ""
            } to message catalog`,
          });
        }

        return filtered;
      }}
    />
  );
};
TextInput.defaultProps = {
  label: "Label",
  value: null,
  autocompleteFilter: () => {},
  languageOptions: [],
  handleAutocompleteChange: () => {},
  setMessageCatalogDialog: () => {},
  allLanguages: [],
  setMessageCatalog: () => {},
  messageCatalog: {},
};

export const SelectInput = (props) => {
  const classes = useStyles();
  const { label, value, options, handleAutocompleteChange } = props;
  return (
    <Autocomplete
      id="combo-box-demo"
      value={{ title: value ?? "" }}
      options={options}
      onChange={(e, v) => {
        handleAutocompleteChange(label, v?.title);
      }}
      getOptionLabel={(option) => option.title}
      getOptionSelected={(option) => option.title}
      style={{ width: "100%" }}
      className={classes.inputField}
      renderInput={(params) => (
        <TextField
          margin={"dense"}
          {...params}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};
SelectInput.defaultProps = {
  label: "Label",
  value: null,
  options: [],
  handleAutocompleteChange: () => {},
};

export const ColorInput = (props) => {
  const classes = useStyles();
  const { label, value, handleColorChange, index } = props;
  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin={"dense"}
      className={classes.inputField}
    >
      <InputLabel htmlFor="color">Color</InputLabel>
      <OutlinedInput
        label={label}
        id="color"
        value={value}
        onChange={(e) => handleColorChange("colors", index, e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <input
              type="color"
              value={value}
              onChange={(e) =>
                handleColorChange("colors", index, e.target.value)
              }
            />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
ColorInput.defaultProps = {
  label: "Label",
  value: null,
  index: 0,
  handleColorChange: () => {},
};

export const FileCard = (props) => {
  const classes = useStyles();
  const {
    fileName,
    fileSize,
    fileType,
    uploading,
    type,
    action,
    actionText,
    handleAction,
    multiple,
    accept,
    src,
  } = props;
  const inputRef = useRef(null);

  const handleInputClick = () => {
    inputRef.current.value = "";
    inputRef.current.click();
  };
  return (
    <div
      style={{
        display: "grid",
        border: "1px solid #E2E8F0",
        gridTemplateColumns: "15% 65% 20%",
        borderRadius: "5px",
        padding: "4px 10px",
        alignItems: "center",
        boxShadow:
          "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
        backgroundColor: "white",
      }}
    >
      <div style={{ display: "grid", alignSelf: "center" }}>
        {uploading && type !== "file" && (
          <Skeleton variant="rect" width={36} height={36} />
        )}
        {type === "file" && (
          <FileCopyIcon style={{ fontSize: "30px", color: "#94A3B8" }} />
        )}
        {type === "image" && src && (
          <img
            src={src}
            style={{ width: "36px", height: "36px", borderRadius: "3px" }}
            alt=""
          />
        )}
      </div>
      <div>
        <Typography
          variant="body1"
          style={{ fontSize: "14px", color: "#475569", wordBreak: "break-all" }}
          gutterBottom
        >
          {fileName}
        </Typography>
        <div style={{ display: "flex" }}>
          <Typography
            variant="body1"
            style={{ fontSize: "12px", color: "#64748B" }}
            gutterBottom
          >
            type : {fileType}
          </Typography>
          {fileSize && (
            <Typography
              variant="body1"
              style={{ fontSize: "12px", color: "#64748B", marginLeft: "6px" }}
              gutterBottom
            >
              Size : {fileSize}
            </Typography>
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {action && (
          <div onChange={handleAction}>
            <input
              ref={inputRef}
              accept={accept}
              multiple={multiple}
              style={{ display: "none" }}
              type="file"
            />
            <Button
              size="small"
              onClick={handleInputClick}
              className={classes.smallButton}
              color="primary"
            >
              {actionText}
            </Button>
          </div>
        )}
        {uploading && (
          <Typography
            variant="body1"
            style={{ fontSize: "12px", color: "#007AFF", marginLeft: "6px" }}
            gutterBottom
          >
            Uploading...
          </Typography>
        )}
      </div>
    </div>
  );
};

FileCard.defaultProps = {
  fileName: "index.html",
  fileSize: null,
  fileType: "html",
  uploading: false,
  type: "file",
  action: false,
  actionText: "Change",
  handleAction: () => {},
  multiple: false,
  accept: "text/html",
};

export const UploadButton = (props) => {
  const classes = useStyles();

  const { handleChange, multiple, accept, name } = props;
  const handleInputClick = () => {
    inputRef.current.value = "";
    inputRef.current.click();
  };
  const inputRef = useRef(null);

  return (
    <div
      onChange={handleChange}
      style={{ display: "grid", placeItems: "center" }}
    >
      <input
        ref={inputRef}
        accept={accept}
        multiple={multiple}
        style={{ display: "none" }}
        type="file"
      />
      <button onClick={handleInputClick} className={classes.uploadButton}>
        <span style={{ marginRight: "12px" }}>
          <CloudUploadIcon style={{ fontSize: 20, color: "#94A3B8" }} />
        </span>
        Upload {name ? name : ""}
      </button>
    </div>
  );
};

UploadButton.defaultProps = {
  handleChange: () => {},
  multiple: false,
  accept: "text/html",
  name: "",
};
