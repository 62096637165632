import React from "react";
import {
  fade,
  makeStyles,
  Grid,
  Typography,
  IconButton,
  ListItem,
  ListItemText,
  Avatar,
  Tooltip,
  TextField,
  Popover,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ScreenSearchPopover from "./screenSearchPopover";
import { alertProps } from "../../../utils";
// import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const options = [
  "Delete",
  "Edit",
  // 'Tree View'
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    marginTop: 20,
    marginBottom: 16,
    borderRadius: 4,
    borderBottom: "1.3px solid rgba(0, 0, 0, 0.12)",
    border: "1.3px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: fade("#fff", 0.15),
    "&:hover": {
      backgroundColor: fade("#fff", 0.25),
    },
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: "0px 6px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    height: "0.9em",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  addButton: {
    border: "1px solid " + theme.palette.text.hint,
    borderRadius: "4px",
  },
  switchButton: {
    margin: 0,
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 0px",
  },
  MenuItem: {
    color: "black",
    cursor: "pointer",
    borderRadius: "4px",
    textTransform: "capitalize",
    padding: "4px 2px 4px 4px",
  },
  selected: {
    /* Increase the specificity */
    // color: "#3f51b5",
    backgroundColor: "#F6F6F8 !important",
  },
  dragIcon: {
    fontSize: "14px",
    marginRight: "4px",
  },
  previewImage: {
    width: "26px",
    marginRight: "6px",
    fontSize: "12px",
    height: "32px",
  },
  deleteIcon: {
    width: 20,
    height: 20,
    color: theme.palette.error.light,
    cursor: "pointer",
    opacity: 0.9,
    "&:hover": {
      opacity: 1,
      color: theme.palette.error.main,
    },
  },
  addIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    opacity: 0.9,
    "&:hover": {
      opacity: 1,
    },
  },
}));

const initialState = {
  position: null,
  data: null,
};
export function FlowsList(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(initialState);
  const [anchorMenu, setAnchorMenu] = React.useState({
    id: "",
    open: false,
    anchorEl: null,
  });
  const open = Boolean(anchorEl.position);
  const [selectedAllScreen, setSelectedAllScreen] = React.useState(false);
  const [addFlow, setAddflow] = React.useState(false);
  const [activeGroup, setActiveGroup] = React.useState(null);
  const [addSreenAnchor, setAddScreenAnchor] = React.useState({
    anchorEl: null,
    open: false,
    id: "",
  });

  const handlAnchorMenuClose = () => {
    setAnchorMenu({
      id: "",
      anchorEl: "",
      open: false,
    });
  };
  const handleClick = (event, id) => {
    setAnchorEl({
      ...anchorEl,
      position: event.currentTarget,
      data: id,
    });
  };
  React.useEffect(() => {
    props.auth.setAuth({
      activeGroup: activeGroup,
    });
  }, [activeGroup]);

  const handleClose = () => {
    setAnchorEl(initialState);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    // {droppableId:'',index:''}
    var flowGroup = props.auth.user.flowGroup;
    let source = result.source;
    let destination = result.destination;
    /// find and remove source index
    let activeSourceIndex = flowGroup.findIndex(
      (item) => item.groupName === source.droppableId
    );
    let removedScreen;
    var gap = 1;
    if (
      flowGroup[activeSourceIndex].screensList[source.index + 1]?.type ===
      "decisionHandler"
    ) {
      removedScreen = flowGroup[activeSourceIndex].screensList.splice(
        source.index,
        2
      );
    } else {
      removedScreen = flowGroup[activeSourceIndex].screensList.splice(
        source.index,
        1
      );
      gap = 2;
    }

    ///find and insert destination index
    let activeDestinationIndex = flowGroup.findIndex(
      (item) => item.groupName === destination.droppableId
    );
    // if (destination.index > source.index) {
    //   destination.index += gap;
    // }
    ///place all removed item
    removedScreen.forEach((item, index) => {
      flowGroup[activeDestinationIndex].screensList.splice(
        destination.index + index,
        0,
        item
      );
    });

    props.auth.setAuth({
      ...props.auth.user,
      flowGroup: flowGroup,
    });
    setActiveGroup(destination.droppableId);
    setActiveGroupLayout(destination.destination);
  };

  const onClickScreenButton = (selected) => {
    let selecte_id = props.auth.user.AllScreenList[0]?.id;

    if (selected === "checkBox") {
      let screen = props.auth.user.AllScreenList.filter(
        (val) => val.id === selecte_id
      );

      setSelectedAllScreen(!selectedAllScreen);
      props.auth.setAuth({
        ...props.auth.user,
        // screens: !selectedAllScreen ? props.auth.user.AllScreenList : screen,
        // selectedScreen: !selectedAllScreen ? null : selecte_id,
      });
    } else {
      let screen = props.auth.user.AllScreenList.filter(
        (val) => val.id === selected.id
      );
      setSelectedAllScreen(false);
      props.auth.setAuth({
        ...props.auth.user,
        // screens: screen,
        // selectedScreen: selected.id,
      });
    }
  };

  const moreOptions = (e, type) => {
    switch (type) {
      case "Delete":
        props.deleteOldScreen(anchorEl.data);
        handleClose();
        break;
      case "Edit":
        props.openModal(anchorEl.position, "edit", anchorEl.data);
        handleClose();
        break;
      case "Tree View":
        props.treeView(2);
        handleClose();
        break;
      default:
        handleClose();
        break;
    }
  };

  const addNewFlow = (event) => {
    if (event.keyCode == 13) {
      var flowGroup = props.auth.user.flowGroup;
      var isExisting = flowGroup.filter(
        (item) => item.groupName === event.target.value
      );
      if (isExisting.length === 0) {
        flowGroup.push({ groupName: event.target.value, screensList: [] });
        setAddflow(false);
        props.auth.setAuth({
          ...props.auth.user,
          flowGroup: flowGroup,
        });
      } else {
        props.alert.setSnack({
          ...props.alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "Group Already exists",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      }
    }
  };
  const setFlowGroup = (screenObj) => {
    return {
      name: screenObj.name,
      id: screenObj.id,
      ignore: screenObj.ignore,
      type: screenObj.type,
    };
  };
  const getSelectedScreen = (screenObj, index) => {
    var flowGroup = props.auth.user.flowGroup;
    var isValid = flowGroup[addSreenAnchor.index]?.screensList.filter(
      (item) => item.name === screenObj.name
    ).length;
    if (!isValid) {
      ///swap current screen
      flowGroup[addSreenAnchor.index].screensList.push(setFlowGroup(screenObj));
      var handlerScreen = props.auth.user.AllScreenList[index + 1];
      if (handlerScreen?.type === "decisionHandler") {
        flowGroup[addSreenAnchor.index].screensList.push(
          setFlowGroup(handlerScreen)
        );
      }
      props.auth.setAuth({
        ...props.auth.user,
        flowGroup: flowGroup,
      });
    } else {
      props.alert.setSnack({
        ...props.alert,
        open: true,
        severity: alertProps.severity.error,
        msg: `${screenObj.name} Screen Already exists in onboarding ${
          flowGroup[addSreenAnchor.index].groupName
        }`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    addSreenToGroup(null);
  };
  const setActiveGroupLayout = (value) => {
    var selectedScreen = props.auth.user?.AllScreenList.filter((item, index) => {
      if (
        (item.type === "decisionHandler" &&
          props.auth.user?.AllScreenList[index - 1]?.groupName === value) ||
        item?.groupName === value
      ) {
        return true;
      }
    });
    props.auth.setAuth({
      ...props.auth.user,
      AllScreenList: props.auth.user.AllScreenList,
    });
  };
  const removeGroupItem = (e, parentIndex, childIndex) => {
    console.log(parentIndex, childIndex);
    var flowGroup = props.auth.user.flowGroup;
    var totalGroupsLength = flowGroup[parentIndex].screensList;
    if (
      flowGroup[parentIndex].screensList[childIndex + 1]?.type ===
      "decisionHandler"
    ) {
      flowGroup[parentIndex].screensList.splice(childIndex, 2);
    } else {
      flowGroup[parentIndex].screensList.splice(childIndex, 1);
    }
    props.auth.setAuth({
      ...props.auth.user,
      flowGroup: flowGroup,
    });
    if (totalGroupsLength.length == 0) {
      handleActiveGroup(null);
    }
  };
  const removeGlobalDialog = () => {
    props.dialog.setDialog({
      ...props.dialog,
      open: false,
      title: "Save Alert",
      body: "Do you need to save the changes?",
      positiveBtn: "Leave the chages",
      negativeBtn: "Yes",
      onOk: () => {},
      onCancel: () => {},
      loading: false,
    });
  };
  const deleteGroup = (groupName) => {
    props.dialog.setDialog({
      ...props.dialog,
      open: true,
      title: "Remove Alert",
      body: `Do you need to remove ${groupName} group?`,
      positiveBtn: "Cancel",
      negativeBtn: "Delete",
      onOk: () => removeGlobalDialog(),
      onCancel: () => {
        if (groupName === activeGroup) {
          setActiveGroup(null);
        }
        ///reset groupname on groupdelete
        props.auth.user.AllScreenList.forEach((item, index) => {
          if (item.groupName === groupName) {
            props.auth.user.AllScreenList[index].groupName = "others";
          }
        });
        var flowGroup = props.auth.user.flowGroup.filter(
          (item) => item.groupName !== groupName
        );
        props.auth.setAuth({
          ...props.user,
          allScreenList: props.auth.user.AllScreenList,
          flowGroup: flowGroup,
        });
        removeGlobalDialog();
      },
      loading: false,
    });
    handlAnchorMenuClose();
  };
  const addSreenToGroup = (currentTarget, groupName, index) => {
    setAddScreenAnchor({
      anchorEl: currentTarget,
      open: groupName ? true : false,
      id: groupName,
      index: index >= 0 ? index : null,
    });
    if (groupName) handleActiveGroup(groupName);
    ///ANCHOR MENU CLOSE AFTER ADD
    handlAnchorMenuClose();
  };
  const handleActiveGroup = (value) => {
    setActiveGroup(value);
    setActiveGroupLayout(value);
  };
  return (
    <div className={classes.root}>
      {/* Flows list */}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        style={{ padding: "16px 16px 0px" }}
      >
        <Grid>
          <Typography style={{ fontWeight: 600 }}>Flows</Typography>
        </Grid>
        {/* Add new flow */}
        <IconButton
          className={classes.addButton}
          aria-describedby={props.id}
          onClick={() => setAddflow(true)}
          size={"small"}
          aria-label="show 4 new mails"
          color={"default"}
        >
          <Tooltip title="Add new flow" placement="right-start">
            <AddIcon fontSize={"small"} />
          </Tooltip>
        </IconButton>
      </Grid>
      {/* Add new flow END */}
      {/* add flow field box START */}
      {addFlow ? (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          style={{ padding: "16px 16px 10px" }}
        >
          <TextField
            id="standard-basic"
            label="Add new flow"
            variant="outlined"
            size="small"
            onKeyDown={addNewFlow}
          />
        </Grid>
      ) : null}
      {/* add flow field box END */}
      {/* Flow List items */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          style={{ padding: "16px 16px 10px" }}
        >
          {props.auth.user.flowGroup.map((item, index1) => {
            return (
              <>
                {/* group header START */}
                <div
                  style={{
                    backgroundColor:
                      activeGroup === item.groupName ? "#F6F6F8" : "",
                    borderRadius: 6,
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    onClick={() => {
                      if (activeGroup === item.groupName)
                        handleActiveGroup(null);
                      else handleActiveGroup(item.groupName);
                    }}
                  >
                    <IconButton
                      size={"small"}
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                    >
                      {item.groupName === activeGroup ? (
                        <ArrowDropUpIcon fontSize={"small"} color={"action"} />
                      ) : (
                        <ArrowDropDownIcon
                          fontSize={"small"}
                          color={"action"}
                        />
                      )}
                    </IconButton>
                    {item.groupName}
                  </Grid>
                  <Grid>
                    {/* <IconButton
                        size={"small"}
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={() => deleteGroup(item.groupName)}
                      >
                        <DeleteOutlineIcon fontSize={"small"} className={classes.deleteIcon} />
                      </IconButton>
                      <IconButton
                        size={"small"}
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        id={`${item.groupName}popover`}
                        onClick={(event) => {
                          alert(event.currentTarget);
                          addSreenToGroup(event?.currentTarget, item.groupName, index1);
                        }}
                      >
                        <AddIcon
                          fontSize={"small"}
                          className={classes.addIcon}
                        />
                      </IconButton> */}
                    <MoreVertIcon
                      id={`${item.groupName}popover`}
                      fontSize={"small"}
                      size={"small"}
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      className={classes.addIcon}
                      onClick={(event) => {
                        setAnchorMenu({
                          id: `${item.groupName}popover`,
                          open: true,
                          anchorEl: event?.currentTarget,
                          groupName: item.groupName,
                          compIndex: index1,
                          compTarget: event.currentTarget,
                        });
                      }}
                    />
                  </Grid>
                </div>
                {/* group header END */}
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                  style={{ padding: "0px 16px 0px" }}
                >
                  <Grid container item>
                    <Droppable droppableId={item.groupName} type="screens">
                      {(dropProvided, dropSnapshot) => (
                        <div
                          {...dropProvided.draggableProps}
                          ref={dropProvided.innerRef}
                          style={{
                            background: `${
                              dropSnapshot.isDraggingOver ? "#F9FAFB" : "white"
                            }`,
                            borderRadius: `${
                              dropSnapshot.isDraggingOver ? "5px" : "0px"
                            }`,
                            padding: `${
                              dropSnapshot.isDraggingOver ? "10px 4px" : "0px"
                            }`,
                          }}
                        >
                          <div
                            style={{
                              display:
                                item.groupName === activeGroup ? "" : "none",
                            }}
                          >
                            {item.screensList?.map((list, index) => {
                              if (!list.ignore) {
                                return (
                                  <Draggable
                                    key={list.id}
                                    draggableId={list.id}
                                    index={index}
                                  >
                                    {(dragProvided, dragSnapshot) => (
                                      <div
                                        {...dragProvided.draggableProps}
                                        ref={dragProvided.innerRef}
                                        key={list.id}
                                        index={index}
                                      >
                                        <ListItem
                                          key={list.id}
                                          // draggable={true}
                                          id={list.id}
                                          style={{
                                            width: "220px",
                                            margin: "5px 0px",
                                            display: `${
                                              list.type == "decisionHandler"
                                                ? "none"
                                                : "flex"
                                            }`,
                                          }}
                                          key={index}
                                          dense
                                          classes={{
                                            root: classes.MenuItem,
                                            selected: classes.selected,
                                          }}
                                        >
                                          <span
                                            {...dragProvided.dragHandleProps}
                                          >
                                            <DragIndicatorIcon
                                              className={classes.dragIcon}
                                            />
                                          </span>
                                          <Avatar
                                            alt={list.name}
                                            onClick={() =>
                                              onClickScreenButton(list)
                                            }
                                            className={classes.previewImage}
                                            variant="rounded"
                                            src={
                                              list?.image ??
                                              "/static/images/avatar/1.jpg"
                                            }
                                          />
                                          <ListItemText
                                            primary={list.name}
                                            onClick={() =>
                                              onClickScreenButton(list)
                                            }
                                          />
                                          <IconButton
                                            size={"small"}
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(e) =>
                                              removeGroupItem(e, index1, index)
                                            }
                                          >
                                            <RemoveIcon
                                              fontSize={"small"}
                                              color={"action"}
                                              className={classes.deleteIcon}
                                            />
                                          </IconButton>
                                        </ListItem>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              }
                            })}
                            {dropProvided.placeholder}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </Grid>
                </Grid>
              </>
            );
          })}
          <ScreenSearchPopover
            anchor={addSreenAnchor}
            setAnchor={addSreenToGroup}
            getSelectedScreen={getSelectedScreen}
          />
          <Menu
            id={anchorMenu?.id}
            anchorEl={anchorMenu?.anchorEl}
            keepMounted
            open={anchorMenu?.open}
            onClose={handlAnchorMenuClose}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              dense
              onClick={() => {
                addSreenToGroup(
                  anchorMenu.compTarget,
                  anchorMenu.groupName,
                  anchorMenu.compIndex
                );
              }}
            >
              Add
            </MenuItem>
            <MenuItem
              dense
              onClick={() => {
                deleteGroup(anchorMenu.groupName);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Grid>

        {/* all screen's list START*/}
        {/* <Grid
          container
          item
          style={{
            justifyContent: "center",
            maxHeight: "calc(100vh - 225px)",
            overflow: "auto",
          }}
          id={"screenList"}
        >
          <Droppable droppableId="others" type="screens">
            {(dropProvided, dropSnapshot) => (
              <div
                {...dropProvided.draggableProps}
                ref={dropProvided.innerRef}
                style={{
                  background: `${dropSnapshot.isDraggingOver ? "#F9FAFB" : "white"
                    }`,
                  borderRadius: `${dropSnapshot.isDraggingOver ? "5px" : "0px"
                    }`,
                  padding: `${dropSnapshot.isDraggingOver ? "10px 4px" : "0px"
                    }`,
                }}
              >
                {props.auth.user.AllScreenList?.map((list, index) => {
                  return (
                    <Draggable
                      key={list.id}
                      draggableId={list.id}
                      index={index}
                    >
                      {(dragProvided, dragSnapshot) => (
                        <div
                          {...dragProvided.draggableProps}
                          ref={dragProvided.innerRef}
                          key={list.id}
                          index={index}
                        >
                          <ListItem
                            key={list.id}
                            // draggable={true}
                            id={list.id}
                            style={{
                              width: "240px",
                              margin: "5px 0px",
                              display: `${list.type == "decisionHandler" ? "none" : "flex"
                                }`,
                            }}
                            key={index}
                            dense
                            selected={
                              props.auth.user.selectedScreen === list.id
                                ? true
                                : false
                            }
                            classes={{
                              root: classes.MenuItem,
                              selected: classes.selected,
                            }}
                          >
                            <span {...dragProvided.dragHandleProps}>
                              <DragIndicatorIcon className={classes.dragIcon} />
                            </span>
                            <Avatar
                              alt={list.name}
                              onClick={() => onClickScreenButton(list)}
                              className={classes.previewImage}
                              variant="rounded"
                              src={list?.image ?? "/static/images/avatar/1.jpg"}
                            />
                            <ListItemText
                              primary={list.name}
                              onClick={() => onClickScreenButton(list)}
                            />
                          </ListItem>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </Grid>
         */}
        {/* all screen's list END*/}
      </DragDropContext>
      {/* Flow List items END */}
      {/* Flow list end */}

      {/* More option menu list based on( Screen ) */}
      <Menu
        id="long-menu"
        anchorEl={anchorEl.position}
        keepMounted
        open={open}
        onClose={() => handleClose()}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((option) => (
          <MenuItem
            dense
            key={option}
            selected={option === "Pyxis"}
            onClick={(e) => moreOptions(e, option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      {/* More option menu list END */}
    </div>
  );
}
