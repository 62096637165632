import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { AuthContext } from '../../../../contexts';
import { InitiateButton, EventCard, ActionController } from './components';
import { getElementData } from './utils';

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "auto",
        backgroundSize: "20px 20px",
        padding: "30px",
        width: '100%'
    },
    cardHolder: {
        display: 'flex',
        justifyContent: 'center'
        // backgroundColor:'orange'
    }
}))
const BubbleSetup = (props) => {
    const classes = useStyles();
    const auth = React.useContext(AuthContext);
    const [allAppEvents, setAllAppEvents] = React.useState([])
    const [selectedCard, setSelectedCard] = React.useState(null)
    const [selectedArrow, setSelectedArrow] = React.useState(null)

    React.useEffect(() => {
        let allScreenList = auth.user?.AllScreenList ?? [];
        let eventsList = []
        allScreenList.forEach((item, index) => {
            if (item && item?.events) {
                var events = item?.events?.map(event => ({ ...event, screenIndex: index }))
                eventsList.push(...events)
            }
        })
        setAllAppEvents(eventsList);
    }, [auth])

    const cardAddAction = () => {
        setSelectedCard(null)
        props.setRightMenu(true, "selectElements")
    }

    const handleCardSelection = (item, index) => {
        setSelectedCard({
            event: item,
            screenIndex: index
        })
        let elementData = [];
        let currentScreen = auth.user?.AllScreenList[index];
        setSelectedArrow(currentScreen?.arrowLink ?? []);
        getElementData(currentScreen?.render?.child, item?.fromId, elementData)
        props.getComponentId(index, elementData?.[0], currentScreen.id, currentScreen.pathId, index, currentScreen.name);
    }

    const cardEditAction = (item, index) => {
        props.setRightMenu(true, "selectElements", item)
        var injectActive = document.getElementsByClassName("display-card")[index];
        injectActive.classList.add("active")
    }


    return <div className={classes.root}>
        <Grid container>
            {
                allAppEvents?.map((item, index) => {
                    return <Grid item sm={4} xs={12} md={3} lg={2} xl={2} className={classes.cardHolder}>
                        <EventCard cardAction={() => handleCardSelection(item, item.screenIndex)} onEdit={() => cardEditAction(item, index)} event={item} />
                    </Grid>
                })
            }
            <Grid item sm={4} xs={12} md={3} lg={2} xl={2} className={classes.cardHolder}>
                <InitiateButton
                    cardAction={cardAddAction}
                    allScreenList={props?.allScreenList} />
            </Grid>
        </Grid>
        <Grid container>
            {
                selectedCard && <ActionController
                    setRightMenu={props?.setRightMenu}
                    selectedArrow={selectedArrow}
                    selectedCard={selectedCard}
                    allScreenList={auth.user?.AllScreenList}
                />
            }
        </Grid>
    </div>
}

export default BubbleSetup;