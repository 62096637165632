import React from "react";
import { debounce } from "lodash";
import { NotificationStatus, AuthContext } from "./contexts";
import Config from "./config";
import Axios from "axios";
class AppNotifyAutosave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveStatus: null,
      notification: "",
    };
    this.update = debounce(this._update.bind(this), 5000);
  }

  componentDidMount() {
    // this.refreshAPI();
    this.previousContext = JSON.parse(JSON.stringify(this.context));
  }

  // componentDidUpdate() {
  //   let cont = this.previousContext;
  //   if (
  //     this.context.user.undoRedoData?.length !== cont.user.undoRedoData.length
  //   ) {
  //     if (this.context.user.isEdited && !this.context.user.undoRedo) {
  //       this.update();
  //     }
  //     this.previousContext = JSON.parse(JSON.stringify(this.context));
  //   }
  // }

  // auto save metaJSON Data's
  _update = async (text) => {
    this.setState({
      ...this.state,
      saveStatus: "Auto Saving...",
    });
    console.log("update");
    let dataList = {
      db_name: Config.db_name,
      entity: "uibuilder",
      is_metadata: false,
      projectname: localStorage.getItem("projectName"),
      doc: {
        metadataId: localStorage.getItem("metaDataId"),
        UidesignerModelerid: localStorage.getItem("UidesignerModelerid"),
        projectname: localStorage.getItem("projectName"),
        attributes: this.context.user.AllScreenList,
        projectTemplates: this.context.user.projectTemplates,
      },
    };
    let data = JSON.stringify([dataList]);
    var config = {
      method: "post",
      url: Config.api_url + "/upsert_document",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await Axios;
    Axios(config)
      .then((response) => {
        console.log("Auto Save Response" + response);
        setTimeout(() => {
          this.setState({
            ...this.state,
            saveStatus: null,
          });
        }, 2000);
      })
      .catch((err) => {
        console.log("Auto Save Error" + err);
        this.setState({
          ...this.state,
          saveStatus: "error",
        });
      });
  };

  setNotifyState = (props) => {};
  render() {
    return (
      <>
        {
          // this.state.isRefreshed ?
          <NotificationStatus.Provider
            value={{ notify: this.state, setNotifyState: this.setNotifyState }}
          >
            {this.props.children}
          </NotificationStatus.Provider>
          // : <>Your Loader Here...</>
        }
      </>
    );
  }
}

AppNotifyAutosave.contextType = AuthContext;

export default AppNotifyAutosave;
