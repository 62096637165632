export const languageCodes = {
  Amharic: "am",
  Arabic: "ar",
  Bangla: "bn",
  Belarusian: "be",
  Bulgarian: "bg",
  ChineseHongKong: "yue-hant",
  ChineseSimplified: "zh",
  ChineseTraditional: "zh-hant",
  French: "fr",
  German: "de",
  Greek: "el",
  Gujarati: "gu",
  Hebrew: "he",
  Hindi: "hi",
  Italian: "it",
  Japanese: "ja",
  Kannada: "kn",
  Malayalam: "ml",
  Marathi: "mr",
  Nepali: "ne",
  Odia: "or",
  Persian: "fa",
  PortugueseBrazil: "pt",
  Punjabi: "pa",
  Russian: "ru",
  Sanskrit: "sa",
  Serbian: "sr",
  Sinhala: "si",
  Spanish: "es",
  Tamil: "ta",
  Telugu: "te",
  Tigrinya: "ti",
  Ukrainian: "uk",
  Urdu: "ur",
  Vietnamese: "vi",
};

export const propertiesToAvoidInDC = [
  "MetaDataId",
  "DbName",
  "keyCloakEndPoint",
];

export const propertiesToAvoidInQDMC = [
  "MetaDataId",
  "DbName",
  "keyCloakEndPoint",
  "helperTextType",
  "maxLength",
  "minLength",
  "isRequired",
];

export const validationsRequiredComponents = [
  "TextInput",
  "DateTimepicker",
  "TextArea",
];

//Validation Properties

//min, max, minLength, maxLength, isRequired, helperText, regex

export const validationProperties = {
  TextInput: {
    text: ["isRequired", "minLength", "maxLength", "helperText", "regex"],
    email: ["isRequired", "helperText", "regex"],
    password: ["isRequired", "minLength", "maxLength", "regex"],
    number: ["isRequired", "min", "max", "regex"],
  },
  DateTimepicker: {
    time: ["isRequired", "min", "max"],
    date: ["isRequired", "min", "max"],
  },
  TextArea: ["isRequired", "maxLength"],
};

export const iconsList = [
  "ac_unit",
  "access_alarm",
  "access_alarms",
  "access_time",
  "accessibility",
  "accessibility_new",
  "accessible",
  "accessible_forward",
  "account_balance",
  "account_balance_wallet",
  "account_box",
  "account_circle",
  "adb",
  "add",
  "add_a_photo",
  "add_alarm",
  "add_alert",
  "add_box",
  "add_circle",
  "add_circle_outline",
  "add_comment",
  "add_location",
  "add_photo_alternate",
  "add_shopping_cart",
  "add_to_home_screen",
  "add_to_photos",
  "add_to_queue",
  "adjust",
  "airline_seat_flat",
  "airline_seat_flat_angled",
  "airline_seat_individual_suite",
  "airline_seat_legroom_extra",
  "airline_seat_legroom_normal",
  "airline_seat_legroom_reduced",
  "airline_seat_recline_extra",
  "airline_seat_recline_normal",
  "airplanemode_active",
  "airplanemode_inactive",
  "airplay",
  "airport_shuttle",
  "alarm",
  "alarm_add",
  "alarm_off",
  "alarm_on",
  "album",
  "all_inbox",
  "all_inclusive",
  "all_out",
  "alternate_email",
  "android",
  "announcement",
  "apps",
  "archive",
  "arrow_back",
  "arrow_back_ios",
  "arrow_downward",
  "arrow_drop_down",
  "arrow_drop_down_circle",
  "arrow_drop_up",
  "arrow_forward",
  "arrow_forward_ios",
  "arrow_left",
  "arrow_right",
  "arrow_right_alt",
  "arrow_upward",
  "art_track",
  "aspect_ratio",
  "assessment",
  "assignment",
  "assignment_ind",
  "assignment_late",
  "assignment_return",
  "assignment_returned",
  "assignment_turned_in",
  "assistant",
  "assistant_photo",
  "atm",
  "attach_file",
  "attach_money",
  "attachment",
  "audiotrack",
  "autorenew",
  "av_timer",
  "backspace",
  "backup",
  "ballot",
  "bar_chart",
  "battery_20",
  "battery_30",
  "battery_50",
  "battery_60",
  "battery_80",
  "battery_90",
  "battery_alert",
  "battery_charging_20",
  "battery_charging_30",
  "battery_charging_50",
  "battery_charging_60",
  "battery_charging_80",
  "battery_charging_90",
  "battery_charging_full",
  "battery_full",
  "battery_std",
  "battery_unknown",
  "beach_access",
  "beenhere",
  "block",
  "bluetooth",
  "bluetooth_audio",
  "bluetooth_connected",
  "bluetooth_disabled",
  "bluetooth_searching",
  "blur_circular",
  "blur_linear",
  "blur_off",
  "blur_on",
  "book",
  "bookmark",
  "bookmark_border",
  "bookmarks",
  "border_all",
  "border_bottom",
  "border_clear",
  "border_color",
  "border_horizontal",
  "border_inner",
  "border_left",
  "border_outer",
  "border_right",
  "border_style",
  "border_top",
  "border_vertical",
  "branding_watermark",
  "brightness_1",
  "brightness_2",
  "brightness_3",
  "brightness_4",
  "brightness_5",
  "brightness_6",
  "brightness_7",
  "brightness_auto",
  "brightness_high",
  "brightness_low",
  "brightness_medium",
  "broken_image",
  "brush",
  "bubble_chart",
  "bug_report",
  "build",
  "burst_mode",
  "business",
  "business_center",
  "cached",
  "cake",
  "calendar_today",
  "calendar_view_day",
  "call",
  "call_end",
  "call_made",
  "call_merge",
  "call_missed",
  "call_missed_outgoing",
  "call_received",
  "call_split",
  "call_to_action",
  "camera",
  "camera_alt",
  "camera_enhance",
  "camera_front",
  "camera_rear",
  "camera_roll",
  "cancel",
  "cancel_presentation",
  "card_giftcard",
  "card_membership",
  "card_travel",
  "casino",
  "cast",
  "cast_connected",
  "cast_for_education",
  "category",
  "cell_wifi",
  "center_focus_strong",
  "center_focus_weak",
  "change_history",
  "chat",
  "chat_bubble",
  "chat_bubble_outline",
  "check",
  "check_box",
  "check_box_outline_blank",
  "check_circle",
  "check_circle_outline",
  "chevron_left",
  "chevron_right",
  "child_care",
  "child_friendly",
  "chrome_reader_mode",
  "class",
  "clear",
  "clear_all",
  "close",
  "closed_caption",
  "cloud",
  "cloud_circle",
  "cloud_done",
  "cloud_download",
  "cloud_off",
  "cloud_queue",
  "cloud_upload",
  "code",
  "collections",
  "collections_bookmark",
  "color_lens",
  "colorize",
  "comment",
  "commute",
  "compare",
  "compare_arrows",
  "compass_calibration",
  "computer",
  "confirmation_number",
  "contact_mail",
  "contact_phone",
  "contact_support",
  "contacts",
  "control_camera",
  "control_point",
  "control_point_duplicate",
  "copyright",
  "create",
  "create_new_folder",
  "credit_card",
  "crop",
  "crop_16_9",
  "crop_3_2",
  "crop_5_4",
  "crop_7_5",
  "crop_din",
  "crop_free",
  "crop_landscape",
  "crop_original",
  "crop_portrait",
  "crop_rotate",
  "crop_square",
  "dashboard",
  "data_usage",
  "date_range",
  "dehaze",
  "delete",
  "delete_forever",
  "delete_outline",
  "delete_sweep",
  "departure_board",
  "description",
  "desktop_access_disabled",
  "desktop_mac",
  "desktop_windows",
  "details",
  "developer_board",
  "developer_mode",
  "device_hub",
  "device_unknown",
  "devices",
  "devices_other",
  "dialer_sip",
  "dialpad",
  "directions",
  "directions_bike",
  "directions_boat",
  "directions_bus",
  "directions_car",
  "directions_railway",
  "directions_run",
  "directions_subway",
  "directions_transit",
  "directions_walk",
  "disc_full",
  "dns",
  "dock",
  "domain",
  "domain_disabled",
  "done",
  "done_all",
  "done_outline",
  "donut_large",
  "donut_small",
  "drafts",
  "drag_handle",
  "drag_indicator",
  "drive_eta",
  "duo",
  "dvr",
  "edit",
  "edit_attributes",
  "edit_location",
  "eject",
  "email",
  "enhanced_encryption",
  "equalizer",
  "error",
  "error_outline",
  "euro_symbol",
  "ev_station",
  "event",
  "event_available",
  "event_busy",
  "event_note",
  "event_seat",
  "exit_to_app",
  "expand_less",
  "expand_more",
  "explicit",
  "explore",
  "explore_off",
  "exposure",
  "exposure_neg_1",
  "exposure_neg_2",
  "exposure_plus_1",
  "exposure_plus_2",
  "exposure_zero",
  "extension",
  "face",
  "fast_forward",
  "fast_rewind",
  "fastfood",
  "favorite",
  "favorite_border",
  "featured_play_list",
  "featured_video",
  "feedback",
  "fiber_dvr",
  "fiber_manual_record",
  "fiber_new",
  "fiber_pin",
  "fiber_smart_record",
  "file_copy",
  "filter",
  "filter_1",
  "filter_2",
  "filter_3",
  "filter_4",
  "filter_5",
  "filter_6",
  "filter_7",
  "filter_8",
  "filter_9",
  "filter_9_plus",
  "filter_b_and_w",
  "filter_center_focus",
  "filter_drama",
  "filter_frames",
  "filter_hdr",
  "filter_list",
  "filter_none",
  "filter_tilt_shift",
  "filter_vintage",
  "find_in_page",
  "find_replace",
  "fingerprint",
  "first_page",
  "fitness_center",
  "flag",
  "flare",
  "flash_auto",
  "flash_off",
  "flash_on",
  "flight",
  "flight_land",
  "flight_takeoff",
  "flip",
  "flip_to_back",
  "flip_to_front",
  "folder",
  "folder_open",
  "folder_shared",
  "folder_special",
  "font_download",
  "format_align_center",
  "format_align_justify",
  "format_align_left",
  "format_align_right",
  "format_bold",
  "format_clear",
  "format_color_fill",
  "format_color_reset",
  "format_color_text",
  "format_indent_decrease",
  "format_indent_increase",
  "format_italic",
  "format_line_spacing",
  "format_list_bulleted",
  "format_list_numbered",
  "format_list_numbered_rtl",
  "format_paint",
  "format_quote",
  "format_shapes",
  "format_size",
  "format_strikethrough",
  "format_textdirection_l_to_r",
  "format_textdirection_r_to_l",
  "format_underlined",
  "forum",
  "forward",
  "forward_10",
  "forward_30",
  "forward_5",
  "four_k",
  "free_breakfast",
  "fullscreen",
  "fullscreen_exit",
  "functions",
  "g_translate",
  "gamepad",
  "games",
  "gavel",
  "gesture",
  "get_app",
  "gif",
  "golf_course",
  "gps_fixed",
  "gps_not_fixed",
  "gps_off",
  "grade",
  "gradient",
  "grain",
  "graphic_eq",
  "grid_off",
  "grid_on",
  "group",
  "group_add",
  "group_work",
  "hd",
  "hdr_off",
  "hdr_on",
  "hdr_strong",
  "hdr_weak",
  "headset",
  "headset_mic",
  "healing",
  "hearing",
  "help",
  "help_outline",
  "high_quality",
  "highlight",
  "highlight_off",
  "history",
  "home",
  "horizontal_split",
  "hot_tub",
  "hotel",
  "hourglass_empty",
  "hourglass_full",
  "how_to_reg",
  "how_to_vote",
  "http",
  "https",
  "image",
  "image_aspect_ratio",
  "image_search",
  "import_contacts",
  "import_export",
  "important_devices",
  "inbox",
  "indeterminate_check_box",
  "info",
  "input",
  "insert_chart",
  "insert_chart_outlined",
  "insert_comment",
  "insert_drive_file",
  "insert_emoticon",
  "insert_invitation",
  "insert_link",
  "insert_photo",
  "invert_colors",
  "invert_colors_off",
  "iso",
  "keyboard",
  "keyboard_arrow_down",
  "keyboard_arrow_left",
  "keyboard_arrow_right",
  "keyboard_arrow_up",
  "keyboard_backspace",
  "keyboard_capslock",
  "keyboard_hide",
  "keyboard_return",
  "keyboard_tab",
  "keyboard_voice",
  "kitchen",
  "label",
  "label_important",
  "label_off",
  "landscape",
  "language",
  "laptop",
  "laptop_chromebook",
  "laptop_mac",
  "laptop_windows",
  "last_page",
  "launch",
  "layers",
  "layers_clear",
  "leak_add",
  "leak_remove",
  "lens",
  "library_add",
  "library_books",
  "library_music",
  "line_style",
  "line_weight",
  "linear_scale",
  "link",
  "link_off",
  "linked_camera",
  "list",
  "list_alt",
  "live_help",
  "live_tv",
  "local_activity",
  "local_airport",
  "local_atm",
  "local_bar",
  "local_cafe",
  "local_car_wash",
  "local_convenience_store",
  "local_dining",
  "local_drink",
  "local_florist",
  "local_gas_station",
  "local_grocery_store",
  "local_hospital",
  "local_hotel",
  "local_laundry_service",
  "local_library",
  "local_mall",
  "local_movies",
  "local_offer",
  "local_parking",
  "local_pharmacy",
  "local_phone",
  "local_pizza",
  "local_play",
  "local_post_office",
  "local_printshop",
  "local_see",
  "local_shipping",
  "local_taxi",
  "location_city",
  "location_disabled",
  "location_off",
  "location_on",
  "location_searching",
  "lock",
  "lock_open",
  "looks",
  "looks_3",
  "looks_4",
  "looks_5",
  "looks_6",
  "looks_one",
  "looks_two",
  "loop",
  "loupe",
  "low_priority",
  "loyalty",
  "mail",
  "mail_outline",
  "map",
  "markunread",
  "markunread_mailbox",
  "maximize",
  "meeting_room",
  "memory",
  "menu",
  "merge_type",
  "message",
  "mic",
  "mic_none",
  "mic_off",
  "minimize",
  "missed_video_call",
  "mms",
  "mobile_friendly",
  "mobile_off",
  "mobile_screen_share",
  "mode_comment",
  "monetization_on",
  "money",
  "money_off",
  "monochrome_photos",
  "mood",
  "mood_bad",
  "more",
  "more_horiz",
  "more_vert",
  "motorcycle",
  "mouse",
  "move_to_inbox",
  "movie",
  "movie_creation",
  "movie_filter",
  "multiline_chart",
  "music_note",
  "music_off",
  "music_video",
  "my_location",
  "nature",
  "nature_people",
  "navigate_before",
  "navigate_next",
  "navigation",
  "near_me",
  "network_cell",
  "network_check",
  "network_locked",
  "network_wifi",
  "new_releases",
  "next_week",
  "nfc",
  "no_encryption",
  "no_meeting_room",
  "no_sim",
  "not_interested",
  "not_listed_location",
  "note",
  "note_add",
  "notes",
  "notification_important",
  "notifications",
  "notifications_active",
  "notifications_none",
  "notifications_off",
  "notifications_paused",
  "offline_bolt",
  "offline_pin",
  "ondemand_video",
  "opacity",
  "open_in_browser",
  "open_in_new",
  "open_with",
  "outlined_flag",
  "pages",
  "pageview",
  "palette",
  "pan_tool",
  "panorama",
  "panorama_fish_eye",
  "panorama_horizontal",
  "panorama_vertical",
  "panorama_wide_angle",
  "party_mode",
  "pause",
  "pause_circle_filled",
  "pause_circle_outline",
  "pause_presentation",
  "payment",
  "people",
  "people_outline",
  "perm_camera_mic",
  "perm_contact_calendar",
  "perm_data_setting",
  "perm_device_information",
  "perm_identity",
  "perm_media",
  "perm_phone_msg",
  "perm_scan_wifi",
  "person",
  "person_add",
  "person_add_disabled",
  "person_outline",
  "person_pin",
  "person_pin_circle",
  "personal_video",
  "pets",
  "phone",
  "phone_android",
  "phone_bluetooth_speaker",
  "phone_callback",
  "phone_forwarded",
  "phone_in_talk",
  "phone_iphone",
  "phone_locked",
  "phone_missed",
  "phone_paused",
  "phonelink",
  "phonelink_erase",
  "phonelink_lock",
  "phonelink_off",
  "phonelink_ring",
  "phonelink_setup",
  "photo",
  "photo_album",
  "photo_camera",
  "photo_filter",
  "photo_library",
  "photo_size_select_actual",
  "photo_size_select_large",
  "photo_size_select_small",
  "picture_as_pdf",
  "picture_in_picture",
  "picture_in_picture_alt",
  "pie_chart",
  "pin_drop",
  "place",
  "play_arrow",
  "play_circle_filled",
  "play_circle_filled_white",
  "play_circle_outline",
  "play_for_work",
  "playlist_add",
  "playlist_add_check",
  "playlist_play",
  "plus_one",
  "poll",
  "polymer",
  "pool",
  "portable_wifi_off",
  "portrait",
  "power",
  "power_input",
  "power_off",
  "power_settings_new",
  "pregnant_woman",
  "present_to_all",
  "print",
  "print_disabled",
  "priority_high",
  "public",
  "publish",
  "query_builder",
  "question_answer",
  "queue",
  "queue_music",
  "queue_play_next",
  "radio",
  "radio_button_checked",
  "radio_button_unchecked",
  "rate_review",
  "receipt",
  "recent_actors",
  "record_voice_over",
  "redeem",
  "redo",
  "refresh",
  "remove",
  "remove_circle",
  "remove_circle_outline",
  "remove_from_queue",
  "remove_red_eye",
  "remove_shopping_cart",
  "reorder",
  "repeat",
  "repeat_one",
  "replay",
  "replay_10",
  "replay_30",
  "replay_5",
  "reply",
  "reply_all",
  "report",
  "report_off",
  "report_problem",
  "restaurant",
  "restaurant_menu",
  "restore",
  "restore_from_trash",
  "restore_page",
  "ring_volume",
  "room",
  "room_service",
  "rotate_90_degrees_ccw",
  "rotate_left",
  "rotate_right",
  "rounded_corner",
  "router",
  "rowing",
  "rss_feed",
  "rv_hookup",
  "satellite",
  "save",
  "save_alt",
  "scanner",
  "scatter_plot",
  "schedule",
  "school",
  "score",
  "screen_lock_landscape",
  "screen_lock_portrait",
  "screen_lock_rotation",
  "screen_rotation",
  "screen_share",
  "sd_card",
  "sd_storage",
  "search",
  "security",
  "select_all",
  "send",
  "sentiment_dissatisfied",
  "sentiment_satisfied",
  "sentiment_satisfied_alt",
  "sentiment_very_dissatisfied",
  "sentiment_very_satisfied",
  "settings",
  "settings_applications",
  "settings_backup_restore",
  "settings_bluetooth",
  "settings_brightness",
  "settings_cell",
  "settings_ethernet",
  "settings_input_antenna",
  "settings_input_component",
  "settings_input_composite",
  "settings_input_hdmi",
  "settings_input_svideo",
  "settings_overscan",
  "settings_phone",
  "settings_power",
  "settings_remote",
  "settings_system_daydream",
  "settings_voice",
  "share",
  "shop",
  "shop_two",
  "shopping_basket",
  "shopping_cart",
  "short_text",
  "show_chart",
  "shuffle",
  "shutter_speed",
  "signal_cellular_0_bar",
  "signal_cellular_1_bar",
  "signal_cellular_2_bar",
  "signal_cellular_3_bar",
  "signal_cellular_4_bar",
  "signal_cellular_alt",
  "signal_cellular_connected_no_internet_0_bar",
  "signal_cellular_connected_no_internet_1_bar",
  "signal_cellular_connected_no_internet_2_bar",
  "signal_cellular_connected_no_internet_3_bar",
  "signal_cellular_connected_no_internet_4_bar",
  "signal_cellular_no_sim",
  "signal_cellular_null",
  "signal_cellular_off",
  "signal_wifi_0_bar",
  "signal_wifi_1_bar",
  "signal_wifi_1_bar_lock",
  "signal_wifi_2_bar",
  "signal_wifi_2_bar_lock",
  "signal_wifi_3_bar",
  "signal_wifi_3_bar_lock",
  "signal_wifi_4_bar",
  "signal_wifi_4_bar_lock",
  "signal_wifi_off",
  "sim_card",
  "skip_next",
  "skip_previous",
  "slideshow",
  "slow_motion_video",
  "smartphone",
  "smoke_free",
  "smoking_rooms",
  "sms",
  "sms_failed",
  "snooze",
  "sort",
  "sort_by_alpha",
  "spa",
  "space_bar",
  "speaker",
  "speaker_group",
  "speaker_notes",
  "speaker_notes_off",
  "speaker_phone",
  "spellcheck",
  "star",
  "star_border",
  "star_half",
  "star_rate",
  "stars",
  "stay_current_landscape",
  "stay_current_portrait",
  "stay_primary_landscape",
  "stay_primary_portrait",
  "stop",
  "stop_screen_share",
  "storage",
  "store",
  "store_mall_directory",
  "straighten",
  "streetview",
  "strikethrough_s",
  "style",
  "subdirectory_arrow_left",
  "subdirectory_arrow_right",
  "subject",
  "subscriptions",
  "subtitles",
  "subway",
  "supervised_user_circle",
  "supervisor_account",
  "surround_sound",
  "swap_calls",
  "swap_horiz",
  "swap_horizontal_circle",
  "swap_vert",
  "swap_vertical_circle",
  "switch_camera",
  "switch_video",
  "sync",
  "sync_disabled",
  "sync_problem",
  "system_update",
  "tab",
  "tab_unselected",
  "table_chart",
  "tablet",
  "tablet_android",
  "tablet_mac",
  "tag_faces",
  "tap_and_play",
  "terrain",
  "text_fields",
  "text_format",
  "text_rotate_up",
  "text_rotate_vertical",
  "text_rotation_down",
  "text_rotation_none",
  "textsms",
  "texture",
  "theaters",
  "three_sixty",
  "three_d_rotation",
  "thumb_down",
  "thumb_down_alt",
  "thumb_up",
  "thumb_up_alt",
  "thumbs_up_down",
  "time_to_leave",
  "timelapse",
  "timeline",
  "timer",
  "timer_10",
  "timer_3",
  "timer_off",
  "title",
  "toc",
  "today",
  "toggle_off",
  "toggle_on",
  "toll",
  "tonality",
  "touch_app",
  "toys",
  "track_changes",
  "traffic",
  "train",
  "tram",
  "transfer_within_a_station",
  "transform",
  "transit_enterexit",
  "translate",
  "trending_down",
  "trending_flat",
  "trending_up",
  "trip_origin",
  "tune",
  "turned_in",
  "turned_in_not",
  "tv",
  "tv_off",
  "unarchive",
  "undo",
  "unfold_less",
  "unfold_more",
  "unsubscribe",
  "update",
  "usb",
  "verified_user",
  "vertical_align_bottom",
  "vertical_align_center",
  "vertical_align_top",
  "vertical_split",
  "vibration",
  "video_call",
  "video_label",
  "video_library",
  "videocam",
  "videocam_off",
  "videogame_asset",
  "view_agenda",
  "view_array",
  "view_carousel",
  "view_column",
  "view_comfy",
  "view_compact",
  "view_day",
  "view_headline",
  "view_list",
  "view_module",
  "view_quilt",
  "view_stream",
  "view_week",
  "vignette",
  "visibility",
  "visibility_off",
  "voice_chat",
  "voice_over_off",
  "voicemail",
  "volume_down",
  "volume_mute",
  "volume_off",
  "volume_up",
  "vpn_key",
  "vpn_lock",
  "wallpaper",
  "warning",
  "watch",
  "watch_later",
  "waves",
  "wb_auto",
  "wb_cloudy",
  "wb_incandescent",
  "wb_iridescent",
  "wb_sunny",
  "wc",
  "web",
  "web_asset",
  "weekend",
  "whatshot",
  "where_to_vote",
  "widgets",
  "wifi",
  "wifi_lock",
  "wifi_off",
  "wifi_tethering",
  "work",
  "work_off",
  "work_outline",
  "wrap_text",
  "youtube_searched_for",
  "zoom_in",
  "zoom_out",
  "zoom_out_map",
];

export const faList = [
  "address-book",
  "address-book-o",
  "address-card",
  "address-card-o",
  "adjust",
  "american-sign-language-interpreting",
  "anchor",
  "archive",
  "area-chart",
  "arrows",
  "arrows-h",
  "arrows-v",
  "asl-interpreting",
  "assistive-listening-systems",
  "asterisk",
  "at",
  "audio-description",
  "automobile",
  "balance-scale",
  "ban",
  "bank",
  "bar-chart",
  "bar-chart-o",
  "barcode",
  "bars",
  "bath",
  "bathtub",
  "battery",
  "battery-0",
  "battery-1",
  "battery-2",
  "battery-3",
  "battery-4",
  "battery-empty",
  "battery-full",
  "battery-half",
  "battery-quarter",
  "battery-three-quarters",
  "bed",
  "beer",
  "bell",
  "bell-o",
  "bell-slash",
  "bell-slash-o",
  "bicycle",
  "binoculars",
  "birthday-cake",
  "blind",
  "bluetooth",
  "bluetooth-b",
  "bolt",
  "bomb",
  "book",
  "bookmark",
  "bookmark-o",
  "braille",
  "briefcase",
  "bug",
  "building",
  "building-o",
  "bullhorn",
  "bullseye",
  "bus",
  "cab",
  "calculator",
  "calendar",
  "calendar-check-o",
  "calendar-minus-o",
  "calendar-o",
  "calendar-plus-o",
  "calendar-times-o",
  "camera",
  "camera-retro",
  "car",
  "caret-square-o-down",
  "caret-square-o-left",
  "caret-square-o-right",
  "caret-square-o-up",
  "cart-arrow-down",
  "cart-plus",
  "cc",
  "certificate",
  "check",
  "check-circle",
  "check-circle-o",
  "check-square",
  "check-square-o",
  "child",
  "circle",
  "circle-o",
  "circle-o-notch",
  "circle-thin",
  "clock-o",
  "clone",
  "close",
  "cloud",
  "cloud-download",
  "cloud-upload",
  "code",
  "code-fork",
  "coffee",
  "cog",
  "cogs",
  "comment",
  "comment-o",
  "commenting",
  "commenting-o",
  "comments",
  "comments-o",
  "compass",
  "copyright",
  "creative-commons",
  "credit-card",
  "credit-card-alt",
  "crop",
  "crosshairs",
  "cube",
  "cubes",
  "cutlery",
  "dashboard",
  "database",
  "deaf",
  "deafness",
  "desktop",
  "diamond",
  "dot-circle-o",
  "download",
  "drivers-license",
  "drivers-license-o",
  "edit",
  "ellipsis-h",
  "ellipsis-v",
  "envelope",
  "envelope-o",
  "envelope-open",
  "envelope-open-o",
  "envelope-square",
  "eraser",
  "exchange",
  "exclamation",
  "exclamation-circle",
  "exclamation-triangle",
  "external-link",
  "external-link-square",
  "eye",
  "eye-slash",
  "eyedropper",
  "fax",
  "feed",
  "female",
  "fighter-jet",
  "file-archive-o",
  "file-audio-o",
  "file-code-o",
  "file-excel-o",
  "file-image-o",
  "file-movie-o",
  "file-pdf-o",
  "file-photo-o",
  "file-picture-o",
  "file-powerpoint-o",
  "file-sound-o",
  "file-video-o",
  "file-word-o",
  "file-zip-o",
  "film",
  "filter",
  "fire",
  "fire-extinguisher",
  "flag",
  "flag-checkered",
  "flag-o",
  "flash",
  "flask",
  "folder",
  "folder-o",
  "folder-open",
  "folder-open-o",
  "frown-o",
  "futbol-o",
  "gamepad",
  "gavel",
  "gear",
  "gears",
  "gift",
  "glass",
  "globe",
  "graduation-cap",
  "group",
  "hand-grab-o",
  "hand-lizard-o",
  "hand-paper-o",
  "hand-peace-o",
  "hand-pointer-o",
  "hand-rock-o",
  "hand-scissors-o",
  "hand-spock-o",
  "hand-stop-o",
  "handshake-o",
  "hard-of-hearing",
  "hashtag",
  "hdd-o",
  "headphones",
  "heart",
  "heart-o",
  "heartbeat",
  "history",
  "home",
  "hotel",
  "hourglass",
  "hourglass-1",
  "hourglass-2",
  "hourglass-3",
  "hourglass-end",
  "hourglass-half",
  "hourglass-o",
  "hourglass-start",
  "i-cursor",
  "id-badge",
  "id-card",
  "id-card-o",
  "image",
  "inbox",
  "industry",
  "info",
  "info-circle",
  "institution",
  "key",
  "keyboard-o",
  "language",
  "laptop",
  "leaf",
  "legal",
  "lemon-o",
  "level-down",
  "level-up",
  "life-bouy",
  "life-buoy",
  "life-ring",
  "life-saver",
  "lightbulb-o",
  "line-chart",
  "location-arrow",
  "lock",
  "low-vision",
  "magic",
  "magnet",
  "mail-forward",
  "mail-reply",
  "mail-reply-all",
  "male",
  "map",
  "map-marker",
  "map-o",
  "map-pin",
  "map-signs",
  "meh-o",
  "microchip",
  "microphone",
  "microphone-slash",
  "minus",
  "minus-circle",
  "minus-square",
  "minus-square-o",
  "mobile",
  "mobile-phone",
  "money",
  "moon-o",
  "mortar-board",
  "motorcycle",
  "mouse-pointer",
  "music",
  "navicon",
  "newspaper-o",
  "object-group",
  "object-ungroup",
  "paint-brush",
  "paper-plane",
  "paper-plane-o",
  "paw",
  "pencil",
  "pencil-square",
  "pencil-square-o",
  "percent",
  "phone",
  "phone-square",
  "photo",
  "picture-o",
  "pie-chart",
  "plane",
  "plug",
  "plus",
  "plus-circle",
  "plus-square",
  "plus-square-o",
  "podcast",
  "power-off",
  "print",
  "puzzle-piece",
  "qrcode",
  "question",
  "question-circle",
  "question-circle-o",
  "quote-left",
  "quote-right",
  "random",
  "recycle",
  "refresh",
  "registered",
  "remove",
  "reorder",
  "reply",
  "reply-all",
  "retweet",
  "road",
  "rocket",
  "rss",
  "rss-square",
  "s15",
  "search",
  "search-minus",
  "search-plus",
  "send",
  "send-o",
  "server",
  "share",
  "share-alt",
  "share-alt-square",
  "share-square",
  "share-square-o",
  "shield",
  "ship",
  "shopping-bag",
  "shopping-basket",
  "shopping-cart",
  "shower",
  "sign-in",
  "sign-language",
  "sign-out",
  "signal",
  "signing",
  "sitemap",
  "sliders",
  "smile-o",
  "snowflake-o",
  "soccer-ball-o",
  "sort",
  "sort-alpha-asc",
  "sort-alpha-desc",
  "sort-amount-asc",
  "sort-amount-desc",
  "sort-asc",
  "sort-desc",
  "sort-down",
  "sort-numeric-asc",
  "sort-numeric-desc",
  "sort-up",
  "space-shuttle",
  "spinner",
  "spoon",
  "square",
  "square-o",
  "star",
  "star-half",
  "star-half-empty",
  "star-half-full",
  "star-half-o",
  "star-o",
  "sticky-note",
  "sticky-note-o",
  "street-view",
  "suitcase",
  "sun-o",
  "support",
  "tablet",
  "tachometer",
  "tag",
  "tags",
  "tasks",
  "taxi",
  "television",
  "terminal",
  "thermometer",
  "thermometer-0",
  "thermometer-1",
  "thermometer-2",
  "thermometer-3",
  "thermometer-4",
  "thermometer-empty",
  "thermometer-full",
  "thermometer-half",
  "thermometer-quarter",
  "thermometer-three-quarters",
  "thumb-tack",
  "thumbs-down",
  "thumbs-o-down",
  "thumbs-o-up",
  "thumbs-up",
  "ticket",
  "times",
  "times-circle",
  "times-circle-o",
  "times-rectangle",
  "times-rectangle-o",
  "tint",
  "toggle-down",
  "toggle-left",
  "toggle-off",
  "toggle-on",
  "toggle-right",
  "toggle-up",
  "trademark",
  "trash",
  "trash-o",
  "tree",
  "trophy",
  "truck",
  "tty",
  "tv",
  "umbrella",
  "universal-access",
  "university",
  "unlock",
  "unlock-alt",
  "unsorted",
  "upload",
  "user",
  "user-circle",
  "user-circle-o",
  "user-o",
  "user-plus",
  "user-secret",
  "user-times",
  "users",
  "vcard",
  "vcard-o",
  "video-camera",
  "volume-control-phone",
  "volume-down",
  "volume-off",
  "volume-up",
  "warning",
  "wheelchair",
  "wheelchair-alt",
  "wifi",
  "window-close",
  "window-close-o",
  "window-maximize",
  "window-minimize",
  "window-restore",
  "wrench",
  "american-sign-language-interpreting",
  "asl-interpreting",
  "assistive-listening-systems",
  "audio-description",
  "blind",
  "braille",
  "cc",
  "deaf",
  "deafness",
  "hard-of-hearing",
  "low-vision",
  "question-circle-o",
  "sign-language",
  "signing",
  "tty",
  "universal-access",
  "volume-control-phone",
  "wheelchair",
  "wheelchair-alt",
  "hand-grab-o",
  "hand-lizard-o",
  "hand-o-down",
  "hand-o-left",
  "hand-o-right",
  "hand-o-up",
  "hand-paper-o",
  "hand-peace-o",
  "hand-pointer-o",
  "hand-rock-o",
  "hand-scissors-o",
  "hand-spock-o",
  "hand-stop-o",
  "thumbs-down",
  "thumbs-o-down",
  "thumbs-o-up",
  "thumbs-up",
  "ambulance",
  "automobile",
  "bicycle",
  "bus",
  "cab",
  "car",
  "fighter-jet",
  "motorcycle",
  "plane",
  "rocket",
  "ship",
  "space-shuttle",
  "subway",
  "taxi",
  "train",
  "truck",
  "wheelchair",
  "wheelchair-alt",
  "genderless",
  "intersex",
  "mars",
  "mars-double",
  "mars-stroke",
  "mars-stroke-h",
  "mars-stroke-v",
  "mercury",
  "neuter",
  "transgender",
  "transgender-alt",
  "venus",
  "venus-double",
  "venus-mars",
  "file",
  "file-archive-o",
  "file-audio-o",
  "file-code-o",
  "file-excel-o",
  "file-image-o",
  "file-movie-o",
  "file-o",
  "file-pdf-o",
  "file-photo-o",
  "file-picture-o",
  "file-powerpoint-o",
  "file-sound-o",
  "file-text",
  "file-text-o",
  "file-video-o",
  "file-word-o",
  "file-zip-o",
  "circle-o-notch",
  "cog",
  "gear",
  "refresh",
  "spinner",
  "check-square",
  "check-square-o",
  "circle",
  "circle-o",
  "dot-circle-o",
  "minus-square",
  "minus-square-o",
  "plus-square",
  "plus-square-o",
  "square",
  "square-o",
  "cc-amex",
  "cc-diners-club",
  "cc-discover",
  "cc-jcb",
  "cc-mastercard",
  "cc-paypal",
  "cc-stripe",
  "cc-visa",
  "credit-card",
  "credit-card-alt",
  "google-wallet",
  "paypal",
  "area-chart",
  "bar-chart",
  "bar-chart-o",
  "line-chart",
  "pie-chart",
  "bitcoin",
  "btc",
  "cny",
  "dollar",
  "eur",
  "euro",
  "gbp",
  "gg",
  "gg-circle",
  "ils",
  "inr",
  "jpy",
  "krw",
  "money",
  "rmb",
  "rouble",
  "rub",
  "ruble",
  "rupee",
  "shekel",
  "sheqel",
  "try",
  "turkish-lira",
  "usd",
  "viacoin",
  "won",
  "yen",
  "align-center",
  "align-justify",
  "align-left",
  "align-right",
  "bold",
  "chain",
  "chain-broken",
  "clipboard",
  "columns",
  "copy",
  "cut",
  "dedent",
  "eraser",
  "file",
  "file-o",
  "file-text",
  "file-text-o",
  "files-o",
  "floppy-o",
  "font",
  "header",
  "indent",
  "italic",
  "link",
  "list",
  "list-alt",
  "list-ol",
  "list-ul",
  "outdent",
  "paperclip",
  "paragraph",
  "paste",
  "repeat",
  "rotate-left",
  "rotate-right",
  "save",
  "scissors",
  "strikethrough",
  "subscript",
  "superscript",
  "table",
  "text-height",
  "text-width",
  "th",
  "th-large",
  "th-list",
  "underline",
  "undo",
  "unlink",
  "angle-double-down",
  "angle-double-left",
  "angle-double-right",
  "angle-double-up",
  "angle-down",
  "angle-left",
  "angle-right",
  "angle-up",
  "arrow-circle-down",
  "arrow-circle-left",
  "arrow-circle-o-down",
  "arrow-circle-o-left",
  "arrow-circle-o-right",
  "arrow-circle-o-up",
  "arrow-circle-right",
  "arrow-circle-up",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "arrows",
  "arrows-alt",
  "arrows-h",
  "arrows-v",
  "caret-down",
  "caret-left",
  "caret-right",
  "caret-square-o-down",
  "caret-square-o-left",
  "caret-square-o-right",
  "caret-square-o-up",
  "caret-up",
  "chevron-circle-down",
  "chevron-circle-left",
  "chevron-circle-right",
  "chevron-circle-up",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "exchange",
  "hand-o-down",
  "hand-o-left",
  "hand-o-right",
  "hand-o-up",
  "long-arrow-down",
  "long-arrow-left",
  "long-arrow-right",
  "long-arrow-up",
  "toggle-down",
  "toggle-left",
  "toggle-right",
  "toggle-up",
  "arrows-alt",
  "backward",
  "compress",
  "eject",
  "expand",
  "fast-backward",
  "fast-forward",
  "forward",
  "pause",
  "pause-circle",
  "pause-circle-o",
  "play",
  "play-circle",
  "play-circle-o",
  "random",
  "step-backward",
  "step-forward",
  "stop",
  "stop-circle",
  "stop-circle-o",
  "youtube-play",
  "500px",
  "adn",
  "amazon",
  "android",
  "angellist",
  "apple",
  "bandcamp",
  "behance",
  "behance-square",
  "bitbucket",
  "bitbucket-square",
  "bitcoin",
  "black-tie",
  "bluetooth",
  "bluetooth-b",
  "btc",
  "buysellads",
  "cc-amex",
  "cc-diners-club",
  "cc-discover",
  "cc-jcb",
  "cc-mastercard",
  "cc-paypal",
  "cc-stripe",
  "cc-visa",
  "chrome",
  "codepen",
  "codiepie",
  "connectdevelop",
  "contao",
  "css3",
  "dashcube",
  "delicious",
  "deviantart",
  "digg",
  "dribbble",
  "dropbox",
  "drupal",
  "edge",
  "eercast",
  "empire",
  "envira",
  "etsy",
  "expeditedssl",
  "fa",
  "facebook",
  "facebook-f",
  "facebook-official",
  "facebook-square",
  "firefox",
  "first-order",
  "flickr",
  "font-awesome",
  "fonticons",
  "fort-awesome",
  "forumbee",
  "foursquare",
  "free-code-camp",
  "ge",
  "get-pocket",
  "gg",
  "gg-circle",
  "git",
  "git-square",
  "github",
  "github-alt",
  "github-square",
  "gitlab",
  "gittip",
  "glide",
  "glide-g",
  "google",
  "google-plus",
  "google-plus-circle",
  "google-plus-official",
  "google-plus-square",
  "google-wallet",
  "gratipay",
  "grav",
  "hacker-news",
  "houzz",
  "html5",
  "imdb",
  "instagram",
  "internet-explorer",
  "ioxhost",
  "joomla",
  "jsfiddle",
  "lastfm",
  "lastfm-square",
  "leanpub",
  "linkedin",
  "linkedin-square",
  "linode",
  "linux",
  "maxcdn",
  "meanpath",
  "medium",
  "meetup",
  "mixcloud",
  "modx",
  "odnoklassniki",
  "odnoklassniki-square",
  "opencart",
  "openid",
  "opera",
  "optin-monster",
  "pagelines",
  "paypal",
  "pied-piper",
  "pied-piper-alt",
  "pied-piper-pp",
  "pinterest",
  "pinterest-p",
  "pinterest-square",
  "product-hunt",
  "qq",
  "quora",
  "ra",
  "ravelry",
  "rebel",
  "reddit",
  "reddit-alien",
  "reddit-square",
  "renren",
  "resistance",
  "safari",
  "scribd",
  "sellsy",
  "share-alt",
  "share-alt-square",
  "shirtsinbulk",
  "simplybuilt",
  "skyatlas",
  "skype",
  "slack",
  "slideshare",
  "snapchat",
  "snapchat-ghost",
  "snapchat-square",
  "soundcloud",
  "spotify",
  "stack-exchange",
  "stack-overflow",
  "steam",
  "steam-square",
  "stumbleupon",
  "stumbleupon-circle",
  "superpowers",
  "telegram",
  "tencent-weibo",
  "themeisle",
  "trello",
  "tripadvisor",
  "tumblr",
  "tumblr-square",
  "twitch",
  "twitter",
  "twitter-square",
  "usb",
  "viacoin",
  "viadeo",
  "viadeo-square",
  "vimeo",
  "vimeo-square",
  "vine",
  "vk",
  "wechat",
  "weibo",
  "weixin",
  "whatsapp",
  "wikipedia-w",
  "windows",
  "wordpress",
  "wpbeginner",
  "wpexplorer",
  "wpforms",
  "xing",
  "xing-square",
  "y-combinator",
  "y-combinator-square",
  "yahoo",
  "yc",
  "yc-square",
  "yelp",
  "yoast",
  "youtube",
  "youtube-play",
  "youtube-square",
  "ambulance",
  "h-square",
  "heart",
  "heart-o",
  "heartbeat",
  "hospital-o",
  "medkit",
  "plus-square",
  "stethoscope",
  "user-md",
  "wheelchair",
  "wheelchair-alt",
];
