import React from "react";
import {
  fade,
  makeStyles,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Avatar,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
  Menu,
  MenuItem,
  InputBase,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withAlertAndDialog } from "../../../HOCs";
import { AlertContext } from "../../../contexts";
import { alertProps } from "../../../utils";
// common function
import cloneDeep from "lodash/cloneDeep";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Layout from "../../../assets/img/icons8-layout.svg";

const options = [
  "Delete",
  "Edit",
  // 'Tree View'
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: "relative !important",
    marginBottom: "14px !important",
    borderRadius: "4px !important",
    borderBottom: "1.3px solid rgba(0, 0, 0, 0.12) !important",
    border: "1.3px solid rgba(0, 0, 0, 0.12) !important",
    backgroundColor: fade("#fff", 0.15),
    "&:hover": {
      backgroundColor: fade("#fff", 0.25),
    },
    width: "100% !important",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: "0px 6px !important",
    height: "100% !important",
    position: "absolute !important",
    pointerEvents: "none !important",
    display: "flex!important",
    alignItems: "center !important",
    justifyContent: "center!important",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    height: "0.9em",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)}px) !important`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  addButton: {
    border: "1px solid " + theme.palette.text.hint,
    borderRadius: "4px",
  },
  switchButton: {
    margin: 0,
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 0px",
  },
  MenuItem: {
    color: "black",
    cursor: "pointer",
    borderRadius: "4px",
    textTransform: "capitalize",
    padding: "4px 2px 4px 4px",
  },
  selected: {
    /* Increase the specificity */
    // color: "#3f51b5",
    backgroundColor: "#F6F6F8 !important",
  },
  dragIcon: {
    fontSize: "14px",
    margin: "0px 4px",
  },
  previewImage: {
    width: "26px",
    marginRight: "6px",
    fontSize: "12px",
    height: "32px",
  },
}));

const initialState = {
  position: null,
  data: null,
};
function ScreenList(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(initialState);
  const [activeGroup, setActiveGroup] = React.useState(null);
  const [groupSearch, setGroupSearch] = React.useState("")
  const [search, setSearch] = React.useState("");
  const open = Boolean(anchorEl.position);
  const alert = React.useContext(AlertContext);

  const handleClick = (event, id) => {
    setAnchorEl({
      ...anchorEl,
      position: event.currentTarget,
      data: id,
    });
  };

  const handleClose = () => {
    setAnchorEl(initialState);
  };

  const handleActiveGroup = (value) => {
    setActiveGroup(value);
    setActiveGroupLayout(value);
  };

  const moreOptions = (e, type) => {
    switch (type) {
      case "Delete":
        if (props.auth.user?.screens?.[0]?.readMode === true) {
          return this.props.alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: "The screen is in read mode",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        }
        if (
          props.auth.user?.screens[0]?.readMode === false &&
          props.auth.user?.user?.name !==
          props.auth.user?.screens[0]?.isLockedBy
        ) {
          return props.alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: `${props.auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        }
        props.deleteOldScreen(anchorEl.data);
        handleClose();
        break;
      case "Edit":
        if (props.auth.user?.screens?.[0]?.readMode === true) {
          return props.alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: "The screen is in read mode",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        }
        if (
          props.auth.user?.screens[0]?.readMode === false &&
          props.auth.user?.user?.name !==
          props.auth.user?.screens[0]?.isLockedBy
        ) {
          return props.alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: `${props.auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        }
        props.openModal(anchorEl.position, "edit", anchorEl.data);
        handleClose();
        break;
      case "Tree View":
        props.treeView(2);
        handleClose();
        break;
      default:
        handleClose();
        break;
    }
  };


  const setActiveGroupLayout = (value) => {
    var selectedScreen = props.auth.user?.AllScreenList.filter((item, index) => {
      if (
        (item.type === "decisionHandler" &&
          props.auth.user?.AllScreenList[index - 1]?.groupName === value) ||
        item?.groupName === value
      ) {
        return true;
      }
    });
    props.setSelectedScreen(null)
    props.auth.setAuth({
      ...props.auth.user,
      AllScreenList: props.auth.user.AllScreenList,
      activeGroup: value
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const allScreenListCopy = cloneDeep(props.auth.user.AllScreenList);
    let drag_item_id = result.draggableId;
    let getIndexDragItem = result.source.index;
    let getIndexDropItem = result.destination.index;
    if (allScreenListCopy[getIndexDragItem + 1]?.type === "decisionHandler") {
      let cutOut = allScreenListCopy.splice(getIndexDragItem, 2);
      allScreenListCopy.splice(getIndexDropItem, 0, ...cutOut);
    } else {
      let cutOut = allScreenListCopy.splice(getIndexDragItem, 1)[0];
      allScreenListCopy.splice(getIndexDropItem, 0, cutOut);
    }
    props.auth.setAuth({
      ...props.auth.user,
      AllScreenList: allScreenListCopy,
    });
  };

  const onClickScreenButton = async (selected) => {
    // debugger
    // props.onClose("left", false);
    let allScreenList = props?.auth?.user?.AllScreenList;
    let currentScreen = allScreenList.filter(
      (screen) => screen.id === selected.id
    );
    props.setSelectedScreen(currentScreen)
    setActiveGroup(null);
  };

  return (
    <div className={classes.root}>
      <Grid style={{ padding: "16px 16px 0px" }}>
        <Typography style={{ fontWeight: 600 }}>Screen's</Typography>
      </Grid>
      {/* Add new screen END */}

      {/* Search screen list */}
      <Grid style={{ padding: "0px 14px", marginTop: 10 }}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon fontSize={"small"} color={"action"} />
          </div>
          <InputBase
            disabled={props.auth.user.loading}
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      </Grid>
      {/* Search screen list END */}

      {/* Screen List's */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid
          container
          item
          style={{
            justifyContent: "center",
            maxHeight: "calc(100vh - 225px)",
            overflow: "auto",
          }}
          id={"screenList"}
        >
          <Droppable droppableId="droppable" type="screens">
            {(dropProvided, dropSnapshot) => (
              <div
                {...dropProvided.draggableProps}
                key="droppable"
                ref={dropProvided.innerRef}
                style={{
                  background: `${dropSnapshot.isDraggingOver ? "#F9FAFB" : "white"
                    }`,
                  borderRadius: `${dropSnapshot.isDraggingOver ? "5px" : "0px"
                    }`
                }}
              >
                {props.auth.user.AllScreenList?.filter(
                  (screen) => screen.type !== "decisionHandler"
                ).map((list, index) => {
                  return (
                    <>
                      {list.name
                        .toLowerCase()
                        .includes(search.toLowerCase()) && (
                          <Draggable
                            key={list.id}
                            draggableId={list.id}
                            index={index}
                          >
                            {(dragProvided, dragSnapshot) => (
                              <div
                                {...dragProvided.draggableProps}
                                ref={dragProvided.innerRef}
                                key={list.id}
                                index={index}
                              >
                                <ListItem
                                  title={list?.isLayout ? "Layout" : "Screen"}
                                  key={list.id}
                                  // draggable={true}
                                  id={list.id}
                                  style={{
                                    width: "240px",
                                    margin: "5px 0px",
                                    display: `${list.type === "decisionHandler"
                                      ? "none"
                                      : "flex"
                                      }`,
                                  }}
                                  dense
                                  selected={
                                    props.selectedScreen?.id === list.id
                                      ? true
                                      : false
                                  }
                                  classes={{
                                    root: classes.MenuItem,
                                    selected: classes.selected,
                                  }}
                                >
                                  <span {...dragProvided.dragHandleProps}>
                                    <DragIndicatorIcon
                                      className={classes.dragIcon}
                                    />
                                  </span>
                                  <Avatar
                                    alt={list.name}
                                    onClick={() => onClickScreenButton(list)}
                                    className={classes.previewImage}
                                    variant="rounded"
                                    src={
                                      list?.image ?? "/static/images/avatar/1.jpg"
                                    }
                                  />

                                  <ListItemText
                                    primary={list.name}
                                    style={{ wordBreak: "break-all" }}
                                    onClick={() => onClickScreenButton(list)}
                                  />
                                  {list?.isLayoutScreen && (
                                    <IconButton
                                      size={"small"}
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                    >
                                      <img
                                        src={Layout}
                                        alt="layout"
                                        width="14px"
                                        height="auto"
                                      />
                                    </IconButton>
                                  )}
                                </ListItem>
                              </div>
                            )}
                          </Draggable>
                        )}
                    </>
                  );
                })}
                {dropProvided.placeholder}
              </div>
            )}
          </Droppable>
        </Grid>
      </DragDropContext>
      {/* Screen List's END */}
      
      {/* More option menu list based on( Screen ) */}
      <Menu
        id="long-menu"
        anchorEl={anchorEl.position}
        keepMounted
        open={open}
        onClose={() => handleClose()}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((option) => (
          <MenuItem
            dense
            key={option}
            selected={option === "Pyxis"}
            onClick={(e) => moreOptions(e, option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      {/* More option menu list END */}
    </div>
  );
}

ScreenList.defaultProps = {
  onSwitchPreview: () => { },
  previewScreen: false,
  treeView: false,
  setState: () => { },
  deleteOldScreen: () => { },
  openModal: () => { },
  menusList: [],
  selected: 1,
  selectedAllScreen: false,
  onClickScreenButton: () => { },
};

export default withAlertAndDialog(ScreenList);
