import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Avatar, Popover, MenuList, MenuItem } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import { getDefaultProfileImg, localStorageKeys } from "../utils";
import { AuthContext } from "../contexts";
import { useHistory } from "react-router-dom";
import config from "../config";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid #ebebeb",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  layoutLeftIcon: {
    width: "18px",
    height: "14px",
    border: "2px solid",
    borderRadius: "2px",
    borderLeftWidth: "6px",
  },
  layoutRightIcon: {
    width: "18px",
    height: "14px",
    border: "2px solid",
    borderRadius: "2px",
    borderRightWidth: "6px",
  },
  sizeRiplle: {
    padding: "4px",
    borderRadius: 2,
  },
  active: {
    backgroundColor: "#3f51b542",
  },
  userMenu: {
    cursor: "pointer",
    display: "flex",
  },
  profilePic: {
    width: "28px",
    height: "28px",
    borderRadius: "8px",
  },
  profileName: {
    margin: "auto",
    padding: "0px 0px 0px 8px",
  },
  header: {
    minHeight: "38px",
  },
  versionNo: {
    paddingLeft: "10px",
    fontSize: "10px",
  },
}));

export function DenseAppBar(props) {
  const auth = React.useContext(AuthContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const logOut = () => {
    localStorage.clear();
    window.location.replace(config.qdm_admin_url);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" color={"inherit"} elevation={0}>
        <Toolbar variant="dense" className={classes.header}>
          <Typography variant="h6" color="primary">
            {props.title ?? ""}
            <Typography
              className={classes.versionNo}
              component={"span"}
              variant={"subtitle2"}
              color={"textSecondary"}
            >
              {"v" + localStorage.getItem(localStorageKeys.version)}
            </Typography>
          </Typography>

          <div className={classes.grow} />
          <div>
            <div className={classes.userMenu} onClick={handleClick}>
              <Avatar
                className={classes.profilePic}
                // alt="Remy Sharp"
                src={getDefaultProfileImg(auth.user?.user?.name || "User")}
              />
              <Typography className={classes.profileName}>
                {auth.user?.user?.name ?? ""}
              </Typography>
              <ArrowDropDownRoundedIcon
                fontSize={"default"}
                style={{ marginTop: "2px" }}
                color={"action"}
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>

      {/* UserProfile details */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          {/* <MenuItem>Profile</MenuItem>
          <MenuItem>My account</MenuItem> */}
          <MenuItem onClick={() => logOut()}>Logout</MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
}
